import * as React from 'react';
import { Text } from 'naan/primitives/text';
import { Badge } from 'naan/primitives/badge';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';

const VolumeCardMetaWrapper = styled('div', {
  marginBottom: '$2',
  lineHeight: '20px',
  '@small': {
    marginBottom: '0',
  },
});

export const VolumeCardMeta = ({ story }: { story: Story }) => {
  const isFree = story.trial;
  const durationAsText = `${story.durationMinutes}m`;
  const chaptersAsText = `${story.chapterCount} chapters`;

  return (
    <VolumeCardMetaWrapper>
      {isFree ? <Badge>Free</Badge> : null}
      {/* {hasActivityGuide ? (
        <Badge type="activityGuide">Classroom activities</Badge>
      ) : null} */}
      <Text as="span" textStyle={'small-text'} color={'textSecondary'}>
        {durationAsText}
      </Text>
      {chaptersAsText ? (
        <Text as="span" textStyle={'small-text'} color={'textSecondary'}>
          , {chaptersAsText}
        </Text>
      ) : null}
    </VolumeCardMetaWrapper>
  );
};
