import React from 'react';
import { getConfig } from 'app/env';

const { emails, emailOptions } = getConfig('website');

export const FeedbackLink: React.FC = ({ children }) => {
  const href = `mailto:${emails.feedback}?subject=${emailOptions.subject}&body=${emailOptions.body}`;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} onClick={() => window.open(href)}>
      {children}
    </a>
  );
};
