import * as React from 'react';
import { Menu, MenuItem } from 'naan/primitives/menus';
import { AlertDialog } from 'naan/primitives/modals';
import { useSwitch } from 'common/hooks/use-switch';
import { useCurrentClassroom } from './current-clasroom-context';
import styled from 'styled-components';

import __ from 'core/lib/localization';
import { AppFactory } from 'app/app-factory';
import { ClassRenamingModal } from './assignment-dialog/class-renaming-modal';

const Wrapper = styled.div`
  max-width: 400px;
`;

export const ClassroomOverflowMenu = () => {
  const { classroom, deleteCurrentClassroom } = useCurrentClassroom();

  const { isDeletable } = classroom;

  const [showArchiveDialog, { toggle, off }] = useSwitch(false);

  const handleRenameClass = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <ClassRenamingModal onDismiss={onDismiss} classroom={classroom} />
    ));
  }, []);

  return (
    <>
      <Menu>
        <MenuItem label="Rename class" action={handleRenameClass} />
        {isDeletable ? (
          <MenuItem label="Delete class" isDestructive={true} action={toggle} />
        ) : null}
      </Menu>

      {showArchiveDialog ? (
        <AlertDialog
          open
          heading={__(
            'Delete class "%{label}"?',
            'common.confirmClassDeletionTitle',
            {
              label: classroom.label,
            }
          )}
          body={
            <Wrapper>
              {__(
                'Students in this class will no longer see this class or its assignments in the Lupa app. Deleting the class will not change any full access to Lupa they have on their accounts. This action cannot be undone.',
                'common.confirmClassDeletionBody'
              )}
            </Wrapper>
          }
          onDismiss={off}
          okButtonAction={deleteCurrentClassroom}
          okButtonLabel={__('Yes, delete', 'classroom.yesDelete')}
          cancelButtonLabel={__('No, keep', 'classroom.noKeep')}
          cancelButtonAction={off}
          showCancelButton={true}
        />
      ) : null}
    </>
  );
};
