/// Finds the first parent of a given DOM node which can scroll
/// I must confess: I just copy pasted this from stack overflow.
/// Seems good enough for me for our usecase, but here's a gist with other versions
/// https://gist.github.com/twxia/bb20843c495a49644be6ea3804c0d775e
export function getFirstScrollingParent(node: any): any {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getFirstScrollingParent(node.parentNode);
  }
}
