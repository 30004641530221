// import React from 'react';
// import { Notification } from 'components/ds/notification';
import { reportException } from 'common/error-reporting';

// import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

const notificationTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
} as const;

type TNotificationType =
  typeof notificationTypes[keyof typeof notificationTypes];

export type TNotificationAction = {
  label: string;
  callback: () => void;
};

export type TNotification = {
  message: string;
  type: TNotificationType;
  action?: TNotificationAction;
  timeout?: number;
};

export type TNotificationService = ReturnType<typeof createNotificationService>;

const DEFAULT_NOTIFICATION_TIMEOUT = 5000;

/**
 * Creates an observable notification object.
 *
 * TODO: sometime, consider using a queue instead.
 */
export function createNotificationService() {
  const current = observable.box<TNotification | null>(null);
  let timeoutId: number | null = null;

  const open = action((notification: TNotification) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    const newNotification = {
      timeout: DEFAULT_NOTIFICATION_TIMEOUT,
      ...notification,
    };

    /// If we have a notification already set, we need to close it first.
    if (current.get() !== null) {
      clear();
      ///… and then open the new one.
      timeoutId = window.setTimeout(() => {
        current.set(newNotification);
      }, 300);
    } else {
      /// open the new one right away.
      current.set(newNotification);
    }
  });

  const clear = action(() => {
    current.set(null);
  });

  return { open, clear, current };
}

// todo: consider using the getInstance pattern here

// Singleton.
export const NotificationService = createNotificationService();

(window as any).NotificationService = NotificationService;

// @deprecated
export const notifySuccess = (message: string) => {
  // log.info(`success: ${message}`);
  NotificationService.open({
    type: notificationTypes.SUCCESS,
    message,
  });
};

// @deprecated
export const alertWarning = (message: string) => {
  // log.info(`warn: ${message}`);
  NotificationService.open({
    type: notificationTypes.WARNING,
    message,
  });
};

// @deprecated
export const alertError = (message: string, data = {}) => {
  /// FIXME: In retrospective, this is a bad pattern. Notifications should not have side effects.
  /// this saves us keystrokes, but makes things fragile.
  forwardException(message, data);
  NotificationService.open({
    type: notificationTypes.ERROR,
    message,
  });
};

// @deprecated
const forwardException = (message: string, data: any = {}) => {
  const { report, exception, level = 'error', handlerTag, ...debugData } = data;

  if (report === false) {
    return;
  }

  if (exception) {
    reportException(exception, { ...debugData }, { handlerTag, level });
  } else {
    // Sentry.captureMessage(message, level);
    // console.log(`TODO Sentry.captureMessage(${message}, ${level})`);
  }
};

// export const NotificationsTarget = observer(() => {
//   const notification = NotificationService.current.get();
//   if (notification === null) {
//     return null;
//   }
//   return (
//     <Notification {...notification} onDismiss={NotificationService.clear} />
//   );
// });
