import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import cx from 'classnames';
import { includeTextStyles, getColor } from 'legacylib/theme/utils';
import { prevent } from 'common/prevent';
import { useSwitch } from 'common/hooks/use-switch';
import { HidePasswordIcon } from 'components/ui/icons/hide-password-icon';
import { ShowPasswordIcon } from 'components/ui/icons/show-password-icon';
import { BaseInput } from './base-input';

import __ from 'core/lib/localization';

const PasswordInputWrapper = styled.div`
  &.with-password-link {
    position: relative;
    z-index: 1;
    @media screen and (min-width: 550px) {
      margin-bottom: 40px;
    }
    a {
      ${includeTextStyles('small-text')};
      color: ${getColor('teal500')};
      position: absolute;
      right: 0;
      top: 3px;
      text-decoration: underline;

      @media screen and (min-width: 550px) {
        top: 56px;
      }
    }
  }
`;

export const PasswordInput = ({ showPasswordLink, ...props }) => {
  const [showing, showSwitch] = useSwitch(false);
  const type = showing ? 'text' : 'password';
  return (
    <PasswordInputWrapper
      className={cx({ 'with-password-link': showPasswordLink })}
    >
      <BaseInput
        {...props}
        type={type}
        accessory={
          <button
            className="password-switch"
            onClick={prevent(showSwitch.toggle)}
            type="button"
            tabIndex={-1}
          >
            {showing ? <HidePasswordIcon /> : <ShowPasswordIcon />}
          </button>
        }
      />
      {showPasswordLink ? (
        <Link to="/reset-password" data-test-id="forgot-password">
          {__('Forgot password?', 'auth.forgotPassword')}
        </Link>
      ) : null}
    </PasswordInputWrapper>
  );
};
