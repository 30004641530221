// DELETEME: this file is obsolete and should be deleted after 6.2
import * as React from 'react';
import { NavItem } from 'naan/primitives/nav';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { GiftIcon } from 'naan/icons/gift-icon';
import { SearchIcon } from 'naan/icons/search-icon';
import { ExternalLinks } from './external-links';
import { FavoriteIcon } from 'naan/icons/favorite-icon';
import { VolumeModeLinks } from './volume-mode-links';
import { DevLinks } from './dev-links';
import { EduIcon } from 'naan/icons/edu-icon';
import __ from 'core/lib/localization';

export const TeacherLinks = () => {
  return (
    <>
      <VolumeModeLinks />
      <NavItem
        to="/app/teach/es/discover"
        icon={<SearchIcon />}
        label={__('Discover', 'nav.discover')}
      />
      <NavItem
        to="/app/teach/es/favorites"
        icon={<FavoriteIcon />}
        label={__('Favorites', 'nav.favorites')}
      />
      <NavItem
        to="/app/teach/es/classrooms"
        icon={<EduIcon />}
        label={__('Classrooms', 'nav.classrooms')}
      />
      {/* <VSpacer size={8} />
      <MyClassesSidebarWidget />
      <VSpacer size={8} /> */}
      <ExternalLinks isTeacher />
      <NavItem
        to="/gift-coupons"
        icon={<GiftIcon />}
        label={__('Gift coupons', 'nav.giftCoupons')}
        size="small"
      />
      <NavItem
        to="/account"
        icon={<AvatarIcon />}
        label={__('My account', 'nav.myAccount')}
        size="small"
      />
      <DevLinks />
    </>
  );
};
