import { AppFactory } from 'app/app-factory';
import __ from 'core/lib/localization';

const DEFAULT_INTERVAL_MILLIS = 1000 * 60 * 5;

export function showUpdateToast() {
  AppFactory.toastService.open({
    message: __('This site has been updated', 'common.thisSiteHasBeenUpdated'),
    type: 'info',
    action: {
      label: __('Refresh', 'common.refresh'),
      callback: () => {
        window.location.reload();
      },
    },
    timeout: null,
  });
}

export function checkForUpdate(
  buildVersion: string,
  intervalMillis: number = DEFAULT_INTERVAL_MILLIS
) {
  setTimeout(() => {
    fetch('/version.txt')
      .then(res => res.text())
      .then(text => {
        const fetchedVersion = text.trim();
        // console.log({ buildVersion, fetchedVersion });
        if (fetchedVersion !== buildVersion) {
          // eslint-disable-next-line no-console
          console.log({ buildVersion, fetchedVersion });
          showUpdateToast();
        }
        checkForUpdate(buildVersion, intervalMillis);
      });
  }, intervalMillis);
}
