// FIXME: this uses an outdated version of the Dialog component. Not recommended to use anymore.
import { devMode } from 'app/platform';
import { action, observable } from 'mobx';

export type DialogProps = {
  title?: null | string | React.ReactNode;
  body: React.ReactNode | string | (() => React.ReactNode);
  onDismiss?: () => void;
  okButtonAction?: () => void;
  okButtonLabel?: string | React.ReactNode | null;
  okIsDangerous?: boolean;
  cancelButtonAction?: () => void;
  cancelButtonLabel?: string | React.ReactNode | null;
  showCancelButton?: boolean;
  showIcon?: boolean;
  iconType?: 'success' | 'warning' | 'error' | 'info';
};

class DialogService {
  currentDialog = observable.box<DialogProps | null>(null);

  openDialog = action((dialog: DialogProps) => {
    this.currentDialog.set(dialog);
  });

  closeDialog = action(() => {
    this.currentDialog.set(null);
  });
}

const singleton = new DialogService();

if (devMode) {
  (window as any).DialogService = singleton;
}

export default singleton;
