import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { CouponCodeForm } from 'components/gift-coupons/coupon-code-form';
import { AppFactory } from 'app/app-factory';
import { CouponAppliedDialog } from 'components/gift-coupons/coupon-applied-dialog';
import { VSpacer } from 'naan/primitives/spacer';

const FormWrapper = styled('div', {
  backgroundColor: '$colors$gray-25',
  padding: 16,
  borderRadius: 12,
  '& > h3': {
    textStyle: 'small-heading',
    gridColumn: '1/-1',
    textAlign: 'center',
    marginBottom: 8,
  },
  '& > h4': {
    textStyle: 'body',
    color: '$colors$textSecondary',
    gridColumn: '1/-1',
    textAlign: 'center',
    marginBottom: 24,
  },
  '& > .form': {
    gridColumn: '3/-3',
  },
  '@small': {
    padding: 24,
  },
  '@medium': {
    paddingTop: 48,
    paddingBottom: 64,
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    '& > h3': {
      textStyle: 'medium-heading',
    },
  },
});

export const CouponCodeFormWidget = ({
  title,
  subtitle,
  placeholder,
  buttonLabel,
}: {
  title: string;
  subtitle?: string;
  placeholder: string;
  buttonLabel: string;
}) => {
  const handleCouponCodeSuccess = (result: any) => {
    if (result?.message) {
      AppFactory.dialogPresenter.present(onDismiss => (
        <CouponAppliedDialog onDismiss={onDismiss} message={result.message} />
      ));
    }
  };

  return (
    <>
      <FormWrapper>
        {title ? <h3>{title}</h3> : null}
        {subtitle ? <h4>{subtitle}</h4> : <VSpacer size={8} />}

        <div className="form">
          <CouponCodeForm
            onCouponCodeSuccess={handleCouponCodeSuccess}
            placeholder={placeholder}
            buttonLabel={buttonLabel}
          />
        </div>
      </FormWrapper>
      {/* <LargeGap /> */}
    </>
  );
};
