import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NavItem } from 'naan/primitives/nav';
import { AppFactory } from 'app/app-factory';
import { MuseumIcon } from 'naan/icons/museum-icon';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { Link } from 'naan/primitives/text';
// import { ArrowTopRightIcon } from 'naan/icons/arrow-top-right-icon';
// import { useNavigate } from 'react-router-dom';
// import { MenuItem } from 'naan/primitives/menus';

// should become obsolete
export const DevLinks = () => {
  if (AppFactory.root.userManager?.accountData?.showDevTools) {
    return (
      <NavItem
        to="/app/profile/dev-tools"
        icon={<MuseumIcon />}
        label={'Dev tools'}
        size="small"
      />
    );
  } else {
    return null;
  }
};

export const DevToolsLink = () => {
  if (AppFactory.root.userManager?.accountData?.showDevTools) {
    return (
      <VStack>
        <VSpacer size={4} />
        <HStack justify="end">
          <Link
            as={RouterLink}
            to="/app/profile/dev-tools"
            className="account__dev-tools-link"
          >
            Dev Tools
          </Link>
        </HStack>
        {/* <VSpacer size={4} /> */}
      </VStack>
    );
  } else {
    return null;
  }
};

// menu item might be handy, but for now will just use a link from the 'my account' screen

// export const DevMenuItem = () => {
//   const navigate = useNavigate();

//   const goto = React.useCallback(
//     (to: string) => () => navigate(to),
//     [navigate]
//   );

//   if (AppFactory.root.userManager?.accountData?.showDevTools) {
//     return (
//       <MenuItem
//         label={'Dev Tools'}
//         leftIcon={<MuseumIcon />}
//         rightIcon={<ArrowTopRightIcon width={20} height={20} />}
//         presentation="navItem"
//         action={goto('/_dev')}
//       />
//     );
//   } else {
//     return null;
//   }
// };
