// DEPRECATED - use primitives to build your own adhoc dialogs
import React from 'react';
import { isFunction } from 'lodash';
import * as Dialog from './dialog';

type OkButtonProps = {
  open: boolean;
  okButtonAction: () => void;
  okButtonLabel?: string;
  okIsDangerous?: boolean;
  okIsDisabled?: boolean;
};

type CancelButtonProps = {
  showCancelButton?: boolean;
  cancelButtonAction?: () => void;
  cancelButtonLabel?: string;
  cancelIsDangerous?: boolean;
  cancelIsDisabled?: boolean;
};

type DialogProps = OkButtonProps &
  CancelButtonProps & {
    heading?: string;
    body: React.ReactNode;
    onDismiss?: () => void;
    showCloseIconButton?: boolean;

    autoDismissOnOk?: boolean;
  };

export const AlertDialog = ({
  open = true,
  heading,
  body = '',
  onDismiss = () => {},
  okButtonAction,
  okButtonLabel = 'OK',
  okIsDisabled = false,
  showCancelButton = true,
  cancelButtonLabel = 'Cancel',
  cancelIsDisabled = false,
  cancelButtonAction,
  showCloseIconButton = false,
  autoDismissOnOk = true,
}: DialogProps) => {
  const handleOkButton = React.useCallback(() => {
    if (isFunction(okButtonAction)) {
      if (okButtonAction() === false) {
        return;
      }
    }

    if (autoDismissOnOk) {
      onDismiss();
    }
  }, [autoDismissOnOk, okButtonAction, onDismiss]);

  return (
    <Dialog.Container open={open} onDismiss={onDismiss}>
      {showCloseIconButton ? <Dialog.CloseButton /> : null}
      {heading ? <Dialog.Heading>{heading}</Dialog.Heading> : null}
      <Dialog.Body>{body}</Dialog.Body>
      <Dialog.ButtonsContainer>
        {showCancelButton ? (
          <Dialog.Button
            presentation={'cancel'}
            label={cancelButtonLabel}
            disabled={cancelIsDisabled}
            data-test-id="cancelButton"
            onClick={cancelButtonAction}
            css={{
              width: '100%',
            }}
          />
        ) : null}

        <Dialog.Button
          presentation={'teal'}
          label={okButtonLabel}
          disabled={okIsDisabled}
          data-test-id="okButton"
          css={{
            width: '100%',
          }}
          onClick={e => {
            e.preventDefault();
            handleOkButton();
          }}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};
