import * as React from 'react';
import { ToastService } from 'common/notifications/toast-service';
import __ from 'core/lib/localization';
import { Story } from 'core/models/story-manager';

export const useFavoriteToggle = (story: Story) => {
  const favoriteToggle = React.useCallback(() => {
    // story.toggleClassroomFavorite().then(() => {
    //   if (!story.isClassroomFavorited) {
    //     ToastService.open({
    //       message: __('Removed from favorites', 'favorites.favoriteRemoved'),
    //       type: 'success',
    //     });
    //   }
    // });
    story.toggleClassroomFavorite();
    // je: i don't think we need to wait for the persistence
    if (!story.isClassroomFavorited) {
      // @frank do we even care about showing a toast here?
      ToastService.open({
        message: __('Removed from favorites', 'favorites.favoriteRemoved'),
        type: 'success',
      });
    }
  }, [story]);

  return { favoriteToggle };
};
