import { compact, includes, pick } from 'lodash';

import { createLogger } from 'app/logger';
import { applySnapshot, ModelTreeNode, snap } from 'ts-state-tree/tst-core';

import {
  BEGINNING_OF_CHAPTER,
  END_OF_CHAPTER,
  END_OF_STORY_CHAPTER,
} from 'core/lib/constants/vars';

import invariant from 'core/lib/invariant';
import { LocationPointer } from './location-pointer';
import { Story } from '../story-manager';
import { Root } from '../root';
import { IObservableArray } from 'mobx';
import { ListeningStats } from './listening-stats';
import { getBaseRoot } from '../base-root';
import { UnitCatalogData } from '../catalog';

const log = createLogger('um:story-progress');

/**
 * StoryProgress
 *
 * holds user's listening progress of a particular story
 */
export class StoryProgress extends ModelTreeNode {
  static CLASS_NAME = 'StoryProgress' as const;

  static create(snapshot: any) {
    return super.create(StoryProgress, snapshot) as StoryProgress;
  }

  slug: string;
  currentPoint: LocationPointer = snap({});
  furthestPoint: LocationPointer = snap({});
  vocabs: string[] = []; //  word group slugs, i.e. "765-cruzar" (millis/100 + first word)
  lastListened: number = 0; // millis since epoch

  get root(): Root {
    return getBaseRoot(this);
  }

  get story(): Story {
    const { storyManager } = this.root;
    if (!storyManager) return null;
    return storyManager.story(this.slug);
  }

  get currentUnit(): UnitCatalogData {
    if (this.currentPoint.atEndOfStory) {
      return null;
    }
    const unitNumber = this.currentPoint.unit;
    return this.story.unitDataByNumber(unitNumber);
  }

  updateCurrentPoint(point: LocationPointer) {
    applySnapshot(this.currentPoint, point);
    if (this.furthestPoint.isLessThan(point)) {
      applySnapshot(this.furthestPoint, point);
    }

    // handle the case that we've completed the first listen
    // and then switched back to study mode during fluent listen
    if (point.chapter === this.furthestPoint.chapter) {
      this.furthestPoint.fluentListenStatus = point.fluentListenStatus;
    }
  }

  // /**
  //  *
  //  * [ ] - invoked upon exiting study view,
  //  * [ ] - update the StoryProgress' currentPoint and potentially furthestPoint
  //  * [ ] - if chapter is completed (based on lastPoint) then add/update ListeningLog entry
  //  */
  // updateProgress(
  //   furthestListenedAddress: string,
  //   furthestListenedMillis = 0 // not relevant if END_OF_CHAPTER address passed in
  // ) {
  //   // try {
  //   log.info(
  //     `updateProgress to: ${furthestListenedAddress} ${furthestListenedMillis}`
  //   );
  //   const story = this.story;
  //   let { currentPoint, furthestPoint } = this;
  //   const chapter = story.currentChapter;
  //   invariant(!!chapter, 'updateProgress - no currentChapter');
  //   log.info(`current chapter: ${JSON.stringify(chapter)}`);
  //   const isRelisten = currentPoint.iteration > 1;
  //   const { durationMillis: chapterLengthMillis, markCompleteMillis } = chapter;

  //   if (furthestListenedMillis >= markCompleteMillis) {
  //     furthestListenedAddress = END_OF_CHAPTER;
  //   }

  //   const isChapterComplete = furthestListenedAddress === END_OF_CHAPTER;

  //   currentPoint.address = furthestListenedAddress;
  //   currentPoint.millisPlayed = furthestListenedMillis;

  //   if (isChapterComplete) {
  //     currentPoint.address = BEGINNING_OF_CHAPTER;
  //     currentPoint.millisPlayed = 0;
  //     if (currentPoint.iteration === 1) {
  //       currentPoint.iteration = 2;
  //     } else {
  //       currentPoint.chapter += 1;
  //       if (currentPoint.chapter > story.chapterCount) {
  //         log.warn(
  //           `updateProgress - current chapter greater than total, marking story complete`
  //         );
  //         currentPoint = LocationPointer.create({
  //           chapter: END_OF_STORY_CHAPTER,
  //           // defaults are fine
  //           // address: BEGINNING_OF_CHAPTER,
  //           // iteration: 1,
  //           // millisPlayed: 0,
  //         });
  //       } else {
  //         if (furthestPoint.isLessThan(currentPoint)) {
  //           currentPoint.iteration = 1;
  //         } else {
  //           currentPoint.iteration = 2;
  //         }
  //       }
  //     }

  //     const { userManager } = this.root;
  //     userManager.userData.addListeningLog(
  //       this.slug,
  //       isRelisten ? 0 : chapterLengthMillis,
  //       isRelisten ? chapterLengthMillis : 0
  //     );
  //   }
  //   if (furthestPoint.isLessThan(currentPoint)) {
  //     furthestPoint = LocationPointer.create({ ...currentPoint });
  //   }
  //   this.currentPoint = currentPoint;
  //   this.furthestPoint = furthestPoint;
  //   this.lastListened = Date.now(); // millis since epoch

  //   // TODO: revisit volume support
  //   // if (!story.isCurrentUnit) {
  //   //   log.info(`updateProgress - updating currentUnit to ${story.slug}`);
  //   //   // @joseph this was breaking in Lupa
  //   //   if (story.volume) {
  //   //     /// avoid running this on Lupa
  //   //     story.markCurrentUnit();
  //   //   }
  //   // }

  //   this.root.userManager.persistUserData(); // async
  //   // } catch (error) {
  //   //   safelyHandleError(this, error);
  //   // }
  // }

  vocabExists(slug: string): boolean {
    return includes(this.vocabs, slug);
  }

  addVocab(slug: string): void {
    if (!this.vocabExists(slug)) {
      this.vocabs.push(slug);
    }
  }

  addVocabs(slugs: string[] = []): void {
    log.info(`addVocabs[${slugs}]`);
    slugs.map(() => this.addVocab);
  }

  removeVocab(slug: string): void {
    // should we check it exists?
    (this.vocabs as IObservableArray).remove(slug);
  }

  clearVocabs(): void {
    log.info('clearVocabs');
    (this.vocabs as IObservableArray).clear();
  }

  removeVocabs(slugs: string[] = []) {
    log.info(`removeVocabs[${slugs}`);
    slugs.map(() => this.removeVocab);
  }

  /**
   * remove any vocabs now found in current story data
   */
  pruneOrphanVocabs(): void {
    const vocabLookupData = this.story.vocabLookupData;
    const orphans = this.vocabs.filter(slug => {
      return !(vocabLookupData as any)[slug]; // TODO
    });
    if (orphans.length > 0) {
      log.info(`removing orphaned vocabs: ${JSON.stringify(orphans)}`);
      this.removeVocabs(orphans);
    }
  }

  markStoryComplete() {
    const location = {
      chapter: END_OF_STORY_CHAPTER,
    };
    this.currentPoint = LocationPointer.create(location);
    this.furthestPoint = LocationPointer.create(location);
    this.root.userManager.persistUserData(); // async
  }

  resetStory() {
    this.currentPoint = LocationPointer.create({});
    this.furthestPoint = LocationPointer.create({});
    this.clearVocabs();
    this.root.userManager.persistUserData(); // async
  }

  // used when navigating back to a completed unit
  relistenStory() {
    this.openChapter(1);
  }

  openChapter(chapterPosition: number) {
    log.info(
      `furthestPoint.chapter: ${this.furthestPoint.chapter}, chapterPosition: ${chapterPosition}`
    );
    const story = this.story;
    if (this.currentPoint.chapter === chapterPosition) {
      log.info(`resuming current chapter - no change to pointers`);
      return;
    }
    if (this.furthestPoint.chapter < chapterPosition) {
      if (chapterPosition > story.chapterCount) {
        this.markStoryComplete();
      } else {
        log.info(`advancing to new chapter: ${chapterPosition}`);
        const location = {
          chapter: chapterPosition,
        };
        this.currentPoint = LocationPointer.create(location);
        this.furthestPoint = LocationPointer.create(location);
      }
    } else if (this.furthestPoint.chapter > chapterPosition) {
      log.info(`revisiting previous chapter: ${chapterPosition}`);
      this.currentPoint = LocationPointer.create({
        chapter: chapterPosition,
        iteration: 2,
      });
    } else {
      log.info(
        `resuming furthest chapter: ${chapterPosition} - old furthest point: ${JSON.stringify(
          this.furthestPoint
        )}`
      );
      this.currentPoint = LocationPointer.create({
        ...this.furthestPoint,
      });
    }
  }

  unlockChapter(chapterPosition: number): void {
    this.markCompleteChapter(chapterPosition - 1);
  }

  markCompleteChapter(chapterPosition: number): void {
    let locationData: any;
    if (chapterPosition >= this.story.currentUnitChapterCount) {
      if (this.story.currentUnitIsLast) {
        this.markStoryComplete();
        return;
      } else {
        locationData = { unit: this.currentPoint.unit + 1, chapter: 1 };
      }
    } else {
      locationData = {
        unit: this.currentPoint.unit,
        chapter: chapterPosition + 1,
      };
    }

    // const location = {
    //   chapter: chapterPosition + 1,
    //   // default values are fine for the rest of the properties
    //   // iteration: 1,
    //   // address: BEGINNING_OF_CHAPTER,
    //   // millisPlayed: 0,
    // };

    this.currentPoint = LocationPointer.create(locationData);
    if (this.furthestPoint.isLessThan(this.currentPoint)) {
      this.furthestPoint = LocationPointer.create(locationData);
    }
    this.root.userManager.persistUserData(); // async
  }

  get unplayed(): boolean {
    return !this.played;
  }

  get played(): boolean {
    return this.furthestPoint?.played;
  }

  get inProgress(): boolean {
    return this.furthestPoint?.played && !this.completed;
  }

  // effectively a sub-state of completed.
  // used by the volume-level logic which reflects the distinction
  // of 'inProgress' when the user has started relistening after completing a volume
  get relistening(): boolean {
    return this.completed && this.listening;
  }

  // drives the "continue" CTA label
  get listening(): boolean {
    return this.currentPoint?.listening;
  }

  get completed(): boolean {
    return this.furthestPoint?.chapter === END_OF_STORY_CHAPTER;
  }

  get completedChapters(): number {
    // const chaptersCompleted = progress.storyProgress?.furthestPoint ? progress.storyProgress.furthestPoint.chapter + progress.storyProgress.furthestPoint.iteration - 2 : null;
    // if (this.furthestPoint?.chapter && this.furthestPoint?.iteration) {
    //   return this.furthestPoint.chapter + this.furthestPoint.iteration - 2;
    // } else {
    //   return 'n/a';
    // }
    return this.furthestPoint?.completedChapters;
  }

  get displayProgress(): string {
    // todo: localize
    // todo: figure out why this.story is sometimes undefined
    return `${this.completedChapters}/${this.story?.chapterCount} chapters complete`;
  }

  get status(): string {
    if (this.unplayed) {
      return 'UNPLAYED';
    }
    if (this.completed) {
      return 'COMPLETED';
    }
    return 'IN_PROGRESS';
  }

  // @armando should be using `vocabs` or `vocab` for these getter names?
  get vocabsCount(): number {
    return this.vocabs.length;
  }

  get hasVocab(): boolean {
    return this.vocabs.length > 0;
  }

  /**
   * data needed by vocab view
   */
  get vocabsViewData() {
    const story = this.story;
    if (!story) return null;

    const { vocabLookupData } = story;
    const list = compact(
      this.vocabs.map(slug => {
        return (vocabLookupData as any)[slug]; // TODO
      })
    ).sort((vocabA, vocabB) => {
      if (vocabA.chapterPosition !== vocabB.chapterPosition) {
        return vocabA.chapterPosition - vocabB.chapterPosition;
      }
      return vocabA.address.localeCompare(vocabB.address);
    });
    const chapterMap: { [index: number]: any } = {}; // TODO
    list.forEach(row => {
      let chapterData = chapterMap[row.chapterPosition];
      if (!chapterData) {
        chapterData = pick(row, ['chapterPosition', 'chapterTitle']);
        chapterData.data = [];
        chapterMap[row.chapterPosition] = chapterData;
      }
      chapterData.data.push(row);
    });
    return Object.values(chapterMap);
  }

  get listeningStats(): ListeningStats {
    const { userManager } = this.root;
    if (!userManager) return null;
    return userManager.userData.storyListeningStats(this.slug);
  }
}
