import React from 'react';

import { Client, BrowserConfig, BugsnagStatic } from '@bugsnag/browser';
import { NodeConfig } from '@bugsnag/node';

/// Bugsnag doesn't export this type, so we have to do it ourselves.
interface UniversalBugsnagStatic extends BugsnagStatic {
  start(apiKeyOrOpts: string | BrowserConfig | NodeConfig): Client;
  createClient(apiKeyOrOpts: string | BrowserConfig | NodeConfig): Client;
}

/// This is necessary because we only want to mount the Bugsnag error boundary when
/// bugsnag is enabled. By default Bugsnag offers a all or none solution, so this is a
/// way to conditionally render it.
export const BugReportingBoundary = React.memo(
  ({
    children,
    bugsnagStatic,
    bugsnagEnabled = false,
  }: {
    children: React.ReactNode;
    bugsnagStatic: UniversalBugsnagStatic;
    bugsnagEnabled?: boolean;
  }) => {
    const ErrorBoundary = React.useMemo(() => {
      if (bugsnagEnabled) {
        return bugsnagStatic.getPlugin('react') as any;
      }
      return React.Fragment;
    }, [bugsnagEnabled, bugsnagStatic]);

    return <ErrorBoundary>{children}</ErrorBoundary>;
  }
);
