import { styled } from 'naan/stitches.config';

export const ResponsiveGridLayout = styled('div', {
  // position: 'fixed',
  // top: 0,
  // left: 0,
  // right: 0,
  // bottom: 0,
  height: '100% ',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  '@large': {
    gridTemplateRows: 'none',
    gridTemplateColumns: '$$sidebarWidth auto',
    gridTemplateAreas: '"sidebar main"',
  },
});

export const ResponsiveMainContainer = styled('div', {
  // height: 'calc( 100% - $$tabbarHeight + env(safe-area-inset-bottom) )',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  // padding: '0 $space$4',

  '@large': {
    gridArea: 'main',
  },
});

ResponsiveMainContainer.displayName = 'ResponsiveMainContainer';

export const ResponsiveNavContainer = styled('div', {
  height: 'calc($$tabbarHeight + env(safe-area-inset-bottom))',

  '@large': {
    gridArea: 'sidebar',
    height: '100vh',
  },
});

export const GlobalGrid = styled('div', {
  height: '100%',
  '@large': {
    display: 'grid',
    gridTemplateColumns: '$$sidebarWidth auto',
  },
});

export const SidebarToggleControl = styled('div', {
  display: 'block',
  '@large': {
    display: 'none',
  },
});

export const ContentGridOuterContainer = styled('div', {
  // backgroundColor: '$yellow-100',
  px: '16px',
  height: '100%',
  overflow: 'auto',
  '@large': {
    px: '32px',
  },
  '@extraLarge': {
    px: '0',
  },
});

ContentGridOuterContainer.displayName = 'ContentGridOuterContainer';

export const ContentGridInnerContainer = styled('div', {
  // backgroundColor: '$blue-100',
  '@extraLarge': {
    margin: '0 auto',
    width: '960px', //maybe this should be a var somewhere
  },
});

export const ContentGrid = styled('div', {
  // backgroundColor: '$teal-100',
  display: 'grid',
  gridTemplateColumns: 'repeat(6,1fr)',
  columnGap: '24px',
  '@small': {
    gridTemplateColumns: 'repeat(12,1fr)',
  },
  '@large': {
    // background: '$blue-100',
    alignContent: 'baseline',
  },
});
