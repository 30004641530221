import { NativeError } from './native-error';
import minibus from '../minibus';
import { alertLevels } from './alert-levels';
import __ from '../localization';
import { GenericError } from './generic-error';
const { ERROR } = alertLevels;

/**
 * Like HandleableError, but tweaked to deal with StoreKit quirks
 */
export class StoreKitError extends GenericError {
  name = 'StoreKitError';

  originalError: NativeError;

  constructor(error: NativeError) {
    let { message, code } = error;

    if (code === 'E_USER_CANCELLED') {
      message = __(
        'Purchase has been cancelled',
        'purchase.cancelledByTheUser'
      );
    }

    super(message);

    this.originalError = error;
    this.report = false;
    this.alertLevel = ERROR;

    // mostly for analytics, but we don't want this file depending on analytics
    minibus.emit(`STOREKIT_ERROR:${code}`);
  }
}
