import { useEffect } from 'react';

import { getBus } from '@jiveworld/minibus';

type MinibusCallback = {
  (...args: any[]): boolean | void;
  __runOnce?: boolean;
};

export const useMinibusSubscription = (
  subName: string,
  cb: MinibusCallback,
  busName = 'global'
) => {
  useEffect(() => {
    const bus = getBus(busName);
    const sub = bus.subscribe(subName, cb);
    return () => {
      sub.unsubscribe();
    };
  }, [busName, cb, subName]);
};
