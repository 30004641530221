import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { Button } from 'naan/primitives/button';
import { PurchasedCoupon } from 'core/models/user-manager/purchased-coupon';
import { AppFactory } from 'app/app-factory';
import __ from 'core/lib/localization';

export const ResendEmailButton = ({ coupon }: { coupon: PurchasedCoupon }) => {
  const handleClick = React.useCallback(() => {
    coupon.resendInstructions().then(result => {
      if (result && result.title) {
        AppFactory.dialogPresenter.present(onDismiss => (
          <Dialog.Container onDismiss={onDismiss} open>
            <Dialog.Heading>{result.title}</Dialog.Heading>
            <Dialog.Body>{result.message}</Dialog.Body>
            <Dialog.ButtonsContainer>
              <Dialog.Button
                fullWidth
                presentation={'teal'}
                onClick={onDismiss}
                label={__('OK', 'common.ok')}
              />
            </Dialog.ButtonsContainer>
          </Dialog.Container>
        ));
      }
    });
  }, [coupon]);

  return (
    <Button
      presentation="grayLight"
      onClick={handleClick}
      label={'Resend email'}
      size="small"
    />
  );
};
