import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import { MediumHeading } from 'naan/primitives/text';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { PricingCards } from 'components/account/pricing-cards';
import { BlueLink } from 'components/ui/buttons/link-button';
import { prevent } from 'common/prevent';
import { useSwitch } from 'common/hooks/use-switch';
import { SectionBreak } from 'components/ds/common/spacers';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import __ from 'core/lib/localization';
import { formatDate } from 'utils/format-date';

export const FullNoRenew = observer(() => {
  const store = useRootStore();
  const [changing, changingSwitch] = useSwitch(false);

  const { userManager } = store;
  const { accountData } = userManager ?? {};

  return (
    <>
      <MediumHeading>
        {__('My membership', 'membershipTable.myMembership')}
      </MediumHeading>
      <VSpacer size="4" />
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipTable.membershipType')}
            </TableLabelCell>
            <TableValueCell>{accountData.membershipDisplay}</TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Recurring billing', 'membershipTable.recurringBilling')}
            </TableLabelCell>
            <TableValueCell>
              {accountData.recurringBillingDisplay}
            </TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Expires', 'membershipTable.expires')}
            </TableLabelCell>
            <TableValueCell>
              {formatDate(accountData.fullAccessUntil)}
              {accountData?.promoteEnrollment ? null : (
                <BlueLink onClick={prevent(changingSwitch.toggle) as any}>
                  {changing ? 'Cancel' : 'Extend'}
                </BlueLink>
              )}
            </TableValueCell>
          </TableRow>
          {changing ? (
            <TableRow noBorder={true}>
              <td colSpan={2}>
                <VStack>
                  <VSpacer size={10} />
                  <PricingCards />
                  <VSpacer size={10} />
                </VStack>
              </td>
            </TableRow>
          ) : null}
        </tbody>
      </Table>
      <SectionBreak />
    </>
  );
});
