import React from 'react';
import { useTitle } from 'common/hooks/use-title';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFoundScreen } from './not-found-screen';
import { ClassroomPageHeader } from 'components/classrooms/classroom-page-header';
import { CurrentClassroomContext } from 'components/classrooms/current-clasroom-context';
import { Assignments } from 'components/classrooms/classroom-assignments/assignments-list';

import { ClassroomStudents } from 'components/classrooms/students';
import { VSpacer } from 'naan/primitives/spacer';
import { ErrorBoundary } from 'components/error-boundary';
import { notifySuccess } from 'lib/services/notification-service';
import __ from 'core/lib/localization';
import { FullScreenLoader } from 'components/ds/modals';
import { ClassroomShareModal } from 'components/classrooms/classroom-share-modal';
import { AppFactory } from 'app/app-factory';
import { ParentScreenLink } from 'components/ui/parent-screen-link';
import { HeaderContainer } from 'components/header/header-container';
import { classroomsPath } from 'components/nav/path-helpers';

const LayoutWrapper = styled('section', {
  gridColumn: '1/-1',
});

export const ClassroomScreen = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { userManager } = AppFactory.root;
  const { accountData } = userManager;
  const classroom = accountData.classroom(id);
  const [deleting, setDeleting] = React.useState(false);

  const navigate = useNavigate();

  useTitle(classroom?.label ?? 'Classroom');

  /// The way we do the classroom deleting is a little contrived because of a couple of factors:
  ///
  /// 1. If we delete from a child component, things will blow up because
  ///    the clasrtoom tree ceases to exist and the child component gets unmounted
  ///    before handling things like redirection and notification. To solve that,
  ///    we pass a callback to the child component through context and handle things from here.
  /// 2. If we just delete the classroom, this screen would render the <NotFoundScreen /> component
  ///    before the rendering happpen. To prevent that, we use a `deleting` state that shows a loader
  ///    after the classroom has been deleted, but before the redirection happens
  const handleClassroomDelete = React.useCallback(() => {
    setDeleting(true);
    classroom.archive().then(() => {
      notifySuccess(__('Classroom deleted', 'classroom.deleted'));
      navigate(classroomsPath());
    });
    // todo: should catch an unexpected error. otherwise we're left in a hosed state
  }, [classroom, navigate]);

  const handleInvite = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <ClassroomShareModal classroom={classroom} onDismiss={onDismiss} />
    ));
  }, [classroom]);

  if (deleting) {
    return <FullScreenLoader />;
  }

  if (!classroom) {
    return <NotFoundScreen />;
  }

  // @armando, i'm pretty sure we can trust the nav state to have already been updated
  // by the layout at this point. agreed?
  // const { l2 } = useParams<{ l2: string }>();

  return (
    <CurrentClassroomContext.Provider
      value={{ classroom, deleteCurrentClassroom: handleClassroomDelete }}
    >
      <ContentGridInnerContainer>
        <HeaderContainer>
          <ParentScreenLink
            to={classroomsPath()}
            title={__('My classes', 'classroom.myClasses')}
          />
        </HeaderContainer>
        <ContentGrid>
          <LayoutWrapper>
            <ClassroomPageHeader />
          </LayoutWrapper>
          <LayoutWrapper>
            <ErrorBoundary>
              <Assignments />
            </ErrorBoundary>
            <VSpacer size={20} />
            <ClassroomStudents classroom={classroom} onInvite={handleInvite} />
          </LayoutWrapper>
        </ContentGrid>
      </ContentGridInnerContainer>
    </CurrentClassroomContext.Provider>
  );
});

export default ClassroomScreen;
