import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
// import { includeTextStyles, getColor } from 'legacylib/theme/utils';
import { OverflowMenu, ConfirmableMenuItem } from 'components/ds/menus';
import { SectionTitle } from 'components/ds/common/section-title';
import { Gap } from 'components/ds/common/spacers';
import { SectionBreak } from 'components/ds/common/spacers';
// import styled, { keyframes } from 'styled-components';
// import { MarkdownContainer } from 'components/ds/common/markdown-container';
import cx from 'classnames';
import { AccountData } from 'core/models/user-manager';
import { getConfig } from 'app/env';
import { keyframes, styled } from 'naan/stitches.config';
import __ from 'core/lib/localization';

// #FFEEAA = light-yellow,
// we cannout use getColor inside of keyframes for some reason.
const fade = keyframes({
  '0%': { background: '#FFEEAA' },
  '50%': { background: '#FFEEAA' },
  '100%': { background: 'transparent' },
});

const Wrapper = styled('div', {
  $$borderColor: '$colors$gray-100',

  textStyle: 'body',

  '.notice': {
    textStyle: 'small-text',
    color: '$colors$textSecondary',
    '& a': {
      color: '$colors$teal-500',
      textDecoration: 'underline',
    },
  },
  '.class-list': {
    borderBottom: '1px solid $$borderColor',
  },

  '.class-row': {
    borderTop: '1px solid $$borderColor',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.highlighted': {
      animationName: `${fade}`,
      animationDuration: '2.5s',
    },

    'div.name': {
      flex: '1',
      padding: '20px 0',
    },

    'div.menu': {
      marginLeft: 'auto',
    },
  },
});

export const JoinedClassrooms = observer(() => {
  const store = useRootStore();
  const flash: any = store.flash;
  const accountData: AccountData = store.userManager?.accountData;
  const { joinedClassrooms } = accountData;

  if (joinedClassrooms?.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <SectionTitle>{__('My classes', 'classroom.myClasses')}</SectionTitle>
      <div className="notice">
        {__(
          'See your class assignments in the Lupa mobile app.',
          'account.seeYourClassAssignmentsInTheLupaApp'
        )}{' '}
        <a
          href={getConfig('website.urls.downloadApp')}
          target="_blank"
          rel="noreferrer"
        >
          {__('Links to install', 'account.linksToInstall')}
        </a>
        <Gap size={2}></Gap>
      </div>
      <div className="class-list">
        {joinedClassrooms?.map(classroom => {
          return (
            <div
              className={cx('class-row', {
                highlighted:
                  flash?.messageParams?.classroomLabel === classroom.label,
              })}
              key={`class-${classroom.id}`}
            >
              <div className="name">{classroom.label}</div>
              {/* FIXME: use new overflow buttons */}
              <div className="menu">
                <OverflowMenu menuPosition="top-right">
                  <ConfirmableMenuItem
                    label={__('Leave classroom...', 'account.leaveClassroom')}
                    action={classroom.drop}
                    confirmationTitle={__(
                      'Leave %{class}?',
                      'account.leaveClassroomDialogTitle',
                      { class: classroom.label }
                    )}
                    confirmationBody={__(
                      'You will no longer see class assignments, and your progress will not be visible to the teacher.',
                      'account.leaveClassroomDialogBody'
                    )}
                    confirmationOkButtonLabel={__(
                      'Leave',
                      'account.leaveClass'
                    )}
                    confirmationCancelButtonLabel={undefined}
                  />
                </OverflowMenu>
              </div>
            </div>
          );
        })}
      </div>
      <SectionBreak />
    </Wrapper>
  );
});
