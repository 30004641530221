import React from 'react';
import { StoryTree } from './story-tree';
import cx from 'classnames';

import styled from 'styled-components';
import { ParagraphData } from 'core/models/catalog/bogota-types';

const Wrapper = styled.div`
  .hint {
    font: var(--medium-heading-font);
    /* margin-top: 56px; */
    margin-bottom: 24px;
    color: var(--dark-blue);
  }

  .speaker {
    font: var(--small-text-font);
    padding: 4px 8px;
    border: 1px solid var(--gray100);
    display: inline-block;
    border-radius: 3px;
    margin: 4px 0 24px;
    color: var(--gray-5);
  }

  .paragraph {
    margin: 0 0 24px;
    break-inside: avoid-page;

    @media print, screen and (min-width: 700px) {
      display: flex;
      justify-content: space-between;
    }

    &.avoid-break {
      break-inside: avoid-page;
    }

    & > p {
      /* @media print, screen and (min-width: 700px) {
        width: calc(50% - 16px);
      } */

      &.original {
        font: var(--compact-script-font);
        margin-bottom: 8px;
        @media print, screen and (min-width: 700px) {
          margin-bottom: 0;
        }
      }

      &.translation {
        font: var(--body-font);
        color: var(--text-secondary);
      }
    }
  }
`;

export const Paragraph = ({
  paragraph,
  hint,
}: {
  paragraph: ParagraphData;
  hint?: string;
}) => {
  const {
    elements,
    speaker: speakerLabel,
    isSpeakerLabelRequired: speakerLabelRequired,
  } = paragraph;
  const showSpeakerNote = speakerLabel && speakerLabelRequired;
  return (
    <Wrapper>
      {hint ? <h3 className="hint">{hint}</h3> : null}
      {showSpeakerNote ? <h4 className="speaker">{speakerLabel}</h4> : null}
      <div className={cx('paragraph', { 'avoid-break': hint })}>
        <p className="original">
          <StoryTree elements={elements} />
        </p>
        {/* <p className="translation">
          {elements.map(({ translation }) => {
            if (!translation) {
              return null;
            }
            return translation + ' ';
          })}
        </p> */}
      </div>
    </Wrapper>
  );
};
