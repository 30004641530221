/// This would be too complicated to port to Typescript,
/// wont be used for new stuff and hopefulyl we would phase it out
/// in favor of design system tokens.
/// I propose moving this to legacylib or something
import { textStyles } from 'components/ds/tokens/text-styles';
import { colors as tokenColors } from 'components/ds/tokens/colors';
import { dimensions } from 'components/ds/tokens/dimensions';

const black = '#444444';

const darkBlue = '#4c7faa';
const darkerBlue = '#4c7faa';
const teal = '#19bbd4';
const teal50 = 'rgba(25,187,212,.5)';
const shadeTeal = '#17abc2';
const darkTeal = '#37aabc';
const darkerTeal = '#0a91a6';
const softTeal = '#6bbdca';
const darkSoftTeal = '#4daebd';
const green = '#69c00d';
const softGreen = '#18b589';
const darkSoftGreen = '#009b6f';
const yellow = '#ffdf5e';
const darkYellow = '#f7c70a';
const lightYellow = '#ffe7a5';
const veryLightYellow = '#FFF2C';
const orange = '#f5832b';
const red = '#e55c58';
const blackHairline = '#e5e5e5';
const whiteHairline = 'rgba(255,255,255,.3)';
const text = black;
const link = teal;
const error = red;
const navBg = '#395f83';
const footerBg = darkBlue;
const primaryBtn = teal;
const homeHeroBg = darkerBlue;
const blockquote = yellow;
const ulBullet = teal;

const colors = {
  black,
  darkBlue,
  darkerBlue,
  teal,
  teal50,
  shadeTeal,
  darkTeal,
  darkerTeal,
  softTeal,
  darkSoftTeal,
  green,
  softGreen,
  darkSoftGreen,
  yellow,
  darkYellow,
  lightYellow,
  veryLightYellow,
  orange,
  red,
  blackHairline,
  whiteHairline,
  text,
  link,
  error,
  navBg,
  footerBg,
  primaryBtn,
  homeHeroBg,
  blockquote,
  ulBullet,
  ...tokenColors,
};

const fonts = {
  larsseitMedium: 'larsseit-medium',
  larsseitBold: 'larsseit-bold',
  roboto: 'roboto',
  freight: 'freight-text-pro',
  heading: 'larsseit-bold',
  headingMedium: 'larsseit-medium',
  body: 'roboto',
  button: 'roboto',
  dialogTitle: 'larsseit-medium',
};

export const theme = {
  colors,
  fonts,
  textStyles,
  dimensions,
};
