import { ChapterDataSubElement } from 'core/models/catalog/bogota-types';
import React from 'react';
import { typeMap } from './type-map';

export const StoryTree = ({
  elements = [],
  overrideType = null,
  ...props
}: {
  elements: ChapterDataSubElement[];
  overrideType?: any;
}) => {
  return (
    <>
      {elements.map((element, i) => {
        const Component = typeMap[element.entityType];
        if (Component) {
          return (
            <Component
              root={element}
              key={`${element.entityType}-${i}`}
              {...props}
            />
          );
        }
        return null;
      })}
    </>
  );
};
