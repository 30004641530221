import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { SpinnerIcon } from '../components/ui/icons/spinner-icon';
import styled from 'styled-components';
import { createLogger } from 'app/logger';
import { getColor } from '../legacylib/theme/utils';
import { objectFromUrlQuery } from 'common/object-from-url-query';
import { devMode } from 'app/platform';

const log = createLogger('back-to-app');

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: ${getColor('darkBlue')};
`;

const sendMessage = (type: any, payload: any) => {
  const msg = JSON.stringify({ type, payload });
  (window as any).ReactNativeWebView.postMessage(msg);
};

export const BackToAppScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // todo: think about using a route-based approach instead of queryParams
    const { success = null, fail = null } = objectFromUrlQuery();
    if (!isUndefined((window as any).ReactNativeWebView)) {
      sendMessage('checkout', { success, fail });
    } else {
      log.info('not inside a webview');
      if (!devMode) {
        navigate('account');
      }
    }
  }, [navigate]);

  return (
    <Container>
      <SpinnerIcon />
    </Container>
  );
};
