import React from 'react';
import { keys, startCase } from 'lodash';
import { includeTextStyles, getColor } from 'legacylib/theme/utils';

// import { textStyles } from 'components/ds/tokens/text-styles';
// import { Text } from 'components/ds/common/text';
import * as allIcons from 'naan/icons/all-icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    border: 1px solid #f0f0f0;

    span {
      ${includeTextStyles('small-text-bold')};
      color: ${getColor('textSecondary')};
      padding-top: 16px;
      cursor: default;
    }

    svg {
      color: ${getColor('gray600')};
    }

    &:hover {
      background-color: ${getColor('yellow100')};

      svg {
        color: ${getColor('teal500')};
      }
    }
  }
`;

export const pathname = '/icons';
export const navlabel = 'Icons';
export const naanReady = true;

export const Screen = () => {
  const icons = keys(allIcons);

  return (
    <Wrapper>
      {icons.map(iconName => {
        const Icon = allIcons[iconName];
        const label = startCase(iconName.replace(/Icon$/, ''));
        return (
          <div key={iconName}>
            <Icon />
            <span>{label}</span>
          </div>
        );
      })}
    </Wrapper>
  );
};
