import { snakeCase } from 'lodash';
import cookies from 'js-cookie';

import { getConfig } from 'app/env';
import { createLogger } from 'app/logger';

import minibus from 'common/minibus';

const log = createLogger('root-listener');

const domain = getConfig('website.cookieDomain');

const setDomainCookie = (name: string, value: string) => {
  name = snakeCase(name);
  cookies.set(name, value, { domain });
};

const removeDomainCookie = (name: string) => {
  name = snakeCase(name);
  cookies.remove(name, { domain });
};

minibus.subscribe('LOGIN_COMPLETE', userManager => {
  log.info(
    `LOGIN_COMPLETE, setting cookies, userName: ${userManager?.accountData?.name}, domain: ${domain}`
  );
  setDomainCookie('membershipState', userManager?.accountData?.membershipState);
  setDomainCookie('userName', userManager?.accountData?.name);
});

minibus.subscribe('LOGOUT_COMPLETE', () => {
  log.info(`LOGOUT_COMPLETE, clearing cookies`);
  removeDomainCookie('membershipState');
  removeDomainCookie('userName');
});
