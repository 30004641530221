import React from 'react';
import { observer } from 'mobx-react';

import { PageLayout } from 'components/guides/page-layout';
import { PageHeader } from 'components/guides/page-header';
import { StoryOverview } from './story-overview';
import { StoryScript } from './story-script';
import { StoryOutline } from './story-outline';
import { StoryFooter } from './story-footer';
import __ from 'core/lib/localization';
import { UnitCatalogData } from 'core/models/catalog';

export const StoryGuide = observer(
  ({ story, simple = false }: { story: UnitCatalogData; simple?: boolean }) => {
    return (
      <PageLayout>
        <PageHeader
          title={__(
            '%{title} story guide (v%{version})',
            'storyGuide.headerVersion',
            { title: story.title, version: story.version }
          )}
        />
        <main className="content">
          <StoryOverview story={story} simple={simple} />
          {simple ? null : <StoryOutline story={story} />}
          <StoryScript story={story} simple={simple} />
        </main>
        <StoryFooter simple={simple} />
      </PageLayout>
    );
  }
);
