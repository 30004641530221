import * as React from 'react';

import { ErrorBoundary } from '../components/error-boundary';
import {
  BrowserRouter as Router,
  createPath,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import { GiftCouponsScreen } from './gift-coupons-screen';
import { CloseAccountScreen } from './close-account-screen';
import { AccountClosedScreen } from './account-closed-screen';
import { CreateAccountScreen } from './create-account-screen';
import { SignInScreen } from './sign-in-screen';
import { ResetPasswordScreen } from './reset-password-screen';
import { CreatePasswordScreen } from './create-password-screen';
import { BackToAppScreen } from './back-to-app';
import { RedirectToStripeScreen } from './redirect-to-stripe-screen';
import { RedeemCode } from './redeem';

import { ClassroomsScreen } from './classrooms-screen';
import { SchoolActivationScreen } from './school-activation-screen';
import { NotFoundScreen } from './not-found-screen';
import { BasicScriptScreen } from './simple-script-screen';
import { StoryGuideScreen } from './story-guide-screen';
import { NotificationService } from 'lib/services/notification-service';
import { LegacySpaToastTarget } from 'components/ui/spa-toast-target';
import { ShowcaseScreen } from './showcase/showcase-screen';
import { AppWideRouteLayout } from './layouts/app-wide-route-layout';
import { SignedInRouteLayout } from './layouts/signed-in-route-layout';
import { SignedOutRouteLayout } from './layouts/signed-out-route-layout';
import { FavoritesScreen } from './favorites-screen';
import { StoryListScreen } from './learn/story-list-screen';
import { StoryDetailScreen } from './learn/story-detail-screen';
import { DashboardScreen } from './learn/dashboard-screen';
import { StudyScreen } from './learn/study-screen';
import { TeachHomeScreen } from './teach/teach-home-screen';
import { DevToolsScreen } from './dev-tools';

// disabling dynamic importing until we figure out what's going on
import AccountScreen from './account-screen';
import ClassroomAssignmentScreen from './classroom-assignment-screen';
import ClassroomScreen from './classroom-screen';
import DiscoverScreen from './discover-screen';
import VolumeDetailScreen from './volume-detail-screen';
import { ProgressScreen } from './progress-screen';
import { StripeProvider } from 'common/stripe-context';
import { ProfileLayout } from 'components/layouts/profile-layout';
import { TeachLayout } from 'components/layouts/teach-layout';
import { LearnLayout } from 'components/layouts/learn-layout';
import {
  defaultPath,
  homePath,
  learnHomePath,
  teachHomePath,
} from 'components/nav/path-helpers';
import { ClassroomsRedirection } from './classrooms-redirection';

const redeemRoutePaths = [
  '/redeem/:code',
  '/redeem-nav-gift/:code',
  '/students/join/:code',
];

const redirects: Record<string, string> = {
  // auth redirects
  '/sign-in': '/auth/sign-in',
  '/create-account': '/auth/create-account',
  '/reset-password': '/auth/reset-password',
  '/create-password': '/auth/create-password',

  // profile redirects
  '/account': '/app/profile/account',
  '/gift-coupons': '/app/profile/gift-coupons',
  '/schools': '/app/profile/schools',
  '/close-account': '/app/profile/close-account',

  // classroom redirects
  '/discover': '/app/teach/es/discover',
};

const FallbackRoute = () => {
  const location = useLocation();

  const redirectPath = redirects[location.pathname];

  if (redirectPath) {
    return (
      <Navigate
        to={createPath({ ...location, pathname: redirectPath })}
        replace={true}
      />
    );
  }

  return <NotFoundScreen />;
};

export const SpaRoutes = () => {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route element={<AppWideRouteLayout />}>
            {/* this is the route we render when the user visits the root path directly `/` */}
            <Route index element={<Navigate to={defaultPath()} replace />} />

            {/* These are a bunch of `redeem` something routes, all rendering the same thing */}
            {redeemRoutePaths.map(path => (
              <Route path={path} element={<RedeemCode />} key={path} />
            ))}

            {/* auth routes */}
            <Route path="/auth" element={<SignedOutRouteLayout />}>
              <Route path="sign-in" element={<SignInScreen />} />
              <Route path="create-account" element={<CreateAccountScreen />} />
              <Route path="reset-password" element={<ResetPasswordScreen />} />
              <Route
                path="create-password"
                element={<CreatePasswordScreen />}
              />
            </Route>

            {/* handle marketing site link, which redirects differently based on auth/user state */}
            <Route path="/classrooms" element={<ClassroomsRedirection />} />

            <Route path="/app" element={<Navigate to={homePath()} replace />} />

            <Route
              path="/app/profile"
              element={<SignedInRouteLayout component={ProfileLayout} />}
            >
              <Route index element={<Navigate to="account" />} />
              <Route path="account" element={<AccountScreen />} />
              <Route path="gift-coupons" element={<GiftCouponsScreen />} />
              <Route path="schools" element={<SchoolActivationScreen />} />
              <Route path="close-account" element={<CloseAccountScreen />} />
              <Route path="dev-tools" element={<DevToolsScreen />} />
            </Route>

            <Route
              path="/app/teach"
              element={<Navigate to={teachHomePath()} replace />}
            />

            <Route
              path="/app/teach/:l2"
              element={<SignedInRouteLayout component={TeachLayout} />}
            >
              {/* for now will redirect to either ./discover or ./classrooms */}
              <Route index element={<TeachHomeScreen />} />
              <Route path="classrooms">
                <Route index element={<ClassroomsScreen />} />
                <Route path=":id">
                  <Route index element={<ClassroomScreen />} />
                  <Route
                    path="assignments/:slug"
                    element={<ClassroomAssignmentScreen />}
                  />
                </Route>
              </Route>
              <Route path="discover" element={<DiscoverScreen />} />
              <Route path="discover/v/:slug" element={<VolumeDetailScreen />} />
              <Route path="favorites" element={<FavoritesScreen />} />
              <Route
                path="favorites/v/:slug"
                element={<VolumeDetailScreen />}
              />
            </Route>

            <Route
              path="/app/learn"
              element={<Navigate to={learnHomePath()} replace />}
            />
            <Route
              path="/app/learn/:l2"
              element={<SignedInRouteLayout component={LearnLayout} />}
            >
              {/* // work-in-progress jwnext fork of the discover screens */}
              <Route index element={<Navigate to="home" replace />} />
              <Route path="home" element={<DashboardScreen />} />
              <Route path="stories" element={<StoryListScreen />} />
              <Route path="stories/:slug" element={<StoryDetailScreen />} />
              <Route path="stories/:slug/study" element={<StudyScreen />} />
              <Route path="progress" element={<ProgressScreen />} />
            </Route>

            <Route path="/scripts">
              <Route path={'basic/:slug'} element={<BasicScriptScreen />} />
              <Route path={':slug'} element={<StoryGuideScreen />} />
            </Route>

            <Route path="/account-closed" element={<AccountClosedScreen />} />

            <Route
              path="/appsupport/back-to-app"
              element={<BackToAppScreen />}
            />

            <Route
              element={
                <StripeProvider>
                  <Outlet />
                </StripeProvider>
              }
            >
              <Route
                path="/appsupport/subscribe"
                element={<RedirectToStripeScreen />}
              />
              <Route
                path="/appsupport/subscribe/:token"
                element={<RedirectToStripeScreen />}
              />
            </Route>
            <Route path="/showcase/*" element={<ShowcaseScreen />} />
            <Route path="*" element={<FallbackRoute />}></Route>
          </Route>
        </Routes>
      </Router>
      <LegacySpaToastTarget service={NotificationService} />
    </ErrorBoundary>
  );
};
