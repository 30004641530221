import { isEmpty } from 'lodash';
import Dayjs from 'dayjs';
import { sortByArrayIndex } from 'utils/util';
import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { Root } from '../root';
import { Story } from '../story-manager';
import { getBaseRoot } from '../base-root';

export class FeaturedRelease extends ModelTreeNode {
  static CLASS_NAME = 'FeaturedRelease' as const;

  static create(snapshot: any) {
    return super.create(FeaturedRelease, snapshot) as FeaturedRelease;
  }

  slug: string = ''; // identifier
  accessibleDate: string = null;
  releaseDate: string = null;
  title: string = null;
  illustrationUrl: string = null;
  article: string = null;
  storySlugs: string[] = [];

  get root(): Root {
    return getBaseRoot(this);
  }

  get articleFirstParagraph() {
    return this.article ? this.article.split('\n\n', 1)[0].trim() : null;
  }

  get resolvedAccessibleDate() {
    return isEmpty(this.accessibleDate)
      ? this.releaseDate
      : this.accessibleDate;
  }

  get isReleased() {
    const isoDate = this.resolvedAccessibleDate;
    if (isEmpty(isoDate)) return false;
    const accessibleDate = Dayjs(isoDate as string);
    const { storyManager } = this.root;
    const { currentDate } = storyManager;
    const today = Dayjs(currentDate);
    return accessibleDate.isSame(today) || accessibleDate.isBefore(today);
  }

  get releaseMonthText() {
    if (!this.releaseDate) return '';
    return Dayjs(this.releaseDate).format('MMMM YYYY');
  }

  get stories(): Story[] {
    if (this.storySlugs === null) {
      return [];
    }

    const { storyManager } = this.root;
    if (!storyManager) return [];

    const sortByFeaturedReleaseStorySlugs = sortByArrayIndex(
      'slug',
      this.storySlugs
    );

    const includedStories = storyManager.availableStories.filter(
      (story: { slug: string }) =>
        this.storySlugs && this.storySlugs.includes(story.slug)
    );

    return sortByFeaturedReleaseStorySlugs<Story>(includedStories);
  }
}
