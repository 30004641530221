import { camelCase } from 'lodash';

type Query = Record<string, string | null>;

// this will only work for simple queries, but it's enough for our needs
export function objectFromUrlQuery<T extends Query>(): T {
  const query = window.location.search.substring(1);
  const obj: Query = {};

  if (query) {
    const pairs = query.split('&');
    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      if (typeof key === 'string') {
        obj[camelCase(key)] = value || null;
      }
    });
  }

  return obj as T;
}

// @armando, copied this variation over from the soundbite project. i don't recall
// where it came from, but i imagine we can likely factor with the above method

export function getQueryParamsFromLocation(
  location: any,
  autoCamelCase = true,
  autoEscape = true
) {
  const query = location.search.substr(1);
  const obj: any = {};

  if (query) {
    const pairs = query.split('&');
    pairs.forEach((pair: string) => {
      const [key, value] = pair.split('=');
      const normalizedKey = autoCamelCase ? camelCase(key) : key;
      obj[normalizedKey] = decodeURIComponent(value) || null;
    });
  }

  return obj;
}
