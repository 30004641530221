import { getFirstScrollingParent } from './get-first-scrolling-parent';

/// Since we use css-grid and overflow-y:auto for our scrolling needs
/// scrolling to the top is not as straightforward as it sounds.
/// in previous versions we relied on a `scrollable-container` id, but that's
/// not reliable anymore because the scrollable container changes if we have a
/// global notice or not. Also, changing the layout will break stuff.
/// Instead, we're now finding the first scrollable parent of the element
/// and use it to scroll to the top.
/// This is a bit of a hack, but it works.
export function scrollContainerToTop(node: any) {
  const scrollingParent = getFirstScrollingParent(node);

  if (scrollingParent) {
    scrollingParent?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }
}
