import React from 'react';
import { Table } from 'components/ds/tables';
import { Anchor } from 'components/ds/common/anchor';
import { Text } from 'components/ds/common/text';

const data = [
  { label: 'Fall 2020 Spanish 6', classCode: 'CL-GW835IDL', studentCount: 0 },
  { label: 'Fall 2020 ESL 3', classCode: 'CL-GW789654', studentCount: 4 },
  {
    label: 'Winter 2020 Spanish 4',
    classCode: 'CL-GW789654',
    studentCount: 4,
  },
];

const getOverflowItems = item => {
  return [{ label: 'Overflow menu item 1' }, { label: `Remove ${item.label}` }];
};

export const pathname = '/tables';
export const navlabel = 'Tables';

export const Screen = () => {
  return (
    <>
      <Table
        cells={[
          {
            fieldName: 'label',
            headerLabel: 'Label',
            renderCell: ({ label, classCode }) => {
              return <Anchor href={classCode}>{label}</Anchor>;
            },
          },
          {
            fieldName: 'classCode',
            headerLabel: 'Class code',
            renderCell: ({ classCode }) => {
              return (
                <Text textStyle={'code'} color={'gray800'}>
                  {classCode}
                </Text>
              );
            },
          },
          {
            fieldName: 'studentCount',
            headerLabel: 'Students',
          },
        ]}
        overflowMenuItems={getOverflowItems}
        data={data}
      ></Table>
    </>
  );
};
