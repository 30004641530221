import * as React from 'react';
import { clamp } from 'lodash';
import cx from 'classnames';
import styled from 'styled-components';

const scale = [0, 8, 16, 24, 32, 40, 80, 96];

export const GapWrapper = styled.div`
  display: block;
  height: ${p => p.height}px;
  &.hide-on {
    @media screen and (${p => p.hideOn}) {
      display: none;
    }
  }
`;

export const Gap = ({ size, hideOn = null }) => {
  const height = scale[clamp(size, 0, scale.length - 1)];
  return (
    <GapWrapper
      height={height}
      hideOn={hideOn}
      className={cx({ 'hide-on': !!hideOn })}
    />
  );
};

Gap.defaultProps = {
  size: 1,
};

export const SectionBreak = styled.div`
  height: 40px;

  @media screen and (min-width: 550px) and (max-width: 799px) {
    height: 56px;
  }

  @media screen and (min-width: 800px) {
    height: 80px;
  }
`;

export const MediumGap = styled.div`
  height: 40px;

  @media screen and (min-width: 550px) and (max-width: 799px) {
    height: 56px;
  }

  @media screen and (min-width: 800px) {
    height: 56px;
  }
`;

export const LargeGap = styled.div`
  height: 40px;

  @media screen and (min-width: 550px) and (max-width: 799px) {
    height: 56px;
  }

  @media screen and (min-width: 800px) {
    height: 80px;
  }
`;

export const SectionEnds = styled.div`
  height: 8px;

  @media screen and (min-width: 550px) {
    height: 16px;
  }
`;
