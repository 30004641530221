import React from 'react';
import __ from 'core/lib/localization';
import styled from 'styled-components';
import { StoryTree } from './story-tree';
import { Glossary } from './glossary';

import { ChapterProvider } from './chapter-context';
import { Notes } from './notes';
import { createGlobalStyle } from 'styled-components';
import { ChapterCatalogData } from 'core/models/catalog';
import { VocabLookup } from 'core/models/catalog/bogota-types';

const GlobalStyle = createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    border: none;
  }

  *, *:before, *:after {
    boxSizing: border-box;
  }

  a{
    text-decoration: none;
  }
  ul li{
    list-style: none;
  }
`;

const Wrapper = styled.div`
  border-top: 1px solid var(--gray100);
  padding: 24px 0 0;
  margin-bottom: 80px;

  &.position--1 {
    page-break-before: always;
    @media print {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  }

  .heading {
    font: var(--large-heading-font);
    margin-bottom: 56px;
  }

  .lines {
    background-image: repeating-linear-gradient(
      #fff,
      #fff 40px,
      var(--gray100) 40px,
      var(--gray100) 42px
    );
    background-size: 42px 100%;

    margin-bottom: 40px;

    .lines-content {
      background: white;

      @media print, screen and (min-width: 700px) {
        width: calc(50% + 16px);
        padding-right: 16px;
      }
    }
  }
`;

export const Chapter = ({
  chapter,
  vocabs,
  sics,
}: {
  chapter: ChapterCatalogData;
  vocabs: VocabLookup[];
  sics: VocabLookup[];
}) => {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Wrapper
        id={`chapter-${chapter.position}`}
        className={`position--${chapter.position}`}
      >
        <ChapterProvider value={chapter}>
          <h2 className="heading">
            {__('%{position}. %{title}', 'storyGuide.chapterHeading', chapter)}
          </h2>
          <Notes chapter={chapter} />
          <div className="lines">
            <div className="lines-content">
              <StoryTree elements={chapter.elements} />
            </div>
          </div>
          <Glossary vocabs={vocabs} sics={sics} id={chapter.position} />
        </ChapterProvider>
      </Wrapper>
    </>
  );
};
