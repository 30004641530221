import React from 'react';
import { Chapter } from './story-tree/chapter';
import { SimpleChapter } from './story-tree/simple-chapter';

import { groupBy } from 'lodash';
import { ChapterCatalogData, UnitCatalogData } from 'core/models/catalog';

export const StoryScript = ({
  story,
  simple = false,
}: {
  story: UnitCatalogData;
  simple: boolean;
}) => {
  const vocabsByPosition = groupBy(story.vocabs, 'chapterPosition');
  const sicsByPosition = groupBy(story.sics, 'chapterPosition');
  return (
    <>
      {story.chapters.map((chapter: ChapterCatalogData) => {
        return simple ? (
          <SimpleChapter chapter={chapter} key={chapter.position} />
        ) : (
          <Chapter
            chapter={chapter}
            vocabs={vocabsByPosition[chapter.position]}
            sics={sicsByPosition[chapter.position]}
            key={chapter.position}
          />
        );
      })}
    </>
  );
};
