import { styled } from 'naan/stitches.config';

export const TagsContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: '8px',
  paddingBottom: '10px',
  '& > * ': {
    marginRight: '6px',
    marginBottom: '6px',
  },
  '@large': {
    maxWidth: '960px',
    margin: '0 auto',
  },
});
