import {
  includeTextStylesFromProp,
  getColorFromProp,
} from '../../../legacylib/theme/utils';

import styled from 'styled-components';

export const Text = styled.span`
  ${includeTextStylesFromProp('textStyle', 'body')};
  color: ${getColorFromProp() /*defaults to `inherit`*/};
`;
