import { IObservableArray } from 'mobx';

import { createLogger } from 'app/logger';
import { track } from 'app/track';

import {
  getParentOfType,
  ModelTreeNode,
  volatile,
} from 'ts-state-tree/tst-core';

import { tipsData } from 'core/lib/constants/vars';
import { UserManager } from './user-manager';

const log = createLogger('um:user-settings');

/**
 * UserSettings
 *
 * non-study view user configuration and misc data
 */
export class UserSettings extends ModelTreeNode {
  static CLASS_NAME = 'UserSettings' as const;

  static create(snapshot: any) {
    return super.create(UserSettings, snapshot) as UserSettings;
  }

  onboarding: boolean = true;
  showVocabListExportOption: boolean = false;
  // @jason, mst was using an IObservableArray, which provided a 'remove' action
  // what's the best way to handle with tst?
  shownTips: string[] = [];
  dismissedMessages: string[] = [];

  @volatile
  currentTip: any = null;

  get userManager(): UserManager {
    return getParentOfType(this, UserManager) as UserManager;
  }

  toggleVocabListExport() {
    this.showVocabListExportOption = !this.showVocabListExportOption;
    this.userManager.persistUserData();
  }

  toggleOnboarding() {
    this.onboarding = !this.onboarding;
    this.userManager.persistUserData();
  }

  enableOnboarding() {
    this.onboarding = true;
    this.userManager.persistUserData();
  }

  disableOnboarding() {
    this.onboarding = false;
    this.userManager.persistUserData();
  }

  resetShownTips() {
    this.shownTips = [];
    this.userManager.persistUserData();
  }

  resetTip(name: string) {
    // todo: better type handling
    (this.shownTips as IObservableArray).remove(name);
  }

  showTip(tip: string) {
    if (!this.shownTips.includes(tip)) {
      this.shownTips.push(tip);
      this.userManager.persistUserData();
    }
  }

  setCurrentTip({
    name,
    layout,
    actions,
  }: {
    name: string;
    layout: any;
    actions: any;
  }) {
    const tip = tipsData[name];
    this.showTip(name);
    this.currentTip = {
      tip,
      layout,
      actions,
    };
  }

  hideCurrentTip() {
    this.currentTip = null;
  }

  dismissMessage(key: string) {
    this.dismissedMessages.push(key);
    this.userManager.persistUserData();
  }

  dismissTrialUserBanner() {
    this.dismissSubscriberMessage();
  }

  // this was confusingly named
  dismissSubscriberMessage() {
    log.info('dismissSubscriberMessage');
    this.dismissMessage('subscriber-message');
  }

  dismissFullAccessBanner() {
    this.dismissMessage('full-access-banner');
  }

  dismissWelcomeMessage() {
    track('dashboard__dismiss_welcome');
    this.dismissMessage('welcome-message');
  }

  resetMessage(key: string) {
    (this.dismissedMessages as IObservableArray).remove(key);
  }

  tipIsShown(tip: string) {
    return this.shownTips.includes(tip);
  }

  tipIsEligible(tip: string) {
    return this.onboarding && !this.tipIsShown(tip);
  }

  messageIsDismissed(key: string) {
    return this.dismissedMessages.includes(key);
  }

  get showTrialUserBanner() {
    return this.showSubscriberMessage;
  }

  // todo: this was confusingly named. it really a welcome message
  // for users who are not yet subscribed!
  get showSubscriberMessage() {
    const manager = this.userManager;
    if (!manager) return false;
    log.debug(
      `showSubscriberMessage - full access: ${manager.accountData?.fullAccess}`
    );
    return (
      !manager.accountData?.fullAccess &&
      !this.messageIsDismissed('subscriber-message')
    );
  }

  get showFullAccessBanner() {
    const manager = this.userManager;
    if (!manager) return false;
    return (
      manager?.accountData.fullAccess &&
      !this.messageIsDismissed('full-access-banner')
    );
  }

  get welcomeMessageDismissed() {
    return this.messageIsDismissed('welcome-message');
  }
}
