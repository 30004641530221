import { ModelTreeNode } from 'ts-state-tree/tst-core';

/**
 * AttemptedPurchase
 *
 * holds the user's attempted purchase
 */
export class AttemptedPurchase extends ModelTreeNode {
  static CLASS_NAME = 'AttemptedPurchase' as const;

  static create(snapshot: any) {
    return super.create(AttemptedPurchase, snapshot) as AttemptedPurchase;
  }

  purchaseType: string;
  contentSlug: string;
}
