import { TFilterQueryParameters } from '../filtering-core';
import { isEmpty } from 'lodash';
import { camelCase } from 'lodash';

const emptyQuery: TFilterQueryParameters = {
  filterType: null,
  filterValue: null,
  sorting: null,
};

export function parseQueryString(search: string): TFilterQueryParameters {
  const q: Record<string, any> = {};

  if (isEmpty(search)) {
    return emptyQuery;
  }

  search
    .replace(/^\?/, '')
    .split('&')
    .forEach(kv => {
      let [key, value = ''] = kv.split('=');
      key = camelCase(decodeURIComponent(key));
      value = decodeURIComponent(value);
      q[key] = value;
    });

  if (q.search) {
    return {
      filterType: 'search',
      filterValue: [q.search],
      sorting: q.sorting ?? null,
    };
  }

  /// ignore queries without a value
  if (!q.filterType) {
    return { ...emptyQuery, sorting: q.sorting ?? null };
  }

  const query: TFilterQueryParameters = {
    filterType: q.filterType,
    filterValue: q.filterValue.split(','),
    sorting: q.sorting ?? null,
  };

  return query;
}
