import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { useFiltering } from 'common/filtering/use-filtering';
import { ClearIcon } from 'naan/icons/clear-icon';
import { IconButton } from 'naan/primitives/button';

import { AnimatePresence, motion } from 'framer-motion';
import { scrollContainerToTop } from 'lib/scroll-utils';

const Wrapper = styled(motion.div, {
  background: '$white',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  zIndex: 1,
  left: 0,
  right: '-8px',
  top: 0,
  bottom: 0,
  '@medium': {
    right: '220px',
  },
});

const TopicWrapper = styled('div', {
  color: '$teal-500',
  textStyle: 'small-heading',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

// const activeFilterKeys = [
//   'catalogData.countries',
//   'catalogData.topics',
//   'catalogData.apTags',
//   'catalogData.ibTags',
// ];

export const ActiveFiltersBar = () => {
  const { filter, resetAllFilters } = useFiltering();

  const handleReset = React.useCallback(() => {
    resetAllFilters();
    setTimeout(scrollContainerToTop, 300);
  }, [resetAllFilters]);

  const hide = filter === null || filter.queryType === 'search';

  return (
    <AnimatePresence>
      {hide ? null : (
        <Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.25 }}
          exit={{ opacity: 0 }}
        >
          <TopicWrapper>{filter.value.join(', ')}</TopicWrapper>
          <IconButton
            aria-label={`remove filters`}
            icon={<ClearIcon />}
            onClick={handleReset}
          />
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
