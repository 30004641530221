import { ModelTreeNode } from 'ts-state-tree/tst-core';

// legacy schema
export class Cast extends ModelTreeNode {
  static CLASS_NAME = 'Cast' as const;

  static create(snapshot: any) {
    return super.create(Cast, snapshot) as Cast;
  }

  accent: string = null;
  fullName: string = '';
  shortName: string = null;
  description: string = null;

  get label() {
    return this.fullName || this.shortName;
  }
}
