import * as React from 'react';
import { Modal } from './modal';
import { getColor } from 'legacylib/theme/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  width: 400px;
  max-width: calc(100% - 32px);
  background: ${getColor('white')};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const Window = ({ children }) => {
  return (
    <Modal>
      <Wrapper>{children}</Wrapper>
    </Modal>
  );
};
