import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import { PlanPricingCard } from './plan-pricing-card';
import { FreeTrialPricingCard } from './free-trial-pricing-card';
import { Text } from 'naan/primitives/text';
import { Plan } from 'core/models/user-manager';
import { styled } from 'naan/stitches.config';
import __ from 'core/lib/localization';

const CardsWrapper = styled('div', {
  '& > * + * ': {
    marginTop: '24px',
    '@medium': {
      marginTop: '0px',
    },
  },
  '@medium': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    columnGap: '24px',
  },
  '&.two-cards': {
    '@medium': {
      display: 'grid',
      gridTemplateColumns: 'repeat(12,1fr)',
      columnGap: '24px',
    },
    '& > div:first-child': {
      gridColumn: '3 / span 4',
    },
    '& > div': {
      gridColumn: 'span 4',
    },
  },
});

export const PricingCards = observer(({ showFreeTrialCard = false }) => {
  const store = useRootStore();
  const accountData = store?.userManager?.accountData;
  const plans: Plan[] = accountData.plans;

  return (
    <>
      <CardsWrapper className={showFreeTrialCard ? '' : 'two-cards'}>
        {showFreeTrialCard ? <FreeTrialPricingCard /> : null}
        {plans.map(plan => (
          <PlanPricingCard key={plan.slug} plan={plan} />
        ))}
      </CardsWrapper>
      <Text
        textStyle="small-text"
        color={'textSecondary'}
        css={{ textAlign: 'center', paddingTop: 24 }}
      >
        {__('Prices are in US dollars', 'account.pricesAreInUsd')}
      </Text>
    </>
  );
});
