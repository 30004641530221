import React from 'react';
import { getConfig } from 'app/env';
import { styled } from 'naan/stitches.config';

const { emails } = getConfig('website');

const Link = styled('a', {
  color: '$teal-500',
  textDecoration: 'underline',
});

export const SupportLink: React.FC = ({ children }) => {
  return (
    <Link href={`mailto:${emails.support}`} target="_blank">
      {children}
    </Link>
  );
};
