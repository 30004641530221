import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Downloadable } from './downloadable';
import { Divider } from 'naan/primitives/divider';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { DownloadableResource } from './get-resources';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import __ from 'core/lib/localization';
import { InfoCircleIcon } from 'naan/icons/info-circle-icon';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
import { discoverPath } from 'components/nav/path-helpers';

const MoreSectionWrapper = styled('div', {
  py: '$space$6',
  px: '$space$6',
  flex: 1,
});

const Link = styled('button', {
  all: 'unset',
  color: '$black-alpha-40',
  textStyle: 'body',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& span.icon': {
    marginRight: '$space$3',
    lineHeight: 0,
  },

  // textDecoration: 'underline',
});

const ActivityGuidesInstructions = styled('div', {
  paddingLeft: '36px',
  paddingBottom: '$space$4',
  borderBottom: '1px solid $black-alpha-10',
  marginBottom: '$space$4',
  textStyle: 'small-text',
  '& h4': {
    textStyle: 'body-bold',
    // marginBottom: '$space$1',
  },
  '& p': {
    color: '$black-alpha-40',
    maxWidth: '560px',
  },
  '& a': {
    color: '$black-alpha-40',
  },
});

export const ResourcesList = observer(
  ({
    resources,
    locked,
    hasActivityGuides,
    onShowTeacherResources,
    onTappingLockedResource,
  }: {
    resources: DownloadableResource[];
    locked: boolean;
    hasActivityGuides: boolean;
    onShowTeacherResources: () => void;
    onTappingLockedResource: () => void;
  }) => {
    return (
      <MoreSectionWrapper>
        <Text textStyle="small-heading">
          {hasActivityGuides
            ? __('More resources', 'resources.moreResources')
            : __('Resources', 'resources.resources')}
        </Text>
        <VSpacer size={6} />
        <Divider type="transparent" />
        {resources.map(resource => (
          <React.Fragment key={resource.url}>
            <Downloadable
              {...resource}
              locked={resource.restricted === false ? false : locked}
              showLockedResourcesDialog={onTappingLockedResource}
            />
            <Divider type="transparent" />
          </React.Fragment>
        ))}
        <VSpacer size={4} />
        {hasActivityGuides ? null : (
          <ActivityGuidesInstructions>
            <h4>
              {__(
                'Looking for Activity Guides?',
                'resources.lookingForactivityGuides'
              )}
            </h4>
            {locked ? (
              /* @LOCALIZE */
              <p>
                For teaching with Lupa we recommend our stories, selected by our
                education specialists, with accompanying Activity Guide
                e-booklets for engaging and productive Spanish classes. The
                Activity Guides for our free stories are unlocked.
              </p>
            ) : (
              /* @LOCALIZE */
              <p>
                For teaching with Lupa we recommend our stories, selected by our
                education specialists, with accompanying Activity Guide
                e-booklets for engaging and productive Spanish classes.
              </p>
            )}
            <RouterLink
              to={discoverPath()}
              onClick={() => {
                clearSavedScrollPosition(discoverPath());
              }}
            >
              {__('View all stories', 'resources.viewAllStories')}
            </RouterLink>
          </ActivityGuidesInstructions>
        )}

        <Link onClick={onShowTeacherResources}>
          <span className="icon">
            <InfoCircleIcon />
          </span>
          {__('About teacher resources', 'resources.aboutTeacherResources')}
        </Link>
      </MoreSectionWrapper>
    );
  }
);
