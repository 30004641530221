import * as React from 'react';
import { reportException } from 'common/error-reporting';
import { styled } from 'naan/stitches.config';

// import { useHistory } from 'react-router-dom';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'body',
  color: '$textSecondary',
  textAlign: 'center',

  h1: {
    color: '$red-500',
    textStyle: 'large-heading--desktop',
    textAlign: 'center',
    marginTop: '$10',
    marginBottom: '$10',
  },
  h2: {
    textStyle: 'medium-heading',
    color: '$textSecondary',
    marginBottom: '$2',
    textAlign: 'center',
  },
  section: {
    padding: '$2 0',
    textAlign: 'center',
  },
  button: {
    marginTop: '$8',
  },
});

export const NotFoundScreen = ({
  title = __('Page not found', 'general.pageNotFound.title'),
  subtitle = __(
    'We’re sorry but we can’t find that page.',
    'general.pageNotFound.text'
  ),
  reportError = true,
}) => {
  React.useEffect(() => {
    if (reportError) {
      reportException(new Error('Bad route'), {
        pathname: window.location.pathname,
      });
    }
  }, [reportError]);

  return (
    <Wrapper>
      <section>
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </section>
    </Wrapper>
  );
};
