import { getConfig } from 'app/env';
import { fail, alertLevels } from './errors';

// const applyFormat = (format: string, args: string[]) => {
//   let argIndex = 0;
//   return format.replace(/%s/g, () => args[argIndex++]);
// };

// const invariant = (condition: boolean, format: string, ...args: string[]) => {
const invariant = (condition: boolean, message: string) => {
  const throwInvariants = getConfig(
    'throwInvariants',
    false
    // todo: figure out why this breaks the json schema generation
    // (process.env.NODE_ENV !== 'production') as any // @armando, can this be cast to a boolean? or actually coercive getters would probably be nice to add
  );

  const failOptions = throwInvariants
    ? {
        alert: true,
        report: true,
        level: 'error',
      }
    : {
        alert: false,
        report: true,
        level: 'warning',
      };

  // completely short-circuit if disabled
  if (!throwInvariants) {
    return;
  }

  if (message === undefined) {
    throw new Error('invariant requires an error message argument');
  }

  if (!condition) {
    /// TODO: we need to document our error extensions.
    /// We've been adding arbitrary properties (which works on js, but not ts)
    // const error = new Error(applyFormat(format, args));
    const error = new Error(message);
    error.name = 'Invariant Violation';
    //@ts-ignore
    error.report = true;
    // @ts-ignore
    error.alertLevel = alertLevels.WARN;
    fail(error, { context: 'Failed Invariant', ...failOptions } as any);
  }
};

export default invariant;
