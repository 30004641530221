import React from 'react';
import { ResendEmailButton } from './resend-email-btn';
import { CopyCodeButton } from './copy-code-btn';
import { styled } from 'naan/stitches.config';
import { VStack, HStack } from 'naan/primitives/stack';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';
import { Text } from 'naan/primitives/text';
import { CopyCode } from 'components/ui/code';

const Dot = styled('div', {
  $$dotColor: '$colors$green-500',
  width: 10,
  height: 10,
  borderRadius: 5,
  backgroundColor: '$$dotColor',
  variants: {
    active: {
      false: {
        $$dotColor: '$colors$yellow-500',
      },
    },
  },
});

export const CouponPurchase = ({ coupon }: { coupon: any }) => {
  return (
    <VStack>
      <VSpacer size={4} />
      <HStack>
        <CopyCode code={coupon.code} />
        <HSpacer expand />
        {coupon.redeemed ? null : <ResendEmailButton coupon={coupon} />}
      </HStack>
      <VSpacer size={2} />
      <VStack>
        <Text color="textSecondary">{coupon.purchaseStatusMessage}</Text>
        <HStack align={'center'}>
          <Dot active={coupon.redeemed} />
          <HSpacer size={2} />
          <Text color="textSecondary">{coupon.redemptionStatusMessage}</Text>
        </HStack>
      </VStack>
      <VSpacer size={4} />
    </VStack>
  );
};
