import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { MyLupaLogo } from 'components/ui/icons/logo';
import { HStack } from 'naan/primitives/stack';
import { HSpacer } from 'naan/primitives/spacer';
import { Link, Route, Routes } from 'react-router-dom';
import { GlobalFooter } from 'components/global-footer';
import {
  ButtonLabel,
  ButtonStyled,
} from 'naan/primitives/button/button.styles';
import { getConfig } from 'app/env';
import { ExternalLinkIcon } from 'naan/icons/external-link-icon';
import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  height: '100%',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
});

const WideInnerWrapper = styled('div', {
  margin: '0 auto',
  width: 'min(960px, calc(100% - 32px))', //maybe this should be a var somewhere
});

const NarrowInnerWrapper = styled('div', {
  margin: '0 auto',
  width: 'min(400px, calc(100% - 32px))', //maybe this should be a var somewhere
});

const AboutLink = styled('a', {
  display: 'flex',
  alignItems: 'center',
  textStyle: 'small-text',
  color: '$textSecondary',
  textDecoration: 'none',
  svg: {
    lineHeight: 0,
    display: 'none',
    '@small': {
      display: 'block',
    },
  },
});

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const marketingSiteUrl = getConfig('website.urls.marketingSite');

  return (
    <Wrapper>
      <WideInnerWrapper>
        <HStack css={{ py: '$4' }} align="center">
          <MyLupaLogo />
          <HSpacer expand />
          <HStack>
            <Routes>
              <Route
                path="/sign-in"
                element={
                  <ButtonStyled
                    as={Link}
                    to="/auth/create-account"
                    link
                    size="small"
                    presentation={'gray'}
                  >
                    <ButtonLabel>
                      {__('Create account', 'common.createAccount')}
                    </ButtonLabel>
                  </ButtonStyled>
                }
              ></Route>
              <Route
                path="/create-account"
                element={
                  <ButtonStyled
                    as={Link}
                    to="/auth/sign-in"
                    link
                    size="small"
                    presentation={'gray'}
                  >
                    <ButtonLabel>{__('Sign in', 'common.signIn')}</ButtonLabel>
                  </ButtonStyled>
                }
              ></Route>
            </Routes>
            <HSpacer size={4} />
            <AboutLink href={marketingSiteUrl} target="blank">
              About Lupa <ExternalLinkIcon width={16} height={16} />
            </AboutLink>
          </HStack>
        </HStack>
      </WideInnerWrapper>
      <NarrowInnerWrapper>{children}</NarrowInnerWrapper>
      <WideInnerWrapper>
        <GlobalFooter />
      </WideInnerWrapper>
    </Wrapper>
  );
};
