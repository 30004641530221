import React from 'react';
import { Button } from 'naan/primitives/button';
import { RetryIcon } from 'naan/icons/retry-icon';

import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'body',
  color: '$textSecondary',
  textAlign: 'center',

  h1: {
    color: '$red-500',
    textStyle: 'large-heading--desktop',
    textAlign: 'center',
    marginTop: '$10',
    marginBottom: '$10',
  },
  h2: {
    textStyle: 'medium-heading',
    color: '$textSecondary',
    marginBottom: '$2',
    textAlign: 'center',
  },
  section: {
    padding: '$2 0',
    textAlign: 'center',
  },
  button: {
    marginTop: '$8',
  },
});

export const NetworkErrorScreen = () => (
  <Wrapper>
    <section>
      <h1>Oops. Something went wrong.</h1>
      <p>
        It seems we're unable to reach our servers for some unknown reason.
        Please wait a few seconds and refresh. If the problem persists, please
        contact us.
      </p>
      <div>
        <Button
          leftIcon={<RetryIcon />}
          onClick={() => {
            window.location.reload();
          }}
          label={'Reload page'}
        />
      </div>
    </section>
  </Wrapper>
);
