import { alertLevels } from './alert-levels';
import { GenericError } from './generic-error';

const { WARN } = alertLevels;

type HandleableErrorOptions = {
  context?: any;
  alertLevel?: keyof typeof alertLevels;
  report?: boolean;
  alert?: boolean;
  level?: string; // let's narrow this
  handlerTag?: string; // let's narrow this, too
  code?: string; // = 'JW_HANDLEABLE',
};

type Coded = { code: string };

function isCoded(obj: object): obj is Coded {
  return (obj as unknown as Coded).code !== undefined;
}
/**
 * This is an error class that can wrap another error
 * and has useful properties that instructs our global handler
 * on how to better deal with errors
 */
export class HandleableError extends GenericError {
  code: string;
  cause?: Error; // @armando, do we know if/where 'cause' is used? should we merge with context?

  level: string;
  handlerTag: string;
  alert: boolean;

  constructor(error: Error | string, options: HandleableErrorOptions = {}) {
    const {
      context = null,
      alertLevel = WARN,
      report = true,
      alert = false,
      level = 'error',
      handlerTag = 'nonfatal-global-error',
      code = 'JW_HANDLEABLE',
    } = options;

    if (error instanceof Error) {
      const message = error.message;
      super(message);
      // this wraps the original error
      this.cause = error;
      /// surface the code from internal errors
      /// so we can blanket-ignore some of them
      /// -- 2022 refactor :  the Error object doesn't really have a `code` field so IDK what to do with this.
      if (isCoded(error)) {
        this.code = error.code;
      } else {
        this.code = code;
      }

      // this.framesToPop = error.framesToPop;
      this.stack = error.stack;
    } else {
      super(error);
      this.code = code;
    }

    this.name = 'HandleableError';
    this.context = context;
    this.report = report;
    this.level = level;
    this.handlerTag = handlerTag;
    this.alert = alert;
    this.alertLevel = alertLevel;
  }
}
