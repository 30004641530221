import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { ToastService } from 'common/notifications/toast-service';
import { Toast, ToastTarget } from 'naan/toasts';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';

export const AppWideRouteLayout = observer(() => {
  const toast = AppFactory.toastService.current;

  return (
    <>
      <Outlet />
      <ToastTarget
        showNotification={!!toast}
        css={{ '@large': { left: '$$sidebarWidth' }, padding: '$space$2' }}
      >
        {toast ? (
          <Toast
            type={toast.type}
            label={toast.message}
            onDismiss={ToastService.clear}
            action={toast.action}
          />
        ) : null}
      </ToastTarget>
      {AppFactory.dialogPresenter.element}
    </>
  );
});
