import * as React from 'react';
import { NavItem } from 'naan/primitives/nav';
import { PlayIcon } from 'naan/icons/all-icons';
import __ from 'core/lib/localization';
import { AppFactory } from 'app/app-factory';

// hidden lupa-web links for forward development

export const VolumeModeLinks = () => {
  const { webModeEnabled } = AppFactory.root.userManager.userData;

  if (webModeEnabled) {
    return (
      <>
        <NavItem
          to="/app/learn/es/stories"
          icon={<PlayIcon />}
          label={__('Stories', 'nav.stories')}
        />
        <NavItem
          to="/app/learn/es/progress"
          icon={<PlayIcon />} // replace me
          label={__('Progress', 'nav.progress')}
        />
      </>
    );
  } else {
    return null;
  }
};
