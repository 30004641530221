import { runInAction } from 'mobx';
import {
  applySnapshot,
  ModelTreeNode,
  volatile,
  snap,
  Bindery,
} from 'ts-state-tree/tst-core';
import { getConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import * as platform from 'app/platform';

import minibus from 'common/minibus';
import invariant from 'core/lib/invariant';

import { AccountData } from './account-data';
import { Root } from '../root';
import { ApiInvoker } from 'core/services/api-invoker';
import { Classroom } from './classroom';
import { License } from './license';
import { Student } from './student';
import { StudentProgress } from './student-progress';
import { Assignment } from './assignment';
import { Plan } from './plan';
import { UserData } from './user-data';
// import { safelyHandleError } from 'core/lib/error-handling';
// import { alertLevels } from 'core/lib/errors';
import { AssistSettings } from './assist-settings';
import { AttemptedPurchase } from './attempted-purchase';
import { ListeningLog } from './listening-log';
import { ListeningStats } from './listening-stats';
import { LocationPointer } from './location-pointer';
// import { PaymentData } from './payment-data';
import { PurchasedCoupon } from './purchased-coupon';
import { StoryProgress } from './story-progress';
import { UserSettings } from './user-settings';
import { PaymentData } from './payment-data';
import { ValidationError } from 'core/lib/errors';
import __ from 'core/lib/localization';
import { getBaseRoot } from '../base-root';
import { ClassroomUserData } from './classroom-user-data';
import { normalizedEqual } from 'utils/util';
// import { Vocab } from './vocab';

const log = createLogger('api-access');

// const { ERROR, WARN } = alertLevels;

// @armando, jason, do you think it's worth defining types for these result structure
// or just declare inline and let typescript infer from there?
// export type UpdateProfileFieldResult = { status: string; message: string };

export class UserManager extends ModelTreeNode {
  static CLASS_NAME = 'UserManager' as const;

  static create(snapshot: any = {}): UserManager {
    return super.create(UserManager, snapshot) as UserManager;
  }

  static bindModels(bindery: Bindery): void {
    bindery.bind(AccountData);
    bindery.bind(Assignment);
    bindery.bind(AssistSettings);
    bindery.bind(AttemptedPurchase);
    bindery.bind(Classroom);
    bindery.bind(License);
    bindery.bind(ListeningLog);
    bindery.bind(ListeningStats);
    bindery.bind(LocationPointer);
    bindery.bind(PaymentData);
    bindery.bind(Plan);
    bindery.bind(PurchasedCoupon);
    bindery.bind(StoryProgress);
    bindery.bind(StudentProgress);
    bindery.bind(Student);
    bindery.bind(UserData);
    bindery.bind(ClassroomUserData);
    bindery.bind(UserManager);
    bindery.bind(UserSettings);
    // bindery.bind(Vocab);
  }

  token?: string = null;
  authSkipped: boolean = false; // local-only 'anonymous' mode
  anonymousUsage: boolean = false; // set 'true' upon 'skipAuth'. used to drive 'go back' vs 'skip' in auth footer
  accountData: AccountData = snap({});

  // user account data managed by server - immutable by client except via api calls to server
  lastSyncedVersion: number = -1; // client side version of the accountData value as of the most recent sync

  // todo: consider if this should be nullable
  userData: UserData = snap({}); //  data to be synced to/from server

  @volatile
  automaticNotifications: boolean = false;

  @volatile
  newsletterPromptNeeded: boolean = false; // set during sign-up flow to trigger newsletter(aka mailing list) prompt after sign-up

  enableNewsletterPrompt() {
    this.newsletterPromptNeeded = true;
  }

  disableNewsletterPrompt() {
    this.newsletterPromptNeeded = false;
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  get apiInvoker(): ApiInvoker {
    return this.root.apiInvoker;
  }

  get authenticated(): boolean {
    return !!this.token;
  }

  // todo: revisit this
  get dataReady(): boolean {
    return !!this.accountData.email;
  }

  // should be able to remove
  get loggedInAndReady(): boolean {
    return this.authenticated && this.dataReady;
  }

  async login(email: string, password: string): Promise<void> {
    // root.setPreauthenticatedSession(false);
    log.info(`login(${email})`);
    track('preauth__email_log_in', { email });

    if (this.authenticated) {
      invariant(
        false,
        `login - unexpectedly already authenticated - token: ${this.token}`
      );
      await this.reset();
    }

    const userCredentials = {
      email,
      password,
    };

    const result = await this.apiInvoker.post(
      'users/auth',
      {},
      userCredentials
    );

    log.debug(`auth result: ${JSON.stringify(result)}`);

    await this.applyAuthentication(result.userToken);
    await this.checkWelcomeUrl();

    this.postAuthenticate();

    /// subscribed to by spa to update cookies
    minibus.emit('LOGIN_COMPLETE', this);
  }

  /**
   * handle the social logins. will create a user on the fly if needed
   *
   * provider codes:
   *   'google' - google oauth
   *   'facebook' - not yet supported
   *   'mock:[name]' - fake mode for test harness
   */
  async omniauth(provider: string, token: string): Promise<void> {
    const anonymousId = platform.getInstallationId();

    log.info(`omniauth(${token})`);

    this.root.setPreauthenticatedSession(false);
    if (this.authenticated) {
      invariant(
        false,
        `omniauth - unexpectedly already authenticated - token: ${this.token}`
      );
      this.reset();
    }

    // 'mock' used by mst-web-proto
    if (provider === 'google' || provider === 'mock') {
      track('preauth__google_auth');
    } else {
      invariant(
        provider === 'google',
        'Currently support only google omniauth, missing branch'
      );
      track('preauth__unexpected_auth');
    }

    const authParams = {
      provider,
      token,
      anonymous_id: anonymousId,
    };

    const result = await this.apiInvoker.post('users/omniauth', authParams);
    await this.applyAuthentication(result.userToken);
    await this.checkWelcomeUrl();

    /// subscribed to by spa to update cookies
    minibus.emit('LOGIN_COMPLETE', this);

    this.postAuthenticate();
    // yield self.syncToServer(); // todo: only sync for new account once we better distinguish
  }

  /**
   * pretends to login via google and create new account if needed with
   * given first name if not already in system
   */
  async mockOmniauth(email: string, name: string): Promise<void> {
    this.root.setPreauthenticatedSession(false);
    await this.omniauth('mock', `${email}|${name}`);
  }

  async signup(credentials = {}): Promise<void> {
    const anonymousId = platform.getInstallationId();

    log.info('create account', credentials);

    this.root.setPreauthenticatedSession(false);

    if (this.authenticated) {
      invariant(
        false,
        `signup - unexpectedly already authenticated - token: ${this.token}`
      );
      await this.reset();
    }

    track('preauth__email_sign_up', credentials);

    const result = await this.apiInvoker.post(
      'users/signup',
      {},
      {
        ...credentials,
        anonymous_id: anonymousId,
      }
    );

    /// this will trigger the explicit mailing list opt-in/out modal after sign-up
    this.enableNewsletterPrompt();

    await this.applyAuthentication(result.userToken);
    await this.checkWelcomeUrl();

    // subscribed to by spa to update cookies
    minibus.emit('LOGIN_COMPLETE', this);

    this.postAuthenticate();

    // /// make sure server's user data version is always >= 1 so it'll always
    // /// be used when signing in from a new device
    // yield self.syncToServer();
  }

  // @action // this doesn't seem to help even when the mutation was prior to the first await
  async logout(): Promise<void> {
    log.info('logout');
    runInAction(() => {
      this.token = null;
      this.root.persist();
      applySnapshot(this.accountData, {});
    });
  }

  /**
   * Like login, except that it takes a token instead of email/password
   */
  async autoLogin(token: string): Promise<void> {
    log.info(`autoLogin(${token})`);

    if (this.authenticated) {
      invariant(
        false,
        `autoLogin - unexpectedly already authenticated - token: ${this.token}`
      );
      this.reset();
    }

    await this.applyAuthentication(token);
    await this.checkWelcomeUrl();

    this.postAuthenticate();

    // Emit an event.
    minibus.emit('LOGIN_COMPLETE', this);
  }

  async reset(): Promise<void> {
    log.info('reset');
    // this.anonymousUsage = false;
    // self.loggedInAndReady = false;

    // note, need to preserve deferredNavigation and pendingPaymentSelection
    // through new authentication, so can't reset here.

    applySnapshot(this, {
      token: null,
      accountData: {},
      lastSyncedVersion: 0,
      userData: {},
    });
    // avoid bleeding the welcome message from the previous state
    // self.whatsNewUrl = null;
    // fetch pre auth account data
    await this.refreshPreauthAccountData();
  }

  // async applyAuthentication(token: string) {
  //   if (self.authenticated) {
  //     self.$log.info('applyAuthentication - auto logout triggered');
  //     // sets a flag to signal the UI that we're not logging out for real
  //     // just temporarilly
  //     getRoot(self).startTransitioningAnonymousAccount();
  //     await self.logout(true /*force*/, true /*skipSync*/);
  //   }

  //   self.token = token;
  //   await self.refreshAccountData(
  //     true /*updateDependents*/,
  //     true /*trackAuthenticated*/
  //   );
  //   const selfRoot = getRoot(self);
  //   if (selfRoot !== null) {
  //     await selfRoot.persistAll(); // no need to sync after logging in. AndSync();
  //   } else {
  //     log.info('root is null for some reason');
  //   }
  //   self.$track('system__authenticated');
  //   // this can't be here because we haven't yet checked the welcome message status
  //   // self.loggedInAndReady = true; // todo: should probably move this out of here
  // }

  // @action // decorators don't seem to apply to async functions
  async applyAuthentication(token: string): Promise<void> {
    runInAction(() => {
      this.token = token;
    });
    this.root.persist();
    const data = (await this.fetchAccountData()) as AccountData;
    await this.applyNewAccountData(data);
    await this.fetchUserData();
  }

  async fetchAccountData(): Promise<AccountData> {
    return await this.apiInvoker.get('users/account', {
      ts: new Date().getTime(), // ensure not cached
    });
  }

  /**
   * used to update server provided config data before logging in
   */
  async refreshPreauthAccountData(): Promise<void> {
    log.info(`refreshPreauthAccountData`);
    const data = await this.fetchAccountData();
    applySnapshot(this.accountData, data);
  }

  /**
   * updates the memory state with freshly received account data from the server.
   * shared helper method invoked from the various places that we receive an
   * accountData response.
   *
   * updateDependents - if false, then skip the catalog and userData updates.
   *   currently necessary when updating the profile info. can hopefully
   *   figure out how to make safe.
   *
   * (private)
   */
  async applyNewAccountData(
    data: AccountData,
    updateDependents = true
  ): Promise<void> {
    log.info('applyNewAccountData', {
      updateDependents: updateDependents,
    });
    // trust now that this operation is robust
    applySnapshot(this.accountData, data);

    // log.info('applyNewAccountData', {
    //   ad_lsv: data.lastSyncedVersion,
    //   um_lsv: this.lastSyncedVersion,
    // });
    this.root.setReportingContext();

    // the catalog data is used by the classroom portal
    // todo: provide lighter weight access to the needed catalog data

    if (updateDependents) {
      await this.ensureSyncedVersion(data.lastSyncedVersion);

      const catalogUrl = this.accountData.resolvedCatalogUrl;
      // log.debug(
      //   `account catalogUrl: ${catalogUrl}, smurl: ${this.root.storyManager.catalogUrl}`
      // );
      await this.root.storyManager.ensureCatalogUrl(catalogUrl);
    }
  }

  //
  // user data sync
  //
  /**
   * checks if our local user data's last sync basis matches the provided version
   * (presumably as provided within the server's accountData result).
   * if there's a mismatch then fetch the latest client data from the server.
   * (private)
   */
  async ensureSyncedVersion(version: number) {
    /// the versions initialize to '0' now, so we should never trigger an
    /// initial sync unless there'a a >1 version coming back from the server
    if (this.lastSyncedVersion !== version) {
      log.info(
        `lastSyncedVersion mismatch (old: ${this.lastSyncedVersion} / new:${version}) - syncing`
      );
      await this.fetchUserData();
    } else {
      log.info(`lastSyncedVersion matched (${version}) - not syncing`);
    }
  }

  async fetchUserData() {
    if (!this.authenticated) {
      log.info(`fetchUserData - skippedAuth}`);
      return;
    }

    log.info(`fetchUserData - um.lsv: ${this.lastSyncedVersion}`);
    const data = await this.apiInvoker.get('users/data', {
      ts: new Date().getTime(), // ensure not cached
    });

    // log.debug('USER->DATA', data);

    // a server bug was previously cause the data to be null
    invariant(data, 'users/data should not return null');
    if (!data) {
      return;
    }

    // todo: update GET data api to return both data and version number
    // this.userData = UserData.create(data); <-- this usage is _wrong_ will not correctly wire the parent
    // applySnapshot(this, { userData: UserData.create(data) }); <-- somehow, this flavor was nuking our logged in state. @jason any idea why?
    applySnapshot(this.userData, data);
    log.info(
      `fetched userData.lastSyncedVersion: ${String(data.lastSyncedVersion)}`
    );
    if (data.lastSyncedVersion !== undefined) {
      this.lastSyncedVersion = data.lastSyncedVersion;
    } else {
      log.info(`missing lastSyncedVersion - fetching via account data`);
      // hack to support old server deploys which don't yet include the sync version in the user data
      const fetchedAccountData = await this.fetchAccountData();
      this.lastSyncedVersion = fetchedAccountData.lastSyncedVersion;
    }

    this.root.storyManager.ensureStoryProgresses();
  }

  async syncToServer() {
    // todo: will we still want to support an anonymous mode?
    if (!this.authenticated) {
      log.info(`syncToServer - skippedAuth}`);
      return;
    }

    // try {
    log.info(`syncToServer - lastSyncVersion: ${this.lastSyncedVersion}`);

    const payload = this.userData.stringify;

    const resultAccountData = await this.apiInvoker.api('users/data', null, {
      // additional fetch params
      method: 'POST',
      body: JSON.stringify({
        client_data: payload,
      }),
    });
    this.accountData = AccountData.create(resultAccountData);

    // sync tracking needs rethinking

    // this.lastSyncedVersion = this.accountData.lastSyncedVersion;
    // // very important to immediately persist new lastSyncedVersion.
    // // otherwise offline local progress can get overwritten.
    // await this.root.persistAll();
    // log.info(`new lastSyncedVersion: ${this.lastSyncedVersion}`);

    // } catch (error) {
    //   safelyHandleError(this, error, {
    //     ignoreNetworkErrors: true,
    //     unexpectedAlertLevel: WARN,
    //   });
    // }
  }

  async persistUserData() {
    // needs more thought
    await this.syncToServer();
  }

  //
  // account page operations
  //

  updateEmail(newEmail: string) {
    return this.updateProfileField('email', newEmail);
  }

  updateName(newName: string) {
    return this.updateProfileField('name', newName);
  }

  /**
   * note, we no longer that the old password is confirmed
   */
  updatePassword(newPassword: string) {
    return this.updateProfileField('password', newPassword);
  }

  updateSchoolName(newName: string) {
    // this.suspendAutomaticNotifications();
    return this.updateProfileField('school_name', newName);
    // self.resumeAutomaticNotifications();
  }

  // dev screen convenience
  toggleClassroomActivation() {
    if (this.accountData.classroomEnabled) {
      this.updateSchoolName('n/a');
    } else {
      this.updateSchoolName('abc high');
    }
  }

  get webModeEnabled() {
    return this.userData.webModeEnabled;
  }

  get classroomEnabled() {
    return this.accountData.classroomEnabled;
  }

  /**
   * Send update of name, email or password to server
   */
  async updateProfileField(
    key: string,
    value: string
  ): Promise<{ status: string; message: string } /*UpdateProfileFieldResult*/> {
    // const { message, accountData } = await this.apiInvoker.post(
    const result = await this.apiInvoker.post('users/update_field', {
      key,
      value,
    });
    const { accountData } = result;

    await this.applyNewAccountData(accountData, false);

    // if (this.automaticNotifications) {
    //   notifications.notifySuccess(message);
    // }
    return result;
  }

  updateMailingListOptIn(value: boolean) {
    return this.updatePreference('mailing_list_opt_in', value);
  }

  /**
   * Update mailing list opt-in/out preference (key=mailing_list_opt_in, value=[boolean])
   * And potentially other server managed attributes in the future)
   */
  async updatePreference(key: string, value: any) {
    const result = await this.apiInvoker.post('users/update_preference', {
      key,
      value,
    });
    const { accountData } = result;

    await this.applyNewAccountData(accountData, false);
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
  }

  async sendPasswordReset(email: string, hardValidation = false) {
    track('preauth__request_password_reset', { email });
    const endpoint = 'users/send_password_reset';
    const result = await this.apiInvoker.post(
      endpoint,
      { hard_validation: hardValidation ? 'true' : 'false' },
      {
        email,
      }
    );

    return { ...result, success: true, key: 'sendPasswordReset' };

    //   self.$notifications.notifySuccess(result.message);
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
    // }
  }

  async resetPasswordByToken(token: string, newPassword: string) {
    const data = {
      reset_password_token: token,
      password: newPassword,
    };

    const result = await this.apiInvoker.post(
      'users/reset_password_by_token',
      {},
      data
    );

    return { ...result, success: true, key: 'resetPassword' };

    //   self.$notifications.notifySuccess(result.message);
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
  }

  async resendEmailConfirmation() {
    track('account__resend_email_confirmation');
    const result = await this.apiInvoker.post(
      'users/send_confirmation_instructions',
      {}
    );
    return result;
  }

  async cancelPendingEmailChange() {
    track('account__cancel_pending_email_change');
    const result = await this.apiInvoker.post(
      'users/cancel_pending_email_change',
      {}
    );
    const { accountData } = result;
    await this.applyNewAccountData(accountData);
    return result;
  }

  /**
   * Assign a catalog slug for a user. Used by catalog selection hidden menu.
   */

  async updateCatalogSlug(slug: string) {
    // const key = v4CatalogMode() ? 'catalog_v4_slug' : 'catalog_v3_slug';
    const key = 'catalog_v3_slug';

    const result = await this.apiInvoker.post('users/update_field', {
      key,
      value: slug,
    });

    const { accountData } = result;

    await this.applyNewAccountData(accountData);
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
    // }
  }

  async applyCoupon(code: string) {
    const specialCouponResult = await this.handleSpecialCoupons(code);
    if (specialCouponResult !== false) {
      return specialCouponResult;
    }

    track('account__redeem_coupon');

    const result = await this.apiInvoker.post('users/apply_coupon', {
      code,
    });

    const { accountData, ...extraParams } = result;
    log.debug(`extra params: ${extraParams}`);

    // there's no other way for mst to communicate this {messageKey, daysLeft} variables to the UI
    // getRoot(self).setFlash(extraParams);
    await this.applyNewAccountData(accountData);
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async handleSpecialCoupons(code: string) {
    if (code === 'crash2') {
      (code as any).crashTest();
    }

    track('account__redeem_coupon');
    // todo: figure out a better place to put back a test hook for unexpected crashes
    invariant(code !== 'invariant', 'invariant failure test');
    if (code === 'crash') {
      (code as any).crashTest();
    }
    if (code === 'crash3') {
      // eslint-disable-next-line no-throw-literal
      throw 'crash3';
    }
    // if (code === 'warn') {
    //   notifications.alertWarning('this is a test warning alert');
    //   return;
    // }
    // if (code === 'error') {
    //   notifications.alertError('this is a test error alert');
    //   return;
    // }
    if (code === 'netfail') {
      await fetch('http://foo.bar');
    }
    if (code === 'debug') {
      await this.apiInvoker.api(
        'users/debug',
        null, // query param
        {
          // additional fetch params
          method: 'POST',
          body: JSON.stringify({
            data: this.root.stringify,
          }),
        }
      );
      // notifications.notifySuccess('Debug data captured');
      return;
    }
    // if (code === 'remove-all-assets') {
    //   await this.root.downloadManager.removeAllAssets();
    //   return;
    // }

    if (code === '$success') {
      return { message: 'This went well. Hurrah.' };
    }
    if (code === '$error') {
      // root.setValidationError({
      //   key: 'code',
      //   message: 'this is a test error',
      // });
      return true;
    }

    return false;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async sendCouponInstructions(code: string) {
    log.info('sendCouponInstructions');
    const result = await this.apiInvoker.post(
      'users/send_coupon_instructions',
      { code }
    );
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async initiateCheckout(plan: Plan, urls: string[]) {
    const { checkoutSuccessUrl: successUrl, checkoutFailureUrl: failureUrl } =
      getConfig('website', urls);
    log.info(
      `initiateCheckout - successUrl: ${successUrl}, failureUrl: ${failureUrl}`
    );
    const result = await this.apiInvoker.post<{
      interstitialMessageKey: string;
      stripeSessionId: string;
      successMessageKey: string;
    }>(
      'users/initiate_checkout',
      {
        planSlug: plan.slug,
        successUrl,
        failureUrl,
      },
      {}
    );

    // const stripe = await StripeLoader.instance.load();

    // stripe.redirectToCheckout({ sessionId: result.stripeSessionId });

    // console.log(result);

    return result;

    // // store the result in memory so it can be accessed by the UI
    // self.setCheckoutResult(result);

    // @joseph I think it's cleaner to let the UI pickup after getting the result.
    // because under some conditions we need the user input before procceeding
    // with the Stripe checkout process
  }

  async createStripePortalSession(returnUrl: string) {
    log.info(`createStripePortalSession`);

    if (!returnUrl) {
      returnUrl = getConfig('website.accountUrl');
    }

    const result = await this.apiInvoker.post<{
      url: string;
    }>(
      'users/create_stripe_portal_session',
      {
        return_url: returnUrl,
      },
      {}
    );

    // console.log(result);
    return result;
  }

  // // @armando, can we factor this in a way to also support the send coupon instructions result which also has multiple parts
  // setCheckoutResult(result) {
  //   self.checkoutResult = result;
  // }

  // resetCheckoutResult() {
  //   self.checkoutResult = null;
  // }

  async cancelAutoRenew({ ignoreError = false } = {}) {
    log.info(`cancelAutoRenew - ignoreError: ${ignoreError}`);
    track('account__cancel_auto_renew');
    const result = await this.apiInvoker.post('users/cancel_auto_renew', {
      ignoreError,
    });
    const { /*message,*/ accountData } = result;

    await this.applyNewAccountData(accountData);
    return result;
  }

  async exportVocab(slug: string) {
    const result = await this.apiInvoker.post('users/send_vocab', {
      slug,
    });
    const { message } = result;
    log.debug(`message: ${message}`);

    // self.$notifications.notifySuccess(message);
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
    // }
    return result;
  }

  async closeAccount() {
    const result = await this.apiInvoker.post('users/close_account', {});
    const { message } = result;
    log.debug(`message: ${message}`);

    // be sure to purge current state to prevent bleeding into new signup
    await this.logout(); //todo: false /*force*/, true /*skipSync*/);
  }

  //
  // classroom portal support
  //

  validateClassroomLabelAvailable(label: string) {
    if (this.classroomLabelExists(label)) {
      log.info('Classroom already exists', label);
      throw new ValidationError({
        key: 'classroom',
        message: __('Class name already exists', 'userManager.classroomExists'),
      });
    }
  }

  classroomLabelExists(label: string) {
    const existingClass = this.accountData.managedClassrooms.find(classroom => {
      return (
        normalizedEqual(classroom.label, label) && classroom.archived === false
      );
    });

    return !!existingClass;
  }

  async createClassroom(label: string) {
    label = label?.trim(); // quietly ignore any leading/trailing whitespace
    this.validateClassroomLabelAvailable(label);
    log.info('will create Classroom', label);

    const result = await this.apiInvoker.post<{
      classroom?: Classroom;
      message: string;
      accountData: any;
    }>('classrooms', { label });

    const {
      message,
      // messageKey,
      accountData,
    } = result;
    log.debug(`message: ${message}`);

    await this.applyNewAccountData(accountData);
    if (accountData && accountData.managedClassrooms) {
      // todo: have the server explicitly return the new classroom
      const classroom =
        accountData.managedClassrooms[accountData.managedClassrooms.length - 1];
      result.classroom = classroom;
    }
    return result;
  }

  async clearClassroomPortalWelcome() {
    // optimistic update
    //TODO this.accountData.setValue('classroomPortalWelcomePending', false);

    // now, do the persistent update
    const { accountData } = await this.apiInvoker.post(
      'users/clear_classroom_portal_welcome',
      {}
    );
    await this.applyNewAccountData(accountData);
  }

  /**
   * nuke all user data and reset preferences back to defaults
   * only exposed via devtools
   */
  // async resetAllUserData() {
  //   log.info('clearAllProgress');
  //   applySnapshot(this.userData, {});
  //   // this.root.storyManager.resetProgress();
  //   // yield root.persist();
  //   // yield self.syncToServer();
  //   // self.$notifications.notifySuccess('All user data has been reset');
  //   // } catch (error) {
  //   //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  // }

  // async clearAllProgress() {
  //   log.info('clearAllProgress');
  //   this.userData?.resetListeningLogs();
  //   this.root.storyManager?.resetProgress();
  //   yield root?.persist();
  //   yield self.syncToServer();
  //   notifications.notifySuccess(
  //     __('All progress cleared', 'userManager.progressCleared')
  //   );
  //   // } catch (error) {
  //   //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  // }

  //
  // legacy api which need rethinking
  //

  // used to handle resuming purchase flow if needed
  postAuthenticate() {
    // log.info(
    //   `postAuthenticate - deferred nav: ${this.deferredNavigation}, pending payment selection: ${this.pendingPaymentSelection}`
    // );
    // // this may need rethinking
    // this.loggedInAndReady = true;
  }

  /**
   * checks if the user should see a welcome message
   * (either on first login, or after updated 'what's now' post)
   * if affirmative, then sets root state
   * todo: confirm best interface to UI layer
   *
   * todo: rework this to be localizeabale
   *
   * this is also being used to send the welcome email
   */
  async checkWelcomeUrl(): Promise<void> {
    // todo: should be able to remove this,
    // but need to confirm lupa web welcome email needs

    // never perform check for lupa-spa
    if (platform.onWebsite()) {
      return;
    }

    // server will handle the logic to trigger the appropriate version of the welcome email.
    const result = await this.apiInvoker.post('users/check_welcome', {});
    log.info(`check_welcome result: ${JSON.stringify(result)}`);

    if (result) {
      // server's 'welcomeUrl' logic no longer relevant for either lupa or jw
      // server's 'whatsNewUrl' is being ignored at the moment
    }
  }

  async fetchCatalogSlugs(): Promise<string[]> {
    const slugs = await this.apiInvoker.get<string[]>('channels/slugs', {
      mode: 'v3',
    });
    // console.log(`fcs: slugs: ${JSON.stringify(slugs)}`);
    return slugs;
  }
}
