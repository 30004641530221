import React from 'react';
import { observer } from 'mobx-react';
import { styled } from 'naan/stitches.config';
import { Markdown, Text } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';
import { VSpacer } from 'naan/primitives/spacer';
import dayjs from 'dayjs';
import __ from 'core/lib/localization';
import { Story } from 'core/models/story-manager';

const Wrapper = styled('div', {
  gridColumn: '1/-1',
  '@medium': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    columnGap: '$space$4',
  },
});

const Item = styled('div', {
  marginTop: '$space$3',
});

const SectionHeader = styled('div', {
  gridColumn: '1/-1',
});

const formatDate = (date: string) => dayjs(date).format('MMM D, YYYY');

export const Credits = observer(({ story }: { story: Story }) => {
  const { cast, creditsList, releaseDate, originalBroadcastDate } = story;

  return (
    <>
      <VSpacer size={14} />
      <Wrapper>
        <SectionHeader>
          <Text color="textSecondary">
            {__('Lupa release on %{date}', 'story.lupaRelease', {
              date: formatDate(releaseDate),
            })}
          </Text>
          <Text color="textSecondary">
            {__(
              'Originally broadcast on %{date}',
              'story.originallyBroadcast',
              {
                date: formatDate(originalBroadcastDate),
              }
            )}
          </Text>
        </SectionHeader>
      </Wrapper>
      <VSpacer size={14} />
      <Wrapper>
        <SectionHeader>
          <Text textStyle={'small-heading'}>Voices</Text>
          <VSpacer size={2} />
          <Divider />
        </SectionHeader>
        {cast.map((person, i) => {
          return (
            <Item key={i}>
              <Text>
                {person.fullName}
                {person.accent ? ` (${person.accent})` : null}
              </Text>
              <Markdown
                source={person.description}
                css={{ color: '$textSecondary' }}
              />
            </Item>
          );
        })}
        <VSpacer size={10} css={{ gridColumn: '1/-1' }} />
        <SectionHeader>
          <Text textStyle={'small-heading'}>Credits</Text>
          <VSpacer size={2} />
          <Divider />
        </SectionHeader>
        {creditsList.map(({ role, name }) => {
          return (
            <Item key={role}>
              <Text color={'textSecondary'}>{role}</Text>
              <Text>{name}</Text>
            </Item>
          );
        })}
      </Wrapper>
    </>
  );
});
