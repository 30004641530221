import * as React from 'react';
import {
  Button,
  ButtonBadge /* IconButton */,
  IconButton,
} from 'naan/primitives/button';
import { Menu, MenuItem } from 'naan/primitives/menus';
import { Responsive } from 'naan/primitives/responsive';
import { PlusIcon } from 'naan/icons/plus-icon';
import { HStack } from 'naan/primitives/stack';
import { FavoriteFilledIcon } from 'naan/icons/favorite-filled-icon';
import { FavoriteIcon } from 'naan/icons/favorite-icon';

import __ from 'core/lib/localization';

type VolumeCardHeaderControlsProps = {
  isFavorited?: boolean;
  onClassAssignment: () => void;
  onFavorite: () => void;
  badgeCount?: number;
};

const TeacherVolumeHeaderDropdown = ({
  onClassAssignment,
  onFavorite,
  isFavorited,
}: VolumeCardHeaderControlsProps) => {
  return (
    <Menu>
      <MenuItem
        label={__('Assign to class', 'classroom.assignToClass')}
        action={onClassAssignment}
        leftIcon={<PlusIcon />}
      />
      <MenuItem
        label={
          isFavorited
            ? __('Remove from favorites', 'classroom.removeFavorites')
            : __('Add to favorites', 'classroom.addFavorites')
        }
        action={onFavorite}
        leftIcon={isFavorited ? <FavoriteFilledIcon /> : <FavoriteIcon />}
      />
    </Menu>
  );
};

export const TeacherVolumeCardHeaderButtons = ({
  onClassAssignment,
  onFavorite,
  isFavorited,
  badgeCount,
}: VolumeCardHeaderControlsProps) => {
  return (
    <HStack gap="small">
      <Button
        presentation={badgeCount ? 'activated' : 'secondary'}
        label="Assign to class"
        onClick={onClassAssignment}
        rightIcon={badgeCount ? <ButtonBadge>{badgeCount}</ButtonBadge> : null}
      />
      <IconButton
        presentation={isFavorited ? 'tealLight' : 'grayLight'}
        icon={isFavorited ? <FavoriteFilledIcon /> : <FavoriteIcon />}
        onClick={onFavorite}
      />
    </HStack>
  );
};

export const ResponsiveTeacherVolumeCardHeaderControls = ({
  isFavorited = false,
  onClassAssignment,
  onFavorite,
  badgeCount = 0,
}: VolumeCardHeaderControlsProps) => {
  return (
    <Responsive
      renderDefault={() => (
        <TeacherVolumeHeaderDropdown
          onClassAssignment={onClassAssignment}
          onFavorite={onFavorite}
          isFavorited={isFavorited}
        />
      )}
      renderMediumAndUp={() => (
        <TeacherVolumeCardHeaderButtons
          onClassAssignment={onClassAssignment}
          onFavorite={onFavorite}
          isFavorited={isFavorited}
          badgeCount={badgeCount}
        />
      )}
    />
  );
};
