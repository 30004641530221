import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HomeIcon } from 'naan/icons/home-icon';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import {
  LinkNavItem,
  NavArrow,
  NavBottomAnchor,
  NavContainer,
  NavItem,
  NavLogoWrapper,
  PopoverNavHeader,
  PopoverNavTrigger,
} from 'naan/primitives/navigation/responsive-navigation';
import { LupaLogo } from 'components/ui/lupa-logo';
import { MenuContent } from 'naan/primitives/menus/menu-content';
import { MenuItem } from 'naan/primitives/menus';
import { CouponIcon } from 'naan/icons/coupon-icon';
import { GiftIcon } from 'naan/icons/gift-icon';
import { SignOutIcon } from 'naan/icons/sign-out-icon';
import { ArrowTopRightIcon } from 'naan/icons/arrow-top-right-icon';
import { Text } from 'naan/primitives/text';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from 'mst';
import { useCouponDialog } from 'components/account/marketing-links/coupon-button';
import { PlayIcon } from 'naan/icons/play-icon';
import { ProgressIcon } from 'naan/icons/progress-icon';
import { Box } from 'naan/primitives/box';
import __ from 'core/lib/localization';
import {
  learnHomePath,
  learnProgressPath,
  learnStoriesPath,
  teachHomePath,
} from './path-helpers';
import { Button } from 'naan/primitives/button';

export const LearnMenuContent = () => {
  const { userManager } = useRootStore();
  const { accountData } = userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => navigate(to),
    [navigate]
  );

  const handleApplyCode = useCouponDialog();

  // todo: better factor this with teach-navigation.tsx

  return (
    <MenuContent sideOffset={-20} css={{ minWidth: '300px', marginLeft: 8 }}>
      <PopoverNavHeader>
        <Text textStyle="small-heading">{accountData.name}</Text>
        <Text color="textSecondary" textStyle="small-text">
          {accountData.email}
        </Text>
      </PopoverNavHeader>
      <MenuItem
        label={'Manage my account'}
        leftIcon={<AvatarIcon />}
        rightIcon={<ArrowTopRightIcon width={20} height={20} />}
        presentation="navItem"
        action={goto('/app/profile/account')}
      />
      <MenuItem
        label={'Redeem a code'}
        leftIcon={<CouponIcon />}
        rightIcon={<ArrowTopRightIcon width={20} height={20} />}
        presentation="navItem"
        action={handleApplyCode}
      />
      <MenuItem
        label={'Gift coupons'}
        leftIcon={<GiftIcon />}
        rightIcon={<ArrowTopRightIcon width={20} height={20} />}
        presentation="navItem"
        action={goto('/app/profile/gift-coupons')}
      />

      <MenuItem
        label={'Sign out'}
        leftIcon={<SignOutIcon />}
        presentation="navItem"
        separatorAfter={false}
        action={() => {
          userManager.logout();
        }}
      />

      <DropdownMenu.Arrow offset={16} asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};

export const LearnNavigation = () => {
  const {
    userManager: { accountData, classroomEnabled },
  } = useRootStore();

  const navigate = useNavigate();

  return (
    <NavContainer>
      <NavLogoWrapper>
        <LupaLogo />
      </NavLogoWrapper>
      {classroomEnabled ? (
        <Button
          label={__('Go to Classrooms', 'common.goToClassrooms')}
          onClick={() => navigate(teachHomePath())}
          rightIcon={<ArrowTopRightIcon width={20} height={20} />}
        />
      ) : null}
      <LinkNavItem to={learnHomePath()} icon={<HomeIcon />} label={'Home'} />
      <LinkNavItem
        to={learnStoriesPath()}
        icon={<PlayIcon />}
        label={'Stories'}
      />
      <LinkNavItem
        to={learnProgressPath()}
        icon={<ProgressIcon />}
        label={'My classes'}
      />
      <NavBottomAnchor>
        <DropdownMenu.Root>
          <PopoverNavTrigger>
            <NavItem
              icon={<AvatarIcon />}
              label={
                <>
                  <Box
                    css={{ display: 'none', '@large': { display: 'block' } }}
                  >
                    {accountData.name}
                  </Box>
                  <Box css={{ '@large': { display: 'none' } }}>
                    {__('Me', 'nav.me')}
                  </Box>
                </>
              }
            />
          </PopoverNavTrigger>
          <LearnMenuContent />
        </DropdownMenu.Root>
      </NavBottomAnchor>
    </NavContainer>
  );
};
