import { getParentOfType, ModelTreeNode } from 'ts-state-tree/tst-core';
import { UserManager } from '.';

/**
 * ClassroomUserData
 *
 * teacher specific client managed data
 */
export class ClassroomUserData extends ModelTreeNode {
  static CLASS_NAME = 'ClassroomUserData' as const;

  static create(snapshot: any) {
    return super.create(ClassroomUserData, snapshot) as ClassroomUserData;
  }

  favoritedStorySlugs: string[] = [];

  get userManager(): UserManager {
    return getParentOfType(this, UserManager);
  }

  toggleFavorite(slug: string) {
    if (this.isFavorited(slug)) {
      return this.removeFromFavorites(slug);
    } else {
      return this.addToFavorites(slug);
    }
  }

  isFavorited(slug: string): boolean {
    return this.favoritedStorySlugs.includes(slug);
  }

  addToFavorites(slug: string) {
    this.favoritedStorySlugs.push(slug);
    // return this.root.userManager.persistUserData();
    return this.userManager.persistUserData(); // async
  }

  removeFromFavorites(slug: string) {
    const index = this.favoritedStorySlugs.indexOf(slug);
    if (index > -1) {
      this.favoritedStorySlugs.splice(index, 1);
      return this.userManager.persistUserData(); // async
    }
  }
}
