import React from 'react';
export const IconCheckmark = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 4.3739L10.6363 21.1489L2 14.2398L3.57626 12.2695L10.0794 17.472L19.911 2.95874L22 4.3739Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
