/// web only
// import { useRef, useEffect } from 'react';
// import { getConfig } from 'app/env';

/// We're disablign it for now. We must think of something better. Most likely something that integrtates with page headings.
export const useTitle = (title: string) => {
  // const prefix = getConfig('appTitle');
  // if (prefix) {
  //   title = `${title} | ${prefix}`;
  // }
  // const prevTitleRef = useRef(document.title);
  // document.title = title;
  // useEffect(() => {
  //   const prevTitle = prevTitleRef.current;
  //   return () => {
  //     document.title = prevTitle;
  //   };
  // }, []);
};
