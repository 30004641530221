import React from 'react';
import styled from 'styled-components';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';
import cx from 'classnames';

/**
 * FIXME:
 * We are defining the color names directly for convenience
 * but eventually we should define utility-color variables
 * e.g. button-default-background-color
 *
 * preferably, those would come extracted from a figma file somewhere
 *
 *
 * Same for other things like padding and border-radii
 *
 */
const ButtonWrapper = styled.button`
  /* Reset browser‘s default button appeareance */
  border: none;
  background: none;

  ${includeTextStyles('body-bold')}

  /* Base css vars*/

  --button-border-radius: 20px;
  --button-background-color: ${getColor('gray600')};
  --button-padding-horizontal: 24px;
  --button-padding-vertical: 8px;
  --button-label-color: ${getColor('white')};
  --button-border-width: 0;
  --button-border-color: transparent;

  /* Base properties */
  border-radius: var(--button-border-radius);
  background-color: var(--button-background-color);
  padding: var(--button-padding-vertical) var(--button-padding-horizontal);
  border-style: solid;
  border-width: var(--button-border-width);
  border-color: var(--button-border-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &:active {
    outline: none;
  }

  .button-label {
    color: var(--button-label-color);
    ${includeTextStyles('body-bold')};
  }

  .button-icon {
    color: var(--button-label-color);
    margin-right: 6px;
    margin-left: -4px;
  }

  /* Overrides */

  &.full-width {
    width: 100%;
    justify-content: center;
  }

  &.is-disabled {
    --button-background-color: ${getColor('gray100')};
    cursor: not-allowed;
  }

  &.button-type--square {
    --button-border-radius: 8px;
    --button-padding-vertical: 12px;
  }

  &.button-type--text {
    --button-background-color: transparent;
    --button-label-color: ${getColor('textSecondary')};
  }

  &.button-size--small {
    --button-padding-horizontal: 16px;
    --button-padding-vertical: 4px;
  }

  &.button-presentation--primary {
    --button-background-color: ${getColor('teal500')};
    &.is-disabled {
      --button-background-color: ${getColor('gray100')};
    }
  }

  &.button-presentation--secondary {
    --button-background-color: ${getColor('green500')};
    &.is-disabled {
      --button-background-color: ${getColor('gray100')};
    }
  }

  &.button-presentation--outline {
    --button-background-color: transparent;
    --button-border-color: ${getColor('gray100')};
    --button-border-width: 1px;
    --button-label-color: ${getColor('gray600')};
    &.is-disabled {
      --button-border-color: ${getColor('gray100')};
      --button-label-color: ${getColor('gray300')};
    }
  }

  &.button-presentation--primary-outline {
    --button-background-color: transparent;
    --button-border-color: ${getColor('teal200')};
    --button-border-width: 1px;
    --button-label-color: ${getColor('teal500')};
    &.is-disabled {
      --button-border-color: ${getColor('gray100')};
      --button-label-color: ${getColor('gray300')};
    }
  }

  &.button-presentation--red-outline {
    --button-background-color: transparent;
    --button-border-color: ${getColor('red500')};
    --button-border-width: 1px;
    --button-label-color: ${getColor('red500')};
    &.is-disabled {
      --button-border-color: ${getColor('red100')};
      --button-label-color: ${getColor('red200')};
    }
  }

  &.button-presentation--white-outline {
    --button-background-color: transparent;
    --button-border-color: ${getColor('white')};
    --button-border-width: 1px;
    --button-label-color: ${getColor('white')};
    &.is-disabled {
      /* FIXME: define this color in the palette!! */
      --button-border-color: rgba(255, 255, 255, 0.3);
      --button-label-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const Button = React.forwardRef(
  (
    {
      label,
      type = 'default',
      size = 'default',
      presentation = 'default',
      disabled = false,
      fullWidth = false,
      icon: Icon = null,
      className: defaultClassName,
      submit = false,
      ...props
    }: {
      label: string;
      type?: 'default' | 'square' | 'text';
      size?: 'default' | 'small';
      presentation?:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'outline'
        | 'primary-outline'
        | 'red-outline'
        | 'white-outline';
      disabled?: boolean;
      fullWidth?: boolean;
      icon?: React.ReactNode;
      className?: string;
      submit?: boolean;
    } & any,
    ref
  ) => {
    const className = cx([
      `button-type--${type}`,
      `button-size--${size}`,
      `button-presentation--${presentation}`,
      { 'is-disabled': disabled },
      { 'full-width': fullWidth },
      defaultClassName,
    ]);

    if (submit) {
      props['type'] = 'submit';
    }

    return (
      <ButtonWrapper className={className} ref={ref} {...props}>
        {Icon ? (
          <span className="button-icon">
            <Icon />
          </span>
        ) : null}
        <span className="button-label">{label}</span>
      </ButtonWrapper>
    );
  }
);
