import * as React from 'react';
import { observer } from 'mobx-react';
import { VSpacer } from 'naan/primitives/spacer';
import { VolumeCard } from 'components/volume-layouts';
import { Divider } from 'naan/primitives/divider';
import { VStack } from 'naan/primitives/stack';
import { useMachine } from '@xstate/react';
import { assignmentMachine } from 'components/classrooms/assignment-dialog/assignment-machine';
import { AssignmentDialog } from 'components/classrooms/assignment-dialog';

type UnitsListProps = {
  units: any[];
};

export const UnitsList = observer(({ units }: UnitsListProps) => {
  const [state, send] = useMachine(assignmentMachine);

  const assignToClassHandler = React.useCallback(
    // FIXME change type, once we have typed JWCORE
    (unit: any) => () => send({ type: 'OPEN', unitSlug: unit.slug }),
    [send]
  );

  return (
    <>
      <VStack>
        <VSpacer size={4} />
        {units?.map((unit, index) => {
          return (
            <React.Fragment key={unit.slug}>
              <VolumeCard
                story={unit}
                truncateDescription={true}
                onClassAssignment={assignToClassHandler(unit)}
              />
              <VSpacer size={2} />
              {index < units.length - 1 ? (
                <>
                  <Divider className="divider" />
                  <VSpacer size={4} />
                </>
              ) : null}
            </React.Fragment>
          );
        })}
      </VStack>
      <AssignmentDialog
        state={state}
        onClose={() => {
          send({ type: 'CLOSE' });
        }}
        onSelectClass={(classId: string) => {
          send({ type: 'SELECT_CLASS', classId });
        }}
        onCreateClass={() => {
          send({ type: 'SHOW_NEW_CLASS' });
        }}
      />
    </>
  );
});
