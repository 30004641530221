import { snakeCase } from 'lodash';
import { ModelTreeNode, identifier, frozen } from 'ts-state-tree/tst-core';
import __, { getLocale } from 'core/lib/localization';
import { minutesToPrettyDuration } from 'core/lib/pretty-duration';
import { ChapterCatalogData } from './chapter-catalog-data';
import { Cast } from './cast';
import { ActivityGuide } from './activity-guide';
import { getBaseRoot } from '../base-root';
import { Credit, CreditsV3Data, VocabLookup } from './bogota-types';

const camelCaseToPhrase = (s: string) =>
  snakeCase(s)
    .replace(/^./, a => a.toUpperCase())
    .replace(/_/g, ' ');

export type TagType = 'topic' | 'country'; // | 'ap' | 'ib';

export type MappedTag = {
  type: TagType; //'topic' | 'country';
  label: string;
  url: string;
};

const tagMapper = (
  tags: string[],
  type: TagType,
  filterKey: string
): MappedTag[] =>
  tags.map(tag => ({
    type,
    label: tag,
    url: `/discover/?${filterKey}[]=${tag}`,
  }));

//
// should probably be renamed as UnitCatalogData
//
export class UnitCatalogData extends ModelTreeNode {
  static CLASS_NAME = 'UnitCatalogData' as const;

  @identifier
  slug: string = '';

  title: string = '';
  dataUrl: string = '';
  tagline?: string = null;
  description: string = '';
  listImageUrl: string = '';
  bannerImageUrl: string = '';
  downloadSize: number = 0;
  durationMinutes: number = 0;
  volumeDurationMinutes: number = 0;
  durationMillis?: number = null; // must be temporarily nullable when hydrating local store
  chapters: ChapterCatalogData[] = [];
  topics: string[] = [];
  countries: string[] = [];
  ibTags?: string[] = [];
  apTags?: string[] = [];
  cast?: Cast[] = null;
  baseTitle: string = null;
  partSuffix: string = null;
  activityGuideData?: ActivityGuide = null;

  @frozen
  credits?: CreditsV3Data; //TSTStringMap<string> = null;

  // publishedOn: types.Date, //iso8601 date,
  // renamed to originalBroadcastDate
  publishedOn?: string = null;
  releaseDate: string = null;
  weblink: string = null;
  lessonPlanUrl: string = null;
  activityGuideUrl?: string = null;
  trial: boolean = false; // - is a free trial episode,
  recommended: boolean = false; // - to be included in 'recommended' dashboard list,
  version: number = 0;

  ingestedAt: string = null; // iso8601 datetime

  // v4 support
  // not sure if both L1 & L2 versions will be useful, but including for now and we can trim later
  titleL1: string = null;
  titleL2?: string = null;
  descriptionL1?: string = null;
  descriptionL2?: string = null;
  //TODO creditsV4?: Credit[] = [];
  volumeSlug?: string = '';
  unitNumber?: number = 0; // todo: confirm if this is working

  //TODO speakerLabels?: SpeakerLabel[] = [];
  // for now we can ignore these and assume that all stories in the catalog will be appropriate
  // for the users current language pair, but in future we'll need a system to support
  // multiple language pairs.
  l1Code?: string = null; // ja
  l2Code?: string = null; // en

  static create(snapshot: any) {
    return super.create(UnitCatalogData, snapshot) as UnitCatalogData;
  }

  get story() {
    const { storyManager } = getBaseRoot(this);
    if (!storyManager) return null;
    return storyManager.story(this.storySlug);
  }

  // resolve the slug of the first unit if we're in a multi-unit story
  get storySlug() {
    const { storyManager, userManager } = getBaseRoot(this);
    if (userManager.webModeEnabled) {
      // implies volume mode
      const story = storyManager.stories.find(story =>
        story.includesUnit(this.slug)
      );
      if (story) {
        return story.slug;
      } else {
        throw Error(
          `unable to resolve containing story for slug: ${this.slug}`
        );
      }
    } else {
      return this.slug;
    }
  }

  get resolvedTitle() {
    return (
      this.resolvedBaseTitle +
      (this.titleSuffix ? ` [${this.titleSuffix}]` : '')
    );
  }

  get resolvedBaseTitle() {
    return this.baseTitle || this.title;
  }

  get titleSuffix() {
    return this.partSuffix ? `Part ${this.partSuffix}` : null;
  }

  get allTags() {
    return [
      ...tagMapper(this.countries, 'country', 'countries'),
      ...tagMapper(this.topics, 'topic', 'topics'),
      // ...tagMapper(this.apTags, 'ap', 'ap'),
      // ...tagMapper(this.ibTags, 'ib', 'ib'),
    ];
  }

  //
  get pedagogicalThemes() {
    return [...this.apTags, ...this.ibTags].sort();
  }

  get numberOfChapters() {
    return this.chapters.length;
  }

  get downloadSizeInMB() {
    return Math.round(this.downloadSize / (1024 * 1024));
  }

  get creditsList(): Credit[] {
    // Plain Old Javascript Object
    // const pojo = this.credits.toPOJO();
    // const pojo = getSnapshot(this.credits); // todo: revisit this

    // returns an array of objects like:
    //[{role:'Refactored by',name:'Armando Sosa'}]
    // I think the data should come like this, it's way easier
    // and safer to iterate on arrays, than objects
    // return Object.keys(this.credits).map(k => ({
    //   roleKey: k, // localization key suffix
    //   role: camelCaseToPhrase(k),
    //   name: pojo[k],
    // }));

    return Object.entries(this.credits).map(([key, value]) => ({
      roleKey: key, // localization key suffix
      role: camelCaseToPhrase(key),
      name: value,
    }));

    // todo
    // return [];
  }

  resolveSpeakerData(label: string) {
    if (!label) {
      return null;
    }
    // // v4/jw catalog mode
    // if (this.speakerLabels) {
    //   const v4Data = this.speakerLabels.find(data => data.label === label);
    //   if (v4Data) {
    //     return v4Data.v3Data;
    //   }
    // }

    // v3/lupa catalog mode
    if (this.cast) {
      const v3Data = this.cast.find(
        speaker => speaker.shortName === label || speaker.fullName === label
      );
      if (v3Data) {
        return v3Data;
      }
    }

    // if no match, then just pass through as the displayed text and no bio
    return {
      label: label,
      fullName: label,
    };
  }

  // renamed
  get originalBroadcastDate() {
    return this.publishedOn;
  }

  // get bannerImagePath() {
  //   return this.storyDownload.assetPath(assetKeys.BANNER_IMAGE);
  // },

  // get listImagePath() {
  //   return this.storyDownload.assetPath(assetKeys.LIST_IMAGE);
  // },

  // get fullDataPath() {
  //   return this.storyDownload.assetPath(assetKeys.FULL_DATA);
  // },

  // used to exclude subsequent parts from recommended list
  get headPart() {
    return !this.partSuffix || this.partSuffix === '1';
  }

  get sortDurationMinutes() {
    if (this.volumeDurationMinutes > 0) {
      return this.volumeDurationMinutes;
    }
    return this.durationMinutes;
  }

  get durationDescription() {
    // const chapters = __(
    //   { one: '%{count} chapter', other: '%{count} chapters' },
    //   'story.nChapters',
    //   { count: this.chapters.length }
    // );

    // looks like we only need the time now, not the chapter counts
    const duration = minutesToPrettyDuration(this.durationMinutes);

    // return __('%{chapters}, %{duration}', 'story.durationDescription', {
    //   chapters,
    //   duration,
    // });
    return duration;
  }

  get chapterCount() {
    return this.chapters?.length;
  }

  get isLastUnit() {
    return this.unitNumber === this.story.unitCount + 1;
  }

  get localizedTagline() {
    /// @joseph: I don't know if you did this intentionally or if it was a mistake.
    /// it seems like there's no such thing as a localied tagline,
    /// so I'm just going to return the tagline as it is, for now.
    return this.tagline;
    // switch (getLocale()) {
    //   case this.l2Code:
    //     return this.titleL2 || this.title;
    //   default:
    //     return this.titleL1 || this.title;
    // }
  }

  get localizedDescription() {
    switch (getLocale()) {
      case this.l2Code:
        return this.descriptionL2 || this.description;
      default:
        return this.descriptionL1 || this.description;
    }
  }

  get promoAudioUrl() {
    return this.chapters[0]?.normalAudioUrl;
  }

  get completed() {
    return this.chapters.every(ch => ch.isCompleted);
  }

  //
  // full data attributes used by story guide rendering
  //

  get vocabs(): VocabLookup[] {
    return (this as any).vocabs || [];
  }

  // this seems to be missing from the latest masala ingested data
  get sics(): VocabLookup[] {
    return (this as any).sics || [];
  }
}
