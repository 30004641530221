import React from 'react';
import { observer } from 'mobx-react';
import { NotFoundScreen } from './not-found-screen';
import { FullScreenLoader } from 'components/ds/modals/full-screen-loader';
import { StoryGuide } from 'components/guides/story-guide';
import { useStoryData } from 'components/guides/use-story-data';
import { fail } from 'core/lib/errors';
// import { usePageTracker } from 'lib/hooks/use-page-tracker';

export const BasicScriptScreen = observer(() => {
  // usePageTracker();
  const { data, loading, error } = useStoryData();

  if (loading) {
    return <FullScreenLoader />;
  }

  if (error) {
    fail(error);
  }

  if (!data) {
    /// we are already handling errors up there 👆,
    /// so there's no need to do it here
    return <NotFoundScreen reportError={false} />;
  }

  return <StoryGuide story={data} simple />;
});
