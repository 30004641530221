import { action, runInAction } from 'mobx';
import fetch from 'cross-fetch';
import {
  ModelTreeNode,
  applySnapshot,
  identifier,
  Bindery,
} from 'ts-state-tree/tst-core';
import { UnitCatalogData } from './unit-catalog-data';
import { ChapterCatalogData } from './chapter-catalog-data';
import { Cast } from './cast';
import { ActivityGuide } from './activity-guide';
import { Author } from './author';
import { FeaturedRelease } from './featured-release';
// import { Credit } from './credit';
// import { Volume } from './volume';
import { VolumeCatalogData } from './volume-catalog-data';
import { Speaker } from './speaker';

export class Catalog extends ModelTreeNode {
  static CLASS_NAME = 'Catalog' as const;

  static create(snapshot: any = {}): Catalog {
    return super.create(Catalog, snapshot) as Catalog;
  }

  static bindModels(bindery: Bindery): void {
    bindery.bind(ActivityGuide);
    bindery.bind(Author);
    bindery.bind(Cast);
    bindery.bind(Catalog);
    // bindery.bind(Credit);
    bindery.bind(ChapterCatalogData);
    bindery.bind(FeaturedRelease);
    bindery.bind(UnitCatalogData);
    // bindery.bind(Volume);
    bindery.bind(VolumeCatalogData);
    bindery.bind(Speaker);
  }

  @identifier
  catalogUrl: string;

  version: number = 0;
  generatedAt: string;
  l1: string = '';
  l2: string = '';
  stories: UnitCatalogData[] = [];
  // renamed so new schema can diverge from legacy mst schema
  volumeList: VolumeCatalogData[] = [];
  featuredReleases: FeaturedRelease[] = [];

  @action
  l1Foo() {
    this.l1 = 'foo';
  }

  l1Bar() {
    runInAction(() => {
      this.l1 = 'bar';
    });
  }

  // trigger warning when invoked from harness unit test
  l1NoAction() {
    this.l1 = 'warn';
  }

  // @action decorator here doesn't appear to change any behavior
  async load(catalogUrl: string) {
    // this.l1NoAction(); // will trigger warning
    // runInAction(() => {
    //   // wrapping this line prevents the unit test warning
    //   this.l1 = 'foo';
    // });
    const response = await fetch(catalogUrl);
    // maybeRunInAction(() => {
    //   this.l1 = 'bar';
    // });
    const json = await response.json();
    applySnapshot(this, json);
    // console.log(`inside reloadCatalog - this.l1: ${this.l1}`);
    // todo: confirm mobx reaction behavior here
  }

  // type WhatYouYield="foo"
  // type WhatYouReturn="bar"
  // type WhatYouAccept="baz"

  // function* myfun(): Generator<
  //   WhatYouYield, // what is being returned by right hand side of yield
  //   WhatYouReturn, // final return values of this function
  //   WhatYouAccept // what the yield is being assigned to?
  // > {
  // const myYield = "foo" //type of myYield is WhatYouYield
  // const myAccepted = yield myYield; //type of myAccepted is WhatYouAccept
  // return "baz" //type of this value is WhatYouReturn
  // }

  // let's not bother with generator function yield/accept typing
  *reloadCatalogGenerator(catalogUrl: string): Generator<any, void, any> {
    // console.log(`inside reloadCatalogGenerator`);
    // this.l1NoAction(); // no warning triggered
    this.l1 = 'foo';
    // this.l1Foo();
    const response = yield fetch(catalogUrl);
    this.l1 = 'bar';
    // this.l1Bar();
    const json = yield response.json();
    applySnapshot(this, json);
    // console.log(`returning from reloadCatalogGenerator, l1: ${this.l1}`);
  }
}
