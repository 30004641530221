/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import styled from 'styled-components';
import { getConfig } from 'app/env';
import { createLogger } from 'app/logger';

const log = createLogger('debug:display-status');

const Container = styled.div`
  position: absolute;
  right: 24px;
  top: 36px;
  background-color: rgba(50, 50, 50, 0.5);
  padding: 8px;
  z-index: 1000;
  opacity: 0.5;
`;

const Text = styled.span`
  color: white;
  font-size: 12px;
`;

const showStatusDisplay = getConfig('showStatusDisplay', false);

export const DisplayStatus = ({ status }) => {
  if (!showStatusDisplay) {
    return null;
  }

  log.info('root status', status);
  return (
    <Container pointerEvents={'none'}>
      <Text>🚩 {status}</Text>
    </Container>
  );
};
