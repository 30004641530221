import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';

const Wrapper = styled.div`
  ${includeTextStyles('small-text')};
  color: ${getColor('textSecondary')};
  margin-bottom: 14px;
`;

export const MobileOnlyTableHeader: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return null;
  }

  return <Wrapper>{children}</Wrapper>;
};
