import * as React from 'react';
import {
  GlobalGrid,
  ContentGridOuterContainer,
  SidebarContainer,
  LayoutProvider,
} from 'components/global-layout';

// import { LupaLogo } from 'components/ui/icons/logo';
// import { VStack } from 'naan/primitives/stack';
import { GlobalSidebar } from '../global-sidebar';
import { GlobalFooter } from 'components/global-footer';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'naan/stitches.config';

function ScrollToTop(): null {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('scrollable-container')?.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// export function ScrollToTopOnMount() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return null;
// }

const MainContent = styled('div', {
  minHeight: 'calc(100vh - 153px)',
  '@medium': {
    minHeight: 'calc(100vh - 82px)',
  },
});

export const OldGlobalLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <LayoutProvider>
      <GlobalGrid className="x-global-grid">
        <SidebarContainer>
          <GlobalSidebar />
        </SidebarContainer>
        <ContentGridOuterContainer id="scrollable-container">
          <ScrollToTop />
          <MainContent>{children}</MainContent>
          <GlobalFooter />
        </ContentGridOuterContainer>
      </GlobalGrid>
    </LayoutProvider>
  );
};
