import { observable } from 'mobx';
import { isString } from 'lodash';
import i18n from 'i18n-js';
import Dayjs from 'dayjs';

import { getConfig } from 'app/env';

import invariant from './invariant';
import { getBus } from '@jiveworld/minibus';

/**
 * This creates a custom plurailzation rule for the `ja` locale.
 * We need to support Phrase‘s implementation of japanese pluralization
 * which results on an object with at least a `other` key, and an optional
 * `zero key`
 */
i18n.pluralization['ja'] = function (count: number) {
  if (count === 0) {
    return ['zero', 'other'];
  }
  return ['other'];
};

export const systemDefaultLocale = 'en';
/**
 * This creates a Mobx observable store for the current locale.
 * This allows us to change the locale at runtime and have the UI react to it.
 */
function createLocalizationStore() {
  const translations = {};

  const __locale = observable.box(systemDefaultLocale);

  i18n.translations = translations;
  i18n.fallbacks = true;
  i18n.missingTranslation = () => undefined;

  function addTranslations(lang: string, strings = {}) {
    i18n.translations[lang] = strings;
  }

  function translateWithoutDefault(
    scope: string,
    options: i18n.TranslateOptions = {}
    // ...rest: any[]
  ) {
    options.locale = getLocale();
    const str = i18n.t(scope, { ...options } /* , ...rest */);
    if (getConfig('i18n.showBrackets', false as any)) {
      return `[${str}]`;
    }

    if (!isString(str)) {
      invariant(
        false,
        `string expected: i18n(${scope}) -> ${JSON.stringify(
          str
        )}, type: ${typeof str}`
      );
      return `[${scope}]`;
    }
    return str;
  }

  function setLocale(newLocale: string) {
    const resolvedLocale: string = getConfig('i18n.forcedLocale') || newLocale;
    __locale.set(resolvedLocale);
    /// Assumes that the Dayjs localization support has been appropriately
    /// initialized within the app level localization-service.js
    Dayjs.locale(resolvedLocale);
    getBus('localization').emit('setLocale', resolvedLocale);
  }

  function getLocale() {
    return __locale.get();
  }

  function onLocaleChange(callback: (...args: any[]) => boolean | void) {
    return getBus('localization').subscribe('setLocale', callback);
  }

  function translateWithDefault(
    defaultValue:
      | string
      | Record</* 'zero' | 'one' | 'other' */ string, string>,
    scope: string,
    options: i18n.TranslateOptions = {}
    // ...rest
  ) {
    if (getConfig('i18n.bypass', false as any)) {
      if (typeof defaultValue === 'string') {
        return defaultValue;
      }
      /// we need to always return a string, otherwise react components that accept strings
      /// will fail typechecking --or worse--, will crash in runtime
      return defaultValue.one ?? defaultValue.other ?? defaultValue.zero ?? '';
    }
    return translateWithoutDefault(
      scope,
      { defaultValue: defaultValue as any, ...options } /*, ...rest */
    );
  }

  return {
    addTranslations,
    translateWithoutDefault,
    setLocale,
    getLocale,
    translateWithDefault,
    onLocaleChange,
  };
}

const store = createLocalizationStore();

// expose a version without a default for usages isolated from the english reference parsing
export const t = store.translateWithoutDefault;

export const getLocale = store.getLocale;
export const setLocale = store.setLocale;
export const onLocaleChange = store.onLocaleChange;

// in runtime all it does is returning the scope,
// but it's picked by the get-strings parser to populate en.json
export const __s = (_: any, scope: string) => scope;
export const __f =
  (...args: Parameters<typeof store.translateWithDefault>) =>
  () =>
    store.translateWithDefault(...args);
export const __ = store.translateWithDefault;

export const addTranslations = store.addTranslations;

export const getAvailableTranslations = () => {
  return Object.keys(i18n.translations);
};

// reexport i18n for debug purposes
export const i18nInstance = i18n;

export default __;
