// derived from masala-lib/utils

import { isEmpty } from 'lodash';
import { L12String } from './util-types';

export const notEmptyOrNA = (text: string): boolean => {
  return !isEmptyOrNA(text);
};

export const isEmptyOrNA = (text: string): boolean => {
  return isEmpty(text) || isNA(text);
};

export const isNA = (text: string): boolean => {
  return !isEmpty(text) && text.toUpperCase() === 'N/A';
};

export const displayedProp = (lString: L12String): string => {
  const value = lString?.l1 || lString?.l2;
  if (isEmptyOrNA(value)) {
    return '';
  }
  return value;
};
