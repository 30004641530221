// import { prevent } from 'common/prevent';
import { useRootStore } from 'mst';
import { InteractiveTag, TagsContainer } from 'naan/primitives/tags';
import * as React from 'react';
import { FilterBox } from './filter-box';

import { AnimatedTagsContainer } from './animated-tags-container';

type RootStore = {
  storyManager: {
    countryList: string[];
  };
};

type Props = {
  show: boolean;
};

export const CountriesFilterPanel = ({ show = false }: Props) => {
  const store = useRootStore() as RootStore;
  const { storyManager } = store ?? {};
  const list = storyManager.countryList;

  return (
    <AnimatedTagsContainer show={show}>
      <TagsContainer className="wrapper">
        <FilterBox
          filterItems={list}
          filterQueryKey={'countries'}
          renderFilterControl={(item, selected, action) => {
            return (
              <InteractiveTag
                role="button"
                selected={selected}
                onSelect={action}
                type="country"
              >
                {item}
              </InteractiveTag>
            );
          }}
        />
      </TagsContainer>
    </AnimatedTagsContainer>
  );
};
