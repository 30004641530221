import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { UnitCatalogData } from 'core/models/catalog';

const fetcher = (url: string): any => fetch(url).then(res => res.json());

export const useStoryData = () => {
  const { slug } = useParams<{ slug: string }>();
  const { storyManager } = AppFactory.root;
  // todo: make sure unexpected errors are gracefully handled
  const story = storyManager.story(slug);
  const url = story?.catalogData?.dataUrl;
  let { data, error } = useSWR<UnitCatalogData, any, any>(url, fetcher);

  /// because of the rules of hooks, we can't return earlier.
  /// but we know that we don't have a story with that slug,
  /// so we manually trigger an error
  if (!story) {
    error = new Error('Story not found');
  }

  const loading = !error && !data;
  return { data, loading, error };
};
