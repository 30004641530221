import * as React from 'react';

import { observer } from 'mobx-react';
import { GoogleButton } from 'components/ui/buttons/google-button';
import { AuthFootnote } from 'components/auth/auth-footnote';
import { Text } from 'naan/primitives/text';
import { Button } from 'naan/primitives/button';
import { MediumGap, LargeGap } from 'components/ds/common/spacers';
import { useTitle } from 'common/hooks/use-title';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { VStack } from 'naan/primitives/stack';
import { AuthHeader } from 'components/auth/auth-header';
import { Input, PasswordInput } from 'naan/primitives/input';
import { Label } from 'naan/primitives/forms/label';
import { useComboForm } from 'common/hooks/use-comboform';
import { VSpacer } from 'naan/primitives/spacer';
import { Link } from 'react-router-dom';
import { styled } from 'naan/stitches.config';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { FormError } from 'naan/primitives/forms';
import { omit } from 'lodash';
import { AppFactory } from 'app/app-factory';
import __ from 'core/lib/localization';

const LinkStyled = styled(Link, {
  textStyle: 'small-text',
  color: '$teal-500',
  textDecoration: 'underline',
  textAlign: 'right',
  position: 'absolute',
  right: 0,
});

const LabelWrapper = styled('div', {
  position: 'relative',
});

type FormInput = {
  email: string;
  password: string;
};

export const SignInScreen = observer(() => {
  useTitle(__('Welcome back', 'auth.welcomeBack'));
  useAfterAuthRedirection();
  const { userManager } = AppFactory.root;

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({});

  /// This is the callback that actually handles the log in.
  const handleLogin = React.useCallback(
    ({ email, password }: FormInput) => {
      clearValidationErrors();
      userManager.login(email, password).then(() => {
        /// this could be a toast!
      }, handleValidationErrors);
    },
    [handleValidationErrors, userManager, clearValidationErrors]
  );

  return (
    <VStack>
      <AuthHeader>
        <Text textStyle="large-heading--desktop">
          {__('Welcome back', 'auth.welcomeBack')}
        </Text>
      </AuthHeader>
      {/* <form className="form" onSubmit={submitHandler}> */}
      <form onSubmit={handleSubmit(handleLogin)}>
        <CatchAllFormErrors
          errors={omit(errors, ['email', 'password'])}
          data-test-id="error-message"
        />
        <VStack>
          <Label htmlFor="email">{__('Email', 'auth.email')}</Label>
          <VSpacer size={2} />
          <Input
            name={'email'}
            data-test-id={'email-address'}
            type="email"
            tabIndex={1}
            {...register('email')}
            required
          />
          <FormError
            error={errors['email']}
            data-test-id="email-error-message"
          />

          <VSpacer size={6} />

          <LabelWrapper>
            <Label htmlFor="password">{__('Password', 'auth.password')}</Label>
            <LinkStyled
              to="/auth/reset-password"
              data-test-id="forgot-password"
              tabIndex={3}
            >
              {__('Forgot password?', 'auth.forgotPassword')}
            </LinkStyled>
          </LabelWrapper>

          <VSpacer size={2} />

          <PasswordInput
            name={'password'}
            data-test-id={'password'}
            tabIndex={2}
            {...register('password')}
            placeholder={''}
            required
          />
          <FormError
            error={errors['password']}
            data-test-id="password-error-message"
          />
          <VSpacer size={6} />
        </VStack>
        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Sign in', 'auth.signIn')}
            onClick={handleSubmit as any}
            presentation="teal"
            size="large"
            data-test-id="create-account-btn"
            fullWidth
            tabIndex={4}
          />
        </div>
      </form>
      <MediumGap />
      <GoogleButton
        title={__('Sign in with Google', 'auth.signInWithGoogle')}
        tabIndex={5}
      />
      <MediumGap />
      <AuthFootnote
        heading={__('Have a code?', 'auth.haveACode')}
        body={__(
          'Sign in above, or create a free account to redeem.',
          'auth.signInToRedeem'
        )}
      />
      <LargeGap />
    </VStack>
  );
});
