const env = {
  forcedApiEnv: 'preprod',

  i18n: {
    forcedLocale: 'en',
  },

  analytics: {
    segmentWriteKey: 'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // lupa-spa (testing)
    // property: jiveworld preprod, stream name: my.preprod.jiveworld.com stream
    googleAnalyticsKey: 'G-0ELVMT9KDM',
  },

  bugsnag: {
    apiKey: '56ed11fed6bfebab28223aa62b630786',
    enabled: true,
  },

  website: {
    urls: {
      marketingSite: 'https://lupa.preprod.jiveworld.com',
      classroomMarketingPage:
        'https://lupa.preprod.jiveworld.com/schools/classrooms',
    },
  },
};

export default env;
