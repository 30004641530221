import * as React from 'react';

export function LockLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 9C10 6.79086 11.7909 5 14 5C16.2092 5 18 6.79086 18 9V11H10V9ZM8.00004 11V9C8.00004 5.68629 10.6863 3 14 3C17.3138 3 20 5.68629 20 9V11H21C22.1046 11 23 11.8954 23 13V21C23 22.1046 22.1046 23 21 23H7.00004C5.89547 23 5.00004 22.1046 5.00004 21V13C5.00004 11.8954 5.89547 11 7.00004 11H8.00004Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function LockSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 5.25C8.25 4.2835 9.0335 3.5 10 3.5C10.9665 3.5 11.75 4.2835 11.75 5.25V6H8.25V5.25ZM6.75 6V5.25C6.75 3.45507 8.20507 2 10 2C11.7949 2 13.25 3.45507 13.25 5.25V6H15C16.1046 6 17 6.89543 17 8V14C17 15.1046 16.1046 16 15 16H5C3.89543 16 3 15.1046 3 14V8C3 6.89543 3.89543 6 5 6H6.75Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function LockIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 7.375C9 5.42481 10.4256 4 12 4C13.5744 4 15 5.42481 15 7.375V9H9V7.375ZM7 9V7.375C7 4.4927 9.15612 2 12 2C14.8439 2 17 4.4927 17 7.375V9H18C19.1046 9 20 9.89543 20 11V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V11C4 9.89543 4.89543 9 6 9H7Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
