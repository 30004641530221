import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { HStack } from 'naan/primitives/stack';
import { styled } from 'naan/stitches.config';
import { AppFactory } from 'app/app-factory';
import { useComboForm } from 'common/hooks/use-comboform';
import { couponCodeSchema } from 'core/lib/validation-rules';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
// import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  // width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const InputWrapper = styled('div', {
  display: 'flex',
  flex: 1,
  borderRadius: '8px',
  background: '$gray-50',
  alignItems: 'center',
  px: '$2',
  marginRight: '$3',
  input: {
    all: 'unset',
    padding: '$2',
    flex: 1,
    width: '100%',
  },
});

type FormValues = {
  code: string;
};

type CouponApiResult = {
  message: string;
} | null;

export const CouponCodeForm = observer(
  ({
    placeholder,
    buttonLabel,
    onCouponCodeSuccess,
  }: {
    placeholder: string;
    buttonLabel: string;
    onCouponCodeSuccess: (result: CouponApiResult) => void;
  }) => {
    const store = AppFactory.root;

    const defaultValues = {
      code: '',
    };

    const { handleSubmit, register, errors, handleValidationErrors } =
      useComboForm<FormValues>({
        validationSchema: couponCodeSchema,
        defaultValues,
      });

    const submitHandler = handleSubmit(({ code }) => {
      store.userManager.applyCoupon(code).then((result: CouponApiResult) => {
        onCouponCodeSuccess(result);
      }, handleValidationErrors);
    });

    return (
      <form className="form" onSubmit={submitHandler}>
        <Wrapper>
          <InputWrapper>
            <input
              {...register('code')}
              id="school-name"
              placeholder={placeholder}
            />
          </InputWrapper>
          <Button
            presentation="teal"
            size={'large'}
            label={buttonLabel}
          ></Button>
        </Wrapper>
        <CatchAllFormErrors errors={errors} />
      </form>
    );
  }
);
