import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRootStore } from 'mst';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { Link } from 'naan/primitives/text';
import __ from 'core/lib/localization';

export const CloseAccountLink = () => {
  const store = useRootStore();

  if (store.userManager?.accountData?.membershipState !== 'trial') {
    return null;
  }
  return (
    <VStack>
      <VSpacer size={4} />
      <HStack justify="end">
        <Link
          as={RouterLink}
          destructive
          to="/close-account"
          className="account__close-account-link"
        >
          {__('Close account', 'account.closeAccount')}
        </Link>
      </HStack>
      <VSpacer size={4} />
    </VStack>
  );
};
