// import { prevent } from 'common/prevent';
import { useRootStore } from 'mst';
import { InteractiveTag, TagsContainer } from 'naan/primitives/tags';
import * as React from 'react';
import { AnimatedTagsContainer } from './animated-tags-container';
import { FilterBox } from './filter-box';

type RootStore = {
  storyManager: {
    topicsList: string[];
  };
};

type Props = {
  show: boolean;
};

export const TopicsFilterPanel = ({ show = false }: Props) => {
  const store = useRootStore() as RootStore;
  const { storyManager } = store ?? {};
  const list = storyManager.topicsList;

  return (
    <AnimatedTagsContainer show={show}>
      <TagsContainer>
        <FilterBox
          filterItems={list}
          filterQueryKey={'topics'}
          renderFilterControl={(item, selected, action) => {
            return (
              <InteractiveTag
                role="button"
                selected={selected}
                onSelect={action}
                type="topic"
              >
                {item}
              </InteractiveTag>
            );
          }}
        />
      </TagsContainer>
    </AnimatedTagsContainer>
  );
};
