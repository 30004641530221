import React from 'react';
import { Text, MediumHeading } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';
import { VSpacer } from 'naan/primitives/spacer';
import { PricingCards } from 'components/account/pricing-cards';
import { CouponButton } from 'components/account/marketing-links/coupon-button';

import { AccountData } from 'core/models/user-manager';
import { FullColumn, MiddleColumn } from '../account-layout';
import { Box } from 'naan/primitives/box';

type Props = {
  accountData: AccountData;
};

export const TrialNotDownloaded = ({ accountData }: Props) => {
  return (
    <>
      <FullColumn>
        <MediumHeading>
          {accountData?.hasAffiliatePricing
            ? accountData.affiliateWelcomeHeading
            : 'Get started'}
        </MediumHeading>
        <VSpacer size="4" />
        <Divider />
        <VSpacer size="5" />
        <Box css={{ '@large': { width: `${(100 / 12) * 8}%` } }}>
          <Text>
            Lupa is available for iOS and Android. Download now and try our two
            free stories. Ready to unlock all Lupa content?{' '}
            {accountData?.hasAffiliatePricing
              ? 'Your discount has already been applied below.'
              : 'Subscribe or purchase below.'}
          </Text>
        </Box>
        <VSpacer size="10" />
        <PricingCards showFreeTrialCard />
        {/* <VSpacer size="10" /> */}
      </FullColumn>
      <MiddleColumn>
        <CouponButton />
        <VSpacer size="20" />
      </MiddleColumn>
    </>
  );
};
