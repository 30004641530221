import React from 'react';
import { Paragraph } from './paragraph';
import { useChapterContext } from './chapter-context';

import styled from 'styled-components';
import {
  isParagraphData,
  ParagraphData,
  PassageData,
} from 'core/models/catalog/bogota-types';
import { ChapterCatalogData } from 'core/models/catalog';

const Wrapper = styled.div`
  padding-bottom: 32px;
`;

export const Passage = ({ root: passage }: { root: PassageData }) => {
  const { elements, address } = passage;
  const chapter = useChapterContext() as ChapterCatalogData;
  const id = chapter.position + '--' + address.replace('.', '-');
  return (
    <Wrapper id={id}>
      {elements
        .filter(el => isParagraphData(el))
        .map((paragraph, i) => {
          const hint = i === 0 ? passage.hint : null;
          return (
            <Paragraph
              paragraph={paragraph as ParagraphData}
              hint={hint}
              key={`passage-${i}`}
            />
          );
        })}
    </Wrapper>
  );
};
