import * as React from 'react';

import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { useSwitch } from 'common/hooks/use-switch';
import { AuthFootnote } from 'components/auth/auth-footnote';
import { Button } from 'naan/primitives/button';
import { MediumGap, LargeGap } from 'components/ds/common/spacers';
import { FormError } from 'naan/primitives/forms/form-error';
import { AuthSmallPrint } from 'components/auth/auth-small-print';
import { useTitle } from 'common/hooks/use-title';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { ConfirmEmailDialog } from 'components/auth/confirm-email-dialog';
import { VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { Checkbox } from 'components/auth/checkbox';
import { styled } from 'naan/stitches.config';
import { AuthHeader } from 'components/auth/auth-header';
import { useComboForm } from 'common/hooks/use-comboform';
import { Label } from 'naan/primitives/forms/label';
import { Input } from 'naan/primitives/input/input';
import { PasswordInput } from 'naan/primitives/input';
import { FNAME_MAX_CHAR_LIMIT } from 'core/lib/constants/vars';

import __ from 'core/lib/localization';
import { useSearchParams } from 'react-router-dom';

const SmallPrint = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  '& a': {
    color: '$teal-500',
  },
});

type FormInput = {
  name: string;
  email: string;
  password: string;
  schoolName: string;
};

export const CreateAccountScreen = observer(() => {
  useTitle(__('Get started', 'auth.getStarted'));
  const [queryParams /*, setSearch*/] = useSearchParams();
  const defaultIsTeacher = queryParams.get('teach') === 'yes';

  useAfterAuthRedirection();

  // clean error messages from other screens
  const { userManager } = AppFactory.root;
  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
    getValues,
  } = useComboForm<FormInput>({});

  const [showingDialog, showingDialogSwitch] = useSwitch(false);
  const [isTeacher, isTeacherSwitch] = useSwitch(defaultIsTeacher);
  const [schoolNameError, setSchoolNameError] = React.useState<string | null>(
    null
  );

  const submitHandler = handleSubmit(({ schoolName }) => {
    if (isTeacher) {
      if (!schoolName) {
        setSchoolNameError(
          __(
            'Enter the name of the school or schools where you teach',
            'auth.schoolNameRequired'
          )
        );
        return;
      }
    }
    setSchoolNameError(null);
    showingDialogSwitch.on();
  });

  const onConfirmEmail = () => {
    const { name, email, password, schoolName } = getValues();

    showingDialogSwitch.off();
    clearValidationErrors();

    userManager
      .signup({ name, email, password, school_name: schoolName })
      .then(() => {}, handleValidationErrors);
  };

  const getEmail = () => getValues()?.email;

  return (
    <VStack>
      <AuthHeader>
        <Text textStyle="large-heading--desktop">
          {__('Get started', 'auth.getStarted')}
        </Text>
      </AuthHeader>
      <form onSubmit={submitHandler}>
        <VStack>
          <Label htmlFor="name">{__('First name', 'auth.firstName')}</Label>
          <VSpacer size={2} />

          <Input
            name={'name'}
            data-test-id={'first-name'}
            type="text"
            {...register('name')}
            required
            maxLength={FNAME_MAX_CHAR_LIMIT}
          />
          <FormError error={errors['name']} data-test-id="name-error-message" />

          <VSpacer size={6} />

          <Label htmlFor="email">{__('Email', 'auth.email')}</Label>
          <VSpacer size={2} />
          <Input
            name={'email'}
            data-test-id={'email-address'}
            type="email"
            {...register('email')}
            required
          />
          <FormError
            error={errors['email']}
            data-test-id="email-error-message"
          />

          <VSpacer size={6} />

          <Label htmlFor="password">{__('Password', 'auth.password')}</Label>
          <VSpacer size={2} />
          <PasswordInput
            name={'password'}
            data-test-id={'password'}
            {...register('password')}
            placeholder={''}
            required
          />
          <FormError
            error={errors['password']}
            data-test-id="password-error-message"
          />
          <VSpacer size={6} />
        </VStack>
        <VSpacer size={2} />
        <Checkbox
          checked={isTeacher}
          onChange={isTeacherSwitch.toggle}
          label={__('I want to teach with Lupa', 'auth.teachWithLupa')}
        />
        {isTeacher ? (
          <>
            <VSpacer size={4} />
            <SmallPrint>
              Enter your school name(s) to activate Lupa Classroom features.{' '}
              <a
                href=" https://www.lupa.app/schools/classrooms"
                target="_blank"
              >
                Learn more
              </a>
            </SmallPrint>
            <VSpacer size={4} />
            <Label htmlFor="schoolName">
              {__('School name(s)', 'auth.schoolName')}
            </Label>
            <VSpacer size={2} />
            <Input
              name={'schoolName'}
              data-test-id={'schoolName'}
              {...register('schoolName')}
            />
            <FormError
              error={schoolNameError ? { message: schoolNameError } : undefined}
            />
          </>
        ) : null}

        <VSpacer size={8} />

        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Create account', 'auth.createAccount')}
            onClick={handleSubmit as any}
            presentation="teal"
            size="large"
            data-test-id="create-account-btn"
            css={{ flex: 1 }}
          />
        </div>
      </form>
      <MediumGap />
      <AuthFootnote
        heading={__('Have a code?', 'auth.haveACode')}
        body={__(
          'Create your free account above, or sign in to redeem.',
          'auth.createAccountToRedeem'
        )}
      />
      <LargeGap />
      <AuthSmallPrint />
      {showingDialog ? (
        <ConfirmEmailDialog
          email={getEmail()}
          onOk={onConfirmEmail}
          onCancel={showingDialogSwitch.off}
        />
      ) : null}
    </VStack>
  );
});
