import React from 'react';
import {
  ContentGridInnerContainer,
  ContentGrid,
} from 'components/global-layout';
import { Button } from 'naan/primitives/button';
import { PageHeading } from 'components/page-heading';
import { styled } from 'naan/stitches.config';
import { HStack, VStack } from 'naan/primitives/stack';

import {
  version,
  versionCheck,
  hash,
  curEnv,
  commit,
  commitTimestampIso,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';

import { VSpacer } from 'naan/primitives/spacer';
import { Link, NavLink } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { devMode } from 'app/platform';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import { showUpdateToast } from 'lib/update-checker';

const Wrapper = styled('section', {
  gridColumn: '1/-1',
  '@large': {
    gridColumn: '1/-1',
  },
});

const DefWrapper = styled('dl', {
  borderBottom: '1px solid $gray-100',
  paddingBottom: '$2',
  marginBottom: '$2',
  dt: {
    textStyle: 'small-text',
    color: '$textSecondary',
    marginBottom: '$space$1',
  },
  dd: {},
});

const ConfigArea = styled('textarea', {
  fontFamily: 'monospace',
  background: '$gray-50',
  padding: '$space$2',
  width: '100%',
  borderRadius: '$space$2',
});

const Definition = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) => (
  <DefWrapper>
    <dt>{label}</dt>
    <dd>{value}</dd>
  </DefWrapper>
);

const WebModeToggle = observer(() => {
  const { userManager } = AppFactory.root;
  const toggle = () => userManager.userData.toggleWebMode();
  return (
    <>
      <HStack gap={'small'}>
        Lupa web mode{/* (and native wrapper)*/}:{' '}
        {String(userManager.webModeEnabled)}
        &nbsp;
        <ActionLink onPress={toggle}>[toggle]</ActionLink>
      </HStack>
    </>
  );
});

const ClassroomToggle = observer(() => {
  const { userManager } = AppFactory.root;
  const toggle = () => userManager.toggleClassroomActivation();
  return (
    <>
      <HStack gap={'small'}>
        Teacher mode: {String(userManager.classroomEnabled)}&nbsp;
        <ActionLink onPress={toggle}>[toggle]</ActionLink>
      </HStack>
    </>
  );
});

export const DevToolsScreen = () => {
  const {
    userManager: { accountData },
  } = AppFactory.root;
  const [renderError, setRendererError] = React.useState(false);
  const handleClick = () => {
    setRendererError(true);
  };
  const buildTimestampLocal = new Date(buildTimestampIso).toString();
  const commitTimestampLocal = new Date(commitTimestampIso).toString();

  if (!accountData.showDevTools) {
    return null;
  }

  return (
    <ContentGridInnerContainer>
      <ContentGrid>
        <Wrapper>
          <PageHeading title={'Dev tools'} />

          <HStack gap={'large'}>
            <Button onClick={handleClick} label={'Render Error'}></Button>
            {renderError && (window as any).nonexistent()}
            <Button
              onClick={() => {
                throw new Error('This is an unhandled error');
              }}
              label={'Throw Error'}
            />
            <Button onClick={showUpdateToast} label={'View update toast'} />
          </HStack>

          <VStack css={{ my: '$10' }}>
            <Link to="/showcase">Go to Components Showcase &rarr;</Link>
          </VStack>

          <VStack>
            <WebModeToggle />
            <ClassroomToggle />
            <NavLink to="/">/</NavLink>
            <NavLink to="/app">/app</NavLink>
            <NavLink to="/app/learn">/app/learn</NavLink>
            <NavLink to="/app/teach">/app/teach</NavLink>
          </VStack>

          <VStack>
            <VSpacer size={10} />
            <Definition label={'package.json version'} value={version} />
            <Definition label={'version.txt'} value={versionCheck} />
            <Definition
              label={'last commit'}
              value={
                <ul>
                  <li>{hash}</li>
                  <li>{commit}</li>
                  <li>{commitTimestampIso}</li>
                  <li>{commitTimestampLocal}</li>
                </ul>
              }
            />
            <Definition
              label={'build timestamp'}
              value={
                <ul>
                  <li>{buildTimestampIso}</li>
                  <li>{buildTimestampLocal}</li>
                </ul>
              }
            />

            <Definition label={'Environment'} value={curEnv} />
            <Definition
              label={'Catalog (V3)'}
              value={
                <>
                  <a
                    href={accountData?.catalogUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {accountData?.catalogV3Slug} &rarr;
                  </a>
                </>
              }
            />
          </VStack>

          <Definition label={'devMode'} value={String(devMode)} />
          <Definition
            label={'meta.env'}
            value={
              <ConfigArea
                cols={30}
                rows={10}
                value={JSON.stringify(import.meta.env, null, 2)}
                readOnly
              />
            }
          />
          {/* // todo: sanitize sensitive keys so we can always show this */}
          {/* // for now, just access via js console: window.jw_config */}
          {/* <Definition
            label={'Current config'}
            value={
              <ConfigArea cols={30} rows={10}>
                {JSON.stringify(config, null, 2)}
              </ConfigArea>
            }
          /> */}
        </Wrapper>
      </ContentGrid>
    </ContentGridInnerContainer>
  );
};
