import React from 'react';
import { styled } from 'naan/stitches.config';
import { CloseSmallIcon } from 'naan/icons/close-icon';
import { IconButton } from 'naan/primitives/button';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { Container } from 'naan/primitives/modals';

const DialogTitle = styled('div', {
  textStyle: 'body-bold',
  borderBottom: '1px solid $colors$gray-100',
  pb: '16px',
});

const CloseButtonContainer = styled('div', {
  all: 'unset',
  position: 'absolute',
  top: 8,
  right: 8,
});

type DialogProps = {
  open: boolean;
  title?: React.ReactNode;
  body: React.ReactNode;
  onDismiss?: () => void;
  autoDismissOnOk?: boolean;
  hideCloseButton?: boolean;
};

export const Window = ({
  open = true,
  title,
  body = '',
  onDismiss = () => {},
  hideCloseButton = false,
}: DialogProps) => {
  return (
    <Container open={open} onDismiss={onDismiss}>
      {hideCloseButton ? null : (
        <DialogPrimitive.Close asChild>
          <CloseButtonContainer>
            <IconButton
              icon={<CloseSmallIcon />}
              size={'small'}
              onClick={onDismiss}
            />
          </CloseButtonContainer>
        </DialogPrimitive.Close>
      )}

      {title ? (
        <DialogPrimitive.Title asChild>
          <DialogTitle>{title}</DialogTitle>
        </DialogPrimitive.Title>
      ) : null}

      {body}
    </Container>
  );
};
