import React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { useTitle } from 'common/hooks/use-title';
import __ from 'core/lib/localization';

export const ProgressScreen = observer(() => {
  const { userData } = AppFactory.root.userManager;
  useTitle(__('My Progress', 'progress.my-progress'));
  const { graphData } = userData.meView;

  return (
    <>
      <pre>{JSON.stringify(graphData, null, 2)}</pre>
    </>
  );
});
