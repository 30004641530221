import dayjs from 'dayjs';
import { sum } from 'lodash';

import { createLogger } from 'app/logger';

import __ from 'core/lib/localization';
import { INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA } from 'core/lib/constants/vars';
import { AssistSettings } from './assist-settings';
import { ListeningLog } from './listening-log';
import { ListeningStats, msToPoints } from './listening-stats';
import { UserSettings } from './user-settings';
import { AttemptedPurchase } from './attempted-purchase';
import {
  ModelTreeNode,
  snap,
  TSTStringMap,
  volatile,
} from 'ts-state-tree/tst-core';
import { FeaturedRelease } from '../catalog/featured-release';
import { Root } from '../root';
import { StoryProgress } from './story-progress';
import { getBaseRoot } from '../base-root';
import { ClassroomUserData } from './classroom-user-data';

const log = createLogger('um:user-data');

const getStatsFromLogs = (logs: ListeningLog[]) => {
  log.debug('getStatsFromLogs', logs);
  return ListeningStats.create(
    logs.reduce(
      (acc, curr) => {
        return {
          // todo: clean up naming dissidence between logs and stats
          millisListened: acc.millisListened + curr.listenedMillis,
          millisRelistened: acc.millisRelistened + curr.relistenedMillis,
        };
      },
      {
        millisListened: 0,
        millisRelistened: 0,
      }
    )
  );
};

/**
 * UserData
 *
 * conceptual owner of the client-side mutable user state which needs to get synced to/from the server
 * note the 'storyProgress's now live under the storyManager trunk, but get spliced during the
 * sync process.
 */
export class UserData extends ModelTreeNode {
  static CLASS_NAME = 'UserData' as const;

  static create(snapshot: any) {
    return super.create(UserData, snapshot) as UserData;
  }

  listeningLogs: ListeningLog[] = [];
  storyProgresses: StoryProgress[] = [];

  // dummy reference to include in schema gen.
  // todo: figure out if this should even be a tst model
  @volatile
  listeningStats: ListeningStats = null;

  // map of volume slugs to unit numbers
  currentUnits: TSTStringMap<number>;

  assistSettings: AssistSettings = snap({});

  userSettings: UserSettings = snap({});

  /*0 and -1 magic values will be assigned to this variable as follows,
     0: user accept the request to review
    -1: user decline the  request to review
    see the spec for more details :
    https://jiveworld.slite.com/app/channels/ptvqrYJErL/notes/ODyxK9uJfe
    */
  numCompletedForReviewCta: number = INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA;

  lastAttemptedPurchase: AttemptedPurchase = null;
  lastAttemptedPendingPurchase: AttemptedPurchase = null;
  lastViewedFeaturedReleaseReleaseDate: string = null;

  // favoritedUnitSlugs: string[] = [];
  classroom: ClassroomUserData = snap({});

  // when true, then represent multi-part volumes as a single 'story' in UI
  // and enable lupa web flavored navigation.
  // also drives experimental webview wrapper mode within mobile app
  webModeEnabled: boolean = false;

  lastSyncedVersion: number = 0;

  get root(): Root {
    return getBaseRoot(this);
  }

  storyProgress(slug: string): StoryProgress {
    return this.storyProgresses.find(progress => {
      return progress.slug === slug;
    });
  }

  ensureStoryProgress(slug: string): StoryProgress {
    let progress = this.storyProgress(slug);
    if (!progress) {
      progress = StoryProgress.create({ slug });
      // log.info(`creating StoryProgress(${slug}): ${progress.stringify}`);
      this.storyProgresses.push(progress);
    }
    return progress;
  }

  addListeningLog(
    slug: string,
    listenedMillis: number,
    relistenedMillis: number
  ) {
    const currentISODate = dayjs().startOf('day').toISOString();
    const log = this.listeningLogs.filter(
      _log => _log.date === currentISODate && _log.storySlug === slug
    );
    if (log.length) {
      log[0].listenedMillis += listenedMillis;
      log[0].relistenedMillis += relistenedMillis;
    } else {
      this.listeningLogs.push(
        ListeningLog.create({
          date: currentISODate,
          storySlug: slug,
          listenedMillis,
          relistenedMillis,
        })
      );
    }
  }

  resetListeningLogs() {
    this.listeningLogs = [];
  }

  setVolumeCurrentUnitNumber(slug: string, number: number) {
    log.debug(`setVolumeCurrentUnitNumber(${slug}, ${number})`);
    return this.currentUnits.set(slug, number);
  }

  postponedReviewCta() {
    this.numCompletedForReviewCta *= 2;
  }

  acceptedReviewCta() {
    this.numCompletedForReviewCta = 0;
  }

  declinedReviewCta() {
    this.numCompletedForReviewCta = -1;
  }

  setLastAttemptedPurchase({
    purchaseType,
    contentSlug,
  }: {
    purchaseType: string;
    contentSlug: string;
  }) {
    this.lastAttemptedPurchase = AttemptedPurchase.create({
      purchaseType,
      contentSlug,
    });
  }

  clearLastAttemptedPurchase() {
    this.lastAttemptedPurchase = null;
  }

  setLastAttemptedPendingPurchase({
    purchaseType,
    contentSlug,
  }: {
    purchaseType: string;
    contentSlug: string;
  }) {
    this.lastAttemptedPendingPurchase = AttemptedPurchase.create({
      purchaseType,
      contentSlug,
    });
  }

  clearLastAttemptedPendingPurchase() {
    this.lastAttemptedPendingPurchase = null;
  }

  setLastViewedFeaturedRelease(featuredRelease: FeaturedRelease) {
    if (
      featuredRelease &&
      featuredRelease.releaseDate !== this.lastViewedFeaturedReleaseReleaseDate
    ) {
      this.lastViewedFeaturedReleaseReleaseDate = featuredRelease.releaseDate;
    }
  }

  clearLastViewedFeaturedRelease() {
    this.lastViewedFeaturedReleaseReleaseDate = null;
  }

  get totalMillis() {
    return sum(
      this.listeningLogs.map(
        ({ listenedMillis, relistenedMillis }) =>
          listenedMillis + relistenedMillis
      )
    );
  }

  get totalPoints() {
    return msToPoints(this.totalMillis);
  }

  get showReviewCta(): boolean {
    const { storyManager } = this.root;
    if (!storyManager) return false;
    const numCompletedStories = storyManager.completed.length;
    return (
      this.numCompletedForReviewCta > 0 &&
      numCompletedStories >= this.numCompletedForReviewCta
    );
  }

  get showReviewCtaMenuItem(): boolean {
    const { userManager, storyManager } = this.root;
    if (!userManager || !storyManager) return false;
    const numCompletedStories = storyManager.completed.length;
    const hasFullAccess = userManager.accountData.fullAccess;
    return (
      hasFullAccess &&
      numCompletedStories >= INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA
    );
  }

  get featuredReleaseViewed(): boolean {
    const { storyManager } = this.root;
    if (!storyManager) return false;
    const currentFeaturedRelease = storyManager.latestFeaturedRelease;
    const hasViewed =
      currentFeaturedRelease &&
      currentFeaturedRelease.releaseDate ===
        this.lastViewedFeaturedReleaseReleaseDate;
    return hasViewed;
  }

  volumeCurrentUnitNumber(slug: string) {
    return this.currentUnits.get(slug) || 1;
  }

  storyListeningStats(slug: string) {
    return getStatsFromLogs(
      this.listeningLogs.filter(log => log.storySlug === slug)
    );
  }

  volumeListeningStats(slug: string) {
    return getStatsFromLogs(
      this.listeningLogs.filter(log => log.storySlug.includes(slug))
    );
  }

  get meView() {
    const today = dayjs().startOf('day');

    const filterLogsByDate = (logs: ListeningLog[], date: dayjs.Dayjs) => {
      return logs.filter(log => dayjs(log.date) >= date);
    };

    const sevenDaysAgo = dayjs(today).subtract(7, 'days');
    const fifteenDaysAgo = dayjs(today).subtract(15, 'days');

    const data15Days = filterLogsByDate(this.listeningLogs, fifteenDaysAgo);
    const data7Days = filterLogsByDate(data15Days, sevenDaysAgo);

    const getPointsByDay = (
      logs: ListeningLog[],
      startDate: dayjs.Dayjs,
      endDate: dayjs.Dayjs
    ) => {
      let currentDate = dayjs(startDate);
      const dates = [];
      while (currentDate <= endDate) {
        dates.push(dayjs(currentDate));
        currentDate = currentDate.add(1, 'days');
      }

      const dayInitials = __('SMTWTFS', 'global.dayInitials');
      const daysAbbreviations = dayInitials.split('');
      return dates.map(date => ({
        letter: daysAbbreviations[date.day()],
        points: logs.reduce(
          (acc, log) =>
            dayjs(log.date).isSame(date, 'day')
              ? acc +
                msToPoints(log.listenedMillis) +
                msToPoints(log.relistenedMillis)
              : acc,
          0
        ),
      }));
    };

    const graphData = getPointsByDay(data15Days, fifteenDaysAgo, today);
    const highestDayPoints = Math.max(...graphData.map(p => p.points));

    return {
      graphData,
      highestDayPoints,
      lastSevenStats: getStatsFromLogs(data7Days),
      allTimeStats: getStatsFromLogs(this.listeningLogs),
    };
  }

  toggleWebMode() {
    this.webModeEnabled = !this.webModeEnabled;
    this.root.storyManager.resolveStories(); // need to refresh after mode switch
    this.root.userManager.persistUserData(); // async
  }
}
