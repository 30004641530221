/**
 * DELETEME: This component is legacy,
 * it dependes on the old NotificationService and we should
 * remove it when safe.
 */
import * as React from 'react';
import { TNotificationService } from 'lib/services/notification-service';
import { Toast } from '../../naan/toasts';
import { observer } from 'mobx-react';
import { useTimedNotification, ToastTarget } from 'naan/toasts';

export const LegacySpaToastTarget = observer(
  ({ service }: { service: TNotificationService }) => {
    const notification = service.current.get();

    useTimedNotification({
      hasNotification: !!notification,
      timeout: notification?.timeout ?? -1,
      onClear: service.clear,
    });

    return (
      <ToastTarget showNotification={!!notification}>
        {notification && (
          <Toast
            type={notification.type}
            label={notification.message}
            onDismiss={service.clear}
            action={notification.action}
          />
        )}
      </ToastTarget>
    );
  }
);
