/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSwitch } from 'common/hooks/use-switch';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';
import { IconTriangleAlert } from 'components/ds/icons';
import { Tooltip } from 'components/ds/tooltip';
import __ from 'core/lib/localization';
import styled from 'styled-components';

const Wrapper = styled.div`
  ${includeTextStyles('small-text')};
  color: ${getColor('textSecondary')};
  display: flex;
  margin-left: 8px;

  &.with-tip {
    cursor: pointer;
  }

  & > span {
    color: ${getColor('red500')};
    margin-left: 4px;
  }
`;

export const LicenseAccessWarningTip: React.FC = ({ children }) => {
  const [showTip, { toggle }] = useSwitch(false);
  return (
    <Wrapper className="with-tip" onClick={toggle}>
      {children}
      <Tooltip
        tipContent={
          __(
            'Some assigned stories in this class require full access',
            'classroom.someAssignedStoriesRequireFullAccess'
          ) as any
        }
        showTip={showTip}
      >
        <span className="icon">
          <IconTriangleAlert />
        </span>
      </Tooltip>
    </Wrapper>
  );
};
