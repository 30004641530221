import React from 'react';
import { useRootStore } from 'mst';
import { observer } from 'mobx-react';
import { NotFoundScreen } from './not-found-screen';
import { FullScreenLoader } from 'components/ds/modals/full-screen-loader';
import { StoryGuide } from 'components/guides/story-guide';
import { useStoryData } from 'components/guides/use-story-data';
import { fail } from 'core/lib/errors';
// import { usePageTracker } from 'lib/hooks/use-page-tracker';

export const StoryGuideScreen = observer(() => {
  const store = useRootStore();
  const { data, loading, error } = useStoryData();

  // usePageTracker();

  if (loading) {
    return <FullScreenLoader />;
  }

  if (error) {
    fail(error);
  }

  if (!data) {
    /// we are already handling errors up there 👆,
    /// so there's no need to do it here
    return <NotFoundScreen reportError={false} />;
  }

  const canAccess = data.trial
    ? true
    : store.userManager?.accountData?.canAccessAllStoryScripts;

  if (!canAccess) {
    return <NotFoundScreen />;
  }

  return <StoryGuide story={data} />;
});
