import { ModelTreeNode } from 'ts-state-tree/tst-core';

/**
 * ListeningLog
 *
 * holds study stats for a given day and story
 */
export class ListeningLog extends ModelTreeNode {
  static CLASS_NAME = 'ListeningLog' as const;

  static create(snapshot: any) {
    return super.create(ListeningLog, snapshot) as ListeningLog;
  }

  date: string = '';
  storySlug: string = '';
  listenedMillis: number = 0;
  relistenedMillis: number = 0;
}
