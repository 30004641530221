import * as React from 'react';
import { SwitchButton } from 'components/ds/switch-button';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  /* width: 100%; */
`;

const ManagedSwitchButton = props => {
  const [active, setActive] = React.useState(false);

  const toggle = React.useCallback(() => {
    setActive(!active);
  }, [active]);

  return <SwitchButton active={active} onChange={toggle} {...props} />;
};

export const pathname = '/switch-inputs';
export const navlabel = 'Switch Inputs';

export const Screen = () => {
  return (
    <Wrapper>
      <ManagedSwitchButton />
      <ManagedSwitchButton presentation="yellow" />
      <ManagedSwitchButton disabled />
    </Wrapper>
  );
};
