import * as React from 'react';
import { EnsureAuth } from 'components/ensure-auth';
import { Outlet } from 'react-router-dom';
import { OldGlobalLayout } from 'components/layouts/old-global-layout';
import { FullScreenLoader } from 'components/ds/modals';
import { StripeProvider } from 'common/stripe-context';
import { DialogTarget } from 'components/ui/dialog-target';
import { LoadingScreen } from 'components/ui/loading-screen';
import { NewsletterDialog } from 'components/account/newsletter-dialog';

import { usePageTracker } from 'lib/hooks/use-page-tracker';
import { useQueryMessageDispatcher } from 'components/account/use-query-message-dispatcher';

export const SignedInRouteLayout = ({
  /// this allows us to have the signed layout, but different design layout
  component: Component = OldGlobalLayout,
}) => {
  usePageTracker();
  useQueryMessageDispatcher();

  return (
    <>
      <StripeProvider>
        <EnsureAuth>
          <Component>
            <React.Suspense fallback={<FullScreenLoader />}>
              <Outlet />
            </React.Suspense>
          </Component>
        </EnsureAuth>
        <DialogTarget />
        <LoadingScreen />
      </StripeProvider>
      <NewsletterDialog />
    </>
  );
};
