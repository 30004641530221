// DELETEME: this file is obsolete and should be deleted after 6.2
import * as React from 'react';
import { MyLupaLogo, LupaClassroomLogo } from 'components/ui/icons/logo';
import { HStack, VStack } from 'naan/primitives/stack';
import { styled } from 'naan/stitches.config';
import { NavItemAction } from 'naan/primitives/nav';
import { prevent } from 'common/prevent';
import { SignOutIcon } from 'naan/icons/sign-out-icon';
import { VisitorLinks } from './visitor-links';
import { TeacherLinks } from './teacher-links';

import __ from 'core/lib/localization';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';

const NavContainer = styled('div', {
  backgroundColor: '$white',
  borderRight: '1px solid $colors$gray-100',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

const LogoContainer = styled('div', {
  background: 'white',
  py: '$space$6',
  textStyle: 'body',
  color: '$colors$text-primary',
  position: 'sticky',
  top: 0,
});

const LogoutContainer = styled('div', {
  // padding: '$space$6',
  padding: '$space$3 $space$6',
  marginTop: 'auto',
  position: 'sticky',
  bottom: 0,
  background: 'white',
  borderTop: '1px solid $colors$gray-100',
  '@medium': {
    padding: '$space$6',
    border: 'none',
  },
});

export const GlobalSidebar = observer(() => {
  const { userManager } = AppFactory.root;
  const { classroomEnabled } = userManager;

  return (
    <NavContainer>
      <VStack css={{ px: '$space$6' }}>
        <LogoContainer>
          <HStack align="center">
            {classroomEnabled ? <LupaClassroomLogo /> : <MyLupaLogo />}
          </HStack>
        </LogoContainer>
        {classroomEnabled ? <TeacherLinks /> : <VisitorLinks />}
      </VStack>
      <LogoutContainer>
        <NavItemAction
          icon={<SignOutIcon />}
          label={__('Sign out {{username}}', 'account.signoutUsername', {
            username: userManager.accountData.name,
          })}
          size="small"
          onClick={prevent(userManager.logout) as any}
          testId="sign-out-button"
        />
      </LogoutContainer>
    </NavContainer>
  );
});
