import { AppFactory } from 'app/app-factory';

export const track = (eventName: string, eventProperties: any = {}) => {
  AppFactory.analyticsManager.trackEvent(eventName, eventProperties);
};

export const trackPage = (pageName: string, pageData?: any) => {
  AppFactory.analyticsManager.trackPage(pageName, pageData);
};

export const setContextData = (data: any) => {
  AppFactory.analyticsManager.setContextData(data);
};
