import React, { useContext, useState } from 'react';

import { DisplayStatus } from 'components/debug/display-status';
import { LoadingRoot } from './loading-root';
import { NetworkErrorScreen } from 'routes/network-error-screen';
import { observer } from 'mobx-react';

import { useMinibusSubscription } from 'common/hooks/use-minibus';
import { AppFactory } from 'app/app-factory';
import { AppRoot } from 'app/app-root';

export const root = AppFactory.root;

(window as any).root = root;

const Context = React.createContext<AppRoot>(root);
export const { Provider } = Context;

export const RootStoreProvider: React.FC = observer(({ children }) => {
  const [networkFailed, setNetworkFail] = useState(false);

  useMinibusSubscription(
    'NETWORK_ERROR:REFRESH_ACCOUNT_DATA', // Maybe define this in a constant, somewhere
    () => {
      setNetworkFail(true);
    },
    'mst'
  );

  if (networkFailed) {
    return <NetworkErrorScreen />;
  }

  if (root.status === 'initializing') {
    return <LoadingRoot root={root} />;
  }

  return (
    <>
      <DisplayStatus status={root.status} />
      <Provider value={root}>{children}</Provider>
    </>
  );
});

// @armando. is there any value in still using a context provider for access
// to the root store, or can we just simplify to always use the singleton now?
export const useRootStore = () => useContext(Context);
