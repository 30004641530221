// DEPRECATED: This component is no longer used.
import React from 'react';
import { CopyCode } from 'components/ui/code';
import { Button } from 'components/ds/buttons/';
import { EmptyCard } from './empty-card';

import __ from 'core/lib/localization';
import styled from 'styled-components';
import { Classroom } from 'core/models/user-manager';

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .instructions {
    margin-top: 16px;
  }

  @media (min-width: 720px) {
    flex-direction: row;

    .instructions {
      margin-top: 0;
      margin-left: 16px;
    }
  }
`;

const ZeroStateControl = ({
  classroom,
  onInvite,
}: {
  classroom: Classroom;
  onInvite: () => void;
}) => {
  return (
    <ControlWrapper>
      <CopyCode code={classroom.code} />
      <Button
        presentation={'primary-outline' as any}
        type={'square' as any}
        size="small"
        label={__(
          'Instructions for students',
          'classroom.instructionsForStudents'
        )}
        onClick={onInvite}
        data-test-id="student-instructions"
        className="instructions"
      />
    </ControlWrapper>
  );
};

export const StudentsZeroState = ({
  classroom,
  onInvite,
}: {
  classroom: Classroom;
  onInvite: () => void;
}) => {
  return (
    <EmptyCard
      control={<ZeroStateControl classroom={classroom} onInvite={onInvite} />}
    >
      <div className="title">
        {__(
          'Share your class code with students',
          'classroom.shareYourClassCode'
        )}
      </div>
      <div className="small-text">
        {__(
          'They will appear here after they enter it in the Lupa app',
          'classroom.noStudents',
          {
            appName: 'Lupa',
          }
        )}
      </div>
    </EmptyCard>
  );
};
