// import { StringToString } from '../../basic-types';
// import { CreditsData } from '../catalog-types';

// export interface BogotaStoryData {
//   slug: string;
//   version: number;
//   dataUrl: string;
//   l1Code: string;
//   l2Code: string;
//   releaseDate: string; //iso date
//   originalBroadcastDate: string;
//   publishedOn: string;
//   recomended: boolean;
//   trial: boolean;
//   activityGuideUrl?: string;
//   activityGuideData?: ActivityGuideV3Data;
//   title: string;
//   titleL1: string;
//   titleL2: string;
//   tagline: string;
//   taglineL1: string;
//   taglineL2: string;
//   description: string;
//   descriptionL1: string;
//   descriptionL2: string;
//   bannerImageUrl: string;
//   listImageUrl: string;
//   topics: string[];
//   countries: string[];
//   apTags?: string[];
//   ibTags?: string[];
//   weblink: string;
//   downloadSize: number;
//   durationMinutes: number;
//   durationMillis: number;
//   volumeDurationMinutes: number;
//   chapters: ChapterData[];
//   vocabs: { [index: string]: VocabLookup };
//   lessonPlanUrl?: string; // obsolete

//   // v3 specific data
//   baseSlug?: string;
//   baseTitle?: string;
//   partSuffix?: string;
//   partSuffixList?: string[];
//   cast?: CastV3Data[];
//   credits?: CreditsData;

//   // v4 specific data - todo: break out typed variants
//   volumeSlug?: string;
//   unitNumber?: number;
//   creditsV4?: CreditV4Data[];
//   speakerLabels?: SpeakerV4Data[];

//   ingestedAt?: string; // ios date time
// }

// export interface CastV3Data {
//   fullName: string;
//   shortName?: string;
//   accent?: string;
//   description: string;
// }

export interface CreditsV3Data {
  producedBy: string;
  producedIn: string;
  editedBy: string;
  soundDesignBy: string;
  artworkBy: string;
}

export interface Credit {
  roleKey: string;
  role: string;
  name: string;
}

export interface NoteData {
  body: string;
  head?: string; // unused
}

// export interface CreditV4Data {
//   name: string;
//   labelEn: string;
//   labelJa: string;
//   labelSlug: string;
// }

// export interface SpeakerV4Data {
//   label: string;
//   bioEn: string;
//   bioJa: string;
//   labelJa: string;
// }

// export interface SpeakerV5Data {
//   id: string; // needed for DataCard interface
//   label: string;
//   bio: string;
//   accent: string;
//   translations: StringToString; // [property]:[locale] -> text
// }

// export interface ChapterData {
//   position: number;
//   title: string;
//   titleEnglish: string; // l2
//   durationMillis: number;
//   markCompleteMillis: number;
//   normalAudioUrl: string;
//   notes: NoteData[];
//   playerDataUrl: string; // jwnext player data
//   elements?: ChapterDataSubElement[]; // lopped off for top level catalog
//   audioStartAbsolute?: number; // track relative start time millis. not needed by client, but convenient when building rest of the player data
//   noMusicAudioUrl?: string; // not used
//   recap?: string; // not used
// }

export type ChapterDataSubElement =
  | BeginningOfChapter
  | PassageData
  | EndOfChapter; // gaps?

interface BaseElement {
  objectClass?: string; // missing from word, not used by bogota clients
  entityType: string;
  address: Address;
}

export type Address = string;

export interface BeginningOfChapter extends BaseElement {
  // objectClass: GenericElement
  // entityType = beginning_of_chapter
}

export interface EndOfChapter extends DurationElement {
  // objectClass: GenericElement
  // entityType = end_of_chapter
  markCompleteMillis: number;
}

export interface DurationElement extends BaseElement {
  audioStart: number; // int
  duration: number; // int
  debugAudioFinish?: number;
}

export interface PassageData extends DurationElement {
  hint: string;
  jumpToAddress: Address;
  elements: PassageDataSubElement[];
  debugId?: string;
}

export type PassageDataSubElement = ParagraphData | GapData;

export function isParagraphData(
  el: ChapterDataSubElement
): el is ParagraphData {
  return el.entityType === 'paragraph';
}

export interface ParagraphData extends BaseElement {
  // no duration. DurationElement {
  speaker: string;
  isSpeakerLabelRequired: boolean;
  audioStart: number; // int
  elements: ParagraphDataSubElement[];
  debugAudioFinish?: number;
  debugLabel?: string;
  debugId?: number;
}

export type ParagraphDataSubElement = SentenceData | GapData;

export interface SentenceData extends BaseElement {
  // no duration. DurationElement {
  // objectClass = 'Sentence';
  // entityType = 'sentence';
  translation: string;
  audioStart: number; // int
  elements: SentenceDataSubElement[];
  debugAudioFinish?: number;
  debugLabel?: string;
  debugId?: number;
  debugMarkdown?: string;
}

export function isSentenceData(
  el: ParagraphDataSubElement
): el is SentenceData {
  return el.entityType === 'sentence';
}

export interface GapData extends DurationElement {}

export interface MusicOrSilenceData extends DurationElement {}

export type SentenceDataSubElement = WordGroupData;

export interface WordGroupData extends BaseElement {
  // objectClass = 'WordGroup';
  // entityType = 'word_group';
  id?: string; // omitted for 'normal' word groups
  groupType: string; // tricky, vocab, normal
  elements: WordGroupDataSubElement[];
  text?: string; // omitted for 'normal' word groups
  usage?: string; // omitted for 'normal' world groups (not sure if even used)
  head?: string; // vocab only
  note?: string;
  chapterPosition: number;
}

export interface VocabLookup {
  id: string;
  head?: string;
  note?: string;
  text: string;
  address: string;
  chapterPosition: number;
  chapterTitle: string;
  storyId: string; // story slug
}

export type WordGroupDataSubElement = WordData; // | GapData; // todo confirm if GapData relevant here

export interface WordData extends DurationElement {
  // entityType: 'word';
  text: string;
  wordGroupId?: string; // omitted for 'normal' word groups
  navigationStop: boolean;
}

export interface LupaInfoFormData {
  unitTitle: string; // l2
  unitTitleL1: string; // l1
  unitDescription: string; // l1
  unitTagline: string; // l1
  originalBroadcastDate: string;
  weblink: string;
}

// lupa mode //*! METADATA */ block
// ? rename to 'v3'
export interface BogotaLupaMetadata {
  title: string;
  partSuffix: string;
  jwReleaseDate: string; // 2019-09-26
  originalBroadcastDate: string; // 2019-09-26
  originalDuration: string; // 00:10:21

  tagline: string;
  description: string;

  // credits
  producedBy: string;
  producedIn: string;
  editedBy: string;
  soundDesignBy: string;
  artworkBy: string;

  topics: string; //: Uplifting, Family - todo: parse into list
  countries: string; //: Colombia, Peru
  slug: string;
  englishTitle: string;
  weblink: string;
  lessonPlanUrl?: string; //: https://go.lupa.app/lupa-lesson-el-show
  contentSource?: string; //: test
  defaultLanguageCode?: string; //: es-US
  audioLanguageCode?: string; //: es-US

  // speakers: SpeakerV5Data[]; // parsed 'SPEAKERS' metadata block - new lupa/bolero content
  // cast: CastV3Data[]; // parsed 'CAST' metadata block - legacy lupa content
}

// lupa mode //*! ASSET-LINKS */ block
export interface BogotaLupaAssetLinks extends BogotaAssetLinks {
  // audioFinalUrl: string;
  // audioTranscribeUrl: string;
  // imageThumbUrl: string; // renamed on import
  // imageBannerUrl: string; // renamed on import

  bannerImage?: string;
  thumbnailImage?: string;
  audio?: string; // legacy
  audioNoMusic?: string; // legacy
  timestamps?: string;
  translationScript?: string;
}

export interface BogotaAssetLinks {
  audioFinalUrl: string;
  audioNoMusicUrl?: string;
  audioTranscribeUrl: string;
  imageThumbUrl?: string;
  imageBannerUrl?: string;
  // metadataUrl?: string; // not quite an asset link, but handy to stuff here during import
}

export interface BoleroUnitMetadata extends BogotaAssetLinks {
  slug: string;
  volumeSlug: string;
  unitNumber: number;
  audioLanguageCode: string;
  l2Code: string;
  l1Code: string;
  workflowTitle: string;
  unitTitleEn: string;
  unitTitleJa: string;
  unitDescriptionEn: string;
  unitDescriptionJa: string;
  // speakerLabels: SpeakerV4Data[]; // parsed 'UNIT VOICES' metadata block
}

// export interface BoleroVolumeMetadata {
//   slug: string;
//   showSlug: string;
//   unitCount: number;
//   titleEn: string;
//   titleJa: string;
//   taglineEn: string;
//   taglineJa: string;
//   descriptionEn: string;
//   descriptionJa: string;
//   // TOPIC SLUGS: food, science
//   // COUNTRY SLUGS: usa
//   // WEBLINK: https://wondery.com/shows/business-wars/
//   originalBroadcastDate: string;
//   seasonNumber: string;
//   imageThumbUrl: string;
//   // speakerLabels: SpeakerV4Data[]; // parsed 'VOLUME VOICES' metadata block
//   credits: StringToString; // slug to value - parsed 'VOLUME CREDITS' metadata block
//   // creditsV4: CreditData[]; // parsed 'VOLUME CREDITS' metadata block
// }

// export interface BoleroShowMetadata {
//   slug: string;
//   channelSlug: string;
//   titleEn: string;
//   titleJa: string;
//   taglineEn: string;
//   taglineJa: string;
//   descriptionEn: string;
//   descriptionJa: string;
//   weblink: string;
//   imageLogoUrl: string;
// }

export interface ActivityGuideV3Data {
  authorData: AuthorV3Data;
  authorNote: string;
  resourceUrl: string;
}

export interface AuthorV3Data {
  slug: string;
  name: string;
  title: string;
  bio: string;
  avatarUrl: string; // to be hosted on jiveworld.com website. See ENG-1340
  priority: number; // exported in feed used for ordering on the marketing site (sort descending, then alphabetically)
}

export interface FeaturedReleaseV3Data {
  slug: string;
  accessibleDate: string; // iso8601
  releaseDate: string; // iso8601
  title: string;
  illustrationUrl: string;
  article: string;
  preview: string;
  storySlugs: string[];
}

// todo: VolumeCredits
// todo: volumeVoices
