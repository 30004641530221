import React from 'react';
import { Text, MediumHeading } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';
import { VSpacer } from 'naan/primitives/spacer';
import { PricingCards } from 'components/account/pricing-cards';

import { AccountData } from 'core/models/user-manager';
import { FullColumn, MiddleColumn } from '../account-layout';

type Props = {
  accountData: AccountData;
};

export const ExpiringSoon = ({ accountData }: Props) => {
  return (
    <>
      <MiddleColumn>
        <MediumHeading>Extend your subscription</MediumHeading>
        <Divider />
        <VSpacer size="5" />
        <Text>
          {/*todo: friendly date formatting*/}
          Your full access will expire on {accountData.fullAccessUntil}.
          Subscribe or purchase now to keep access to all Lupa content.
        </Text>
      </MiddleColumn>
      <FullColumn>
        <VSpacer size="10" />
        <PricingCards />
        <VSpacer size="10" />
      </FullColumn>
    </>
  );
};
