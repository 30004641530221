import { AppFactory } from 'app/app-factory';
import { ReturnNavState } from './return-nav-state';

export const baseTeachPath = () => `/app/teach/${ReturnNavState.l2}`;
export const baseLearnPath = () => `/app/learn/${ReturnNavState.l2}`;

export const discoverPath = () => `${baseTeachPath()}/discover`;
export const favoritesPath = () => `${baseTeachPath()}/favorites`;
export const classroomsPath = () => `${baseTeachPath()}/classrooms`;

export const teachHomePath = () =>
  AppFactory.root.userManager.accountData.managedClassrooms?.length
    ? classroomsPath()
    : discoverPath();

export const learnHomePath = () => `${baseLearnPath()}/home`;
export const learnStoriesPath = () => `${baseLearnPath()}/stories`;
export const learnProgressPath = () => `${baseLearnPath()}/progress`;

export const profileHomePath = () => '/app/profile/account';

export const homePath = () => {
  const { classroomEnabled, webModeEnabled } = AppFactory.root.userManager;

  if (classroomEnabled) {
    return teachHomePath();
  } else {
    if (webModeEnabled) {
      return learnHomePath();
    } else {
      return profileHomePath();
    }
  }
};

export const defaultPath = () => {
  const { authenticated } = AppFactory.root.userManager;
  if (!authenticated) {
    return '/auth/create-account';
  } else {
    return homePath();
  }
};
