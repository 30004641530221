import React from 'react';
export const IconX = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 13.4142L18.2929 19.7071L19.7071 18.2929L13.4142 12L19.7071 5.70712L18.2929 4.29291L12 10.5858L5.70712 4.29291L4.29291 5.70712L10.5858 12L4.29291 18.2929L5.70712 19.7071L12 13.4142Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
