/// This component is a relic of the very first SPA version,
/// I don't want to rewrite it at this point so I just keep patching it.;
/// it may look a little off, though.
/// @frankharrison let me know if you think making this look good is a priority
/// and I will rewrite it with new components.
import React from 'react';
import { isFunction } from 'lodash';
import { styled } from 'naan/stitches.config';
import { prevent } from '../../common/prevent';
import { useKeyboardBinding } from '../../common/hooks/use-keyboard-binding';
import { SuccessIcon } from './icons/success-icon';
import { InfoIcon } from './icons/info-icon';

const AlertContainer = styled('div', {
  borderRadius: 8,
  boxShadow: `0px 1px 4px $colors-black-alpha-20`,
  background: '$gray-50',
  minWidth: '380px',
  maxWidth: '400px',
  marginLeft: '$space$4',
  marginRight: '$space$4',
  padding: '$space$8',
  paddingBottom: 0,
  position: 'relative',
});

const AlertTitle = styled('div', {
  textStyle: 'body-bold',
  display: 'flex',
  alignItems: 'center',
});

const AlertBody = styled('div', {
  textStyle: 'body',
  marginBottom: '$space$2',
});

const ButtonsContainer = styled('div', {
  flexFlow: 'row-reverse',
  display: 'flex',
  justifyContent: 'center',
  borderTop: '1px solid $gray-50',
  variants: {
    hasCancelButton: {
      justifyContent: 'space-between',
    },
  },
});

const DialogButton = styled('a', {
  textStyle: 'body',
  // display: 'flex',
  flex: '1',
  display: 'inline-block',
  padding: '$space$4 0',
  transition: 'color 0.1s',
  margin: '0 auto',
  textDecoration: 'none',
});

const OkButton = styled(DialogButton, {
  color: '$teal-500',
  textAlign: 'center',
  variants: {
    danger: {
      true: {
        color: '$red-500',
      },
    },
    hasCancelButton: {
      true: {
        textAlign: 'right',
      },
    },
  },
});

const CancelButton = styled(DialogButton, {
  color: '$textSecondary',
});

const IconContainer = styled('div', {
  marginRight: '8px',
});

export const AlertDialog = React.forwardRef(
  (
    {
      title = null,
      body,
      onDismiss = () => {},
      okButtonAction,
      okButtonLabel = 'OK',
      okIsDangerous = false,
      cancelButtonAction = () => {},
      cancelButtonLabel = 'Cancel',
      showCancelButton = false,
      showIcon = true,
      iconType = 'success',
    },
    ref
  ) => {
    const bodyContent = isFunction(body) ? body() : body;

    const handleOkButton = () => {
      if (isFunction(okButtonAction)) {
        if (okButtonAction() === false) {
          return;
        }
      }

      onDismiss();
    };

    // close with enter
    useKeyboardBinding('Enter', handleOkButton);

    const Icon = iconType === 'warning' ? InfoIcon : SuccessIcon;

    return (
      <AlertContainer ref={ref}>
        {title ? (
          <AlertTitle>
            {showIcon ? (
              <IconContainer>
                <Icon />
              </IconContainer>
            ) : null}
            {title}
          </AlertTitle>
        ) : null}
        <AlertBody>{bodyContent}</AlertBody>
        <ButtonsContainer hasCancelButton={showCancelButton}>
          <OkButton
            href={'#'}
            onClick={prevent(handleOkButton)}
            hasCancelButton={showCancelButton}
            danger={okIsDangerous}
            data-test-id="okButton"
          >
            {okButtonLabel}
          </OkButton>
          {showCancelButton ? (
            <CancelButton
              href={'#'}
              onClick={prevent(cancelButtonAction)}
              data-test-id="cancelButton"
            >
              {cancelButtonLabel}
            </CancelButton>
          ) : null}
        </ButtonsContainer>
      </AlertContainer>
    );
  }
);
