import React from 'react';
import { styled } from 'naan/stitches.config';
import { IconCheckmark } from 'components/ds/icons';
import { CardRibbon } from 'components/card-ribbon';
import { Button } from 'naan/primitives/button';
import __ from 'core/lib/localization';
import { Text } from 'naan/primitives/text';
import { Box } from 'naan/primitives/box';
import { VSpacer } from 'naan/primitives/spacer';

const PricingCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const PricingCardContainer = styled('div', {
  $$textColor: 'white',
  $$backgroundColor: '$colors$gray-100',

  position: 'relative',
  borderRadius: '8px',
  padding: '16px',
  paddingTop: '48px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  flexGrow: '1',
  background: '$$backgroundColor',
  color: '$$textColor',
  variants: {
    presentation: {
      coupon: {
        $$backgroundColor: 'white',
        $$textColor: 'textPrimary',
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.2)',
      },
      'one-year': {
        $$backgroundColor: '#009B6C',
      },
      monthly: {
        $$backgroundColor: '$colors$teal-600',
      },
      'free-trial': {
        $$backgroundColor: '$colors$blue-600',
      },
    },
  },
});

const buttonColors: Record<string, string> = {
  coupon: '$colors$teal-500',
  'one-year': '#01885F',
  monthly: '$colors$teal-700',
  'free-trial': '$colors$blue-800',
};

const round = (n: number) => Math.floor(n);

const cents = (n: number) => {
  const c = n % 1;
  if (c) {
    return '.' + Math.floor(c * 100);
  }
};

const PriceDisplayWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  marginTop: '$space$1',
  marginBottom: '$space$1',
  '& > .currency-symbol': {
    fontWeight: '500',
    fontSize: '27px',
    lineHeight: '32px',
    alignSelf: 'flex-start',
  },
  '& > .price': {
    fontWeight: '700',
    fontSize: '40px',
    lineHeight: '44px',
  },
  '& > .interval': {
    fontWeight: '500',
    fontSize: '27px',
    lineHeight: '32px',
    opacity: 0.6,
  },
});

export const PriceDisplay = ({
  interval,
  price = 0,
}: Pick<Props, 'interval' | 'isDiscounted' | 'price'>) => {
  return (
    <PriceDisplayWrapper>
      <span className="currency-symbol">$</span>
      <span className="price">{round(price ?? 0)}</span>
      <span className="price">{cents(price ?? 0)}</span>
      {interval ? <span className="interval">{interval}</span> : null}
    </PriceDisplayWrapper>
  );
};

const PricingExplanation = styled('div', {
  '& > .cross': {
    textDecoration: 'line-through',
  },
});

const Footnote = styled('div', {
  width: '100%',
  textStyle: 'small-text',
  color: '$textSecondary',
  textAlign: 'center',
  marginTop: '16px',
});

type Props = {
  cta: string;
  featureTitle: string;
  // featureSubTitle: string,
  interval?: string;
  isCurrent?: boolean;
  isDiscounted?: boolean;
  normalPrice?: number | null;
  price?: number | null;
  ribbonTitle?: string | null;
  showRibbon?: boolean;
  showOriginalPrice?: boolean;
  title: string;
  titleAsterisk?: boolean;
  type: string;
  pricingExplanation?: string;
  footNote?: string;
  onCtaClick?: () => void;
};

export const PricingCard = ({
  cta,
  featureTitle,
  // featureSubTitle,
  interval,
  isCurrent = false,
  isDiscounted = false,
  showOriginalPrice = false,
  normalPrice = 0,
  price = 0,
  ribbonTitle = '',
  showRibbon = false,
  title = '',
  titleAsterisk = false,
  type = '',
  pricingExplanation = '',
  footNote,
  onCtaClick = () => {},
}: Props) => {
  return (
    <PricingCardWrapper>
      <PricingCardContainer
        // className={cx([
        //   'card',
        //   'pricing-cta',
        //   `type-${type}`,
        //   { 'has-footnote': !!footNote },
        // ])}
        presentation={type as any}
        data-test-id={title}
      >
        {showRibbon ? <CardRibbon ribbonTitle={ribbonTitle} /> : null}

        <Text textStyle="pricing-card-heading" color="inherit">
          {title}
          {titleAsterisk ? <span className="asterisk">*</span> : null}
        </Text>

        <PriceDisplay
          interval={interval}
          isDiscounted={isDiscounted}
          price={price}
        />

        {!!pricingExplanation ? (
          <PricingExplanation>
            {showOriginalPrice ? (
              <span className="cross">${normalPrice} </span>
            ) : null}

            {!!pricingExplanation ? pricingExplanation : null}
          </PricingExplanation>
        ) : null}

        <p className="feature">{featureTitle}</p>

        <VSpacer size={10} />

        {isCurrent ? (
          <p className="pricing-card__your-plan">
            <IconCheckmark />
            {__('My plan', 'plans.myPlan')}
          </p>
        ) : (
          <Button
            fullWidth
            size="large"
            aria-label={cta}
            onClick={onCtaClick}
            label={cta}
            css={{
              $$buttonBackgroundColor: buttonColors[type] as any,
              '&:hover': {
                opacity: 0.9,
              },
            }}
          />
        )}
      </PricingCardContainer>
      {footNote ? <Footnote>* {footNote}</Footnote> : null}
    </PricingCardWrapper>
  );
};
