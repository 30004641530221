import React from 'react';
import styled from 'styled-components';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';

const CardContainer = styled.div`
  ${includeTextStyles()}
  background: ${getColor('gray25')};
  border-radius: 12px;
  padding: 16px 20px 20px;
`;

export const Card = props => {
  return <CardContainer {...props} />;
};
