import React, { useEffect } from 'react';
import { useStripeCheckout } from '../common/stripe-context';
import { SpinnerIcon } from '../components/ui/icons/spinner-icon';
import styled from 'styled-components';
import { getColor } from '../legacylib/theme/utils';
import { useParams } from 'react-router-dom';

const cleanAddressBar = () => {
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: ${getColor('darkBlue')};
`;

export const RedirectToStripeScreen = () => {
  const params = useParams();
  const stripeCheckout = useStripeCheckout();

  useEffect(() => {
    const checkoutToken = params?.token;

    if (checkoutToken) {
      cleanAddressBar();
      stripeCheckout(checkoutToken);
    }
  }, [stripeCheckout, params]);

  return (
    <Container>
      <SpinnerIcon />
    </Container>
  );
};
