import { alertLevels } from './alert-levels';

export class GenericError extends Error {
  // alertLevel - string - controls if red or yellow style notifications are presented, fallback: 'WARN'
  alertLevel: string = alertLevels.WARN;
  // report - boolean - if false, skip bugsnag report, fallback: true
  report: boolean = true;
  // userMessage - string - end-user friendly version of message to display
  userMessage?: string;
  // expected - boolean - an error that's expected to be seen under normal conditions, if not marked as expected, then user message is prefixed as such
  expected: boolean = false;
  // globalErrorHandler - boolean - set to true when picked up the global error handler - used to avoid duplicate bugsnag reports
  globalErrorHandler: boolean = false;

  // todo: consider adding 'code' as a base property here

  // @armando, should 'name' be a declared property here?
  // also, seems like 'name' and 'type' should be unified, they seem to serve similar purposes
  // perhaps rename to 'kind'? i generally prefer to avoid 'type' to avoid keyword conflicts and 'name' is confusing

  // @armando, @nuwan do you have a sense of if/how 'context' is used?
  context: any = {};

  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    // cast needed to appease the json schema gen (for some unknown reason)
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(this, this.constructor);
    }
  }
}
