import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { GoogleLogin } from 'react-google-login';
import { useRootStore } from 'mst';
import { GoogleIcon } from 'components/ui/icons/google-icon';
import { alertError } from 'lib/services/notification-service';
import { getConfig } from 'app/env';
import { styled } from 'naan/stitches.config';
import cx from 'classnames';

const GOOGLE_CLIENT_ID = getConfig('google.clientId');

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$white',
  $$borderColor: '$colors$gray-100',
  $$textColor: '$colors$gray-600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& button': {
    border: '1px solid $$borderColor',
    borderRadius: '50px',
    background: 'none',
    backgroundColor: '$$backgroundColor',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    // cursor: 'pointer',

    '& svg': {
      paddingRight: '$space$2',
    },

    '& span': {
      textStyle: 'body',
      color: '$$textColor',
      paddingRight: '$space$1',
    },

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      $$backgroundColor: '$colors$gray-25',
      $$borderColor: '$colors$gray-200',
      $$textColor: '$colors$gray-700',
    },
  },
});

export const GoogleButton = observer(({ title, tabIndex = 0 }) => {
  const navigate = useNavigate();

  const store = useRootStore();

  useEffect(() => {
    if (store.userManager.loggedInAndReady) {
      navigate('/account');
    }
  }, [store.userManager.loggedInAndReady, navigate]);

  const onGoogleSuccess = response => {
    store.userManager.omniauth('google', response.accessToken);
  };

  const onGoogleError = error => {
    // const err = new Error('Google auth error');
    // err.context = error;
    // console.log(error);
    if (error.details) {
      // this error returned by google is not an actual Error instance,
      // if we pass it as-is, bugsnag will complain and log the complaint instead of the error
      alertError(error.details, { exception: new Error(error.details) });
      return;
      // throw new Error(error.details);
    }
    throw error;
  };

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      buttonText="Login"
      onSuccess={onGoogleSuccess}
      onFailure={onGoogleError}
      cookiePolicy={'single_host_origin'}
      render={({ onClick, disabled }) => (
        <Wrapper className={cx({ disabled })}>
          <button onClick={onClick} tabIndex={tabIndex}>
            <GoogleIcon />
            <span>{title}</span>
          </button>
        </Wrapper>
      )}
    />
  );
});
