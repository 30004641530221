import React from 'react';
import ReactDOM from 'react-dom';

import { getConfig } from 'app/env';

import App from './app';
import { theme } from './legacylib/theme';
import { RootStoreProvider } from './truth';
import { ThemeProvider } from 'styled-components';
import { BugReportingBoundary } from 'common/bugsnag/bug-reporting-boundary';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { globalErrorHandler } from './global-error-handler';
import { GlobalStyles } from 'naan/global-styles';
import './root-listeners';

import 'lib/services/localization-service';

/// hack to mock window.process, for some reason our markdown library requires it
(window as any).process = { cwd: () => '' };
(window as any).Bugsnag = Bugsnag;

const bugsnagEnabled = getConfig('bugsnag.enabled');

Bugsnag.start({
  apiKey: getConfig('bugsnag.apiKey'),
  plugins: [new BugsnagPluginReact(React)],
  onError: globalErrorHandler,
});

ReactDOM.render(
  <BugReportingBoundary bugsnagStatic={Bugsnag} bugsnagEnabled={bugsnagEnabled}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <RootStoreProvider>
        <App />
      </RootStoreProvider>
    </ThemeProvider>
  </BugReportingBoundary>,
  document.getElementById('root')
);
