import { getParentOfType, ModelTreeNode } from 'ts-state-tree/tst-core';
import {
  BEGINNING_OF_CHAPTER,
  END_OF_CHAPTER,
  END_OF_STORY_CHAPTER,
  END_OF_UNIT_CHAPTER,
} from 'core/lib/constants/vars';
import invariant from 'core/lib/invariant';
import { FluentListenStatus, PlayerMode } from 'common/misc-types';
import { StoryProgress } from './story-progress';
import { AppFactory } from 'app/app-factory';

const pad = (n: number) => String(n).padStart(3, '0');

/**
 * LocationPointer
 *
 * study position within an story.
 * two instances for each story: currentPoint, furthestPoint
 */

export class LocationPointer extends ModelTreeNode {
  static CLASS_NAME = 'LocationPointer' as const;

  static create(snapshot: any) {
    return super.create(LocationPointer, snapshot) as LocationPointer;
  }

  address: string = BEGINNING_OF_CHAPTER;

  unit: number = 1;
  chapter: number = 1;
  // for now 1 = first listen in progress, 2 = first listen complete
  iteration: number = 1;
  millisPlayed: number = 0; //reflects address, not actual play counter. - Perhaps rename to just "millis"?

  playerMode: PlayerMode;
  fluentListenStatus: FluentListenStatus;

  // this should probably be false when completed (atEndOfStory)
  // deprecated
  get inProgress(): boolean {
    return this.played;
  }

  get played(): boolean {
    return this.chapter > 1 || this.iteration > 1 || this.millisPlayed > 0;
  }

  // drives the "continue" CTA label
  get listening(): boolean {
    return this.inProgress && !this.atEndOfStory;
  }

  get sortableString(): string {
    return `${pad(this.unit)}-${pad(this.chapter)}-${pad(this.iteration)}:${
      this.address
    }`;
  }

  isLessThan(other: LocationPointer): boolean {
    return this.sortableString < other.sortableString;
  }

  // get stage(): PlayerMode {
  //   return this.iteration === 1 ? PlayerMode.STUDY : PlayerMode.FLUENT_LISTEN;
  // }
  get stage(): string {
    return this.iteration === 1 ? 'STUDY' : 'FLUENT_LISTEN';
  }

  get atEndOfChapter(): boolean {
    return this.address === END_OF_CHAPTER;
  }

  get atEndOfUnit(): boolean {
    return this.chapter === END_OF_UNIT_CHAPTER;
  }

  get atEndOfStory(): boolean {
    return this.atEndOfUnit && this.unit === this.unitCount;
  }

  // todo: revisit
  get completedChapters(): number {
    if (this.atEndOfStory) {
      return END_OF_STORY_CHAPTER;
    } else {
      return this.chapter - 1;
    }
  }

  get carouselIndex(): number {
    if (this.atEndOfStory) {
      return this.chapterCount; // summary card
    } else {
      return this.chapter - 1;
    }
  }

  // not sure if factoring up carouselIndex is worth the getParent dependency
  get chapterCount() {
    const story = this.story;
    if (story) {
      // being paranoid
      return story.chapterCount;
    } else {
      invariant(false, 'chapterCount - null story');
      return this.chapter;
    }
  }

  get unitCount() {
    return this.story?.unitCount || 0;
  }

  get story() {
    // this fails because our getters are getting prematurely called during applySnapshot
    // return getParentOfType(this, Story)

    const storyProgress = getParentOfType(this, StoryProgress);
    if (!storyProgress) {
      console.error(`missing storyProgress parent`);
      return null;
    }
    const result = AppFactory.root.storyManager.story(storyProgress.slug);
    if (!result) {
      console.error(`missing story for progress slug: ${storyProgress.slug}`);
    }
    return result;
  }
}
