import { styled } from 'naan/stitches.config';
import * as React from 'react';
import image from './lupa-for-schools.png';
const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  img: {
    maxWidth: '100%',
  },
});

export const ClassroomOnboardingScreenshot = () => {
  return (
    <Wrapper>
      <img src={image} alt="Screenshot" />
    </Wrapper>
  );
};
