/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';
import { IconLicence as IconLicense } from 'components/ds/icons';
import __ from 'core/lib/localization';
import styled from 'styled-components';
import { IconTriangleAlert } from 'components/ds/icons';
import cx from 'classnames';
import { Classroom } from 'core/models/user-manager';

const Wrapper = styled.div`
  ${includeTextStyles('small-text')};
  padding: 3px 6px;
  border-radius: 4px;
  border: 1px solid ${getColor('gray100')};
  color: ${getColor('textSecondary')};

  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    color: ${getColor('green500')};
    margin-right: 6px;
  }

  .duration-description {
    &.is-expired {
      color: ${getColor('red500')};
    }
  }
  .number {
    font-weight: 600;

    color: ${getColor('textSecondary')};
    &.is-oversubscribed {
      color: ${getColor('red500')};
    }

    &.is-active {
      color: ${getColor('green500')};
    }
  }

  .vr {
    height: 16px;
    width: 1px;
    background: ${getColor('gray100')};
    margin: 0 8px;
  }

  .triangle-icon {
    color: ${getColor('red500')};
    margin-left: 4px;
  }
`;

export const Seats = ({ classroom }: { classroom: Classroom }) => {
  const { license } = classroom;

  return (
    <Wrapper>
      <div className="icon">
        <IconLicense />
      </div>
      <div
        className={`duration-description ${
          license.isExpired ? 'is-expired' : ''
        }`}
      >
        {license.licenseDescription}
      </div>
      <span className="vr" />
      <>
        {__('Seats', 'classroom.seats')}:&nbsp;
        <span
          className={cx([
            'number',
            {
              'is-oversubscribed': license.isOverSubscribed,
              'is-active': license.isActive,
            },
          ])}
        >
          {license.seatsDescription}
        </span>
        {license.isOverSubscribed ? (
          <span className="triangle-icon">
            <IconTriangleAlert />
          </span>
        ) : null}
      </>
    </Wrapper>
  );
};
