import { action, observable, makeObservable } from 'mobx';
import { TNotificationType } from './notification-types';

export type TMessageAction = {
  label: string;
  callback: () => void;
};

export type TMessage = {
  message: string;
  type: TNotificationType;
  renderLink?: () => JSX.Element;
  action?: TMessageAction;
};

export class MessageService {
  private static _instance?: MessageService;

  @observable
  public current: TMessage | null = null;

  constructor() {
    this.clear = this.clear.bind(this);
    this.open = this.open.bind(this);
    makeObservable(this);
  }

  @action
  open(message: TMessage) {
    this.current = message;
  }

  @action
  clear() {
    this.current = null;
  }

  static get instance() {
    return (
      MessageService._instance ??
      (MessageService._instance = new MessageService())
    );
  }

  static open(toast: TMessage) {
    MessageService.instance.open(toast);
  }

  static get current() {
    return MessageService.instance.current;
  }

  static clear() {
    MessageService.instance.clear();
  }
}

(window as any).MessageService = MessageService;
