import * as React from 'react';
import * as Layout from './volume-page-layout';
import { styled } from 'naan/stitches.config';
import { Markdown, Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { AudioPlayer } from './volume-audio-player';
import { Story } from 'core/models/story-manager';
import { TeacherMeta } from './teacher-meta';
import { VolumeTagLinks } from './volume-tag-links';
import { VolumeCardMeta } from './volume-card-meta';

const Title = styled('h2', {
  textStyle: 'medium-heading',
  color: '$colors$textPrimary',
  marginTop: '0',
  marginBottom: '8px',
  'span.suffix': {
    marginLeft: '$space$1',
    color: '$colors$textSecondary',
  },
  '@medium': {
    marginTop: '$space$4',
  },
  '@large': {
    textStyle: 'large-heading--desktop',
  },
});

export const VolumePage = ({ story }: { story: Story }) => {
  if (story === null) {
    return null;
  }

  const title = story.baseTitle;
  const titleSuffix = story.titleSuffix;

  return (
    <>
      {story.listImageUrl ? (
        <Layout.FigureContainer>
          <Layout.Figure src={story.listImageUrl} alt={title} />
        </Layout.FigureContainer>
      ) : null}
      <Layout.Content>
        <Layout.Header>
          <Title>
            {title}
            {titleSuffix ? <span className="suffix">{titleSuffix}</span> : null}
          </Title>
          <Layout.Meta>
            <VolumeCardMeta story={story} />
          </Layout.Meta>
        </Layout.Header>
        <VSpacer size={'10'} />
        <Text textStyle="small-heading" color="textPrimary">
          {story.tagline}
        </Text>

        <VSpacer size={2} />
        <Markdown
          source={story.description ?? ''}
          css={{ color: '$textSecondary' }}
        />
        <VSpacer size={4} />
        <TeacherMeta story={story} />
        <VSpacer size={5} />
        <VolumeTagLinks tags={story.allTags} />
        <AudioPlayer audioUrl={story.promoAudioUrl} />
      </Layout.Content>
    </>
  );
};
