import React, { useState, useEffect, useContext } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getConfig } from 'app/env';
import { devMode } from 'app/platform';

const stripePromise = loadStripe(getConfig('stripe.publishableKey'));
const StripeContext = React.createContext<Stripe | null>(null);

export function StripeProvider({ children }: { children: React.ReactNode }) {
  const [instance, setInstance] = useState<Stripe | null>(null);

  useEffect(() => {
    stripePromise.then(setInstance);
  }, []);

  if (!instance) {
    return null;
  }

  return (
    <StripeContext.Provider value={instance}>{children}</StripeContext.Provider>
  );
}

export function useStripe() {
  return useContext(StripeContext);
}

export const useStripeCheckout = () => {
  const stripe = useStripe();
  return (sessionId: string) => {
    if (stripe) {
      return stripe.redirectToCheckout({ sessionId });
    }

    return new Promise<null>((resolve, reject) => {
      if (devMode) {
        reject(new Error('Stripe context is not loaded'));
      }

      resolve(null);
    });
  };
};
