import * as Yup from 'yup';
import { FNAME_MAX_CHAR_LIMIT } from 'core/lib/constants/vars';
import __ from 'core/lib/localization';

// @armando, i'll let you think about if/where this file belongs (i moved from the prior core/lib into here)

/// FIXME: this three rules should be private, but we'll leave them this way for now
export const nameRule = Yup.string()
  .max(
    FNAME_MAX_CHAR_LIMIT,
    __('First name must be at most 15 characters', 'auth.firstNameNotValid')
  )
  .required(__('First name is required', 'auth.firstNameRequired'))
  .trim();

export const emailRule = Yup.string()
  .email(__('Email is not valid', 'auth.emailNotValid'))
  .required(__('Email is required', 'auth.emailRequired'))
  .trim();

export const passwordRule = Yup.string().required(
  __('Password is required', 'auth.passwordRequired')
);

/**
 * Validation schema for signup form
 */
export const signUpSchema = Yup.object().shape({
  name: nameRule,
  email: emailRule,
  password: passwordRule,
});

/**
 * Validation schema for login form
 */
export const signInSchema = Yup.object().shape({
  email: emailRule,
  password: passwordRule,
});

export const nameSchema = Yup.object().shape({
  name: nameRule,
});

export const emailSchema = Yup.object().shape({
  email: emailRule,
});

export const passwordSchema = Yup.object().shape({
  password: passwordRule,
});

export const couponCodeSchema = Yup.object().shape({
  code: Yup.string().required(__('Code is required', 'auth.codeIsRequired')),
});

//
// Classroom
//

const classNameMin = 2;
const classNameMax = 20;
const classNameMinMaxMsg = __(
  'Class name needs to be between %{min} and %{max} characters',
  'classroom.labelMaxLengthValidationMessage',
  { min: classNameMin, max: classNameMax }
);

export const classNameRule = Yup.string()
  .min(2, classNameMinMaxMsg)
  .max(20, classNameMinMaxMsg)
  .trim();

export const classNameSchema = Yup.object().shape({
  label: classNameRule,
});

const schoolNameMaxLength = 80;

/// I had to duplicate the rules because Typescript
export const schoolNameSchema = Yup.object().shape({
  schoolName: Yup.string()
    .max(
      schoolNameMaxLength,
      __(
        'School name must be at most %{n} characters',
        'auth.schoolNameMaxLengthError',
        { n: schoolNameMaxLength }
      )
    )
    .required(
      __(
        'Enter the name of the school or schools where you teach',
        'auth.schoolNameRequired'
      )
    )
    .trim(),
});

//
// Assignment
//

// export const dateRule = Yup.string()
//   .trim();

export const assignmentDetailsRule = Yup.string().max(250).trim();

export const assignmentSchema = Yup.object().shape({
  details: assignmentDetailsRule,
});
