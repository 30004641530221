import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';

export const EnsureAuth = observer(({ children }: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated, loggedInAndReady } = AppFactory.root.userManager;

  React.useEffect(() => {
    // @Armando, i'm not exactly sure when it would make a difference, but i think 'authenticated' is conceptually the better guard here
    if (!authenticated /*loggedInAndReady*/) {
      /// We were seeign a race-condition bug where the user never got to see the
      /// `/account-closed` screen because this code was prematurely redirecting them
      /// to the sign-in page.
      /// So, if the user is in `/app/profile/close-account` and sudenly becomes unauthenticated,
      /// I think we can safely assume that it's because he just closed his account
      /// so we will not, just in this case, redirect him to the login page.
      /// and let the close-account-screen handle the redirect itself.
      /// -- AS
      if (location.pathname.includes('close-account')) {
        return;
      }

      navigate('/auth/sign-in');
    }
  }, [navigate, authenticated, location]);

  if (!loggedInAndReady) {
    return null;
  }

  return children;
});
