import * as React from 'react';

export function ChevronDownLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4 9.80005L14 18.2L5.59999 9.80005"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}

export function ChevronDownSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 6L10 14L2 6" stroke={color} strokeWidth="2" />
    </svg>
  );
}

export function ChevronDownIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 8L12 17L3 8" stroke={color} strokeWidth="2" />
    </svg>
  );
}
