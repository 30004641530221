import { useState } from 'react';

/**
 * Most us the things I use useState for, are simple on/off switches,
 * so I came up with this simple hook that makes it's use more straightforward
 *
 * use:
 *
 * const [show,showSwitch] = useSwitch(false);
 *
 * <Button onPress={showSwitch.toggle} />
 *
 * @param {*} initialState
 */
export const useSwitch = initialState => {
  const [thing, setThing] = useState(initialState);
  const toggle = () => setThing(!thing);
  const on = () => setThing(true);
  const off = () => setThing(false);
  return [thing, { toggle, on, off }];
};
