import React from 'react';
import ReactDOM from 'react-dom';

export class Portal extends React.Component {
  element: HTMLElement | null = null;

  constructor(props: any) {
    super(props);
    this.element = document.createElement('DIV');
    document.body.appendChild(this.element);
  }

  render() {
    if (this.element) {
      return ReactDOM.createPortal(this.props.children, this.element);
    }
    return null;
  }
}
