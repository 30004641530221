import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { getColor } from 'legacylib/theme/utils';

const Wrapper = styled.div`
  --background-color: ${getColor('gray400')};
  --border-color: ${getColor('gray400')};
  --active-background-color: ${getColor('green500')};
  --active-border-color: ${getColor('green600')};
  --foreground-color: ${getColor('white')};

  height: 30px;
  width: 52px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);

  transition-property: all;
  transition-duration: 120ms;
  transition-delay: 50ms;

  &.presentation-yellow {
    --active-background-color: ${getColor('yellow300')};
    --active-border-color: ${getColor('yellow500')};
  }

  &.disabled {
    --background-color: ${getColor('gray100')};
    --border-color: ${getColor('gray100')};
    --active-background-color: ${getColor('gray100')};
    --active-border-color: ${getColor('gray100')};
    cursor: default;
  }

  .knob {
    position: absolute;
    height: 28px;
    width: 28px;
    left: 0;
    top: 0;
    border-radius: 14px;
    background-color: var(--foreground-color);
    transition-property: transform;
    transition-duration: 180ms;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  }

  &.active {
    --background-color: var(--active-background-color);
    --border-color: var(--active-border-color);
    .knob {
      transform: translateX(22px);
    }
  }
`;

export const SwitchButton = ({
  active = false,
  disabled = false,
  presentation = 'default',
  onChange = () => {},
}) => {
  const handleClick = React.useCallback(() => {
    if (disabled) {
      return null;
    }
    onChange();
  }, [onChange, disabled]);

  return (
    <Wrapper
      className={cx([`presentation-${presentation}`, { active, disabled }])}
      onClick={handleClick}
    >
      <div className="knob"></div>
    </Wrapper>
  );
};
