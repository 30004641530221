// DELETEME: this file is obsolete and should be deleted after 6.2
import * as React from 'react';
import { NavItemExternal } from 'naan/primitives/nav';
import { InfoCircleIcon } from 'naan/icons/info-circle-icon';
import { LifebuoyIcon } from 'naan/icons/lifebuoy-icon';
import { CellphoneIcon } from 'naan/icons/cellphone-icon';
import { getConfig } from 'app/env';

import __ from 'core/lib/localization';
import { FacebookSmallIcon } from 'naan/icons/facebook-icon';

export const ExternalLinks = ({
  isTeacher = false,
}: {
  isTeacher?: boolean;
}) => {
  return (
    <>
      <NavItemExternal
        href={getConfig('website.urls.marketingSite')}
        icon={<InfoCircleIcon />}
        label={__('About Lupa', 'nav.aboutLupa')}
        size="small"
      />
      {isTeacher ? (
        <NavItemExternal
          href={getConfig('website.urls.facebookTeachersGroup')}
          icon={<FacebookSmallIcon />}
          label={__('Teachers community', 'nav.teachersCommunity')}
          size="small"
        />
      ) : null}
      <NavItemExternal
        href={getConfig('website.urls.support')}
        icon={<LifebuoyIcon />}
        label={__('Support', 'nav.support')}
        size="small"
      />
      <NavItemExternal
        href={getConfig('website.urls.downloadApp')}
        icon={<CellphoneIcon />}
        label={__('Download the app', 'nav.downloadTheApp')}
        size="small"
      />
    </>
  );
};
