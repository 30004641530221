const l = window.location;
const baseUrl = l.protocol + '//' + l.hostname + (l.port ? `:${l.port}` : '');
const accountUrl = `${baseUrl}/app/profile/account`;

export const env = {
  VITE_CONFIG_ENV: import.meta.env.VITE_CONFIG_ENV, // for debug reference

  appName: 'MyLupa',
  appSlug: 'myLupa',
  appTitle: import.meta.env.VITE_SITE_TITLE ?? '',
  forcedApiEnv: import.meta.env.VITE_DEFAULT_API_ENV ?? 'devtest',
  manifestVersion: 'proto',

  // volumeMode: true,

  jiveworldApiKey: {
    website: 'QowNmFgzVLu4WnattXzN', // for now, shared for all deployment environments
  },

  bugsnag: {
    apiKey: '56ed11fed6bfebab28223aa62b630786',
    enabled: true,
  },
  // will probably want to make this always true, for now just enabled in the dev and devtest configs
  showUnhandledErrors: false,

  google: {
    clientId:
      '903123262392-86hnsj69ds3oatf615tuf7q4vf4kajh7.apps.googleusercontent.com',
  },

  stripe: {
    publishableKey: 'pk_test_Zri5LbMMapbrkavc5yW3Z1fV00rJUj8DZw',
  },

  analytics: {
    //'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // internal testing
    segmentWriteKey: null, // segment is disabled if no key provided, enabled when provided
    enableSimulatedAnalytics: false,
    enableAnonymous: true,
    eventPrefix: 'w',
    googleAnalyticsKey: null, //'UA-143472151-6', // set to null to disable
  },

  // @armando is this flag still relevant?
  logging: {
    // `enableConsoleLog` when true, the logger‘s use of console.log is short-circuited
    enableConsoleLog: !!import.meta.env.DEV,
  },

  alertUnhandledErrors: false,

  website: {
    baseUrl,
    staticPath: `/assets`,
    accountUrl,
    checkoutSuccessUrl: `${accountUrl}?checkout=success`,
    checkoutFailureUrl: `${accountUrl}?checkout=error`,
    // this allows sharing cookies between the service tier and website
    cookieDomain: '.jiveworld.com',

    urls: {
      marketingSite: 'https://www.lupa.app',
      downloadApp: 'https://get.lupa.app',
      classroomMarketingPage: 'https://lupa.app/schools/classrooms',
      classroomLicenseLearnMore: 'https://jiveworld.com',
      jwPressServer: 'https://resources.jiveworld.com',
      contactUs: 'https://www.lupa.app/contact-us',
      support: 'https://support.lupa.app',
      spa: 'https://my.lupa.app',
      facebookTeachersGroup: 'https://www.facebook.com/groups/jiveworld',
    },
    emails: {
      feedback: 'feedback@jiveworld.com',
      support: 'support@jiveworld.com',
      educationalSales: 'sales@jiveworld.com',
    },
    emailOptions: {
      subject: 'My feedback on Lupa',
      body: "Please explain what worked, what didn't work, and what you'd like to see improved",
    },
    downloadUrls: {
      appStore:
        'https://apps.apple.com/us/app/lupa-learn-spanish-fluently/id1480744663?ls=1',
      playStore:
        'https://play.google.com/store/apps/details?id=com.jiveworld.lupa',
    },
  },

  classrooms: {
    exampleLessonPlansUrl: 'https://go.lupa.app/lupa-lessons',
  },
};
