import React from 'react';
import { Text, MediumHeading } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';
import { VSpacer } from 'naan/primitives/spacer';
import { PricingCards } from 'components/account/pricing-cards';
import { CouponButton } from 'components/account/marketing-links/coupon-button';
import { AccountData } from 'core/models/user-manager';

import { FullColumn, MiddleColumn } from '../account-layout';

type Props = {
  accountData: AccountData;
};

export const TrialDownloaded = ({ accountData }: Props) => {
  return (
    <>
      <MiddleColumn>
        <MediumHeading>
          {/*todo: confirm if we keep affiliate heading in this case*/}
          {accountData?.hasAffiliatePricing
            ? accountData.affiliateWelcomeHeading
            : 'Unlock full access'}
        </MediumHeading>
        <Divider />
        <VSpacer size="5" />
        <Text>
          You’re currently using our free trial. Subscribe or purchase now to
          unlock all Lupa content.
        </Text>
      </MiddleColumn>
      <FullColumn>
        <VSpacer size="10" />
        <PricingCards />
        {/* <VSpacer size="10" /> */}
      </FullColumn>
      <MiddleColumn>
        <CouponButton />
        <VSpacer size="20" />
      </MiddleColumn>
    </>
  );
};
