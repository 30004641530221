import React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
import { FilteringProvider } from 'common/filtering/filtering-provider';
import { FiltersUi } from 'components/discover/filters-ui';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import __ from 'core/lib/localization';
import { OldHeaderContainer } from 'components/header';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { WelcomeMessage } from 'components/welcome-message';
import { FilteredStories } from 'components/stories/filtered-stories';

const LayoutWrapper = styled('section', {
  gridColumn: '1/-1',
});

export const StoryListScreen = observer(() => {
  useTitle(__('Stories', 'nav.stories'));

  return (
    <>
      <FilteringProvider>
        <WelcomeMessage />
        <OldHeaderContainer>
          <ContentGridInnerContainer>
            <PageHeading title={__('Stories', 'nav.stories')} />
          </ContentGridInnerContainer>
        </OldHeaderContainer>
        <FiltersUi />
        <ContentGridInnerContainer>
          <ContentGrid>
            <LayoutWrapper>
              <FilteredStories />
            </LayoutWrapper>
          </ContentGrid>
        </ContentGridInnerContainer>
      </FilteringProvider>
    </>
  );
});
