import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { CouponCodeForm } from 'components/gift-coupons/coupon-code-form';
import { AppFactory } from 'app/app-factory';

import __ from 'core/lib/localization';
import { CouponAppliedDialog } from 'components/gift-coupons/coupon-applied-dialog';

// FIXME: find a better place for this component
const CouponDialog = observer(
  ({
    onDismiss,
    onSuccess,
  }: {
    onDismiss: () => void;
    onSuccess: (result: any) => void;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Enter your code', 'account.enterYourCode')}
        </Dialog.Heading>
        <Dialog.CloseButton />
        <Dialog.Body>
          <CouponCodeForm
            onCouponCodeSuccess={onSuccess}
            placeholder={__('Coupon code', 'common.couponCode')}
            buttonLabel={__('Apply', 'common.apply')}
          />
        </Dialog.Body>
      </Dialog.Container>
    );
  }
);

export function useCouponDialog() {
  const handleCouponCodeSuccess =
    (dismissParent: () => void) => (result: any) => {
      /// we close the parent dialog before showing the coupon applied dialog
      dismissParent();
      if (result?.message) {
        AppFactory.dialogPresenter.present(onDismiss => (
          <CouponAppliedDialog onDismiss={onDismiss} message={result.message} />
        ));
      }
    };
  const handleApplyCode = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <CouponDialog
        onDismiss={onDismiss}
        onSuccess={handleCouponCodeSuccess(onDismiss)}
      />
    ));
  }, []);

  return handleApplyCode;
}

export const CouponButton = observer(
  ({
    label = __(
      'Have a classroom, coupon or promo code?',
      'account.haveACoupon'
    ),
  }) => {
    const handleApplyCode = useCouponDialog();
    return (
      <>
        <VStack>
          <VSpacer size={6} />
          <HStack justify="center">
            <Button
              label={label}
              onClick={handleApplyCode}
              testId="redeem-code"
              presentation="tealTransparent"
              css={{ textDecoration: 'underline' }}
            />
          </HStack>
        </VStack>
      </>
    );
  }
);
