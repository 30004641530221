import { createLogger } from 'app/logger';
// import invariant from 'core/lib/invariant';
import __ from 'core/lib/localization';
import {
  millisToMinutes,
  minutesToPrettyDuration,
} from 'core/lib/pretty-duration';
import { deburr, sumBy } from 'lodash';
import moment from 'moment';
import {
  ModelTreeNode,
  identifier,
  volatile,
  snap,
} from 'ts-state-tree/tst-core';
import { getBaseRoot } from '../base-root';
import { ChapterCatalogData, VolumeCatalogData } from '../catalog';
import { Speaker } from '../catalog/speaker';
import { UnitCatalogData } from '../catalog/unit-catalog-data';
import { Root } from '../root';
import { Assignment } from '../user-manager';
import { LocationPointer } from '../user-manager/location-pointer';
import { StoryProgress } from '../user-manager/story-progress';

const log = createLogger('story');

export class Story extends ModelTreeNode {
  static CLASS_NAME = 'Story' as const;

  @identifier
  slug: string = '';

  units: UnitCatalogData[] = [];

  volumeData: VolumeCatalogData = snap({});

  @volatile
  vocabLookupData: Object = {};

  static create(snapshot: any = {}) {
    return super.create(Story, snapshot) as Story;
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  // todo: remove usages, but needs to be dealt with carefully because heavily used by spa.
  // should perhaps wait until after 6.1.0 is releasead, or at least perform first in the spa
  get catalogData(): UnitCatalogData {
    return this.firstUnitData;
  }

  get firstUnitData(): UnitCatalogData {
    return this.units[0];
  }

  unitDataByNumber(unitNumber: number) {
    return this.units.find(unit => unit.unitNumber === unitNumber);
  }

  get unitCount() {
    return this.units.length;
  }

  get multiUnit() {
    return this.unitCount > 1;
  }

  get singleUnit() {
    return !this.multiUnit;
  }

  includesUnit(slug: string): boolean {
    return this.units.some(unit => unit.slug === slug);
  }

  get hasProgress(): boolean {
    return !!this.progress;
  }

  get progress(): StoryProgress {
    const { userManager } = this.root;
    return userManager.userData.storyProgress(this.slug);
  }

  updateCurrentPoint(point: LocationPointer) {
    const { userManager } = this.root;
    const progress = userManager.userData.ensureStoryProgress(this.slug);
    progress.updateCurrentPoint(point);
    userManager.persistUserData(); // note, persistance runs async
  }

  get atEndOfStory() {
    return this.progress?.currentPoint?.atEndOfStory;
  }

  // probably shouldn't be used
  get chapterCount() {
    return this.chapters.length;
  }

  get currentUnitChapterCount(): number {
    return this.progress?.currentUnit?.chapterCount;
  }

  get currentUnitIsLast(): boolean {
    return this.progress?.currentUnit?.isLastUnit;
  }

  async exportVocab() {
    if (this.vocabCount) {
      await this.root.userManager?.exportVocab(this.slug);
    } else {
      log.info(`story[${this.slug}.exportVocab - no vocab saved, skipping`);
    }
  }

  toggleClassroomFavorite() {
    return this.root.userManager.userData.classroom.toggleFavorite(this.slug);
  }

  get searchableText() {
    if (!this.firstUnitData) return '';
    return deburr(
      [
        this.firstUnitData.title,
        this.firstUnitData.description,
        this.firstUnitData.tagline,
        ...this.firstUnitData.countries,
        ...this.firstUnitData.topics,
        ...this.firstUnitData.ibTags,
        ...this.firstUnitData.apTags,
      ]
        .join(' ')
        .toLowerCase()
    );
  }

  get topics() {
    return this.firstUnitData.topics;
  }

  get countries() {
    return this.firstUnitData.countries;
  }

  // todo: should probably remove usages
  get themes() {
    return this.pedagogicalThemes;
  }

  get pedagogicalThemes() {
    return this.firstUnitData.pedagogicalThemes;
  }

  get apTags() {
    return this.firstUnitData.apTags;
  }

  get ibTags() {
    return this.firstUnitData.ibTags;
  }

  get allTags() {
    return this.firstUnitData.allTags;
  }

  get listImageUrl() {
    return this.firstUnitData.listImageUrl;
  }

  get bannerImageUrl() {
    return this.firstUnitData.bannerImageUrl;
  }

  get activityGuideUrl() {
    return this.firstUnitData.activityGuideUrl;
  }

  get promoAudioUrl() {
    return this.firstUnitData.promoAudioUrl;
  }

  get trial() {
    return this.firstUnitData.trial;
  }

  get assignCount() {
    const assignmentMap =
      this.root.userManager.accountData?.assignmentMap ?? {};
    if (assignmentMap && assignmentMap[this.slug]) {
      return assignmentMap[this.slug];
    }
    return 0;
  }

  get unplayed(): boolean {
    return this.progress?.unplayed;
  }

  get played() {
    return this.progress?.played;
  }

  get inProgress() {
    return this.progress?.inProgress;
  }

  // substate of 'completed'
  get relistening() {
    return this.progress?.relistening;
  }

  // drives the "continue" CTA label
  get listening() {
    return this.progress?.listening;
  }

  get completed() {
    return this.progress?.completed;
  }

  // deprecated
  get progressBar() {
    return this.studyProgressRatio;
  }

  // todo: revisit this
  get minutesRemaining() {
    // const { catalogData, progress } = this;
    // if (!catalogData || !progress) return 0;

    // const { chapter: chapterPosition, millisPlayed } = progress.furthestPoint;
    // const pastMillisPlayed =
    //   this.catalogData.chapters
    //     .slice(0, chapterPosition - 1)
    //     .reduce((sum, chapter) => sum + chapter.durationMillis, 0) +
    //   millisPlayed;

    // const oneMinuteInMillis = 60 * 1000;

    // const minutesRemaining =
    //   (catalogData.durationMinutes * oneMinuteInMillis - pastMillisPlayed) /
    //   oneMinuteInMillis;
    // return Math.round(minutesRemaining);
    return millisToMinutes(this.timeLeftMillis);
  }

  // get furthestChapter() {
  //   if (!this.progress) return 0;
  //   return this.chapter(this.progress.furthestPoint.chapter);
  // }

  // get currentChapterPosition() {
  //   if (!this.progress) return 0;
  //   return this.progress.currentPoint.chapter;
  // }

  get chapters(): ChapterCatalogData[] {
    return this.units.map(unit => unit.chapters).flat();
  }

  get furthestChapter(): ChapterCatalogData {
    return this.chapters.find(ch => ch.isFurthest);
  }

  get durationMillis() {
    return this.units.reduce((sum, unit) => sum + unit.durationMillis, 0);
  }

  get progressMillis(): number {
    return this.chapters.reduce((sum, ch) => sum + ch.progressMillis, 0);
  }

  get timeLeftMillis(): number {
    return this.durationMillis - this.progressMillis;
  }

  get durationMinutes() {
    // return this.units.reduce((sum, unit) => sum + unit.durationMinutes, 0);
    return millisToMinutes(this.durationMillis);
  }

  get timeLeftMinutes() {
    return millisToMinutes(this.timeLeftMillis);
  }

  // reflects furthestPoint on progress bars
  get studyProgressRatio(): number {
    return this.progressMillis / this.durationMillis;
  }

  get studyProgressPercentage() {
    return Math.round(this.studyProgressRatio * 100);
  }

  get totalPoints() {
    return this.progress?.listeningStats?.totalPoints;
  }

  get vocabCount() {
    return this.progress?.vocabsCount;
  }

  get vocabCountDescription() {
    return __('%{count} items', 'vocab.itemsCount', {
      count: this.vocabCount,
    });
  }

  get listeningStats() {
    return this.progress?.listeningStats;
  }

  get locked() {
    const { accountData } = this.root.userManager;
    if (accountData?.fullAccess) {
      return false;
    }
    const unlockedSlugs = accountData?.unlockedStorySlugs;
    if (unlockedSlugs && unlockedSlugs.includes(this.slug)) {
      return false;
    }
    // if (this.volume?.isUnlocked) {
    //   return false;
    // }
    return !this.trial;
  }

  get vocabViewData() {
    return this.progress?.vocabsViewData;
  }

  get showResetStory() {
    return !this.progress?.unplayed;
  }

  get showMarkComplete() {
    return !this.progress?.completed && !this.locked;
  }

  get isNew() {
    const { newThisWeek } = this.root.storyManager;
    return newThisWeek.includes(this);
  }

  get isReleased() {
    const { currentDate } = this.root.storyManager;
    const today = moment(currentDate);
    const storyDate = moment(this.firstUnitData.releaseDate);
    return storyDate.isSameOrBefore(today);
  }

  // links to the matching classroom assignment if this story is included in any joined classrooms
  // if story included in multiple classrooms, last one wins
  // (not going to worry about fully handling that edge case for now)
  get assignment(): Assignment {
    let result = null;
    const { accountData } = this.root.userManager;
    accountData.joinedClassrooms.forEach(classroom => {
      const match = classroom.assignmentForSlug(this.slug);
      if (match) {
        result = match;
      }
    });
    return result;
  }

  // get volume() {
  //   const { storyManager } = getRoot(this) as Root;
  //   return storyManager?.volumes?.find(volume => {
  //     return volume.slug === this.catalogData.volumeSlug;
  //   });
  // }

  get unitNumber() {
    return this.catalogData?.unitNumber;
  }

  get title() {
    return this.baseTitle + (this.titleSuffix ? ` [${this.titleSuffix}]` : '');
  }

  get baseTitle() {
    return this.catalogData?.resolvedBaseTitle;
  }

  get hasTitleSuffix() {
    return (
      !this.root.userManager?.webModeEnabled && this.catalogData?.partSuffix
    );
  }

  get titleSuffix() {
    return this.hasTitleSuffix ? this.catalogData.titleSuffix : null;
  }

  get tagline() {
    return this.catalogData?.localizedTagline;
  }

  get description() {
    return this.catalogData?.localizedDescription;
  }

  get cast() {
    return this.firstUnitData?.cast;
  }

  get creditsList() {
    return this.firstUnitData.creditsList;
  }

  get activityGuideData() {
    return this.firstUnitData.activityGuideData;
  }

  //
  // sort keys
  //

  get durationDescription() {
    // return this.catalogData?.durationDescription;
    const parts = __(
      { one: '%{count} part', other: '%{count} parts' },
      'story.nParts',
      { count: this.unitCount }
    );

    const duration = minutesToPrettyDuration(this.durationMinutes);

    // beware 'story.durationDescriptionParts' was coliding with an outdated en.json, but don't dare regen that right now
    return __('%{parts}, %{duration}', 'story.durationDescriptionNew', {
      parts,
      duration,
    });
  }

  // should be redundant now with 'durationMinutes' once the legacy unit based discover view is retired
  get sortDurationMinutes() {
    return this.catalogData.sortDurationMinutes;
  }

  get releaseDate() {
    return this.firstUnitData.releaseDate;
  }

  get originalBroadcastDate() {
    return this.firstUnitData.originalBroadcastDate;
  }

  // get thumbImagePath() {
  //   return this.download?.listImagePath;
  // },

  // get bannerImagePath() {
  //   if (this.volume) {
  //     return this.volume.thumbImagePath;
  //   } else {
  //     return this.download?.bannerImagePath;
  //   }
  // },

  get isClassroomFavorited() {
    return this.root.userManager.userData.classroom.isFavorited(this.slug);
  }

  // legacy
  resolveSpeakerData(label: string) {
    return this.firstUnitData?.resolveSpeakerData(label);
  }

  // jw.next
  resolveSpeaker(label: string): Speaker {
    const result = this.volumeData.speaker(label);
    if (!result) {
      // todo: make this an assert
      // currently happens because of the order of resolving the web mode
      // and initializing the story manager data
      console.log(`warning - missing speaker data for label: ${label}`);
      return Speaker.create({});
    }
    return result;
  }

  // todo: figure out sorting
  get voicesList(): Speaker[] {
    return this.volumeData.speakers.filter(speaker => speaker.includeInVoices);
  }
}
