import { observable } from 'mobx';
import {
  Bindery,
  getRoot,
  getSnapshot,
  ModelTreeNode,
  snap,
  volatile,
} from 'ts-state-tree/tst-core';

import { createLogger } from 'app/logger';
import minibus from 'common/minibus';

import { ApiInvoker } from '../services/api-invoker';
import { UserManager } from './user-manager/user-manager';
import { GlobalConfig } from './global-config';
import { Catalog } from './catalog/catalog';
import { StoryManager } from './story-manager';

const log = createLogger('root');
const READY = 'ready'; //  magic status

export abstract class BaseRoot extends ModelTreeNode {
  protected static bindModels(bindery: Bindery): void {
    // assume app's Root model will be bound within app level code
    UserManager.bindModels(bindery);
    StoryManager.bindModels(bindery);
    Catalog.bindModels(bindery);
    bindery.bind(GlobalConfig);
  }

  @volatile
  @observable.ref // @observable.not
  apiInvoker: ApiInvoker; // delegate for rails server REST API invocations

  userManager: UserManager = snap({});
  globalConfig: GlobalConfig = snap({});

  catalog: Catalog = snap({});

  storyManager: StoryManager = snap({});

  @volatile
  status: string = 'off'; // Enum: off/initializing/backgrounded/ready/dead

  @volatile
  successMessage: string = null; // @armando, these success and warning messages are no longer used correct?

  @volatile
  warningMessage: string = null;

  // should be typed as ValidationError, but we need to figure out why the schema gen is barfing
  @volatile
  validationError: any = null;

  @volatile
  startingUp: boolean = false; // lock to only attempt one startup sequence at a time

  @volatile
  foregrounding: boolean = false; // lock to only attempt one toForeground sequence at a time

  @volatile
  retryingStartup: boolean = false; // if we're reattempting the startup sequence after an initial unexpected error. prevents infinite loop

  // true when auto-authenticated via userToken upon cold start or
  // foregrounded into logged-in state
  // used to avoid forced restart modal after OTA directly after logging in
  @volatile
  preauthenticatedSession: boolean = false;

  @volatile
  transitioningAnonymousAccount: boolean = false; // let's try not to freak the UI out

  // can hopefully remove this
  @volatile
  flash: FlashObj = null;

  // this gets called automatically by the base ModelTreeNode after the initial snapshot
  // data is applied
  afterCreate() {
    // super.afterCreate();
    log.debug('afterCreate');
    this.apiInvoker = new ApiInvoker(this);
    // this.userManager = UserManager.create();

    // to consider: should we invoke this from the factory and make the factory async?
    this.initState(); // no await because afterCreate isn't async
    log.info('root store created');
  }

  abstract initState(): Promise<void>;
  abstract persist(): Promise<void>;

  get loadingData() {
    return this.apiInvoker.loadingData;
  }

  get ready() {
    return this.status === READY;
  }

  setReady() {
    log.info('application state now ready()');
    this.status = READY;
  }

  setStatus(status: string): void {
    this.status = status;
  }

  setFlash(flash: FlashObj): void {
    this.flash = flash;
  }

  resetFlash(): void {
    this.flash = null;
  }

  /**
   * tracks if we cold-started or foregrounded into an already authenticated
   * state, or if we've explicitly logged in this session.
   * used to suppress the force-restart modal just after logging in
   */
  setPreauthenticatedSession(bool: boolean): void {
    this.preauthenticatedSession = bool;
  }

  setReportingContext() {
    // todo: better way to turn the snapshot into a POJSO?
    // without the stringify/parse dance. my apiEnv patch below had no effect
    const globalConfigData = JSON.parse(
      JSON.stringify(getSnapshot(this.globalConfig))
    );
    // hack because apiEnv is a view instead of serialized property
    globalConfigData.apiEnv = this.globalConfig.apiEnv;

    const data: any = {
      globalConfig: globalConfigData,
    };
    data.accountData = getSnapshot(this.userManager.accountData);
    log.info(`setReportingContext`); //(${JSON.stringify(data)})`);
    minibus.emit('SET_REPORTING_CONTEXT', data);
  }

  //
  // to be removed with error/validation/messaging refactor

  setValidationError(error: any) {
    log.info(`validation error: ${error}`);
    this.validationError = error;
  }

  resetValidationError() {
    this.validationError = null;
  }
}

export interface FlashObj {
  messageKey: string;
}

export const getBaseRoot = (node: any): BaseRoot => {
  const root = getRoot(node);
  if (root && root instanceof BaseRoot) {
    return root;
  } else {
    throw Error(`unexpectedly missing root`);
  }
};
