import Dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ModelTreeNode } from 'ts-state-tree/tst-core';

Dayjs.extend(localizedFormat);

export class Student extends ModelTreeNode {
  static CLASS_NAME = 'Student' as const;

  static create(snapshot: any) {
    return super.create(Student, snapshot) as Student;
  }

  email: string = '';
  name: string = '';
  membershipType: string = null;
  accessDescription: string = null;
  fullAccess: boolean = false;
  fullAccessUntil: string; // iso8061 formatted date string

  get localizedFullAccessUntil() {
    return Dayjs(this.fullAccessUntil).format('ll');
  }
}
