import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FormError } from 'naan/primitives/forms';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';
import { useForm } from 'react-hook-form';
import __ from 'core/lib/localization';

import { Dialog } from './dialog';

const Wrapper = styled.div`
  ${includeTextStyles('body')};
  .hint {
    padding-bottom: 16px;
  }
  input,
  textarea {
    flex: 1;
    width: 100%;
    display: block;
    background: ${getColor('white')};
    border: 1px solid ${getColor('gray100')};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;

    &::placeholder {
      color: ${getColor('gray300')};
    }

    &:focus {
      border-color: ${getColor('teal500')};
      outline: none;
    }
  }

  textarea {
    height: 142px;
    resize: none;
  }
`;

export const Prompt = ({
  label = null,
  initialValue = '',
  placeholder = '',
  okButtonAction = () => {},
  cancelButtonAction = () => {},
  showClearButton = false,
  type,
  validation = {},
  hint,
  error: externalError,
  ...props
}) => {
  const inputRef = useRef();

  const form = useForm({
    defaultValues: { value: initialValue },
  });

  const { handleSubmit, register, watch, setValue, reset } = form;

  const { errors: localValidationErrors } = form.formState;

  const inputName = 'value';

  const submitHandler = handleSubmit(({ value }) => {
    return okButtonAction(value);
  });

  /// the result changed from v5 to v6, not sure if it and how it impacts us.
  const valueWatch = watch(inputName);

  const handleCancelButton = () => {
    reset();
    return cancelButtonAction(valueWatch);
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current[0]) {
      const input = inputRef.current[0];
      input.focus();
      input.select();
    }
    // console.log(inputRef);
  }, []);

  const clear = () => {
    if (valueWatch) {
      setValue(inputName, '', { shouldValidate: true });
    }
  };

  return (
    <Dialog
      title={label}
      okIsDisabled={!valueWatch}
      okButtonAction={submitHandler}
      cancelButtonAction={handleCancelButton}
      hideRulerOnButtonsContainer={true}
      showThirdButton={showClearButton}
      thirdButtonLabel={__('Clear', 'common.clear')}
      thirdButtonIsDisabled={!valueWatch}
      thirdButtonIsDangerous={true}
      thirdButtonAction={clear}
      body={() => (
        <Wrapper>
          <form onSubmit={submitHandler} ref={inputRef}>
            {hint ? <div className="hint">{hint}</div> : null}
            {type === 'textarea' ? (
              <textarea
                name={inputName}
                placeholder={placeholder}
                {...register(inputName, validation)}
                data-test-id="prompt-input-textarea"
              />
            ) : (
              <input
                name={inputName}
                placeholder={placeholder}
                type={type}
                {...register(inputName, validation)}
                data-test-id="prompt-input-value"
              />
            )}
            {localValidationErrors?.value ? (
              <FormError
                error={localValidationErrors?.value}
                maxHeight={false}
              />
            ) : null}
            {externalError ? (
              <FormError error={externalError} maxHeight={false} />
            ) : null}
          </form>
        </Wrapper>
      )}
      {...props}
    />
  );
};
