import React from 'react';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { HSpacer } from 'naan/primitives/spacer';
import { HStack, VStack } from 'naan/primitives/stack';
import { ArrowLeftSmallIcon } from 'naan/icons/arrow-left-icon';
import { Text } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';

const BackButton = styled(RouterLink, {
  textDecoration: 'none',
  marginRight: 'auto',
  textStyle: 'small-heading',
  display: 'flex',
  alignItems: 'center',
  color: '$gray-800',
});

export const ParentScreenLink = observer(
  ({ to, title }: { to: string; title: string }) => {
    return (
      <VStack>
        <HStack css={{ py: '$space$5' }}>
          <BackButton to={to}>
            <ArrowLeftSmallIcon />
            <HSpacer size={2} />
            <Text textStyle="small-heading">{title}</Text>
          </BackButton>
        </HStack>
        <Divider />
      </VStack>
    );
  }
);
