import { useRef, useState } from 'react';

/**
 * useCopyPowers hook
 * Abstracts the functionality to make components that put things in the DOM
 *
 */
export const useCopyPowers = (options: { activeDuration?: number } = {}) => {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>();
  const [active, setActive] = useState(false);
  const { activeDuration = 1000 } = options;

  const copyAction = () => {
    ref.current?.select();
    // @ts-ignore
    document.execCommand('copy');
    setActive(true);
    window.setTimeout(() => {
      setActive(false);
    }, activeDuration);
  };

  return { ref, active, copyAction };
};
