import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'naan/stitches.config';
import { ChevronDownIcon } from 'naan/icons/chevron-down-icon';
import { useSwitch2 } from 'common/hooks/use-switch-2';
import cx from 'classnames';
import { PlayIcon } from 'naan/icons/play-icon';
import { IconButton } from 'naan/primitives/button';
import { HeadphonesIcon } from 'naan/icons/headphones-icon';

/// This is an experimental mechanism to use descriptive css variables
/// so we can reuse most of the player styles and just switch the colors
/// when necessary.
/// This is different from the global theming mechanism which will work just by swapping palettes.
const theme = {
  $$player_backgroundDefault: '$colors$gray-75',
  $$player_backgroundPlaying: '$colors$gray-300',
  $$header_backgroundColor: '$colors$blue-500',
} as const;

type ThemeShape = typeof theme;

const darkTheme: Record<keyof ThemeShape, string> = {
  $$player_backgroundDefault: '$colors$gray-500',
  $$header_backgroundColor: '$colors$gray-800',
  $$player_backgroundPlaying: '$colors$gray-700',
};

// doesn't really do anything, just a TS helper
function themeKey(key: keyof ThemeShape) {
  return key;
}

const Wrapper = styled('section', {
  ...theme,
  position: 'fixed',
  left: 0,
  bottom: 0,
  right: 0,
  top: 0,
  backgroundColor: themeKey('$$player_backgroundDefault'),
  zIndex: 2,
  display: 'grid',
  gridTemplateRows: '1fr auto',

  '&.listen-mode': {
    ...darkTheme,
  },
});

const Header = styled('div', {
  $$backgroundColor: themeKey('$$header_backgroundColor'),

  position: 'absolute',
  top: '0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '56px',
  padding: '0 8px',
  flexShrink: '0',
  background: '$$backgroundColor',
  zIndex: '2',
  transition: 'transform .3s, background .3s',
  '& a': {
    color: '$colors$white',
  },
  '&.playing': {
    transform: 'translateY(-100%)',
    $$backgroundColor: themeKey('$$player_backgroundPlaying'),
  },
});

const Script = styled('div', {
  $$backgroundColor: 'transparent',
  overflowY: 'overlay',
  transition: 'background .3s',
  backgroundColor: '$$backgroundColor',
  '&.playing': {
    $$backgroundColor: themeKey('$$player_backgroundPlaying'),
  },
});

const Controls = styled('div', {
  display: 'flex',
  zIndex: '2',
  flexShrink: '0',
  background: '$colors$gray-25',
  position: 'relative',
  height: 'calc(80px + env(safe-area-inset-bottom))',
});

export const StudyScreen = observer(() => {
  const listenModeSwitch = useSwitch2(false);
  const temporalPlayerSwitch = useSwitch2(false);
  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);
  const storyPath = `./..`;

  return (
    <Wrapper className={cx({ 'listen-mode': listenModeSwitch.value })}>
      <Header className={cx({ playing: temporalPlayerSwitch.value })}>
        <RouterLink to={storyPath}>
          <ChevronDownIcon />
        </RouterLink>
      </Header>
      <Script className={cx({ playing: temporalPlayerSwitch.value })}>
        <br />
        {story?.title}
        <br />
        Lorem
      </Script>
      <Controls>
        <IconButton
          icon={<PlayIcon />}
          onClick={temporalPlayerSwitch.toggle}
        ></IconButton>
        <IconButton
          icon={<HeadphonesIcon />}
          onClick={listenModeSwitch.toggle}
        ></IconButton>
      </Controls>
    </Wrapper>
  );
});
