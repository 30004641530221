import { ModelTreeNode } from 'ts-state-tree/tst-core';

import { env } from 'app/env';

const UNINITIALIZED = 'UNINITIALIZED';

export const apiEnvChoices = [
  'prod',
  'bolero',
  'preprod',
  'staging',
  'devtest',
  'beta',
  'eslbeta',
  'jfedev',
];

const apiHosts: { [index: string]: string } = {
  jfedev: 'https://jfedev.ngrok.io',
  devtest: 'https://service.devtest.jiveworld.com',
  staging: 'https://service.staging.jiveworld.com',
  beta: 'https://service.beta.jiveworld.com',
  eslbeta: 'https://service-esl-beta.jiveworld.com',
  bolero: 'https://service.bolero.jiveworld.com',
  prod: 'https://service.lupa.app',
  preprod: 'https://service.preprod.jiveworld.com',
};

export const apiUrlForEnv = (apiEnv: string): string => {
  return `${apiHosts[apiEnv]}/api/v3/`;
};

export class GlobalConfig extends ModelTreeNode {
  static CLASS_NAME = 'GlobalConfig' as const;

  savedApiEnv: string = UNINITIALIZED;
  modelVersion: number = 0;
  cpuAdjust: number = 0;
  locale: string = 'en'; // ln.systemDefaultLocale;
  appInstallAttribution: object = {}; //TODO types.maybeNull(types.map(types.string)),

  static create(snapshot: any = {}): GlobalConfig {
    return super.create(GlobalConfig, snapshot) as GlobalConfig;
  }

  /**
   * it returns the current apiEnv(ironment) according to these rules
   * - if `forcedApiEnv` is set in the global SimpleEnv instance, that is what is returned
   * - if there's a savedApiEnv, that value is returned
   * - Finally, we return `defaultApiEnv` from the SimpleEnv instance.
   *
   * @returns {string} current ApiEnv
   *
   */
  get apiEnv(): string {
    const forcedApiEnv = env.get('forcedApiEnv', null);

    if (forcedApiEnv) {
      return forcedApiEnv;
    }

    const defaultApiEnv = env.get('defaultApiEnv');

    if (!this.savedApiEnv || this.savedApiEnv === UNINITIALIZED) {
      return defaultApiEnv;
    }
    return this.savedApiEnv;
  }

  /**
   * returns the correct url for the API, depending on the current apiEnv
   *
   * @returns {string} apiUrl http address
   */
  get apiUrl(): string {
    const result = apiUrlForEnv(this.apiEnv);
    if (result) {
      return result;
    } else {
      throw Error(`apiUrl not found for env: ${this.apiEnv}`);
    }
  }

  /**
   * returns the valid choices for apiEnv
   *
   * @returns {string[]}
   */
  get availableChoices(): string[] {
    return apiEnvChoices;
  }
}
