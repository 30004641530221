import * as React from 'react';
import { FilterDisclosureButton } from 'components/filters';
import { useRadioReturnType } from 'common/hooks/use-radio';
import { HSpacer } from 'naan/primitives/spacer';
import { SearchBox } from './search-box';
import { TFilterTabsIds } from './filters-ui';
import { useFiltering } from 'common/filtering/use-filtering';
import { ActiveFiltersBar } from './active-filters-bar';
import { Responsive } from 'naan/primitives/responsive';
import { styled } from 'naan/stitches.config';
import { scrollContainerToTop } from 'lib/scroll-utils';

type FilterAndSearchProps = {
  radio: useRadioReturnType<TFilterTabsIds>;
};

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  // borderBottom: '1px solid $gray-100',
  // position: 'sticky',
  // top: '0',
  // left: '16px',
  // right: '16px',
  background: '$white',
  // zIndex: '999',
  // '@large': {
  //   position: 'relative',
  //   top: '0',
  //   left: '0',
  //   right: '0',
  //   borderBottom: 'none',
  // },
});

export const FilterAndSearchSection = ({ radio }: FilterAndSearchProps) => {
  const { resetAllFilters, replaceFilter, filter } = useFiltering();

  // const clearSearch = React.useCallback(() => {
  //   setSearch('');
  // }, [setSearch]);

  const toggleFilter = React.useCallback(
    tabName => (e: any) => {
      // console.log(e.target);
      const toggle = radio.toggler(tabName);
      scrollContainerToTop(e.target);
      toggle();
    },
    [radio]
  );

  const searchQuery = React.useMemo(() => {
    if (filter?.queryType === 'search') {
      return filter.value[0];
    }
    return null;
  }, [filter]);

  return (
    <Wrapper>
      <ActiveFiltersBar />
      <FilterDisclosureButton
        selected={radio.isCurrent('country')}
        onClick={toggleFilter('country') as any}
        label="Country"
      />
      <HSpacer size={3} />
      <FilterDisclosureButton
        selected={radio.isCurrent('topic')}
        onClick={toggleFilter('topic') as any}
        label="Topic"
      />
      <HSpacer size={3} />
      <FilterDisclosureButton
        selected={radio.isCurrent('theme')}
        onClick={toggleFilter('theme') as any}
        label="AP/IB theme"
      />
      <Responsive
        renderDefault={() => <HSpacer expand />}
        renderMediumAndUp={() => <HSpacer size={3} />}
      />
      <SearchBox
        initialQuery={searchQuery}
        onChangeQuery={query => {
          if (query) {
            replaceFilter('search', query);
          }
        }}
        onClearQuery={resetAllFilters}
        onEnterSearchMode={radio.resetCurrent}
        onExitSearchMode={resetAllFilters}
      />
    </Wrapper>
  );
};
