import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import { Flash } from 'components/ds/flash';
import { Gap } from 'components/ds/common/spacers';

import __ from 'core/lib/localization';

type TLegacyFlashMessage = {
  messageKey: string;
  messageParams: any;
};

const getFlashMessage = (flash: TLegacyFlashMessage) => {
  if (!flash) {
    return null;
  }

  const { messageKey, messageParams } = flash;

  switch (messageKey) {
    case 'joined-classroom':
      return __(
        'You have joined "%{classroomLabel}"',
        'account.flash.joinedClassroom',
        messageParams
      );
    case 'api.classrooms.dropped':
      return __('You have left the class', 'account.flash.leftClassroom');
    default:
      break;
  }

  return null;
};

export const AccountFlash = observer(() => {
  const [message, setMessage] = useState<string | null>(null);

  const store = useRootStore();

  useEffect(() => {
    setMessage(getFlashMessage(store.flash as any));
  }, [store.flash]);

  if (!message) {
    return null;
  }

  return (
    <>
      <Flash message={message} onDismiss={store.resetFlash} />
      <Gap size={3} />
    </>
  );
});
