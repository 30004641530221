import * as React from 'react';
import { observer } from 'mobx-react';
import { TableRow, TableLabelCell, TableValueCell } from './table';
import { BlueLink as EditLink } from 'components/ui/buttons/link-button';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import __ from 'core/lib/localization';

export const TableFieldWrapper = observer(
  ({
    label,
    children,
    value,
    testId,
    editing,
    onStartEditing,
  }: {
    label: React.ReactNode;
    children: React.ReactNode;
    value: React.ReactNode;
    testId?: string;
    editing: boolean;
    onStartEditing: () => void;
  }) => {
    // const [editing, setEditing] = useState(false);

    return (
      <TableRow data-test-id={testId}>
        <TableLabelCell>{label}</TableLabelCell>
        <TableValueCell>
          {editing ? (
            children
          ) : (
            <HStack justify={'space'}>
              <VStack
                css={{
                  marginRight: '16px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </VStack>
              <Button
                onClick={onStartEditing}
                label={__('Edit', 'common.edit')}
                size={'small'}
                presentation={'grayLight'}
                css={{
                  margin: '-4px 0',
                }}
              />
              {/* <FormError error={serverError} /> */}
            </HStack>
          )}
        </TableValueCell>
      </TableRow>
    );
  }
);

export const TableFieldButtonSet = observer(
  ({
    updateLabel,
    cancelAction,
  }: {
    updateLabel: string;
    cancelAction: () => void;
  }) => {
    return (
      <VStack>
        <VSpacer size={2} />
        <HStack>
          <Button
            presentation="teal"
            data-test-id="submit-button"
            label={updateLabel}
          />

          <Button
            type={'button'}
            presentation={'tealTransparent'}
            css={{ $$buttonTextColor: '#999' }}
            onClick={cancelAction}
            data-test-id="cancel-button"
            label={__('Cancel', 'common.cancel')}
          />
        </HStack>
      </VStack>
    );
  }
);

// type TableFieldProps = Omit<
//   React.ComponentProps<typeof EditInput>,
//   'formContext' | 'validationRules'
// > & {
//   isPassword?: boolean;
//   validation: any;
//   label: React.ReactNode;
//   value?: any;
//   updateLabel: string;
//   footNote?: string | null;
//   name: string;
//   onEdit: (key: string, callback: () => void) => void;
//   note?: React.ReactNode;
//   error: any;
// };

// // const TableFieldForm = observer(
// //   ({
// //     isPassword = false,
// //     validation,
// //     label,
// //     value,
// //     updateLabel = 'update',
// //     footNote = null,
// //     name = 'value',
// //     onEdit,
// //     note,
// //     error: serverError,
// //     editing,
// //     stopEditing,
// //     ...fieldProps
// //   }: TableFieldProps & {
// //     editing: boolean;
// //     stopEditing: () => void;
// //   }) => {
// //     const store = useRootStore();

// //     const form = useForm({
// //       defaultValues: { [name]: value },
// //     });

// //     const { handleSubmit, formState } = form;
// //     const { errors: localErrors } = formState;

// //     const submitHandler = handleSubmit(formValues => {
// //       return onEdit(formValues[name], stopEditing);
// //     });

// //     // React.useEffect(() => {
// //     //   if (serverError) {
// //     //     startEditing();
// //     //   }
// //     // }, [serverError]);

// //     const cancelHandler = React.useCallback(() => {
// //       store.resetValidationError();
// //       stopEditing();
// //     }, [stopEditing, store]);

// //     return (
// //       <>
// //         <form onSubmit={submitHandler}>
// //           <EditInput
// //             {...fieldProps}
// //             isPassword={isPassword}
// //             name={name}
// //             formContext={form}
// //             validationRules={validation}
// //           />
// //           {localErrors[name] ? (
// //             <FormError error={localErrors[name]} data-test-id="error-message" />
// //           ) : (
// //             <FormError error={serverError} data-test-id="error-message" />
// //           )}
// //           <TableFieldButtonSet
// //             updateLabel={updateLabel}
// //             cancelAction={cancelHandler}
// //           />
// //         </form>
// //         {footNote ? (
// //           <>
// //             <VSpacer size={5} />
// //             <Text textStyle="small-text" color="textSecondary">
// //               {footNote}
// //             </Text>
// //           </>
// //         ) : null}
// //         {!editing ? note : null}
// //       </>
// //     );
// //   }
// // );

// // // export const TableFieldLegacy = observer(
// // //   ({
//     isPassword = false,
//     validation,
//     label,
//     value,
//     updateLabel = 'update',
//     footNote = null,
//     name = 'value',
//     onEdit,
//     note,
//     error: serverError,
//     ...fieldProps
//   }: TableFieldProps) => {
//     const displayValue = isPassword ? '●●●●●●●●' : value;
//     const editing = useSwitch2(false);

//     return (
//       <TableFieldWrapper
//         label={label}
//         value={displayValue}
//         editing={editing.value}
//         onStartEditing={editing.on}
//       >
//         <TableFieldForm
//           isPassword={isPassword}
//           validation={validation}
//           label={label}
//           value={value}
//           updateLabel={updateLabel}
//           footNote={footNote}
//           name={name}
//           onEdit={onEdit}
//           note={note}
//           error={serverError}
//           editing={editing.value}
//           stopEditing={editing.off}
//           {...fieldProps}
//         />
//       </TableFieldWrapper>
//     );
//   }
// );
