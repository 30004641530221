import React from 'react';
import ReactDOM from 'react-dom';

export class Portal extends React.Component {
  element = null;

  constructor(props) {
    super(props);
    this.element = document.createElement('DIV');
    document.body.appendChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}
