import React from 'react';
import { useMediaQuery } from 'react-responsive';
/**
 * WIP. Not actually responsive for the moment.
 */
export const responsiveComponentFactory = (
  LargeComponent,
  SmallComponent,
  className
) => props => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (isMobile) {
    return <SmallComponent className={`${className}  d-small`} {...props} />;
  } else {
    return <LargeComponent className={`${className}  d-large`} {...props} />;
  }
};

export const TableWrapper = responsiveComponentFactory('table', 'div', 'table');
export const Row = responsiveComponentFactory('tr', 'div', 'tr');
export const Cell = responsiveComponentFactory('td', 'div', 'td');
export const HeaderCell = responsiveComponentFactory('th', 'div', 'th');
export const HeaderContainer = responsiveComponentFactory(
  'thead',
  'div',
  'thead'
);
export const BodyContainer = responsiveComponentFactory(
  'tbody',
  'div',
  'tbody'
);
