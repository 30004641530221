import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';

import { objectFromUrlQuery } from 'common/object-from-url-query';
import { useRootStore } from '../../truth';
import { getMessages } from './account-message-target/get-account-messages';
import { SupportLink } from './account-message-target/support-link';
import { AppFactory } from 'app/app-factory';
import { get } from 'lodash';

import __ from 'core/lib/localization';
import { devMode } from 'app/platform';

const cleanAddressBar = () => {
  var yourCurrentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', yourCurrentUrl);
};

type MessageKey = keyof ReturnType<typeof getMessages>;

export function useQueryMessageDispatcher() {
  const store = useRootStore();

  React.useEffect(() => {
    type MessageQueryType = {
      messageKey: MessageKey;
      checkout: string;
    };

    const query = objectFromUrlQuery<MessageQueryType>();
    if (!devMode) {
      cleanAddressBar();
    }

    if (query.checkout === 'error') {
      AppFactory.dialogPresenter.present(onDismiss => (
        <Dialog.Container onDismiss={onDismiss} open={true}>
          <Dialog.Heading type="error">
            {__('Payment unsuccessful', 'account.paymentUnsuccessful')}
          </Dialog.Heading>
          <Dialog.Body>
            {/* FIXME: this message is unlocalizable as is. */}
            Your credit card payment was not processed. Please{' '}
            <SupportLink>let us know</SupportLink> if you encountered an
            unexpected error.
          </Dialog.Body>
          <Dialog.ButtonsContainer>
            <Dialog.Button
              presentation="secondary"
              label={__('Close', 'account.close')}
              onClick={onDismiss}
              fullWidth
            />
          </Dialog.ButtonsContainer>
        </Dialog.Container>
      ));
    } else {
      const { accountData } = store.userManager;
      if (accountData) {
        const messageKey = query.messageKey;
        const messages = getMessages(accountData);
        let message = get(messages, messageKey);

        if (!message) {
          if (accountData?.paymentData?.autoRenewFailed) {
            message = get(messages, 'auto-renew-failure-with-access');
          }
        }
        // typeof messageKey === 'string' ? messages[messageKey] : null;

        if (message) {
          AppFactory.dialogPresenter.present(onDismiss => (
            <Dialog.Container onDismiss={onDismiss} open={true}>
              <Dialog.Heading type={message.type}>
                {message.title}
              </Dialog.Heading>
              <Dialog.Body>{message.body}</Dialog.Body>
              <Dialog.ButtonsContainer>
                {message.type === 'success' ? (
                  <Dialog.Button
                    presentation="teal"
                    label={__('Done', 'common.done')}
                    onClick={onDismiss}
                    fullWidth
                  />
                ) : (
                  <Dialog.Button
                    presentation="secondary"
                    label={__('Close', 'account.close')}
                    onClick={onDismiss}
                    fullWidth
                  />
                )}
              </Dialog.ButtonsContainer>
            </Dialog.Container>
          ));
        }

        // } else {
        //   if (accountData?.paymentData?.autoRenewFailed) {
        //     MessageService.showMessage(
        //       messages['auto-renew-failure-with-access']
        //     );
        //   }
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.userManager.accountData]);
}
