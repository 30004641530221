import React from 'react';
import styled from 'styled-components';
import { PlainMarkdown } from 'naan/primitives/text';
import __ from 'core/lib/localization';
import { Cast, UnitCatalogData } from 'core/models/catalog';

const Wrapper = styled.div`
  h3 {
    font: var(--small-heading-font);
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray100);
    margin-bottom: 12px;
  }

  dl {
    margin-bottom: 32px;
  }

  dt,
  dd {
    font: var(--body-font);
  }
  dd {
    margin-bottom: 16px;
    color: var(--text-secondary);
    /* WHY? because Markdown creates extra paragraph elements and we have zero control over it */
    * {
      color: inherit;
    }
  }

  @media print, screen and (min-width: 700px) {
    dl {
      column-count: 2;
      column-gap: 32px;
    }
    .credit {
      break-inside: avoid-column;
    }
  }
  @media print, screen and (min-width: 900px) {
    dl {
      column-count: 3;
    }
  }
`;

export const StoryCast = ({ story }: { story: UnitCatalogData }) => {
  return (
    <Wrapper>
      <h3>{__('Voices', 'story.voices')}</h3>
      <dl>
        {story.cast.map((person: Cast, i: number) => (
          <div className="credit" key={`person-${i}`}>
            <dt>
              {person.fullName}
              {person.accent ? ` (${person.accent})` : null}
            </dt>
            <dd>
              <PlainMarkdown source={person.description} />
            </dd>
          </div>
        ))}
      </dl>
    </Wrapper>
  );
};
