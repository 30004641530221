import React from 'react';

export const IconTriangleAlert = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7202 3.09441L2.39479 16.7178C1.78397 17.7173 2.50332 19 3.67472 19H20.3255C21.4969 19 22.2163 17.7173 21.6055 16.7178L13.2801 3.09441C12.6952 2.13731 11.3051 2.13731 10.7202 3.09441ZM11.0001 7.00001H13.0001V13H11.0001V7.00001ZM12.0001 17C12.5524 17 13.0001 16.5523 13.0001 16C13.0001 15.4477 12.5524 15 12.0001 15C11.4478 15 11.0001 15.4477 11.0001 16C11.0001 16.5523 11.4478 17 12.0001 17Z"
      fill={color}
    />
  </svg>
);
