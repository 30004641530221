import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import {
  getFontName,
  includeTextStyles,
  getColor,
} from 'legacylib/theme/utils';

const Field = styled.div`
  position: relative;

  .field-label {
    ${includeTextStyles('body')};
    margin-bottom: 8px;
    display: block;
    @media screen and (min-width: 550px) {
      position: absolute;
      top: 13px;
      left: 16px;
      color: #999;
      width: 128px;
    }
  }

  .field-input {
    --border-color: ${getColor('gray100')};
    display: block;
    ${includeTextStyles('body')}
    background: ${getColor('gray50')};
    width: 100%;
    padding: 12px 16px;
    margin-bottom: 24px;
    border-radius: 8px;

    &.is-invalid {
      background: ${getColor('red50')};
      @media screen and (max-width: 550px) {
        /* This is to visually compensate for the error message extra vertical-spacing*/
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 550px) {
      margin-bottom: 8px;
      padding-left: 144px;
    }
  }

  .password-switch {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    cursor: pointer;
    @media screen and (max-width: 549px) {
      top: 32px;
    }
    &:focus {
      outline: none;
    }
  }

  .field-error {
    font-family: ${getFontName('body')};
    font-size: 2.125rem;
    line-height: 3rem;
    font-weight: 400;
    color: #e55c58;
    padding-left: 3.25rem;
    transition: all 0.3s;
    position: relative;
    margin: 8px 0 12px 0;
    &.max-height {
      max-height: 9rem;
    }
    &.no-max-height {
      max-height: auto;
    }

    .error-icon {
      position: absolute;
      left: 0;
      padding: 0;
    }
  }
`;

// random ids for inputs
const useUniqueId = () => {
  return React.useRef(Math.floor(Math.random() * 1e3).toString(16)).current;
};

export const BaseInput = ({
  label,
  _ref,
  type = 'text',
  accessory,
  formContext,
  validationRules,
  name,
  isInvalid,
  ...inputProps
}) => {
  // console.log('CTX', formContext);
  const id = useUniqueId();
  const { register } = formContext;
  return (
    <Field>
      <label className="field-label" htmlFor={id}>
        {label}
      </label>
      <input
        className={cx('field-input', { 'is-invalid': isInvalid })}
        type={type}
        {...register(name, validationRules)}
        {...inputProps}
        id={id}
      />
      {accessory}
    </Field>
  );
};
