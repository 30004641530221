import * as React from 'react';

export function FacebookSmallIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5435 8.64033V7.29697C13.5435 6.64226 13.9777 6.48985 14.2825 6.48985C14.5871 6.48985 16.1576 6.48985 16.1576 6.48985V3.61124L13.5752 3.6001C10.709 3.6001 10.0576 5.74754 10.0576 7.12178V8.64033H8.3999V12H10.0714C10.0714 15.808 10.0714 20.4001 10.0714 20.4001H13.4143C13.4143 20.4001 13.4143 15.7617 13.4143 12H15.8954L16.1999 8.64033H13.5435Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
