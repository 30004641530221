import * as React from 'react';
import cx from 'classnames';
import {
  LeftButtonIcon,
  RightButtonIcon,
  ButtonLabel,
  ButtonStyled,
} from './button.styles';

type ButtonStyledProps = React.ComponentProps<typeof ButtonStyled>;

type ButtonProps = ButtonStyledProps & {
  label: string;
  tabIndex?: number;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  testId?: string;
};

export const Button = React.forwardRef(
  (
    {
      label,
      size,
      // presentation,
      disabled = false,
      tabIndex = 0,
      leftIcon,
      rightIcon,
      testId,
      ...restOfProps
    }: ButtonProps,
    ref
  ) => {
    // const hasIcon: boolean = !!leftIcon || !!rightIcon;
    return (
      <ButtonStyled
        size={size}
        // presentation={presentation}
        tabIndex={tabIndex}
        aria-label={label}
        data-testid={testId}
        ref={ref as any}
        {...restOfProps}
        disabled={disabled}
        className={cx({ disabled })}
      >
        {leftIcon ? (
          <LeftButtonIcon size={size}>{leftIcon}</LeftButtonIcon>
        ) : null}
        <ButtonLabel size={size}>{label}</ButtonLabel>
        {rightIcon ? (
          <RightButtonIcon size={size}>{rightIcon}</RightButtonIcon>
        ) : null}
      </ButtonStyled>
    );
  }
);
