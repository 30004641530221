import React from 'react';
import { observer } from 'mobx-react';
// import { useRootStore } from '../../../truth';
import { TableForm } from './table-form';
import { MediumHeading } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { CloseAccountLink } from '../close-account-link';
import { SectionBreak } from 'components/ds/common/spacers';
import { NameForm } from './name-form';
import { EmailForm } from './email-form';
import { PasswordForm } from './password-form';
import __ from 'core/lib/localization';
import { DevToolsLink } from 'components/global-sidebar/dev-links';

export const AccountForm = observer(() => {
  return (
    <>
      <MediumHeading>{__('My details', 'accountForm.myDetails')}</MediumHeading>
      <VSpacer size="4" />
      <TableForm>
        <NameForm />
        <EmailForm />
        <PasswordForm />
      </TableForm>
      <DevToolsLink />
      <CloseAccountLink />
      <SectionBreak />
    </>
  );
});
