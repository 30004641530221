import { HandleableError } from './handleable-error';
import { alertLevels } from './alert-levels';
import { NativeError } from './native-error';
import { StoreKitError } from './storekit-error';

/**
 * utility function to create errors that can be properly handled by the global error handler
 *
 * options = {
 *   defer = if true, wraps exception in a RAF
 *   report = if true, tells the global error handler to report to sentry
 *   level = error level
 *   alert = if set to true, it will show a toast
 * }
 *
 */
type FailOptions = {
  /** defer = if true, wraps exception in a RAF */
  defer?: boolean;
  /** report = if true, tells the global error handler to report to sentry */
  report?: boolean;
  /** level = error level */
  level?: keyof typeof alertLevels;
  /** alert = if set to true, it will show a toast */
  alert?: boolean;
};

export const fail = (error: Error | NativeError, options: FailOptions = {}) => {
  const { defer = true, ...rest } = options;

  const throwError = () => {
    if (error instanceof NativeError) {
      if (error.domain && error.domain === 'SKErrorDomain') {
        throw new StoreKitError(error);
      }
    }

    if (error instanceof Error) {
      throw error;
    }

    // log.info(`errors.fail - wrapping base error: ${error}`);
    // this wraps the error received into an error that can be handled by the global error handler
    throw new HandleableError(error, { ...rest });
  };

  // todo: think about node usage/linting of this code
  if (defer) {
    // WHY am I wrapping this? in a RAF?
    // Glad you asked! It's because if we throw inside a component
    // and we interrupt the rendering of such component, then React
    // intercepts the error and it never gets to our global error handler
    // which is what we want most of the time.
    (window as any).requestAnimationFrame(throwError);
  } else {
    throwError();
  }
};
