import * as React from 'react';
import * as Layout from './volume-page-layout';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { AudioPlayer } from './volume-audio-player';
import { Story } from 'core/models/story-manager';

type Props = {
  story: Story;
  audioUrl?: string;
  tagLinks?: React.ReactNode;
};

export const HeadlessVolumePage = ({ story, tagLinks, audioUrl }: Props) => {
  if (story === null) {
    return null;
  }

  return (
    <>
      <Layout.Content>
        <Text textStyle="small-heading" color="textPrimary">
          {story.tagline}
        </Text>
        <VSpacer size={'2'} />
        <Text textStyle="body" color="textSecondary">
          {story.description}
        </Text>
        <VSpacer size={'5'} />
        {tagLinks}
        <AudioPlayer audioUrl={audioUrl} />{' '}
      </Layout.Content>
    </>
  );
};
