import React from 'react';
import { IconX } from 'components/ds/icons';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  ${includeTextStyles('body')};

  border-radius: 8px;
  display: flex;

  span {
    padding: 16px;
  }

  button.close-btn {
    margin: 0;
    padding: 16px;
    color: inherit;
    border: none;
    background: none;
    cursor: pointer;
    margin-left: auto;

    &:active {
      outline: none;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &.type-message {
    background: ${getColor('green500')};
    color: ${getColor('white')};
  }
`;

export const Flash = ({ message, type = 'message', onDismiss = () => {} }) => {
  return (
    <Wrapper className={`type-${type}`}>
      <span data-test-id="flash-message">{message}</span>
      <button className="close-btn" onClick={onDismiss}>
        <IconX />
      </button>
    </Wrapper>
  );
};
