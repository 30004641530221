import * as React from 'react';
import { useRootStore } from 'mst';
import { TMessage } from 'common/notifications/message-service';
import { useNavigate } from 'react-router-dom';
import { classroomsPath } from './nav/path-helpers';
import __ from 'core/lib/localization';

/**
 * This is a temporary hack to create the ONLY global message we currently have.
 */

export function useGlobalMessages() {
  const store = useRootStore();
  const navigate = useNavigate();

  const { showClassroomLicenseNag } = store?.userManager?.accountData ?? {};

  const currentMessage = React.useMemo(() => {
    if (!showClassroomLicenseNag) {
      return undefined;
    }
    return {
      type: 'error',
      message: __(
        'A classroom has exceeded its license quota.',
        'common.aClassroomHasExceeded'
      ),
      action: {
        label: __('My classes', 'common.myClasses'),
        callback: () => navigate(classroomsPath()),
      },
    } as TMessage;
  }, [navigate, showClassroomLicenseNag]);

  return currentMessage;
}
