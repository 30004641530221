import * as React from 'react';

export function CheckmarkCircleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.8284 8.67738L15.5034 7.74963L11.0193 14.1536L7.92775 11.9889L7 13.3138L11.4165 16.4063L16.8284 8.67738Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
