import React from 'react';
import { FullScreenLoader } from 'components/ds/modals';
import { DisplayStatus } from '../components/debug/display-status';
import { AppRoot } from 'app/app-root';

/**
 * Shows a loading screen with a Footer and a Dummy nav bar while MST is being initialized
 */
export const LoadingRoot = ({ root }: { root: AppRoot }) => {
  return (
    <>
      <FullScreenLoader />
      <DisplayStatus status={root.status} />
    </>
  );
};
