import React from 'react';
import styled from 'styled-components';
import { WordGroupData } from 'core/models/catalog/bogota-types';
// import { useChapterContext } from './chapter-context';

const Wrapper = styled.span`
  text-decoration: none;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 bottom;
  /* cursor: pointer; */

  hyphens: auto; /** Polemic? I think it looks better.  */

  &.vocab {
    background-image: linear-gradient(var(--green) 50%, rgba(0, 0, 0, 0) 50%);
  }

  &.sic {
    background-image: linear-gradient(
      var(--light-orange) 50%,
      rgba(0, 0, 0, 0) 50%
    );
  }
`;

// const goToVocabsPanel = (group, chapter) => () => {
//   const id = `${chapter.slug}-${group.groupType}`;
//   const element = document.getElementById(id);
//   if (element) {
//     element.scrollIntoView({ behavior: 'smooth', block: 'center' });
//   }
// };

const specialGroups = ['vocab', 'sic'];

export const WordGroup = ({ root: group }: { root: WordGroupData }) => {
  // const chapter = useChapterContext();

  const { elements } = group;
  // we concatenate all the words into a single space separated string of words.
  let words = elements
    .filter((element: { entityType: string }) => element.entityType === 'word')
    .reduce((p: any, w: { text: any }) => {
      return p + w.text + ' ';
    }, '')
    // remove `*` and `+` characters
    .replace(/[*+]/g, '');

  // don't surround
  if (specialGroups.includes(group.groupType)) {
    // remove the trailing space so we can add it after the word group
    // otherwise, the underlining looks weird.
    words = words.trim();

    // same with punctuation. It looks weird when the WG underline extends into a trailing punctuation marks
    // we don't want weird, so we move it outside the word group wrapper.
    let punctuation = '';
    const hasTrailingPunctuation = words.match(/[.,?]$/); //, words);
    if (hasTrailingPunctuation) {
      punctuation = hasTrailingPunctuation[0];
      words = words.substr(0, words.length - 1);
    }

    return (
      <>
        {' '}
        <Wrapper className={`${group.groupType}`}>{words}</Wrapper>
        {punctuation}{' '}
      </>
    );
  }

  return words;
};
