import * as React from 'react';
// import { MarkdownContainer } from 'components/ds/common/markdown-container';
import { styled } from 'naan/stitches.config';
import { NavLink } from 'react-router-dom';

const Wrapper = styled('div', {
  textStyle: 'body',
  color: '$textSecondary',
  textAlign: 'center',

  '& .footnote-heading': {
    textStyle: 'body-bold',
    display: 'block',
  },

  '& a': {
    color: '$teal-500',
    cursor: 'pointer',
  },
});

export const AuthFootnote = ({
  heading,
  body,
}: {
  heading: React.ReactNode;
  body: string;
}) => {
  return (
    <Wrapper>
      <span className="footnote-heading">{heading}</span>
      {/* <MarkdownContainer source={body} /> */}
      {body}
    </Wrapper>
  );
};

// export const AuthFootnote = ({
//   heading,
//   plainText,
//   linkText,
//   linkPath,
// }: {
//   heading: React.ReactNode;
//   plainText: string;
// }) => {
//   return (
//     <Wrapper>
//       <span className="footnote-heading">{heading}</span>
//       {plainText} / <NavLink to={linkPath}>{linkText}</NavLink>
//     </Wrapper>
//   );
// };
