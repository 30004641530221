import React from 'react';
import { ValidationError } from 'core/lib/errors';

/// This "Server" in the name is just so we know that
/// this is not for client-side validation, which should
/// will be done using a client-side form library.
export function useServerValidation() {
  const [errors, setErrors] = React.useState<
    Record<string, { message: string }>
  >({});

  const setValidationError = (error: ValidationError) => {
    setErrors({ [error.key]: { message: error.message } });
  };

  const clearValidationErrors = React.useCallback(() => {
    setErrors({});
  }, [setErrors]);

  const handleValidationErrors = (error: any) => {
    if (error instanceof ValidationError) {
      setValidationError(error);
      return;
    }
    throw error;
  };
  return {
    errors,
    handleValidationErrors,
    clearValidationErrors,
  };
}
