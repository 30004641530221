// FIXME: this are wither obsolete or should be relocated somewhere else.
import { styled } from 'naan/stitches.config';

export const MiddleColumn = styled('section', {
  gridColumn: '1/-1',
  '@large': {
    gridColumn: '3/-3',
  },
});

export const FullColumn = styled('section', {
  gridColumn: '1/-1',
  '@large': {
    gridColumn: '1/-1',
  },
});

export const TableLayoutWrapper = styled('div', {
  gridColumn: '1/-1',
  '@medium': {
    gridColumn: '1/-4',
  },
});
