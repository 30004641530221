import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../truth';
import { TrialNotDownloaded } from './trial-not-downloaded';
import { TrialDownloaded } from './trial-downloaded';
import { ExpiringSoon } from './expiring-soon';

export const AccountCta = observer(() => {
  const store = useRootStore();
  const accountData = store.userManager?.accountData;
  const isTrial = accountData.membershipState === 'trial';

  if (isTrial) {
    if (accountData.promoteDownload) {
      return <TrialNotDownloaded accountData={accountData} />;
    } else {
      return <TrialDownloaded accountData={accountData} />;
    }
  } else {
    if (accountData?.promoteEnrollment) {
      return <ExpiringSoon accountData={accountData} />;
    } else {
      return null;
    }
  }
});
