import { AppFactory } from 'app/app-factory';

export const useWelcomeMessage = () => {
  const { userManager } = AppFactory.root;
  const {
    accountData,
    newsletterPromptNeeded,
    clearClassroomPortalWelcome: onClose,
  } = userManager;

  // never show the welcome message until after newsletter prompt dismissed
  const show =
    !!accountData.classroomPortalWelcomePending && !newsletterPromptNeeded;

  return {
    show,
    onClose,
  };
};
