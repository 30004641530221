import React from 'react';
export const IconLicence = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 17L3 7C4.10457 7 5 6.10457 5 5L19 5C19 6.10457 19.8954 7 21 7V17C19.8954 17 19 17.8954 19 19H5C5 17.8954 4.10457 17 3 17ZM2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM16 8H8V10H16V8ZM7 13H17V14H7V13ZM17 15H7V16H17V15Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
