import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { getColor } from 'legacylib/theme/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${getColor('gray800')};
  span {
    color: ${getColor('textSecondary')};
  }

  @media screen and (max-width: 549px) {
    margin-bottom: 8px;
  }
`;

export const StudentTimeStudiedCell = ({
  allTimeStudiedHourMin = null,
}: {
  allTimeStudiedHourMin: string | null;
}) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return <>{allTimeStudiedHourMin}</>;
  }

  return (
    <Wrapper>
      {allTimeStudiedHourMin} <span>studied</span>
    </Wrapper>
  );
};
