import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { NavLink } from 'react-router-dom';
import { ExternalLinkIcon } from 'naan/icons/external-link-icon';

const NavItemStyled = styled('a', {
  $$itemColor: '$colors$textSecondary',
  color: '$$itemColor',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '& > .icon': {
    marginRight: '$space$3',
    lineHeight: 0,
  },
  '& > .external-icon': {
    marginLeft: '$space$1',
    lineHeight: 0,
  },
  '&.active': {
    $$itemColor: '$colors$teal-500',
  },
  variants: {
    size: {
      large: {
        py: '$space$3',
        textStyle: 'small-heading',
      },
      small: {
        py: '$space$2',
        textStyle: 'small-text',
      },
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

type NavItemStyledProps = React.ComponentProps<typeof NavItemStyled>;

type BaseNavItemProps = NavItemStyledProps & {
  icon: React.ReactNode;
  label: React.ReactNode;
  testId?: string;
};

type NavItemProps = NavItemStyledProps &
  BaseNavItemProps & {
    to: string;
  };

export const NavItem = ({ icon, label, to, size }: NavItemProps) => {
  return (
    <NavItemStyled as={NavLink} to={to} size={size}>
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
    </NavItemStyled>
  );
};

type NavItemExternalProps = NavItemStyledProps & BaseNavItemProps;

export const NavItemExternal = ({
  icon,
  label,
  href,
  size,
}: NavItemExternalProps) => {
  return (
    <NavItemStyled href={href} size={size} target="_blank">
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
      <span className="external-icon">
        <ExternalLinkIcon width={12} height={12} />
      </span>
    </NavItemStyled>
  );
};

export const NavItemAction = ({
  icon,
  label,
  onClick,
  size,
  testId,
}: NavItemExternalProps) => {
  return (
    <NavItemStyled
      href={'#'}
      size={size}
      onClick={onClick}
      data-test-id={testId} // this isn't currently honored
    >
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
    </NavItemStyled>
  );
};
