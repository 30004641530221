/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { IconArrowLeft } from 'components/ds/icons';
import { styled } from 'naan/stitches.config';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { AlertTriangleIcon } from 'naan/icons/alert-triangle-icon';
import { PageHeading } from 'components/page-heading';
import { HStack, VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { Button } from 'naan/primitives/button';
import __ from 'core/lib/localization';
import { LargeGap } from 'components/ds/common/spacers';

const Wrapper = styled('section', {
  gridColumn: '1/-1',
  '@large': {
    gridColumn: '3/-3',
  },

  '& .close-title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    svg: {
      color: '$red-500',
      marginTop: '3px',
    },
  },
});

const CloseAccountConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Heading>
        {__('Are you sure?', 'common.areYouSure')}
      </Dialog.Heading>
      <Dialog.Body>
        <VStack>
          <VSpacer size={3} />
          <Text>
            {__(
              'Closing your account cannot be undone',
              'common.closingYourAccountCannot'
            )}
          </Text>
          <VSpacer size={1} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer direction={'column'}>
        <Dialog.Button
          presentation={'cancel'}
          label={__('Keep account', 'common.noKeepIt')}
          data-test-id="cancelButton"
          onClick={onDismiss}
        />
        <Dialog.Button
          presentation={'destructive'}
          label={__('Yes, close my account', 'common.yesCloseMyAccount')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const CloseAccountScreen = () => {
  const { userManager } = AppFactory.root;
  const { accountData } = userManager ?? {};
  const navigate = useNavigate();

  const handleCloseAccount = () => {
    AppFactory.dialogPresenter.present(onDismiss => {
      return (
        <CloseAccountConfirmationDialog
          onDismiss={onDismiss}
          okAction={() => {
            userManager.closeAccount().then(() => {
              navigate('/account-closed');
            });
          }}
        />
      );
    });
  };

  return (
    <ContentGridInnerContainer>
      <ContentGrid>
        <Wrapper>
          <PageHeading
            showBackArrow={true}
            backAction={() => navigate('/account')}
            backTitle={__('My Account', 'account.myAccount')}
            size="large"
          />
          <HStack>
            {/* <InlineBox css={{ color: '$red-400' }}>
              <AlertTriangleIcon width={36} height={36} />
            </InlineBox> */}
            <Text textStyle="medium-heading">
              {__('Close Account', 'account.closeAccount')}
            </Text>
          </HStack>
          <VSpacer size={2} />
          <Text>
            if you close your account you will no longer be able to sign in with{' '}
            {accountData.email} and you will not receive any further account
            related emails.
          </Text>
          <VSpacer size={14} />
          <HStack justify={'space'}>
            <Button
              label={__('Go back', 'common.goBack')}
              presentation={'secondary'}
              onClick={() => navigate('/account')}
              leftIcon={<IconArrowLeft />}
            />
            <Button
              label={__('Close account', 'account.closeAccount')}
              presentation={'destructive'}
              onClick={handleCloseAccount}
              leftIcon={<AlertTriangleIcon />}
            />
          </HStack>
          <LargeGap />
        </Wrapper>
      </ContentGrid>
    </ContentGridInnerContainer>
  );
};
