import * as React from 'react';
import { observer } from 'mobx-react';
// import { AlertDialog } from 'naan/primitives/modals';
import * as Dialog from 'naan/primitives/modals/dialog';
import { styled } from 'naan/stitches.config';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  '& .title': {
    textAlign: 'center',
    textStyle: 'body-bold',
    marginBottom: '24px',
  },

  '& .email': {
    textStyle: 'small-heading',
    textAlign: 'center',
    marginTop: '8px',
    marginBottom: '16px',
  },
});

export const ConfirmEmailDialog = observer(
  ({ onOk = () => {}, onCancel = () => {}, email = null }) => {
    return (
      <Dialog.Container open={true} onDismiss={onCancel}>
        <Dialog.Heading>
          {__('Check that this email is correct:', 'auth.checkThatThisEmail')}
        </Dialog.Heading>
        <Dialog.Body>
          <Wrapper>
            <div className="email">{email}</div>
          </Wrapper>
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            presentation={'cancel'}
            label={'Go back'}
            data-test-id="cancelButton"
            onClick={onCancel}
          />
          <Dialog.Button
            presentation={'teal'}
            label={'Confirm'}
            data-test-id="okButton"
            onClick={onOk}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
    // return (
    //   <AlertDialog
    //     open
    //     body={
    //       <Wrapper>
    //         <div className="title">
    //           {__(
    //             'Check that this email is correct:',
    //             'auth.checkThatThisEmail'
    //           )}
    //         </div>
    //         <div className="email">{email}</div>
    //       </Wrapper>
    //     }
    //     okButtonLabel={__('Confirm', 'auth.confirm')}
    //     okButtonAction={onOk}
    //     cancelButtonLabel={__('Go back', 'auth.goBack')}
    //     cancelButtonAction={onCancel}
    //   />
    // );
  }
);
