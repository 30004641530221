import ReactGA from 'react-ga4';

import { getConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { AnalyticsAdapter } from '../analytics-adapter';

const log = createLogger('analytics:ga');

// const format = eventName => {
//   const prefix = env('analytics.eventPrefix');
//   return `${prefix}:${eventName}`;
// };

const gaKey = getConfig('analytics.googleAnalyticsKey', null);

export class GoogleAnalytics extends AnalyticsAdapter {
  isEnabled = gaKey !== null;

  private initialized: boolean = false;

  identify(userId: string) {
    log.info('Identifying with GoogleAnalytics as', { userId });
    ReactGA.initialize(gaKey, { userId } as any);
    this.initialized = true;
  }

  track() {}

  page(pageName: string, data?: any) {
    if (this.initialized) {
      log.info('Call to GA.pageview', { pageName, data });
      ReactGA.pageview(pageName);
    }
  }
}
