import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import __ from 'core/lib/localization';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { /*Link,*/ MediumHeading } from 'naan/primitives/text';
import { ErrorIcon } from 'components/ui/icons/error-icon';
import { SectionBreak } from 'components/ds/common/spacers';
import { VSpacer } from 'naan/primitives/spacer';
import { formatDate } from 'utils/format-date';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { Box } from 'naan/primitives/box';
// import { useStripeCheckout } from 'common/stripe-context';
// import { launchCheckoutInterstitial } from '../account-utils/launch-checkout-interstitial';
import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
// import { ActionLink } from 'common/ui/action-link';

const TextButton = styled('button', {
  all: 'unset',
  color: '$colors$teal-500',
  textStyle: 'body',
  textDecoration: 'underline',
  cursor: 'pointer',
});

const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

// const capitalizeFirstLetter = (str: string) => {
//   if (typeof str === 'string') {
//     return str.charAt(0).toUpperCase() + str.slice(1);
//   }

//   return '';
// };

// const formatCardDate = (date: string) => {
//   if (date) {
//     const [month, year] = date.split(' / ');
//     return `${month.length > 1 ? month : '0' + month}/${year.slice(2)}`;
//   }
//   return '';
// };

const CancelAppleAutoRenewDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Cancel auto-renew', 'account.cancelAutorenew')}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            'Your subscription is managed by the Apple App Store. You can cancel from your device’s Settings.',
            'account.cancelAutorenew'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'common.goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onDismiss();
              setTimeout(() => {
                window.open('https://support.apple.com/en-us/HT202039');
              }, 100);
            }}
            label={__('Learn more', 'account.learnMore')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

const CancelAutoRenewDialog = observer(
  ({
    onOk,
    onDismiss,
    fullAccessUntil,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    fullAccessUntil: string;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Cancel auto-renew', 'account.cancelAutorenew')}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            "By canceling your subscription you won't receive any further charges, and your membership to Lupa will end on %{date}",
            'dialogs.cancelAutoRenew',
            {
              date: formatDate(fullAccessUntil),
            }
          )}{' '}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'common.goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Cancel subscription', 'account.cancelSubscription')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export const FullAutoRenew = observer(() => {
  const store = useRootStore();
  // const [changing, changingSwitch] = useSwitch(false);

  const { userManager } = store;
  const { accountData } = userManager ?? {};
  const { membershipState, hasApplePaidAccess /* autoRenew */ } = accountData;
  const isSuspended = membershipState === 'suspended';
  const { paymentData } = accountData;

  // const stripeCheckout = useStripeCheckout();
  // const updatePaymentMethod = React.useCallback(() => {
  //   const monthlyPlan = accountData.plans.find(
  //     (plan: any) => plan.slug === 'monthly'
  //   );
  //   monthlyPlan?.select().then(result => {
  //     if (result.interstitialMessageKey) {
  //       launchCheckoutInterstitial(result, () =>
  //         stripeCheckout(result.stripeSessionId)
  //       );
  //     } else {
  //       stripeCheckout(result.stripeSessionId);
  //     }
  //   });
  // }, [accountData.plans, stripeCheckout]);

  const gotoStripePortal = React.useCallback(() => {
    userManager
      .createStripePortalSession(null /*default returnUrl*/)
      .then(result => {
        // console.log(`stripe portal: ${JSON.stringify(result)}`);
        const { url } = result;
        window.location.href = url;
      });
  }, [userManager]);

  const handleAutoRenew = () => {
    if (hasApplePaidAccess) {
      AppFactory.dialogPresenter.present(onDismiss => (
        <CancelAppleAutoRenewDialog onDismiss={onDismiss} />
      ));
    } else {
      AppFactory.dialogPresenter.present(onDismiss => (
        <CancelAutoRenewDialog
          onOk={() => {
            onDismiss();
            userManager.cancelAutoRenew().then(result => {
              // console.log(result);
              if (result.message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message: result.message,
                });
              }
            });
            // setTimeout(() => {
            //   DialogService.closeDialog();
            // }, 100);
          }}
          onDismiss={onDismiss}
          fullAccessUntil={accountData?.fullAccessUntil}
        />
      ));
    }
  };

  if (membershipState === 'full-auto-renew' || isSuspended) {
    return (
      <>
        <MediumHeading>
          {__('My membership', 'membershipTable.myMembership')}
        </MediumHeading>
        <VSpacer size="4" />
        <Table>
          <tbody>
            <TableRow>
              <TableLabelCell>
                {__('Membership type', 'membershipTable.membershipType')}
              </TableLabelCell>
              <TableValueCell>
                {accountData.membershipDisplay}
                {/*hasApplePaidAccess && autoRenew ? null : (
                  <BlueLink onClick={prevent(changingSwitch.toggle)}>
                    {changing ? 'Hide' : 'Change'}
                  </BlueLink>
                )*/}
              </TableValueCell>
            </TableRow>
            {/*changing ? (
              <TableRow noBorder={true}>
                <td colSpan="2">
                  <div>
                    <PricingCards />
                  </div>
                </td>
              </TableRow>
            ) : null*/}

            <TableRow>
              <TableLabelCell>
                {__('Recurring billing', 'membershipTable.recurringBilling')}
              </TableLabelCell>
              <TableValueCell>
                <HStack justify={'space'}>
                  <VStack
                    css={{
                      maxWidth: '66%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {accountData.recurringBillingDisplay}
                  </VStack>
                  <Button
                    onClick={handleAutoRenew}
                    label={__('Turn off', 'membershipTable.turnOff')}
                    size={'small'}
                    presentation={'redLight'}
                    data-test-id="auto-renew-off"
                  />
                </HStack>
              </TableValueCell>
            </TableRow>

            <TableRow>
              <TableLabelCell>
                {__('Renews', 'membershipTable.renews')}
              </TableLabelCell>
              <TableValueCell>
                {formatDate(accountData.fullAccessUntil)}
              </TableValueCell>
            </TableRow>
            <TableRow>
              <TableLabelCell>
                {__('Renewal amount', 'membershipTable.renewalAmount')}
              </TableLabelCell>
              <TableValueCell>
                {paymentData && formatMoney(paymentData.autoRenewAmount)}
              </TableValueCell>
            </TableRow>
            <TableRow>
              <TableLabelCell>
                {__('Payment method', 'membershipTable.paymentMethod')}
              </TableLabelCell>
              {hasApplePaidAccess ? (
                <TableValueCell error={isSuspended}>
                  {isSuspended ? <ErrorIcon /> : null}
                  Apple subscription
                </TableValueCell>
              ) : (
                <TableValueCell error={isSuspended}>
                  <HStack justify={'space'}>
                    <Box
                      css={{
                        maxWidth: '66%',
                        overflow: 'hidden',
                      }}
                    >
                      {isSuspended ? <ErrorIcon /> : null}
                      {/* {paymentData &&
                        capitalizeFirstLetter(paymentData.cardBrand)}{' '}
                      ****
                      {paymentData && paymentData.cardLastFour},{' '}
                      {isSuspended ? 'expired' : 'expires'}
                      {paymentData && formatCardDate(paymentData.cardExp)}{' '} */}

                      {/* just show a place holder string since we can't trust that we always have the accurate card info details */}
                      <TextButton onClick={gotoStripePortal}>
                        {__('View / update', 'membershipTable.viewUpdate')}
                      </TextButton>

                      {/* the button didn't quite render as desired */}
                      {/* <Button
                        label={__(
                          'View / update',
                          'membershipTable.viewUpdate'
                        )}
                        onClick={gotoStripePortal}
                        testId="redeem-code"
                        presentation="tealTransparent"
                        css={{ textDecoration: 'underline' }}
                      /> */}
                    </Box>
                    {/* <Button
                      onClick={gotoStripePortal / *updatePaymentMethod* /}
                      label={__('Update', 'common.update')}
                      size={'small'}
                      presentation={'grayLight'}
                    /> */}
                    {/* <FormError error={serverError} /> */}
                  </HStack>
                </TableValueCell>
              )}
            </TableRow>
            {/* <TableRow>
              <TableLabelCell>
                Experimental Stripe customer portal
              </TableLabelCell>
              <TableValueCell>
                <Button
                  onClick={gotoStripePortal}
                  label={__('Go to Stripe Portal', 'common.gotoStripePortal')}
                  size={'small'}
                  presentation={'grayLight'}
                />
              </TableValueCell>
            </TableRow> */}
          </tbody>
        </Table>
        <SectionBreak />
      </>
    );
  }

  return null;
});
