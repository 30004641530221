import { getConfig } from 'app/env';
import * as NotificationService from 'app/notification-service';

export const globalErrorHandler = (event: any) => {
  if (getConfig('showUnhandledErrors')) {
    const message = event?.originalError?.message ?? 'Unhandled error';
    NotificationService.alertError(message, {
      report: false, // do not re-send to bugsnag
    });
  }
};
