// DELETEME: this file is obsolete and should be deleted after 6.2
import * as React from 'react';
import { VSpacer } from 'naan/primitives/spacer';
import { NavItem } from 'naan/primitives/nav';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { GiftIcon } from 'naan/icons/gift-icon';
import { EduIcon } from 'naan/icons/edu-icon';
import { ExternalLinks } from './external-links';

import __ from 'core/lib/localization';
import { VolumeModeLinks } from './volume-mode-links';
import { DevLinks } from './dev-links';

export const VisitorLinks = () => {
  return (
    <>
      <VolumeModeLinks />
      <NavItem
        to="/account"
        icon={<AvatarIcon />}
        label={__('My account', 'nav.myAccount')}
      />
      <NavItem
        to="/gift-coupons"
        icon={<GiftIcon />}
        label={__('Gift coupons', 'nav.giftCoupons')}
      />

      <VSpacer size={8} />
      <NavItem
        to="/schools"
        icon={<EduIcon />}
        label={__('Lupa for schools', 'nav.lupaForSchools')}
        size="small"
      />
      <ExternalLinks />
      <DevLinks />
    </>
  );
};
