// useful only in the spa
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { createLogger } from 'app/logger';

import { objectFromUrlQuery } from 'common/object-from-url-query';
import { AppFactory } from 'app/app-factory';
import { homePath } from 'components/nav/path-helpers';

const clearQuery = () => {
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

const useAfterQueryVar = (defaultValue: string): [string, () => void] => {
  let redirectAfter = defaultValue;

  const sskey = 'redirect-after-auth';
  const { after: afterFromQuery = null } =
    objectFromUrlQuery<{ after: string | null }>();
  clearQuery();

  if (afterFromQuery) {
    try {
      window.sessionStorage.setItem(sskey, afterFromQuery);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('User disabled localStorage');
    }
    redirectAfter = afterFromQuery;
  } else {
    try {
      const afterFromStorage = window.sessionStorage.getItem(sskey);
      if (afterFromStorage) {
        redirectAfter = afterFromStorage;
      }
    } catch (error) {}
  }

  const clear = () => {
    clearQuery();
    try {
      window.sessionStorage.removeItem(sskey);
    } catch (error) {}
  };

  return [redirectAfter, clear];
};

export const useAfterAuthRedirection = () => {
  const navigate = useNavigate();
  const { userManager } = AppFactory.root;

  const [redirectAfter, clearAfter] = useAfterQueryVar('');
  const log = createLogger('auth-redirection-hook');

  useEffect(() => {
    const { loggedInAndReady } = userManager;

    if (loggedInAndReady) {
      log.info('just logged in, will redirect to', redirectAfter);
      clearAfter();
      const path = redirectAfter ? redirectAfter : homePath();
      log.info(`resolved path: ${path}`);
      navigate(path);
    } else {
      log.info('Signed out, after auth, should redirect to', redirectAfter);
    }
    // we only care about the logged in status changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManager.loggedInAndReady]);

  return redirectAfter;
};
