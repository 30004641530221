import * as React from 'react';
import * as Dialog from 'naan/primitives/modals';
import { observer } from 'mobx-react';
import { Text } from 'naan/primitives/text';
import { Button } from 'naan/primitives/button';
import { LargeGap } from 'components/ds/common/spacers';
import { useTitle } from 'common/hooks/use-title';
import { HStack, VStack } from 'naan/primitives/stack';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';
import { useComboForm } from 'common/hooks/use-comboform';
import { passwordSchema } from 'core/lib/validation-rules';
import { AppFactory } from 'app/app-factory';
import { Label } from 'naan/primitives/forms/label';
import { PasswordInput } from 'naan/primitives/input';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { FormError } from 'naan/primitives/forms';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';
import { objectFromUrlQuery } from 'common/object-from-url-query';
import __ from 'core/lib/localization';

export const CreatePasswordSuccessDialog = ({
  onDismiss,
}: {
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.CloseButton />
      <Dialog.Heading type="success">Password set</Dialog.Heading>
      <Dialog.Body>
        <VStack>
          <VSpacer size={3} />
          {__('Your new password has been set', 'common.passwordSet')}
          <VSpacer size={1} />
          <Dialog.LinkContainer>
            <Link to={'/auth/sign-in'} onClick={onDismiss}>
              {__('Go to your account', 'common.goToYourAccount')}
            </Link>
          </Dialog.LinkContainer>
          <VSpacer size={3} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer direction="column">
        <Dialog.Button
          presentation={'teal'}
          label={'Close'}
          data-test-id="okButton"
          onClick={onDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

type FormInput = {
  password: string;
};

export const CreatePasswordScreen = observer(() => {
  useTitle('Create password');
  const { userManager } = AppFactory.root;

  React.useEffect(() => {
    userManager.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({ validationSchema: passwordSchema });

  const handleResetPassword = React.useCallback(
    ({ password }: FormInput) => {
      clearValidationErrors();
      const { resetPasswordToken } = objectFromUrlQuery() as any;

      userManager
        .resetPasswordByToken(resetPasswordToken, password)
        .then(() => {
          AppFactory.dialogPresenter.present(onDismiss => (
            <CreatePasswordSuccessDialog onDismiss={onDismiss} />
          ));
        }, handleValidationErrors);
    },
    [clearValidationErrors, handleValidationErrors, userManager]
  );

  return (
    <VStack className="forgot-password" css={{ minHeight: '75vh' }}>
      <VSpacer size={10} />
      <HStack>
        <HSpacer expand />
        <Text textStyle="large-heading--desktop">
          {__('Create a new password', 'common.createpassword')}
        </Text>
        <HSpacer expand />
      </HStack>
      <VSpacer size={4} />
      <form onSubmit={handleSubmit(handleResetPassword)} noValidate>
        <CatchAllFormErrors errors={omit(errors, ['email'])} />
        <Label>{__('New password', 'auth.newPassword')}</Label>
        <VSpacer size={2} />
        <PasswordInput
          name={'password'}
          data-test-id={'password'}
          autoComplete="off"
          {...register('password')}
          placeholder=""
        />
        <FormError error={errors['email']} data-test-id="email-error-message" />
        <VSpacer size={6} />
        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Create password', 'common.createPassword')}
            presentation="teal"
            size="large"
            fullWidth
          />
        </div>
      </form>
      <LargeGap />
    </VStack>
  );
});
