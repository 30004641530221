import * as React from 'react';
import { useRootStore } from 'mst';
import { observer } from 'mobx-react';
import { AlertDialog } from 'naan/primitives/modals';

import dancers from './dancers.svg';

import __ from 'core/lib/localization';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  // width: '360px',
  img: {
    display: 'block',
    width: '100%',
    marginTop: '8px',
    padding: '0',
    backgroundColor: 'white',
  },
  h3: {
    textStyle: 'medium-heading',
    color: '$gray-800',
    // color: ${getColor('gray800')};
    margin: '24px 0 16px 0',
  },
});

export const NewsletterDialog = observer(({ lupaMode = true }) => {
  const store = useRootStore();
  const {
    newsletterPromptNeeded,
    disableNewsletterPrompt,
    updateMailingListOptIn,
  } = store.userManager;

  const optin = () => {
    disableNewsletterPrompt();
    updateMailingListOptIn(true);
  };

  const optout = () => {
    disableNewsletterPrompt();
    updateMailingListOptIn(false);
  };

  if (!newsletterPromptNeeded) {
    return null;
  }

  return (
    <AlertDialog
      open
      body={
        <Wrapper>
          <figure>
            <img src={dancers} alt="" role="presentation" />
          </figure>
          <h3>{__('Stay in the loop', 'newsletter.title')}</h3>
          <div className="content">
            {lupaMode
              ? __(
                  'Subscribe to the Lupa newsletter and be in the know about new weekly episodes, app features, promotions, and more. (You can unsubscribe at any time.)',
                  'newsletter.description.lupa'
                )
              : __(
                  'Subscribe to the Jiveworld newsletter and be in the know about new content, app features, promotions, and more. (You can unsubscribe at any time.)',
                  'newsletter.description.bolero'
                )}
          </div>
        </Wrapper>
      }
      okButtonLabel={__('Yes please', 'common.yesPlease')}
      okButtonAction={optin}
      cancelButtonLabel={__('No thanks', 'common.noThanks')}
      cancelButtonAction={optout}
    />
  );
});
