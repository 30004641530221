import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';

const Wrapper = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  height: 'max(calc(100vh - 180px), 30vw)',
});

const InnerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: ' min(468px , calc(100% - 32px))',
  '& .icon-container': {
    color: '$gray-400',
  },
});

export type ZeroStateViewProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  children?: React.ReactNode;
};

export const ZeroStateView = ({
  icon,
  title,
  description,
  children,
}: ZeroStateViewProps) => {
  return (
    <Wrapper>
      <InnerContainer>
        <div className="icon-container">{icon}</div>
        <VSpacer size={4} />
        <Text textStyle={'small-heading'}>{title}</Text>
        <VSpacer size={4} />
        <Text color="textSecondary">{description}</Text>
        {children}
      </InnerContainer>
    </Wrapper>
  );
};
