import * as React from 'react';

export function HeadphonesIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4C7.65065 4 4 7.82222 4 12.697V13.5C4 12.6716 4.67157 12 5.5 12C6.32843 12 7 12.6716 7 13.5V20.5C7 21.3284 6.32843 22 5.5 22C4.67157 22 4 21.3284 4 20.5V19C2.89543 19 2 18.1046 2 17V12.697C2 6.86075 6.40822 2 12 2C17.5918 2 22 6.86075 22 12.697V17C22 18.1046 21.1046 19 20 19V20.5C20 21.3284 19.3284 22 18.5 22C17.6716 22 17 21.3284 17 20.5V13.5C17 12.6716 17.6716 12 18.5 12C19.3284 12 20 12.6716 20 13.5V12.697C20 7.82222 16.3493 4 12 4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
