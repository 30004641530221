import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HomeIcon } from 'naan/icons/home-icon';
import { FavoriteFilledIcon } from 'naan/icons/favorite-filled-icon';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { EduIcon } from 'naan/icons/edu-icon';
import {
  ExternalItemWrapper,
  LinkNavItem,
  NavArrow,
  NavBottomAnchor,
  NavContainer,
  NavItem,
  NavLogoWrapper,
  PopoverNavHeader,
  PopoverNavTrigger,
} from 'naan/primitives/navigation/responsive-navigation';
import { LupaLogo } from 'components/ui/lupa-logo';
import { MenuContent } from 'naan/primitives/menus/menu-content';
import { MenuItem } from 'naan/primitives/menus';
import { CouponIcon } from 'naan/icons/coupon-icon';
import { GiftIcon } from 'naan/icons/gift-icon';
import { SignOutIcon } from 'naan/icons/sign-out-icon';
import { ArrowTopRightIcon } from 'naan/icons/arrow-top-right-icon';
import { Text } from 'naan/primitives/text';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from 'mst';
import { useCouponDialog } from 'components/account/marketing-links/coupon-button';
import { Button } from 'naan/primitives/button';
import { Responsive } from 'naan/primitives/responsive';
import { Box } from 'naan/primitives/box';
import {
  classroomsPath,
  discoverPath,
  favoritesPath,
  learnHomePath,
} from './path-helpers';
import { LifebuoyIcon } from 'naan/icons/lifebuoy-icon';
import { getConfig } from 'app/env';
import __ from 'core/lib/localization';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';

type MenuItemProps = React.ComponentProps<typeof MenuItem>;

const ExternalMenuItem = ({
  label,
  leftIcon,
  action,
}: Pick<MenuItemProps, 'label' | 'leftIcon' | 'action'>) => {
  return (
    <MenuItem
      label={label}
      leftIcon={leftIcon}
      rightIcon={<ArrowTopRightIcon width={20} height={20} />}
      presentation="navItem"
      action={action}
    />
  );
};

export const TeachMenuContent = () => {
  const { userManager } = useRootStore();
  const { accountData, userData } = userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => {
      navigate(to);
    },
    [navigate]
  );

  const open = React.useCallback(
    (to: string) => () => {
      window.open(to);
    },
    []
  );

  const handleApplyCode = useCouponDialog();

  return (
    <MenuContent sideOffset={-20} css={{ minWidth: '300px', marginLeft: 8 }}>
      <PopoverNavHeader>
        <Text textStyle="small-heading">{accountData.name}</Text>
        <Text color="textSecondary" textStyle="small-text">
          {accountData.email}
        </Text>
      </PopoverNavHeader>
      <ExternalMenuItem
        label={'Manage my account'}
        leftIcon={<AvatarIcon />}
        action={goto('/app/profile/account')}
      />
      <ExternalMenuItem
        label={'Redeem a code'}
        leftIcon={<CouponIcon />}
        action={handleApplyCode}
      />
      <ExternalMenuItem
        label={'Gift coupons'}
        leftIcon={<GiftIcon />}
        action={goto('/app/profile/gift-coupons')}
      />
      <ExternalMenuItem
        label={'Support'}
        leftIcon={<LifebuoyIcon />}
        action={open(getConfig('website.urls.support'))}
      />

      <MenuItem
        label={'Sign out'}
        leftIcon={<SignOutIcon />}
        presentation="navItem"
        separatorAfter={false}
        action={() => {
          userManager.logout();
        }}
      />

      {userData.webModeEnabled ? (
        <Responsive
          renderDefault={() => (
            <Box css={{ padding: 16 }}>
              <Button
                label={__('Go to my Lupa', 'common.goToMyLupa')}
                onClick={() => navigate(learnHomePath())}
                rightIcon={<ArrowTopRightIcon width={20} height={20} />}
                presentation="grayLight"
              />
            </Box>
          )}
          renderLargeAndUp={() => null}
        />
      ) : null}

      <DropdownMenu.Arrow offset={16} asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};

export const TeachNavigation = () => {
  const {
    userManager: { accountData, webModeEnabled },
  } = useRootStore();

  const navigate = useNavigate();

  return (
    <NavContainer presentation={'classroom'}>
      <NavLogoWrapper>
        <LupaLogo classrooms />
      </NavLogoWrapper>
      {webModeEnabled ? (
        <Responsive
          renderLargeAndUp={() => (
            <ExternalItemWrapper>
              <Button
                label={__('Go to my Lupa', 'common.goToMyLupa')}
                onClick={() => navigate(learnHomePath())}
                rightIcon={<ArrowTopRightIcon width={20} height={20} />}
              />
            </ExternalItemWrapper>
          )}
        />
      ) : null}
      <LinkNavItem
        to={discoverPath()}
        beforeNavigate={clearSavedScrollPosition}
        icon={<HomeIcon />}
        label={'Discover'}
      />
      <LinkNavItem
        to={favoritesPath()}
        beforeNavigate={clearSavedScrollPosition}
        icon={<FavoriteFilledIcon />}
        label={'Favorites'}
      />
      <LinkNavItem
        to={classroomsPath()}
        beforeNavigate={clearSavedScrollPosition}
        icon={<EduIcon />}
        label={'My classes'}
      />
      <NavBottomAnchor>
        <DropdownMenu.Root>
          <PopoverNavTrigger>
            <NavItem
              icon={<AvatarIcon />}
              label={
                <>
                  <Box
                    css={{ display: 'none', '@large': { display: 'block' } }}
                  >
                    {accountData.name}
                  </Box>
                  <Box css={{ '@large': { display: 'none' } }}>
                    {__('Me', 'nav.me')}
                  </Box>
                </>
              }
            />
          </PopoverNavTrigger>
          <TeachMenuContent />
        </DropdownMenu.Root>
      </NavBottomAnchor>
    </NavContainer>
  );
};
