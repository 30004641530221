import * as React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
// import { useLayoutHeader } from '../layouts/global-layout';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { Button } from 'naan/primitives/button';
// import { ChapterActions } from './chapter-actions';
// import { PlayerMode } from 'soundbite/models/soundbite-model';

export const StoryDetailScreen = observer(() => {
  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);
  const studyPath = `study`;
  // useLayoutHeader(story?.title);

  const handleReset = () => {
    story.progress.resetStory();
  };

  if (!story) {
    return <>loading…</>;
  }

  return (
    <VStack>
      {story.durationDescription} - {story.studyProgressPercentage}% complete
      <br />
      <img src={story.bannerImageUrl} width={'400px'} alt={story.baseTitle} />
      {story.description}
      <VSpacer size={4} />
      <RouterLink to={studyPath}>Study</RouterLink>
      {story.units.map(unit => (
        <div key={unit.slug}>
          {story.multiUnit ? (
            <p>
              <br />[{unit.titleSuffix}] {unit.durationDescription}
              {unit.completed ? (
                <> (unit completed - should be collapsed by default)</>
              ) : null}
            </p>
          ) : null}
          <ul>
            {unit.chapters.map(chapter => {
              const text = `[${chapter.position}] ${chapter.title} - ${chapter.prettyDuration}`;
              if (chapter.playerDataUrl) {
                return (
                  <li key={chapter.position}>
                    {/* <NavLink
                      to={`/study/${unit.slug}/${chapter.position}?stage=${PlayerMode.STUDY}`}
                    > */}
                    {text}
                    {/* </NavLink>{' '} */}
                    {chapter.isCompleted ? '(completed)' : null}
                    {chapter.isLocked ? '(unvisited)' : null}
                    {/* {chapter.isCurrent || chapter.isFurthest ? (
                      <ChapterActions chapter={chapter} />
                    ) : null} */}
                  </li>
                );
              } else {
                return <li>{text}</li>;
              }
            })}
          </ul>
        </div>
      ))}
      <VSpacer size={4} />
      <Button label="Reset" onClick={handleReset} />
      <VSpacer size={8} />
      <br />
      Lupa release on: {story.releaseDate}
      <br />
      Originally broadcast on: {story.originalBroadcastDate}
      <br />
      <VSpacer size={4} />
      <h3>Voices</h3>
      {/* // note, this code assumes recently ingested with jw.next enabled, i.e. el coyote */}
      {story.voicesList.map(speaker => {
        return (
          <>
            <strong>
              {speaker.label}
              {speaker.accent ? ` (${speaker.accent})` : null}
            </strong>
            <br />
            {speaker.bio}
            <VSpacer size={2} />
          </>
        );
      })}
      <VSpacer size={4} />
      <h3>Credits</h3>
      todo
      <br />
    </VStack>
  );
});
