import React from 'react';
import __ from 'core/lib/localization';

import { useRootStore } from 'mst';

import styled from 'styled-components';

const Wrapper = styled.div`
  font: var(--body-font);
  text-align: center;
  color: var(--red);
  margin-top: 56px;
  padding: 8px 12px;
  border: 1px solid var(--pink);
  break-inside: avoid-page;

  .learn-more,
  .learn-more a {
    color: var(--text-secondary);
  }
`;

export const StoryGuideLicense = ({ learnMore = false }) => {
  const store = useRootStore();
  const { email } = store?.userManager?.accountData ?? {};
  return (
    <Wrapper>
      {__(
        'This guide is licensed to %{email} for use with the Lupa app in a classroom setting',
        'storyGuide.license',
        { email }
      )}
      {learnMore ? (
        <div className="learn-more">
          Learn about Lupa for Schools at{' '}
          <a href="https://www.lupa.app/schools">lupa.app/schools</a>
        </div>
      ) : null}
    </Wrapper>
  );
};
