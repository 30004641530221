import * as React from 'react';

import { MessageService } from 'common/notifications/message-service';
import { GlobalMessage } from 'naan/global-messages';
// import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { useGlobalMessages } from 'components/use-global-messages';

const Wrapper = styled('div', {
  variants: {
    withMessage: {
      true: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100%',
      },
    },
  },
});

const Inner = styled('div', {
  padding: '0 $space$4',
  '@medium': {
    padding: '0 $space$6',
  },
  variants: {
    withMessage: {
      true: {
        overflowY: 'auto',
      },
    },
  },
});

export const GlobalMessageWrapper: React.FC = observer(({ children }) => {
  const currentMessage = useGlobalMessages();

  return (
    <Wrapper withMessage={!!currentMessage}>
      {currentMessage ? (
        <GlobalMessage
          type={currentMessage.type}
          message={currentMessage}
          onDismiss={MessageService.clear}
        />
      ) : null}
      <Inner withMessage={!!currentMessage}>{children}</Inner>
    </Wrapper>
  );
});
