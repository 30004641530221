import React from 'react';
import { isFunction } from 'lodash';
import { useMediaQuery } from 'react-responsive';

import {
  TableWrapper,
  Row,
  Cell,
  HeaderCell,
  HeaderContainer,
  BodyContainer,
} from './primitives';
import { OverflowMenu } from '../menus/overflow-menu';
import { TableStyleWrapper } from './table-styles';

const TableHeaders = ({ cells, data, extraHeader = false }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  // we don't have headers in mobile tables
  if (!isDesktopOrLaptop) {
    return null;
  }

  return (
    <HeaderContainer>
      <Row>
        {cells
          .filter(cell => cell.show !== false)
          .map(({ headerLabel, headerTextAlign = 'left' }, index) => {
            let content;
            if (isFunction(headerLabel)) {
              content = headerLabel(data);
            } else {
              content = headerLabel;
            }
            return (
              <HeaderCell
                key={`headcell-${index}`}
                className={`text-align-${headerTextAlign}`}
              >
                {content}
              </HeaderCell>
            );
          })}
        {extraHeader ? <HeaderCell /> : null}
      </Row>
    </HeaderContainer>
  );
};

const LoadingTable = () => {
  return (
    <>
      <HeaderContainer>
        <Row>
          <HeaderCell>&nbsp;</HeaderCell>
        </Row>
      </HeaderContainer>
      <BodyContainer>
        <Row className="loading">
          <Cell>&nbsp;</Cell>
        </Row>
        <Row className="loading">
          <Cell>&nbsp;</Cell>
        </Row>
        <Row className="loading">
          <Cell>&nbsp;</Cell>
        </Row>
      </BodyContainer>
    </>
  );
};

const TableRows = ({ cells, data, overflowMenuItems }) => {
  return (
    <BodyContainer>
      {data.map((row, index) => {
        return (
          <TableRow
            key={`row-${index}`}
            {...{ row, cells, overflowMenuItems }}
          />
        );
      })}
    </BodyContainer>
  );
};

const TableRow = ({ row, cells, overflowMenuItems }) => {
  return (
    <Row>
      {cells
        .filter(cell => cell.show !== false)
        .map((cell = {}, index) => {
          const { component: CellComponent, renderCell, fieldName } = cell;

          let content;

          // we pass the cell too, so mobile tables that render headers have access to the configuration
          if (CellComponent) {
            content = <CellComponent cell={cell} {...row} />;
          } else if (isFunction(renderCell)) {
            content = renderCell(row, cell);
          } else {
            content = row[fieldName];
          }
          return <Cell key={`cell-${fieldName}-${index}`}>{content}</Cell>;
        })}
      <OverflowMenuCell {...{ row, overflowMenuItems }} />
    </Row>
  );
};

const OverflowMenuCell = ({ row, overflowMenuItems }) => {
  const items = isFunction(overflowMenuItems)
    ? overflowMenuItems(row)
    : overflowMenuItems;
  if (items) {
    return (
      <Cell className={'overflow-menu-cell'}>
        <OverflowMenu items={items} menuPosition="top-right" />
      </Cell>
    );
  }

  return null;
};

export const Table = ({
  cells = [],
  data = [],
  overflowMenuItems = null,
  loading = false,
}) => {
  return (
    <TableStyleWrapper>
      <TableWrapper>
        {loading ? (
          <LoadingTable />
        ) : (
          <>
            <TableHeaders
              {...{ cells, data, extraHeader: overflowMenuItems !== null }}
            />
            <TableRows {...{ cells, data, overflowMenuItems }} />
          </>
        )}
      </TableWrapper>
    </TableStyleWrapper>
  );
};
