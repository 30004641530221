import { alertLevels } from './alert-levels';
// import { prefix } from './prefix';
import { GenericError } from './generic-error';

const { ERROR } = alertLevels;

export class ValidationError extends GenericError {
  /**
   * @param {string} key - the key of the validation error
   */
  key?: string;

  /**
   * @param {string} code - the internal code of the validation error
   */
  code?: string;

  constructor(error: { message: string; key?: string; code?: string }) {
    // @armando, was there a specific reason you added the prefix here?
    // it was being displayed (and luckily breaking the cypress tests)
    // super(prefix('Validation error', error.message ?? ''));
    super(error.message);

    this.context = { error };
    this.key = error.key;
    this.code = error.code;
    this.expected = true;
    this.report = false;
    // expected to be usually by the safelyHandleError logic
    // but if this does reach the gobal-error-handler it should generally be
    // end-user visible
    this.alertLevel = ERROR;
  }
}
