import * as React from 'react';
import { CouponExtraLargeIcon } from 'naan/icons/coupon-icon';
import { DownloadExtraLargeIcon } from 'naan/icons/download-icon';
import { EduExtraLargeIcon } from 'naan/icons/edu-icon';
import { LifebuoyIcon } from 'naan/icons/lifebuoy-icon';
import { styled } from 'naan/stitches.config';
import { getConfig } from 'app/env';
import { useCouponDialog } from './marketing-links/coupon-button';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import __ from 'core/lib/localization';

const LinkWrapper = styled('a', {
  $$iconColor: '$colors$teal-500',
  display: 'flex',
  alignItems: 'center',
  background: '$colors$gray-25',
  padding: '16px 20px',
  borderRadius: '12px',
  cursor: 'pointer',
  transition: 'background .15s',
  textDecoration: 'none',

  '&:hover': {
    background: '$colors$gray-50',
  },

  '& > .icon': {
    marginRight: 20,
    color: '$$iconColor',
    '& > svg': {
      width: 40,
      height: 40,
    },
  },

  '& > .label': {
    color: '$textSecondary',
  },

  '@small': {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 24px',
    marginBottom: '0',
    '& > .icon': {
      margin: '0 0 8px 0',
    },
  },
});

const QuickLink = ({
  label,
  icon,
  ...props
}: {
  label: string;
  icon: React.ReactNode;
} & React.ComponentProps<typeof LinkWrapper>) => {
  return (
    <LinkWrapper {...props}>
      <span className="icon">{icon}</span>
      <span className="label">{label}</span>
    </LinkWrapper>
  );
};

const Wrapper = styled('div', {
  display: 'grid',
  gap: '8px',
  '@small': {
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    gap: '16px',
  },
});

export const QuickLinks = () => {
  const navigate = useNavigate();
  const handleApplyCode = useCouponDialog();
  const { classroomEnabled, webModeEnabled } = AppFactory.root.userManager;

  return (
    <Wrapper>
      <QuickLink
        href="#"
        css={{ $$iconColor: '$colors$yellow-600' }}
        icon={<CouponExtraLargeIcon />}
        label={__('Have a code?', 'account.haveACode')}
        onClick={e => {
          e.preventDefault();
          handleApplyCode();
        }}
      />
      <QuickLink
        href={getConfig('website.urls.contactUs')}
        target="_blank"
        css={{ $$iconColor: '$colors$orange-500' }}
        icon={<LifebuoyIcon />}
        label={__('Help and support', 'account.helpAndSupport')}
      />
      {webModeEnabled ? null : (
        <QuickLink
          href={getConfig('website.urls.downloadApp')}
          target="_blank"
          icon={<DownloadExtraLargeIcon />}
          label={__('Download the app', 'account.downladTheApp')}
        />
      )}
      {classroomEnabled ? null : (
        <QuickLink
          href="#"
          css={{ $$iconColor: '$colors$gray-800' }}
          icon={<EduExtraLargeIcon />}
          label={__('Lupa for schools', 'account.lupaForSchools')}
          onClick={e => {
            e.preventDefault();
            navigate('/app/profile/schools');
          }}
        />
      )}
    </Wrapper>
  );
};
