import * as React from 'react';
import { isEmptyOrNA } from 'utils/string-utils';
import { VSpacer } from 'naan/primitives/spacer';
import { Text } from 'naan/primitives/text';
import { VStack } from 'naan/primitives/stack';
import { styled } from 'naan/stitches.config';
import { getConfig } from 'app/env';

const Footer = styled('footer', {
  all: 'unset',
  textStyle: 'small-text',
  color: '$colors$textSecondary',
  a: {
    color: '$colors$textSecondary',
  },
});

export const AudioPlayer = ({ audioUrl }: { audioUrl?: string }) => {
  if (isEmptyOrNA(audioUrl)) {
    return null;
  }

  return (
    <VStack>
      <VSpacer size={4} />
      <Text textStyle="small-heading">Audio preview (chapter 1)</Text>
      <VSpacer size={4} />
      <audio controls controlsList="nodownload" src={audioUrl}></audio>
      <VSpacer size={4} />
      <Footer>
        <a
          href={getConfig('website.urls.downloadApp')}
          target="_blank"
          rel="noreferrer"
        >
          Download the Lupa app
        </a>{' '}
        to study this story
      </Footer>
    </VStack>
  );
};
