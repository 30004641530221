import React from 'react';
import { getColor, includeTextStyles } from 'legacylib/theme/utils';
import { useMediaQuery } from 'react-responsive';

import styled from 'styled-components';

const VocabBadge = styled.div`
  .badge {
    ${includeTextStyles('body-bold')};
    width: 48px;
    padding: 2px 8px;
    border-radius: 14px;
    text-align: center;
    color: ${getColor('white')};
    background: ${getColor('green500')};
  }
  &.is-zero {
    opacity: 0.3;
  }

  @media screen and (max-width: 549px) {
    display: flex;
    flex-direction: row;
    span {
      margin-left: 8px;
      color: ${getColor('green500')};
    }
  }
`;

export const VocabCountCell = ({ vocabCount }: { vocabCount: number }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  return (
    <VocabBadge className={vocabCount === 0 ? 'is-zero' : ''}>
      <div className="badge">{vocabCount}</div>
      {isMobile ? <span>vocab</span> : null}
    </VocabBadge>
  );
};
