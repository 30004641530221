const oldPalette = {
  'darker-blue': '#45769F',
  'dark-blue': '#4C7FAA',
  'dark-teal': '#00ABC6',
  'darker-teal': '#0a91a6',
  teal: '#19BBD4',
  'bright-teal': '#40E6FF',
  'very-dark-green': '#42A745',
  'dark-green': '#45B248',
  'dark-soft-green': '#009B6F',
  green: '#44C548',
  'light-green': '#4ED152',
  'very-dark-yellow': '#DEB100',
  'dark-yellow': '#F6C790',
  yellow: '#FFDF5E',
  'light-yellow': '#FFEEAA',
  'very-light-yellow': '#FFF2CD',
  orange: '#F5832B',
  'light-orange': '#FFA000',
  red: '#FF6767',
  'dark-red': '#F43D3D',
  pink: '#FFADAD',
  'light-pink': '#FFE0E0',
  'black-hairline': 'rgba(0, 0, 0, 0.12)',
};

const palette = {
  transparent: 'transparent',
  textPrimary: '#383838',
  textSecondary: '#909090',
  white: '#ffffff',
  gray20: '#fafafa',
  gray25: '#F9F9F9',
  gray50: '#f2f2f2',
  gray100: '#e6e6e6',
  gray200: '#cccccc',
  gray300: '#b3b3b3',
  gray400: '#999999',
  gray500: '#808080',
  gray600: '#666666',
  gray700: '#4d4d4d',
  gray800: '#383838',
  gray900: '#292929',
  blue50: '#ebf2f7',
  blue100: '#d9e5f0',
  blue200: '#b3cce1',
  blue300: '#8cb2d2',
  blue400: '#6699c3',
  blue500: '#3e7bae',
  blue600: '#3971a0',
  blue700: '#346792',
  blue800: '#2d597e',
  blue900: '#264c6c',
  teal50: '#e8f8fb',
  teal100: '#d1f1f6',
  teal200: '#a3e4ee',
  teal300: '#75d6e5',
  teal400: '#47c9dd',
  teal500: '#19bbd4',
  teal600: '#17acc3',
  teal700: '#1496aa',
  teal800: '#128395',
  teal900: '#0f707f',
  green50: '#ecf9ec',
  green100: '#daf3da',
  green200: '#b4e8b6',
  green300: '#8fdc91',
  green400: '#69d16d',
  green500: '#44c548',
  green600: '#3fb642',
  green700: '#39a53c',
  green800: '#308a33',
  green900: '#29762b',
  yellow50: '#fff9e5',
  yellow100: '#fef3cc',
  yellow200: '#fde899',
  yellow300: '#fddc66',
  yellow400: '#f9d23b',
  yellow500: '#fbc500',
  yellow600: '#e7b600',
  yellow700: '#c69f08',
  yellow800: '#ad8c07',
  yellow900: '#947706',
  orange50: '#fef2e9',
  orange100: '#fde6d5',
  orange200: '#fbcdaa',
  orange300: '#f9b580',
  orange400: '#f79c55',
  orange500: '#f5832b',
  orange600: '#dd7627',
  orange700: '#c46922',
  orange800: '#ac5c1e',
  orange900: '#934f1a',
  red50: '#ffeded',
  red70: '#ffe5e5',
  red100: '#ffdddc',
  red200: '#ffbab9',
  red300: '#ff9a9a',
  red400: '#ff7878',
  red500: '#ff534f',
  red600: '#eb4c49',
  red700: '#cc4545',
  red800: '#b33c3c',
  red900: '#993434',
  opacity10White: 'rgba(255, 255, 255, 0.1)',
  opacity20White: 'rgba(255, 255, 255, 0.2)',
  opacity30White: 'rgba(255, 255, 255, 0.3)',
  opacity40White: 'rgba(255, 255, 255, 0.4)',
  opacity50White: 'rgba(255, 255, 255, 0.5)',
  opacity60White: 'rgba(255, 255, 255, 0.6)',
  opacity70White: 'rgba(255, 255, 255, 0.7)',
  opacity80White: 'rgba(255, 255, 255, 0.8)',
  opacity90White: 'rgba(255, 255, 255, 0.9)',
  opacity10Black: 'rgba(0, 0, 0, 0.1)',
  opacity20Black: 'rgba(0, 0, 0, 0.2)',
  opacity40Black: 'rgba(0, 0, 0, 0.4)',
  opacity30Black: 'rgba(0, 0, 0, 0.3)',
  opacity50Black: 'rgba(0, 0, 0, 0.5)',
  opacity60Black: 'rgba(0, 0, 0, 0.6)',
  opacity70Black: 'rgba(0, 0, 0, 0.7)',
  opacity80Black: 'rgba(0, 0, 0, 0.8)',
  opacity90Black: 'rgba(0, 0, 0, 0.9)',
  opacity90Gray800: 'rgba(56, 56, 56, 0.9)',
  opacity50Blue500: 'rgba(62, 123, 174, 0.5)',
  opacity50Red500: 'rgba(255, 83, 79, 0.5)',
  opacity50Teal500: 'rgba(25, 187, 212, 0.5)',
};

export const colors = {
  ...oldPalette,
  ...palette,

  inherit: 'inherit',
  /**
   * Tables
   * =====================================
   */
  'table-header-text-color': palette.textSecondary,
  'table-header-background-color': palette.gray20,
  'table-header-border-color': palette.gray100,
  'table-cell-alternate-background-color': palette.gray20,
  'table-cell-border-color': palette.gray100,

  /**
   * Headers
   * =====================================
   */
  'header-main-text-color': palette.gray800,
  'header-backlink-text-color': palette.textSecondary,
  'header-backlink-text-hover-color': palette.teal600,
  'header-backlink-icon-color': palette.gray400,
  'header-backlink-icon-hover-color': palette.teal600,
};
