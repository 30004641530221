import micromatch from './micromatch';
import { LogFunction, LogLevel, logLevels } from './logger';

export class LoggerConfig {
  private static _instance?: LoggerConfig;

  public disabled: string[] = [];
  // eslint-disable-next-line no-console
  public logFunction: LogFunction = console.log;
  public minimumLogLevel: LogLevel = logLevels.debug;
  public showLevelPrefix: boolean = true;

  private constructor() {
    if (LoggerConfig._instance) {
      throw new Error('Use Singleton.instance instead of new.');
    }
    LoggerConfig._instance = this;
  }

  static get instance() {
    return (
      LoggerConfig._instance ?? (LoggerConfig._instance = new LoggerConfig())
    );
  }

  public enable(namespace: string) {
    this.disabled = this.disabled.filter(n => n !== namespace);
  }

  public disable(namespace: string) {
    this.disabled.push(namespace);
  }

  public isDisabled(namespace: string) {
    /// is the namespace disabled?
    if (this.disabled.includes(namespace)) {
      return true;
    }

    /// let's check against the wildcards
    for (let i = 0; i < this.disabled.length; i++) {
      const pattern = this.disabled[i];
      if (micromatch(namespace, pattern)) {
        /// add the pattern to the disabled list
        /// so we don't have to check again
        this.disable(namespace);
        return true;
      }
    }

    return false;
  }
}
