import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Responsive } from 'naan/primitives/responsive';
import { isFunction } from 'lodash';
import { ArrowLeftIcon, ArrowLeftSmallIcon } from 'naan/icons/arrow-left-icon';
import type * as Stitches from '@stitches/react';

import { HSpacer } from 'naan/primitives/spacer';

export const PageHeadingContainer = styled('header', {
  gridColumn: '1/-1',
  py: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  background: '$white',
  '@medium': {
    py: '$space$6',
  },
  variants: {
    size: {
      small: {
        '@medium': {
          py: '$space$3',
        },
      },
      large: {
        '@medium': {
          py: '$space$24',
        },
      },
    },
  },
});

const BackButtonWrapper = styled('button', {
  all: 'unset',
  marginRight: 'auto',
  textStyle: 'small-heading',
  display: 'flex',
  alignItems: 'center',
  color: '$textPrimary',
});

const PageTitleWrapper = styled('h2', {
  all: 'unset',
  marginRight: 'auto',
  textStyle: 'large-heading--mobile',
  '@medium': {
    textStyle: 'large-heading--desktop',
  },
  color: '$textPrimary',
});

const AccessoryWrapper = styled('div', {
  // backgroundColor: '$teal-100',
  margin: 0,
  padding: 0,
});

type THeaderVariants = Stitches.VariantProps<typeof PageHeadingContainer>;

type PageHeadingProps = {
  title?: React.ReactNode;
  showBackArrow?: boolean;
  backTitle?: React.ReactNode;
  backAction?: () => void;
  renderAccessory?: () => React.ReactNode;
  showAccessoryInSmall?: boolean;
  size?: THeaderVariants['size'];
};

export const BackButton = ({
  label,
  action,
}: {
  showBackArrow?: boolean;
  label?: React.ReactNode;
  action?: () => void;
}) => {
  return (
    <BackButtonWrapper onClick={action}>
      <Responsive
        renderDefault={() => (
          <>
            <ArrowLeftIcon />
          </>
        )}
        renderLargeAndUp={() => (
          <>
            <ArrowLeftSmallIcon />
            <HSpacer size={2} />
            <span>{label}</span>
          </>
        )}
      />
    </BackButtonWrapper>
  );
};

export const PageTitle = ({ title }: { title: React.ReactNode }) => {
  return <PageTitleWrapper>{title}</PageTitleWrapper>;
};

export const PageHeading = ({
  title,
  showBackArrow = false,
  backTitle,
  backAction,
  renderAccessory,
  showAccessoryInSmall = false,
  size,
}: PageHeadingProps) => {
  return (
    <PageHeadingContainer size={size}>
      {showBackArrow ? (
        <BackButton label={backTitle} action={backAction} />
      ) : null}
      <PageTitle title={title} />
      {isFunction(renderAccessory) ? (
        <Responsive
          renderDefault={() =>
            showAccessoryInSmall ? (
              <AccessoryWrapper>{renderAccessory()}</AccessoryWrapper>
            ) : null
          }
          renderLargeAndUp={() => (
            <AccessoryWrapper>{renderAccessory()}</AccessoryWrapper>
          )}
        />
      ) : null}
    </PageHeadingContainer>
  );
};
