import Bugsnag from '@bugsnag/js';
import { getConfig } from 'app/env';
import { createLogger } from 'app/logger';
import minibus from './minibus';
import { setContextData as setAnalyticsContextData } from 'app/track';

const log = createLogger('error-reporting');

const enableBugsnag = getConfig('bugsnag.enabled');

export const setErrorReportingContext = (data: any) => {
  log.info('setting reporting context', data);
  setAnalyticsContextData(data);

  const { accountData, globalConfig } = data;
  Bugsnag.setUser(accountData.userId, accountData.email, accountData.name);
  Bugsnag.addMetadata('account', accountData);
  Bugsnag.addMetadata('globalConfig', globalConfig);
};

minibus.subscribe('SET_REPORTING_CONTEXT', setErrorReportingContext);

export const logglyTags = () => {};

/// I don't think this is used anymore
// export const debugLog = (/* message, data */) => {};

/// I don't think this is used anymore
// export const addBreadcrumb = config => {
//   const { message = null, ...meta } = config;
//   Bugsnag.addBreadcrumb(message, meta);
// };

const reportExceptionWithBugsnag = async (
  error: any,
  info: any = {},
  options = {}
) => {
  const {
    //handlerTag = null,
    level = 'error',
  }: any = options;

  if (!(error instanceof Error) && info.exception) {
    error = info.exception; // for backward compatibility
  }

  Bugsnag.notify(error, event => {
    event.severity = level;
    event.addMetadata('info', info);
  });
};

let __disabledNotice = false;
export const reportException = enableBugsnag
  ? reportExceptionWithBugsnag
  : () => {
      if (!__disabledNotice) {
        log.error('Bugsnag is disabled for this build.');
        __disabledNotice = true;
      }
    };

/// I don't think this is used anymore
// export const notify = (...args) => {
//   // eslint-disable-next-line no-console
//   console.log('notify is deprecated, use notifyException instead');
//   reportException(...args);
// };
