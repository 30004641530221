/// DEPRECATED: use dialog primitives to build your own adhoc dialogs
import * as React from 'react';
import { AlertDialog } from './alert-dialog';

type AlertProps = {
  open: boolean;
  title?: string;
  text: React.ReactNode;
  action: () => void;
};

export const Alert = ({ open, title, text, action, ...props }: AlertProps) => {
  return (
    <AlertDialog
      open={open}
      heading={title}
      body={text}
      okButtonAction={action}
      showCancelButton={false}
      {...props}
    />
  );
};
