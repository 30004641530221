import React from 'react';
import __ from 'core/lib/localization';
import styled from 'styled-components';
import { ChapterCatalogData, UnitCatalogData } from 'core/models/catalog';
import { PassageData } from 'core/models/catalog/bogota-types';

const Wrapper = styled.section`
  border-top: 1px solid var(--gray100);
  padding: 24px 0 0;
  margin-bottom: 80px;

  @media print {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
    page-break-before: always;
  }

  @media print, screen and (min-width: 900px) {
    display: flex;
  }

  .heading {
    font: var(--large-heading-font);
    margin-bottom: 56px;
    @media print, screen and (min-width: 900px) {
      flex: 4;
    }
  }

  .outline {
    margin-top: 8px;

    @media print, screen and (min-width: 700px) {
      column-count: 2;
      column-gap: 32px;
      flex: 8;
    }

    ul {
      break-inside: avoid-column;
    }

    ul,
    li {
      margin: 0 0 16px;
      padding: 0;
    }

    .chapter-title {
      font: var(--medium-heading-font);
      display: block;
      line-height: 28px;
      margin-bottom: 16px;
      padding-left: 32px;
      position: relative;
      .chapter-position {
        position: absolute;
        left: 0;
        top: 0;
        width: 32px;
      }
    }

    ul ul {
      margin-bottom: 56px;
      line-height: 24px;
      padding-left: 32px;
    }

    ul ul li {
      line-height: 24px;
    }
    ul ul li a {
      font: var(--body-font);
      color: var(--dark-blue);
    }
  }
`;

const goToFragment =
  (id: string, block: ScrollLogicalPosition = 'center') =>
  (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block });
    }
  };

const ChapterOutline = ({ chapter }: { chapter: ChapterCatalogData }) => {
  return (
    <ul>
      {(
        chapter.elements.filter(
          element => element.entityType === 'passage'
        ) as PassageData[]
      ).map((passage: PassageData) => {
        const id = chapter.position + '--' + passage.address.replace('.', '-');
        return (
          <li key={passage.address}>
            <a href={`#${id}`} onClick={goToFragment(id)}>
              {passage.hint}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export const StoryOutline = ({ story }: { story: UnitCatalogData }) => {
  return (
    <Wrapper>
      <h2 className="heading">
        {__('Story outline', 'studyGuide.storyOutline')}
      </h2>
      <div className="outline">
        {story.chapters.map((chapter: ChapterCatalogData) => {
          const id = `chapter-${chapter.position}`;

          return (
            <ul key={`outline-chapter-{${chapter.position}}`}>
              <li>
                <a
                  href={`#${id}`}
                  className="chapter-title"
                  onClick={goToFragment(id, 'nearest')}
                >
                  <span className="chapter-position">{chapter.position}</span>
                  {chapter.title}
                </a>
                <ChapterOutline chapter={chapter} />
              </li>
            </ul>
          );
        })}
      </div>
    </Wrapper>
  );
};
