//
// beware, this the in-progress jwnext fork of the discover screen view
//

import * as React from 'react';
import { Text } from 'naan/primitives/text';
import { observer } from 'mobx-react';
import { useFiltering } from 'common/filtering/use-filtering';
import { groupBy } from 'lodash';
import { styled } from 'naan/stitches.config';
import { VSpacer } from 'naan/primitives/spacer';
import { Divider } from 'naan/primitives/divider';
import { StoryList } from './story-list';
import { AppFactory } from 'app/app-factory';
import { LicenseLearnMoreDialog } from 'components/discover/filtered-units';

import __ from 'core/lib/localization';

const groups = {
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
};

function storyGroupBy(unit: any) {
  if (unit.trial === true) {
    return groups.FREE;
  }
  return groups.PREMIUM;
}

const Link = styled('button', {
  all: 'unset',
  color: '$teal-500',
  textStyle: 'small-text-bold',
  textDecoration: 'none',
  cursor: 'pointer',
});

const PremiumDescription = () => {
  const handleLearnMore = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <LicenseLearnMoreDialog onDismiss={onDismiss} />
    ));
  }, []);

  return (
    <>
      <Text textStyle="small-text" color="textSecondary">
        <i>
          Accessible to paid subscribers and students in a licensed Lupa
          Classroom. Wat.{' '}
          <Link onClick={handleLearnMore} data-test-id="learn-more">
            Learn more
          </Link>
        </i>
      </Text>
    </>
  );
};

export const FreeHeading = observer(() => {
  return (
    <>
      <VSpacer size={8} />
      <Text textStyle="medium-heading" data-test-id="free-stories-title">
        {__('Free stories', 'discover.freeStories')}
      </Text>
      <VSpacer size={4} />
      <Divider />
    </>
  );
});

export const PremiumHeading = observer(() => {
  return (
    <>
      <VSpacer size={8} />
      <Text textStyle="medium-heading" data-test-id="premium-stories-title">
        {__('Premium stories', 'discover.premiumStories')}
      </Text>
      <VSpacer size={1} />
      <PremiumDescription />
      <VSpacer size={4} />
      <Divider />
    </>
  );
});

export const FilteredStories = observer(() => {
  const { filteredUnits } = useFiltering();
  const grouped = React.useMemo(
    () => groupBy(filteredUnits, storyGroupBy),
    [filteredUnits]
  );

  const anyPremiumContent = grouped[groups.PREMIUM];

  return (
    <>
      {grouped[groups.FREE] ? (
        <>
          <FreeHeading />
          <StoryList stories={grouped[groups.FREE]} />
        </>
      ) : null}
      {anyPremiumContent ? <PremiumHeading /> : null}
      {grouped[groups.PREMIUM] ? (
        <StoryList stories={grouped[groups.PREMIUM]} />
      ) : null}
    </>
  );
});
