import React from 'react';
import { ZeroStateView } from 'components/ui/zero-state-view';

import __ from 'core/lib/localization';
import { CookieIcon } from 'naan/icons/cookie-icon';
import { Button } from 'naan/primitives/button';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';

export const CookiesRequired = () => {
  return (
    <ZeroStateView
      icon={<CookieIcon height={40} width={40} />}
      title={__('Cookies are required', 'common.cookiesAreRequired')}
      description={__(
        'Please enable cookies in your browser and try again.',
        'common.pleaseEnableCookies'
      )}
    >
      <VStack align={'center'}>
        <VSpacer size={5} />
        <Button
          label={__('Retry', 'common.retry')}
          onClick={() => {
            window.location.reload();
          }}
        />
      </VStack>
    </ZeroStateView>
  );
};
