import { Button } from 'naan/primitives/button';
import { styled } from 'naan/stitches.config';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import { reportException } from 'common/error-reporting';

const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'body',
  color: '$textSecondary',
  textAlign: 'center',

  h1: {
    color: '$red-500',
    textStyle: 'large-heading--desktop',
    textAlign: 'center',
    marginTop: '$10',
    marginBottom: '$10',
  },
  h2: {
    textStyle: 'medium-heading',
    color: '$textSecondary',
    marginBottom: '$2',
    textAlign: 'center',
  },
  section: {
    padding: '$2 0',
    textAlign: 'center',
  },
  button: {
    marginTop: '$8',
  },
});

export const FullScreenError = ({ message = 'Something went wrong' }) => {
  const reload = () => {
    (window as any).location = '/account';
  };
  return (
    <Wrapper>
      <div className="error-boundary__container">
        <h1>Oops</h1>
        <h2>{message}</h2>
        <section>The developers have been notified.</section>
        <Button onClick={reload} label="Reload application" />
      </div>
    </Wrapper>
  );
};

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    reportException(error, info);
  }

  public render() {
    if (this.state.hasError) {
      return <FullScreenError />;
    }
    return this.props.children;
  }
}

// export class ErrorBoundary extends React.Component {
//   state: { hasError: boolean };

//   constructor(props: any) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   componentDidCatch(error: any, info: any) {
//     // Display fallback UI
//     this.setState({ hasError: true });
//     // You can also log the error to an error reporting service
//     reportException(error, info);
//   }

//   render() {
//     if (this.state.hasError) {
//       return <FullScreenError />;
//     }
//     return this.props.children;
//   }
// }
