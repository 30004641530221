import React from 'react';
import { PricingCard } from '../account/pricing-card';
import { useStripeCheckout } from 'common/stripe-context';

import __ from 'core/lib/localization';

export const Ecoupon = ({ plan }) => {
  const {
    price,
    normalPrice,
    cardDescriptionText,
    discountPercentage,
    hasSpecialPricing,
    pricingDescription,
    cardFootnoteText,
  } = plan;

  const isDiscounted = discountPercentage > 0;

  const stripeCheckout = useStripeCheckout();
  const handleCheckout = React.useCallback(() => {
    plan.select().then(result => {
      stripeCheckout(result.stripeSessionId);
    });
  }, [plan, stripeCheckout]);

  let ribbonTitle = pricingDescription;

  if (hasSpecialPricing && isDiscounted) {
    ribbonTitle = __(
      '%{pricingDescription}: save %{discountPercentage}%',
      'plans.discountedRibbonTitle',
      {
        pricingDescription,
        discountPercentage,
      }
    );
  } else if (isDiscounted) {
    ribbonTitle = __(
      'Save %{discountPercentage}%',
      'plans.savePercentageRibbonTitle',
      {
        discountPercentage,
      }
    );
  }

  return (
    <PricingCard
      cta={__('Purchase', 'account.purchase')}
      discountPercentage={discountPercentage}
      featureTitle={__('All Lupa stories', 'plans.allLupaStories')}
      isDiscounted={discountPercentage > 0}
      normalPrice={normalPrice}
      price={price}
      ribbonTitle={ribbonTitle}
      showRibbon={hasSpecialPricing || isDiscounted}
      title={cardDescriptionText}
      titleAsterisk={true}
      type={'coupon'}
      onCtaClick={handleCheckout}
      footNote={cardFootnoteText}
      className={'e-coupon'}
    />
  );
};
