import React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
// import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import __ from 'core/lib/localization';
import { OldHeaderContainer } from 'components/header';
import { ContentGridInnerContainer } from 'components/global-layout';

// const LayoutWrapper = styled('section', {
//   gridColumn: '1/-1',
// });

export const DashboardScreen = observer(() => {
  useTitle(__('Dashboard', 'nav.dashboard'));

  return (
    <>
      <OldHeaderContainer>
        <ContentGridInnerContainer>
          <PageHeading title={__('Dashboard', 'nav.dashboard')} />
        </ContentGridInnerContainer>
      </OldHeaderContainer>
      TODO
    </>
  );
});
