import * as React from 'react';
import { observer } from 'mobx-react';
import { VSpacer } from 'naan/primitives/spacer';
import { Divider } from 'naan/primitives/divider';
import { VStack } from 'naan/primitives/stack';
import { Story } from 'core/models/story-manager';
import { StoryCard } from './story-card';

export const StoryList = observer(({ stories }: { stories: Story[] }) => {
  return (
    <>
      <VStack>
        <VSpacer size={4} />
        {stories?.map((story, index) => {
          return (
            <React.Fragment key={story.slug}>
              <StoryCard story={story} truncateDescription={true} />
              <VSpacer size={2} />
              {index < stories.length - 1 ? (
                <>
                  <Divider className="divider" />
                  <VSpacer size={4} />
                </>
              ) : null}
            </React.Fragment>
          );
        })}
      </VStack>
    </>
  );
});
