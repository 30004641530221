import React from 'react';
import { styled } from 'naan/stitches.config';
import { VSpacer } from 'naan/primitives/spacer';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  textAlign: 'center',
  '& a': {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export const AuthSmallPrint = () => {
  return (
    <Wrapper>
      By creating an account, you accept our:
      <br />
      <a
        href="https://www.jiveworld.com/terms-of-service"
        target="_blank"
        rel="noreferrer"
      >
        Terms&nbsp;of&nbsp;Service
      </a>
      {', '}
      <a
        href="https://www.jiveworld.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy&nbsp;Policy
      </a>
      {', '}
      <a href="https://www.jiveworld.com/eula" target="_blank" rel="noreferrer">
        User&nbsp;License&nbsp;Agreement
      </a>
      <VSpacer size={10} />
    </Wrapper>
  );
};
