import { styled } from 'naan/stitches.config';

export const AuthHeader = styled('header', {
  display: 'flex',
  justifyContent: 'center',
  margin: '40px 0 56px',
  position: 'relative',
  '@media screen and (min-width: 768px)': {
    margin: '64px 0 80px',
  },
});
