import styled from 'styled-components';
import { getColor } from '../../../legacylib/theme/utils';

export const BlueLink = styled.a`
  text-decoration: none;
  color: ${getColor('teal500')};
  background-image: linear-gradient(#19bbd4 50%, transparent 0);
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 1.12em;
  cursor: pointer;
  float: right;
  margin-left: 1rem;
  transition: opacity 0.3s, visibility 0.3s;
`;

export const RedLink = styled.a`
  text-decoration: none;
  color: ${getColor('red500')};
  background-image: linear-gradient(${getColor('red500')} 50%, transparent 0);
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 1.12em;
  cursor: pointer;
  float: right;
  margin-left: 1rem;
  transition: opacity 0.3s, visibility 0.3s;
`;
