// import deepmerge from 'deepmerge';
import { objectFromUrlQuery } from 'common/object-from-url-query';
import * as ln from 'core/lib/localization';

// import coreEn from 'core/langs/en.json';
// import coreEs from 'core/langs/es.json';
// import coreJa from 'core/langs/ja.json';
// import appEn from '../../langs/en.json';
// import appEs from '../../langs/es.json';
// import appJa from '../../langs/ja.json';

// const en = deepmerge(appEn, coreEn);
// const es = deepmerge(appEs, coreEs);
// const ja = deepmerge(appJa, coreJa);

// ln.addTranslations('en', en);
// ln.addTranslations('es', es);
// ln.addTranslations('ja', ja);

const { locale } = objectFromUrlQuery<{ locale: string | null }>();

/// just take the first part of the language string e.g. "en-US" -> "en"
const defaultLocale =
  navigator.language.toLowerCase().replace(/-.+$/, '') ?? 'en';

ln.setLocale(locale ?? defaultLocale);

(window as any).__l = ln;
