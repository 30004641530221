import styled from 'styled-components';
import { includeTextStyles } from '../../../legacylib/theme/utils';

export const SectionTitle = styled.h2`
  ${includeTextStyles('medium-heading')};
  margin-top: 0;
  margin-bottom: 16px;
  /* margin: 6rem 0 2rem;
  @media screen and (min-width: 768px) {
    margin: 10rem 0 2rem;
  } */
`;
