import * as React from 'react';
import { useTitle } from 'common/hooks/use-title';
import { observer } from 'mobx-react';
import { ContentGridInnerContainer } from 'components/global-layout';
import { PageHeading } from 'components/page-heading';
import { styled } from 'naan/stitches.config';
import {
  ClassroomOnboardingScreenshot,
  SchoolnameForm,
} from 'components/classrooms/onboarding';
import { Link, Text } from 'naan/primitives/text';
import { Navigate } from 'react-router-dom';
import { VSpacer } from 'naan/primitives/spacer';
import { ExternalLinkIcon } from 'naan/icons/external-link-icon';
import { getConfig } from 'app/env';
import { AppFactory } from 'app/app-factory';
import { CouponCodeFormWidget } from 'components/gift-coupons/coupon-code-form-widget';
import __ from 'core/lib/localization';

const MobileHeaderSpacer = styled('div', {
  height: '24px',
  '@large': {
    height: '0',
  },
});

export const SchoolCouponsForm = () => {
  return (
    <CouponCodeFormWidget
      title={'Joining a Lupa classroom?'}
      subtitle={'Enter the code your teacher gave you'}
      placeholder={__('Classroom code', 'common.classroomCode')}
      buttonLabel={__('Join', 'common.join')}
    />
  );
};

const CopyWrapper = styled('div', {
  // display: 'flex',
  // flexDirection: 'column-reverse',
  '.screenshot': {
    marginBottom: 16,
  },
  '@medium': {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    '.screenshot': {
      display: 'none',
    },
  },
});

const ScreenshotWrapper = styled('div', {
  display: 'none',
  '@medium': {
    display: 'block',
    gridColumn: '6/13',
  },
});

const CtaWrapper = styled('div', {
  '@medium': {
    gridColumn: '1/6',
  },
});

const OnboardingCopy = observer(() => {
  return (
    <CopyWrapper>
      <CtaWrapper>
        <Text textStyle="medium-heading">
          {__('Start teaching with Lupa', 'classrooms.startTeachingWithLupa')}
        </Text>
        <VSpacer size={4} />
        <div className="screenshot">
          <ClassroomOnboardingScreenshot />
        </div>

        <Text>
          Invite your students to your Lupa Classroom, create listening
          assignments, track their progress, and explore our activity guides and
          other teacher resources for selected stories. There are free resources
          to get started, with many more available with a paid classroom
          license.
        </Text>
        <VSpacer size={4} />
        <Link
          href={getConfig('website.urls.classroomMarketingPage')}
          target="_blank"
          css={{
            display: 'flex',
            alignItems: 'center',
            svg: { marginLeft: '$space$1' },
          }}
        >
          Learn more <ExternalLinkIcon width={12} height={12} />
        </Link>
        <VSpacer size={10} />
        <SchoolnameForm />
      </CtaWrapper>
      <ScreenshotWrapper>
        <ClassroomOnboardingScreenshot />
      </ScreenshotWrapper>
    </CopyWrapper>
  );
});

export const SchoolActivationScreen = observer(() => {
  useTitle('Lupa for Schools');

  const { classroomEnabled } = AppFactory.root.userManager;
  if (classroomEnabled) {
    // unexpected flow
    return <Navigate to="/app/teach" />;
  }

  return (
    <>
      <ContentGridInnerContainer>
        <PageHeading title={'Lupa for schools'} size="large" />
        <MobileHeaderSpacer />
        <SchoolCouponsForm />
        <VSpacer size={10} mediumSize={20} />
        <OnboardingCopy />
        <VSpacer size={24} />
      </ContentGridInnerContainer>
    </>
  );
});
