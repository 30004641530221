import React from 'react';
import { styled } from 'naan/stitches.config';
import { Message } from 'components/ui/message';

const Wrapper = styled('div', {
  width: 'min(720px, calc(100% - 32px))',
  margin: '$space$20 auto',
  a: {
    color: '$teal-500',
  },
});
export const AccountClosedScreen = () => {
  return (
    <Wrapper>
      <Message
        type={'success'}
        title="Your account has been closed"
        body={
          <p>
            Thank you for using Lupa. Please take a moment to{' '}
            <a href="mailto:feedback@jiveworld.com">share your feedback</a> –
            we'd love to know what we could improve.
          </p>
        }
      />
    </Wrapper>
  );
};
