import { GoogleAnalytics } from 'common/analytics/adapters/ga-analytics';
import { SegmentAnalytics } from 'common/analytics/adapters/segment-analytics';
import { SimulatedAnalytics } from 'common/analytics/adapters/sim-analytics';
import { AnalyticsManager } from 'common/analytics/analytics-manager';
import { DialogPresenter } from 'common/dialog-presenter';
import { MessageService } from 'common/notifications/message-service';
import { ToastService } from 'common/notifications/toast-service';
import { AppRoot } from './app-root';

export class AppFactory {
  private static _root: AppRoot;

  public static createRoot(): AppRoot {
    return AppRoot.create({});
  }

  public static get root(): AppRoot {
    if (!AppFactory._root) {
      AppFactory._root = AppFactory.createRoot();
    }
    return AppFactory._root;
  }

  private static _analyticsManager: AnalyticsManager;

  public static createAnalyticsManager(): AnalyticsManager {
    const result = new AnalyticsManager();
    result.addAdapter(new SimulatedAnalytics());
    result.addAdapter(new GoogleAnalytics());
    result.addAdapter(new SegmentAnalytics());
    return result;
  }

  public static get analyticsManager(): AnalyticsManager {
    if (!AppFactory._analyticsManager) {
      AppFactory._analyticsManager = AppFactory.createAnalyticsManager();
    }
    return AppFactory._analyticsManager;
  }

  public static messageService = MessageService;
  public static dialogPresenter = DialogPresenter;
  public static toastService = ToastService;
}

(window as any)._app_ = AppFactory;
