import React from 'react';
export const IconArrowLeft = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.02176 11L12.6854 4.72837L11.3146 3.27197L2.04092 12.0002L11.3146 20.7284L12.6854 19.272L6.02139 13H22V11H6.02176Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
