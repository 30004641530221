import { styled, theme } from 'naan/stitches.config';
const { colors } = theme;
export const Tag = styled('div', {
  textStyle: 'tag',
  borderRadius: '4px',
  backgroundColor: colors['gray-100'],
  border: `1px solid ${colors.transparent}`,
  py: '3px',
  px: '7px',
  variants: {
    type: {
      topic: {
        color: '$colors$gray-500',
        backgroundColor: '$colors$white',
        borderColor: '$colors$gray-100',
        $$hoverColor: '$colors$gray-500',
        $$hoverBackgroundColor: '$colors$gray-50',
        $$hoverBorderColor: '$colors$gray-100',
      },
      country: {
        color: '$colors$gray-500',
        backgroundColor: '$colors$gray-50',
        borderColor: '$colors$gray-50',
        $$hoverColor: '$colors$gray-500',
        $$hoverBackgroundColor: '$colors$gray-100',
        $$hoverBorderColor: '$colors$gray-100',
      },
      // ib: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['yellow-300'],
      //   color: colors['gray-500'],
      // },
      // ap: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['orange-300'],
      //   color: colors['gray-500'],
      // },
      // ib: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['gray-100'],
      //   color: colors['textSecondary'],
      //   pl: '34px',
      //   '&::before': {
      //     content: 'IB',
      //     color: colors['green-500'],
      //     textStyle: 'small-text-bold',
      //     width: '20px',
      //     marginLeft: '-34px',
      //     paddingLeft: '10px',
      //     paddingRight: '4px',
      //   },
      // },
      // ap: {
      //   backgroundColor: colors['transparent'],
      //   borderColor: colors['gray-100'],
      //   color: colors['textSecondary'],
      //   pl: '34px',
      //   '&::before': {
      //     content: 'AP',
      //     color: colors['blue-500'],
      //     textStyle: 'small-text-bold',
      //     width: '20px',
      //     marginLeft: '-34px',
      //     paddingLeft: '10px',
      //     paddingRight: '4px',
      //   },
      // },
    },
  },
});
