import React from 'react';
import { values, chunk } from 'lodash';
import cx from 'classnames';
import __ from 'core/lib/localization';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import { VocabLookup } from 'core/models/catalog/bogota-types';

const Wrapper = styled.div`
  .vocabs,
  .sics {
    --background-color: var(--green400);
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 20px 0;

    @media print, screen and (min-width: 700px) {
      display: flex;
    }

    h5 {
      font: var(--body-font);
      font-weight: 600;
      padding: 0 16px;
      margin-bottom: 16px;
      flex: 2;
      @media print, screen and (min-width: 700px) {
        margin-bottom: 0;
      }
    }

    ul {
      padding: 0 16px;
      flex: 5;
      &.single {
        flex: 10;
      }
    }

    li {
      margin: 0;
      padding: 0;
      line-height: 24px;
      div {
        display: inline;
      }
      div p {
        display: inline;
        font: var(--body-font);
        line-height: 24px;
      }
      .term-note p {
        color: var(--text-secondary);
        * {
          color: inherit;
        }
      }
    }
  }

  .sics {
    margin-top: 24px;
    --background-color: var(--lighter-orange);
  }
`;

const clean = (word: string) => {
  return word.replace(/[*+]/g, '');
};

export const Columns = ({
  vocabs,
  className = 'vocabs',
  id,
  title,
}: {
  vocabs: VocabLookup[];
  className?: string;
  id: string;
  title: string;
}) => {
  const items = values(vocabs);
  if (items.length === 0) {
    return null;
  }
  const columns =
    items.length > 1 ? chunk(items, Math.ceil(items.length / 2)) : [items];

  return (
    <div className={className} id={id}>
      <h5>{title}</h5>
      {columns.map(column => {
        return (
          <ul className={cx({ single: items.length === 1 })}>
            {column.map(item => {
              let termText = clean(item.text);
              if (item.head) {
                termText += ` (${item.head})`;
              }
              return (
                <li>
                  <Markdown className="term-text" source={termText} />
                  {item.note ? (
                    <>
                      &nbsp;
                      <Markdown
                        className="term-note"
                        source={__('– %{note}', 'storyGuide.vocabsNote', {
                          note: item.note,
                        })}
                      />
                    </>
                  ) : null}
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
};

export const Glossary = ({
  vocabs,
  sics,
  id,
}: {
  vocabs: VocabLookup[];
  sics: VocabLookup[];
  id: number;
}) => {
  return (
    <Wrapper>
      <Columns
        title={__('Vocab', 'common.vocab')}
        vocabs={vocabs}
        id={`${id}-vocab`}
      />
      <Columns
        title={__('Sic', 'story.sic')}
        vocabs={sics}
        className="sics"
        id={`${id}-sic`}
      />
    </Wrapper>
  );
};
