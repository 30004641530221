import * as React from 'react';

export function InfoCircleSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke={color} />
      <path
        d="M8.57853 14.1361C8.57853 13.7827 8.68325 13.233 8.76178 12.8403L9.42932 9.68585L8.5 9.59422L8.57853 9.09684L11.3665 8.25915L11.5759 8.44239L10.5942 12.9843C10.5288 13.2199 10.5026 13.4031 10.5026 13.5471C10.5026 13.7173 10.5942 13.8089 10.7251 13.8089C10.9476 13.8089 11.2356 13.5471 11.6675 12.9974L12.0602 13.2591C11.5367 14.1492 10.712 15 9.58639 15C8.9712 15 8.57853 14.6989 8.57853 14.1361ZM10.9477 7.04189C10.4504 7.04189 9.99224 6.68848 9.99224 6.11257C9.99224 5.45812 10.5289 5 11.1179 5C11.6676 5 12.0734 5.37958 12.0734 5.91623C12.0734 6.55759 11.5367 7.04189 10.9477 7.04189Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function InfoCircleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.3141 15.4084C10.2199 15.8796 10.0942 16.5393 10.0942 16.9633C10.0942 17.6387 10.5654 18 11.3037 18C12.6544 18 13.644 16.979 14.2722 15.911L13.801 15.5968C13.2827 16.2565 12.9372 16.5707 12.6702 16.5707C12.5131 16.5707 12.4031 16.4607 12.4031 16.2565C12.4031 16.0838 12.4346 15.8639 12.5131 15.5811L13.6911 10.1309L13.4398 9.91098L10.0942 10.9162L10 11.5131L11.1152 11.623L10.3141 15.4084ZM11.7906 7.33508C11.7906 8.02618 12.3404 8.45026 12.9372 8.45026C13.644 8.45026 14.288 7.86911 14.288 7.09948C14.288 6.4555 13.8011 6 13.1414 6C12.4346 6 11.7906 6.54974 11.7906 7.33508Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
