import { Passage } from './passage';
import { Paragraph } from './paragraph';
import { Sentence } from './sentence';
import { WordGroup } from './word-group';

export const typeMap = {
  passage: Passage,
  paragraph: Paragraph,
  sentence: Sentence,
  word_group: WordGroup,
} as any; // todo: tighten this up
