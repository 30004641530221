import * as React from 'react';
import { observer } from 'mobx-react';
import { ZeroStateView } from 'components/ui/zero-state-view';
import { colors } from 'naan/tokens/colors';
import { FavoriteIcon } from 'naan/icons/favorite-icon';

import __ from 'core/lib/localization';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { BrowseStoriesButton } from 'components/classrooms/browse-stories-button';

export const NoFavorites = observer(() => {
  return (
    <ZeroStateView
      icon={<FavoriteIcon height={40} width={40} color={colors['gray-400']} />}
      title={__('Your favorites list is empty', 'favorites.zeroStateTitle')}
      description={__(
        'Favorites are a handy way to keep track of stories you might want to teach with. Tap the heart icon on any story to add it to this list.',
        'favorites.zeroStateDescription'
      )}
    >
      <VStack align={'center'}>
        <VSpacer size={5} />
        <BrowseStoriesButton />
      </VStack>
    </ZeroStateView>
  );
});
