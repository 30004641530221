import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { getBaseRoot } from '../base-root';

/**
 * PurchasedCoupon
 *
 * list of gift coupons purchased by this account.
 * for display on the website
 */

export class PurchasedCoupon extends ModelTreeNode {
  static CLASS_NAME = 'PurchasedCoupon' as const;

  static create(snapshot: any) {
    return super.create(PurchasedCoupon, snapshot) as PurchasedCoupon;
  }

  code: string = '';
  redeemed: boolean = false;
  purchaseStatusMessage?: string;
  redemptionStatusMessage?: string;
  purchasedAt?: string; // 2020-01-31T23:10:05.756Z
  purchasedOn?: string; // 2020-01-31
  redeemedAt?: string; // 2019-12-13T00:29:37.808Z
  redeemedOn?: string; // 2019-12-12
  redeemedByName?: string;
  redeemedByEmail?: string;

  async resendInstructions() {
    const { userManager } = getBaseRoot(this);
    const result = await userManager.sendCouponInstructions(this.code);
    return result;
  }

  // JE: this action didn't make sense to me for this class
  /// AS: Is for parity with the plan class, so we can reuse the Card UI.
  // select() {
  //   const userManager = getRoot(self)?.userManager;
  //   // @armando, i think i've seen places where this 'if' check isn't sufficiently safe
  //   // and I needed a try/catch when not properly initialized.
  //   // did you add this check for specific reason or just out of general paranoia?
  //   if (userManager) {
  //     // @armando we should have a yield here and make this a generator function correct?
  //     userManager.initiateCheckout(self);
  //   }
  // },
}
