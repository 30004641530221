import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { getColor } from 'legacylib/theme/utils';
import { SpinnerIcon } from 'components/ui/icons/spinner-icon';
import { Modal } from 'components/ds/modals/modal';

const Container = styled.div`
  background: ${getColor('gray50')};
  border-radius: 8px;
  padding: 16px;
`;

export const FullScreenLoader = observer(() => {
  return (
    <Modal>
      <Container>
        <SpinnerIcon />
      </Container>
    </Modal>
  );
});
