import * as React from 'react';
import { Toast } from 'naan/toasts';
import { LegacySpaToastTarget } from 'components/ui/spa-toast-target';
import { styled } from 'naan/stitches.config';
import { HStack, VStack } from 'naan/primitives/stack';

import { NotificationService } from 'lib/services/notification-service';
import { Text } from 'naan/primitives/text';
import { Button } from 'naan/primitives/button';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';

export const pathname = '/toasts';
export const navlabel = 'Toasts';
export const naanReady = true;

const Wrapper = styled('div', {
  display: 'flex',
  padding: '20px',
});

function getSuccessMessage() {
  const messages = [
    'That was a success!',
    'You did it!',
    'That went well!',
    'Hurrah!',
    'Great!',
  ];

  return messages[Math.floor(Math.random() * messages.length)];
}

function getErrorMessage() {
  const messages = [
    'Oh no!',
    'Oh crap! Something went wrong!',
    'Thigs are bad!',
    'Something melted!',
    "It wasn't me!",
  ];

  return messages[Math.floor(Math.random() * messages.length)];
}

export const Screen = () => {
  return (
    <Wrapper>
      <VStack>
        <VStack gap="small">
          <Text textStyle="small-heading">Toast varieties</Text>
          <Toast type="success" label="Success message" />
          <Toast type="warning" label="Warning message" />
          <Toast type="error" label="Error message" />
          <Toast
            type="success"
            label="Success message with action"
            action={{
              label: 'Action',
              // eslint-disable-next-line no-alert
              callback: () => alert('Action clicked'),
            }}
          />
        </VStack>
        <VSpacer size={10} />
        <VStack>
          <Text textStyle="small-heading">Test toasts </Text>
          <HStack>
            <Button
              label="Show success toast"
              onClick={() => {
                NotificationService.open({
                  message: getSuccessMessage(),
                  type: 'success',
                });
              }}
            />
            <HSpacer size={3} />
            <Button
              label="Show error toast"
              onClick={() => {
                NotificationService.open({
                  message: getErrorMessage(),
                  type: 'error',
                });
              }}
            />
            <HSpacer size={3} />
            <Button
              label="Show success toast with undo"
              onClick={() => {
                NotificationService.open({
                  message: 'The thing is done',
                  type: 'success',
                  action: {
                    label: 'Undo',
                    callback: () => {
                      // eslint-disable-next-line no-alert
                      alert('Undid');
                      NotificationService.clear();
                    },
                  },
                });
              }}
            />
          </HStack>
        </VStack>
      </VStack>

      <LegacySpaToastTarget service={NotificationService} />
    </Wrapper>
  );
};
